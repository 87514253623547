import Loading from '@/app/component/dotpict/loading/Loading';
import useOfficialEventContainer from '@/app/component/page/officialevent/container/useOfficialEventContainer';
import OfficialEventPage from '@/app/component/page/officialevent/normalevent';

const OfficialEventContainerPage = () => {
  const state = useOfficialEventContainer();

  if (state.tag === '') return <Loading />;

  return <OfficialEventPage />;
};

export default OfficialEventContainerPage;
