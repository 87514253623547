import useOfficialEvent from '@/app/component/page/officialevent/normalevent/useOfficialEvent';
import OfficialEvent, {
  OfficialEventContext,
} from '@/app/component/page/officialevent/normalevent/OfficialEvent';

const OfficialEventPage = () => {
  const props = useOfficialEvent();

  return (
    <OfficialEventContext.Provider value={props}>
      <OfficialEvent />
    </OfficialEventContext.Provider>
  );
};

export default OfficialEventPage;
