import { useState, useCallback, useRef, useContext } from 'react';
import styled from '@emotion/styled';
import presetColorList from '@/app/component/page/draw/SideMenu/ToolSelector/EditColor/PresetColorList';
import ColorSelector from '@/app/component/page/draw/SideMenu/ColorSelector';
import { ToolsMap, ToolType } from '@/app/component/page/draw/Context/ToolContext';
import ToolButton from '@/app/component/page/draw/SideMenu/ToolSelector/ToolButton';
import { ClickAwayListener, Grow, MenuList, Paper, Popper } from '@material-ui/core';
import { Column, HorizontalGravity } from '@/app/component/base/layout/Layout';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import { ColorContext } from '@/app/component/page/draw/Context/ColorContext';

const MenuBody = styled.div`
  padding: 14px;
`;

const PresetColorList = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(16, 20px);
  grid-gap: 2px;
  padding: 8px;
  width: 357px;
  height: 252px;
  border: 2px solid #eee;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ColorSelectorContainer = styled.div`
  margin-bottom: -16px;
`;

const Kakusu = styled.div`
  position: relative;
  height: 24px;
  background: #fff;
`;

const PaletteColor = styled.div<{ color: string }>`
  background: ${({ color }) => `#${color}`};
  cursor: pointer;
`;

const ToolButtonWrapper = styled.div``;

type Props = {
  editPalette(index: number, color: string): void;
  selectTool: (selected: ToolType) => () => void;
  selectColor: (selectedIndex: number) => () => void;
};

const EditColor = ({ editPalette, selectTool, selectColor }: Props) => {
  const { palette, colorIndex } = useContext(ColorContext);
  const [openMenu, setOpenMenu] = useState(false);
  const menuAnchorRef = useRef(null);

  const selectTarget = useCallback(
    (index: number) => {
      return () => {
        selectColor(index)();
      };
    },
    [selectColor],
  );

  const handleEditPalette = useCallback(
    (color: string) => {
      return () => {
        editPalette(colorIndex, color);
      };
    },
    [colorIndex, editPalette],
  );

  const handleClickToolButton = useCallback(() => {
    setOpenMenu(true);
  }, []);

  const handleClosePopper = useCallback(() => {
    setOpenMenu(false);
  }, []);

  return (
    <div>
      <Popper
        open={openMenu}
        anchorEl={menuAnchorRef.current}
        role={undefined}
        transition
        placement="left"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClosePopper}>
                <MenuList autoFocusItem={openMenu} id="menu-list-grow">
                  <MenuBody>
                    <Column horizontalGravity={HorizontalGravity.end}>
                      <PixelArtComponent
                        src="https://storage.googleapis.com/dotpict-images/web/ic_close.svg"
                        alt="copy_color_code"
                        width={32}
                        height={32}
                        handleClick={() => handleClosePopper()}
                      />
                    </Column>
                    <Spacer height={24} />
                    <ColorSelectorContainer>
                      <ColorSelector
                        palette={palette}
                        vertical={false}
                        selectColor={selectTarget}
                        selectedColorIndex={colorIndex}
                      />
                    </ColorSelectorContainer>
                    <Kakusu />

                    <PresetColorList>
                      {presetColorList.map((color) => (
                        <PaletteColor
                          color={color}
                          onClick={handleEditPalette(color)}
                          key={color}
                        />
                      ))}
                    </PresetColorList>
                  </MenuBody>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <ToolButtonWrapper
        ref={menuAnchorRef}
        aria-controls={openMenu ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleClickToolButton}
      >
        <ToolButton toolType={ToolsMap.EditColor} selectTool={selectTool} />
      </ToolButtonWrapper>
    </div>
  );
};

export default EditColor;
