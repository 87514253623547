import { RefObject, useCallback, useContext, useState } from 'react';
import styled from '@emotion/styled';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import ButtonComponent from '@/app/component/ButtonComponent';
import Text from '@/app/component/Text';
import { SizeContext, SizeType } from '@/app/component/page/draw/Context/SizeContext';
import { ColorContext } from '@/app/component/page/draw/Context/ColorContext';
import PopupMenu from '@/app/component/page/draw/Header/PopupMenu';
import DownloadModal from '@/app/component/page/draw/DownloadModal';
import TitleAndSizeSelector from '@/app/component/page/draw/Header/TitleAndSizeSelector';
import { useNavigate } from 'react-router-dom';
import { useCurrentStorageKey } from '@/app/component/page/draw/hooks/storage';
import useTranslator from '@/app/hooks/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';
import { TitleContext } from '@/app/component/page/draw/Context/TitleContext';
import useAnalytics from '@/app/hooks/useAnalytics';

const HeaderContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  justify-items: center;
  grid-template-columns: auto 1fr auto auto;
  grid-gap: 16px;
  box-sizing: border-box;
  width: 100%;
  padding: 0 24px;
  height: 88px;
  background: ${DotpictColorStyle.WHITE};
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.1);
`;

const BackLink = styled.a`
  display: grid;
  grid-gap: 4px;
  grid-template-columns: auto auto;
  align-items: center;
`;

type Props = {
  canvasRef: RefObject<HTMLCanvasElement>;
  selectSize: (selected: SizeType) => () => void;
  editTitle(title: string): void;
};

const Header = ({ selectSize, editTitle, canvasRef }: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const canvasSize = useContext(SizeContext);
  const title = useContext(TitleContext);
  const { pixels } = useContext(ColorContext);
  const currentId = useCurrentStorageKey();
  const navigator = useNavigate();
  const translator = useTranslator();
  const analytics = useAnalytics();

  const handleClickDownload = useCallback(() => {
    setOpenModal((prevOpen) => !prevOpen);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleClickPostWork = () => {
    analytics('draw_post_clicked');
    navigator(`/upload?canvasId=${currentId}`);
  };

  return (
    <HeaderContainer>
      <BackLink href="/canvases">
        <PixelArtComponent
          alt="back"
          src="https://storage.googleapis.com/dotpict-images/web/ic_back.svg"
          width={16}
          height={16}
        />

        <Text
          text={translator(TranslationKeys.BackToCanvases)}
          textStyle={DotpictTextStyle.BOLD14}
          colorStyle={DotpictColorStyle.PRIMARY}
        />
      </BackLink>
      <TitleAndSizeSelector selectSize={selectSize} editTitle={editTitle} />
      <ButtonComponent
        text={translator(TranslationKeys.PostCanvas)}
        width={128}
        height={40}
        isFilled
        handleClick={handleClickPostWork}
      />
      <PopupMenu onDownload={handleClickDownload} />
      <DownloadModal
        open={openModal}
        onCloseModal={handleCloseModal}
        canvasRef={canvasRef}
        canvasSize={canvasSize}
        title={title}
        pixels={pixels}
      />
    </HeaderContainer>
  );
};

export default Header;
