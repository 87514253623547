export type BattleTeamType = {
  name: string;
  tag: string;
  point: number;
  colorCode: string;
};

const emptyTeam: BattleTeamType = {
  name: '',
  tag: '',
  point: 0,
  colorCode: '',
};

export default emptyTeam;
