import { usePaintCanvas } from '@/app/component/page/draw/hooks';
import { useCanvasesData } from '@/app/component/page/draw/hooks/storage';
import { useEffect, useState } from 'react';
import { OfficialEventInfoType } from '@/app/component/page/draw/hooks/types';

const useLoadCanvas = () => {
  const [canvasId, setCanvasId] = useState<number>(0);
  const [canvasFile, setCanvasFile] = useState<File | undefined>();
  const [colors, setColors] = useState<string>('');
  const [officialEventInfo, setOfficialEventInfo] = useState<OfficialEventInfoType | undefined>();
  const [canvasItems] = useCanvasesData();
  const [paint] = usePaintCanvas();

  const loadCanvasEvent = (canvasId: number) => {
    if (canvasId === 0) return;
    setCanvasId(canvasId);
  };

  const loadCanvas = async (canvasId: number) => {
    const canvasItem = canvasItems.find((canvasItem) => Number(canvasItem.id) === canvasId)!;
    const canvas = document.createElement('canvas');
    canvas.width = canvasItem.canvasSize.width;
    canvas.height = canvasItem.canvasSize.height;
    const context = canvas.getContext('2d')!;
    paint(context, 1, 1, canvasItem.pixels);

    const file = await new Promise<File>((resolve) => {
      canvas.toBlob((blob) => {
        resolve(new File([blob!], 'upload_image.png', { type: 'image/png' }));
      }, 'image/png');
    });

    setOfficialEventInfo(canvasItem.officialEventInfo);
    setColors(canvasItem.palette.join(','));
    setCanvasFile(file);
  };

  useEffect(() => {
    if (canvasItems.length === 0 || canvasId === 0) return;
    loadCanvas(canvasId);
  }, [canvasItems, canvasId]);

  return {
    canvasFile,
    colors,
    officialEventInfo,
    loadCanvas: loadCanvasEvent,
  };
};

export default useLoadCanvas;
