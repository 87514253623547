import { UserSummaryType } from '@/app/model/UserSummaryType';
import VerticalContainerStyle from '@/app/style/VerticalContainerStyle';
import UserComponent, { DummyUserComponent } from '@/app/component/UserComponent';
import Works from '@/app/component/Works';
import { css } from '@emotion/react';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import { Skeleton } from '@material-ui/lab';

const imageSize = 128;

const rootContainerStyle = css(VerticalContainerStyle, {
  padding: 16,
  background: DotpictColorStyle.WHITE,
});

const gridStyle = css({
  display: 'grid',
  justifyContent: 'center',
  gridGap: '4px',
  gridTemplateColumns: `repeat(auto-fill, ${imageSize}px)`,
});

interface IProps {
  userSummary: UserSummaryType;
}

const UserSummaryComponent = (props: IProps) => {
  const { userSummary } = props;

  return (
    <div css={rootContainerStyle}>
      <UserComponent user={userSummary.user} />
      {userSummary.works.length !== 0 && (
        <>
          <SquareSpaceComponent size={24} />
          <Works
            works={userSummary.works}
            isLoading={false}
            isVisibleEnd={false}
            isVisibleAds={false}
            isVisibleLike
            gridStyle={gridStyle}
            imageSize={imageSize}
            dummyItemCount={6}
          />
        </>
      )}
    </div>
  );
};

export const DummyUserSummaryComponent = () => (
  <div css={rootContainerStyle}>
    <DummyUserComponent />
    <SquareSpaceComponent size={24} />
    <div css={gridStyle}>
      {[...new Array(6)].map(() => (
        <Skeleton variant="rect" width={imageSize} height={imageSize} />
      ))}
    </div>
  </div>
);

export default UserSummaryComponent;
