import { useIntl } from 'react-intl';
import TranslationKeys from '@/app/translation/TranslationKeys';
import { useContext } from 'react';
import { APIContext, GlobalContext, MeContext } from '@/app/component/RootComponent';
import ButtonComponent from '@/app/component/ButtonComponent';
import { DotpictAction } from '@/app/reducer/reducer';

interface IProps {
  userId: number;
  isFollowed: boolean;
}

const FollowButtonComponent = (props: IProps) => {
  const { dispatch } = useContext(GlobalContext);
  const meState = useContext(MeContext);
  const { userId, isFollowed } = props;
  const intl = useIntl();
  const { client } = useContext(APIContext);

  const handleClick = () => {
    if (meState.isLoggedIn) {
      const request = async () => {
        const user = isFollowed
          ? await client.unfollow(meState.token, `${userId}`)
          : await client.follow(meState.token, `${userId}`);
        const action: DotpictAction = {
          type: 'UPDATE_FOLLOW_STATE',
          payload: { user },
        };
        dispatch(action);
      };
      request();
    } else {
      // eslint-disable-next-line no-alert
      window.alert('ログインが必要です');
    }
  };

  return (
    <ButtonComponent
      width={112}
      height={32}
      text={intl.formatMessage({
        id: isFollowed ? TranslationKeys.Followed : TranslationKeys.Follow,
      })}
      isBorder
      isFilled={isFollowed}
      handleClick={handleClick}
    />
  );
};

export default FollowButtonComponent;
