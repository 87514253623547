import { Dialog } from '@material-ui/core';
import { SizeContext, SizeType } from '@/app/component/page/draw/Context/SizeContext';
import { Column, HorizontalGravity } from '@/app/component/base/layout/Layout';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import Text from '@/app/component/Text';
import TranslationKeys from '@/app/translation/TranslationKeys';
import useTranslator from '@/app/hooks/useTranslator';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import CanvasSizeSelector from '@/app/component/page/draw/Header/CanvasSizeSelector';
import useNewCanvas from '@/app/component/page/canvases/NewCanvas/useNewCanvas';
import { PaletteType } from '@/app/model/PaletteType';
import Palette from '@/app/component/dotpict/palette/Palette';
import styled from '@emotion/styled';
import ButtonComponent from '@/app/component/ButtonComponent';
import Loading from '@/app/component/dotpict/loading/Loading';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const PalettesContainer = styled.div`
  overflow-y: scroll;
  height: 375px;
`;

const PaletteContainer = styled.div`
  position: relative;
  width: 375px;
  height: 96px;
`;

const PaletteItem = styled.div`
  position: absolute;
  width: 375px;
  height: 96px;
`;

const NewCanvasDialog = ({ open, setOpen }: Props) => {
  const translator = useTranslator();
  const {
    isLoadingPalette,
    palettes,
    selectedPaletteIndex,
    size,
    handleSelectCanvasSize,
    handleClickPalette,
    handleClickCreate,
  } = useNewCanvas(open);

  const renderPalettes = () => {
    if (isLoadingPalette && palettes.length === 0) {
      return <Loading />;
    }
    return (
      <PalettesContainer>
        <Column width={375} innerPadding={4} horizontalGravity={HorizontalGravity.center}>
          {palettes.map((palette: PaletteType, index: number) => (
            <PaletteContainer key={palette.id} onClick={() => handleClickPalette(index)}>
              <PaletteItem>
                <Palette palette={palette} />
              </PaletteItem>
              <PaletteItem>
                <ButtonComponent
                  width={375}
                  height={96}
                  text=""
                  isFilled={false}
                  isBorder={selectedPaletteIndex === index}
                />
              </PaletteItem>
            </PaletteContainer>
          ))}
        </Column>
      </PalettesContainer>
    );
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={() => setOpen(false)}>
      <Column background={DotpictColorStyle.WHITE} horizontalGravity={HorizontalGravity.center}>
        <Spacer height={20} />
        <Text
          text={translator(TranslationKeys.NewCanvas)}
          textStyle={DotpictTextStyle.BOLD20}
          colorStyle={DotpictColorStyle.BASE}
        />
        <Spacer height={36} />
        <Text
          text={translator(TranslationKeys.SelectSize)}
          textStyle={DotpictTextStyle.REGULAR16}
          colorStyle={DotpictColorStyle.BASE}
        />
        <Spacer height={16} />
        <SizeContext.Provider value={size}>
          <CanvasSizeSelector selectSize={(size: SizeType) => () => handleSelectCanvasSize(size)} />
        </SizeContext.Provider>
        <Spacer height={16} />
        <Text
          text={translator(TranslationKeys.SelectPalette)}
          textStyle={DotpictTextStyle.REGULAR16}
          colorStyle={DotpictColorStyle.BASE}
        />
        <Spacer height={16} />
        <Text
          text={translator(TranslationKeys.SelectPaletteDescription1)}
          textStyle={DotpictTextStyle.REGULAR12}
          colorStyle={DotpictColorStyle.BASE}
        />
        <Text
          text={translator(TranslationKeys.SelectPaletteDescription2)}
          textStyle={DotpictTextStyle.REGULAR12}
          colorStyle={DotpictColorStyle.BASE}
        />
        <Spacer height={16} />
        {renderPalettes()}
        <Spacer height={24} />
        <ButtonComponent
          width={240}
          height={48}
          text={translator(TranslationKeys.Create)}
          isFilled
          handleClick={handleClickCreate}
        />
        <Spacer height={24} />
      </Column>
    </Dialog>
  );
};

export default NewCanvasDialog;
