import React, { useContext, useEffect, useCallback, useRef } from 'react';
import styled from '@emotion/styled';
import { TitleContext } from '@/app/component/page/draw/Context/TitleContext';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';

const TextInput = styled.input`
  padding: 6px;
  color: ${DotpictColorStyle.BASE};
  outline: none;
  border: none;

  // ↓はTextコンポーネントから持ってきた。スタイル部分だけ分離したい。
  font-size: 1rem;
  font-weight: bold;
`;

type Props = {
  editTitle(title: string): void;
  onClose(): void;
};

const TitleEditor = ({ onClose, editTitle }: Props) => {
  const title = useContext(TitleContext);
  const ref = useRef<HTMLInputElement>(null);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (ref.current === null) {
        return;
      }

      if (e.key === 'Enter') {
        const rawTitle = ref.current.value;
        const newTitle = rawTitle.trim();

        // トリミングした結果空文字になっていたら更新しない
        if (newTitle !== '') {
          editTitle(newTitle);
        }
        onClose();
      }
    },
    [ref, editTitle, onClose],
  );

  // 自動選択
  useEffect(() => {
    if (ref.current !== null) {
      ref.current.select();
    }
  }, []);

  return <TextInput type="text" defaultValue={title} onKeyPress={handleKeyPress} ref={ref} />;
};

export default TitleEditor;
