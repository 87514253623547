import PixelArtComponent from '@/app/component/PixelArtComponent';
import { css } from '@emotion/react';
import Like from '@/app/component/Like';
import useTranslator from '@/app/hooks/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';
import { useNavigate } from 'react-router-dom';
import { defaultWorkDetailState } from '@/app/state/IWorkDetailState';
import { useContext } from 'react';
import { GlobalContext } from '@/app/component/RootComponent';
import { WorkType } from '@/app/model/WorkType';

export type WorkThumbnailProps = {
  work: WorkType;
  imageSize: number;
  isVisibleLike: boolean;
  isDeepLink?: boolean;
};

const WorkThumbnail = ({ work, imageSize, isVisibleLike, isDeepLink }: WorkThumbnailProps) => {
  const translator = useTranslator();
  const navigator = useNavigate();
  const { dispatch } = useContext(GlobalContext);

  const likeSize = Math.min(imageSize / 4, 48);
  const iconSize = Math.min(imageSize / 6, 48);

  const rootStyle = css({
    position: 'relative',
  });

  const likeStyle = css({
    position: 'absolute',
    top: imageSize - likeSize - 8,
    left: imageSize - likeSize - 8,
  });

  const animationIconStyle = css({
    position: 'absolute',
    top: 0,
    left: imageSize - iconSize,
  });

  const eventIconStyle = css({
    position: 'absolute',
    top: 0,
    left: 0,
  });

  const handleClickImage = () => {
    dispatch({
      type: 'UPDATE_WORK_DETAIL_STATE',
      payload: {
        workDetailState: {
          ...defaultWorkDetailState,
          work,
          shouldReload: true,
        },
      },
    });
    if (isDeepLink) {
      window.open(`dotpict://dotpict.net/works/${work.id}`);
    } else {
      navigator(`/works/${work.id}`);
    }
  };

  return (
    <div css={rootStyle}>
      <PixelArtComponent
        alt={translator(TranslationKeys.ThumbnailAlt)}
        src={work.imageUrl}
        width={imageSize}
        height={imageSize}
        handleClick={() => handleClickImage()}
      />
      {isVisibleLike && (
        <div css={likeStyle}>
          <Like workId={work.id} isLike={work.isLike} size={likeSize} />
        </div>
      )}
      {work.isAnimation && (
        <div css={animationIconStyle}>
          <PixelArtComponent
            alt="animation_icon"
            width={iconSize}
            height={iconSize}
            src="https://storage.googleapis.com/dotpict-images/web/ic_animation.svg"
          />
        </div>
      )}
      {work.userEventId > 0 && (
        <div css={eventIconStyle}>
          <PixelArtComponent
            alt="event_icon"
            width={iconSize}
            height={iconSize}
            src="https://storage.googleapis.com/dotpict-images/web/event/event_short.svg"
          />
        </div>
      )}
    </div>
  );
};

export default WorkThumbnail;
