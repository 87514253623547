import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import { Column } from '@/app/component/base/layout/Layout';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import Text from '@/app/component/Text';
import CanvasList from '@/app/component/page/canvases/CanvsList';
import useTranslator from '@/app/hooks/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';
import NewCanvasDialog from '@/app/component/page/canvases/NewCanvas';
import { useState } from 'react';
import DrawTrial from '@/app/component/dotpict/drawtrial/DrawTrial';

const CanvasesPageRoot = () => {
  const [openNewCanvas, setOpenNewCanvas] = useState(false);
  const translator = useTranslator();

  return (
    <>
      <Column>
        <Spacer height={32} />
        <DrawTrial />
        <Spacer height={32} />
        <Text
          text={translator(TranslationKeys.MyCanvases)}
          textStyle={DotpictTextStyle.BOLD20}
          colorStyle={DotpictColorStyle.BASE}
        />
        <Spacer height={32} />
        <CanvasList setOpenNewCanvas={setOpenNewCanvas} />
      </Column>
      <NewCanvasDialog open={openNewCanvas} setOpen={setOpenNewCanvas} />
    </>
  );
};
export default CanvasesPageRoot;
