import { createContext, useContext } from 'react';
import {
  Column,
  Grid,
  HorizontalGravity,
  Row,
  VerticalGravity,
} from '@/app/component/base/layout/Layout';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import { LanguageContext, LayoutContext } from '@/app/component/RootComponent';
import Works from '@/app/component/Works';
import { defaultDummyItemCount } from '@/app/common/Constant';
import { css } from '@emotion/react';
import Loading from '@/app/component/dotpict/loading/Loading';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import { Helmet } from 'react-helmet';
import Text from '@/app/component/Text';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import { OfficialEventResultStateType } from '@/app/state/OfficialEventResultStateType';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import styled from '@emotion/styled';
import { Box } from '@material-ui/core';
import { WorkType } from '@/app/model/WorkType';
import WorkThumbnail from '@/app/component/part/work/thumbnail/WorkThumbnail';

export const OfficialEventResultContext = createContext<OfficialEventResultStateType>(
  {} as OfficialEventResultStateType,
);

const Gradient1 = styled.div`
  background-image: url('https://storage.googleapis.com/dotpict-images/web/event/result/gradient_primary.png');
  background-repeat: repeat-x;
  width: inherit;
`;

const Gradient2 = styled.div`
  background-image: url('https://storage.googleapis.com/dotpict-images/web/event/result/gradient_deep_purple.png');
  background-repeat: repeat-x;
  width: inherit;
`;

const Gradient3 = styled.div`
  background-image: url('https://storage.googleapis.com/dotpict-images/web/event/result/gradient_light_purple.png');
  background-repeat: repeat-x;
  width: inherit;
`;

type PickUpType = {
  fromApp: boolean;
  work: WorkType;
  imageSize: number;
};

const PickUpImage = ({ fromApp, work, imageSize }: PickUpType) => {
  const containerStyle = css({
    position: 'relative',
    width: imageSize,
    height: imageSize,
  });

  const frameStyle = css({
    position: 'absolute',
    top: 0,
    left: 0,
    width: imageSize,
    height: imageSize,
  });

  const imageStyle = css({
    position: 'absolute',
    top: imageSize * (24 / 392),
    left: imageSize * (24 / 392),
  });

  return (
    <Column horizontalGravity={HorizontalGravity.center}>
      <Box css={containerStyle}>
        <img
          alt="frame"
          src="https://storage.googleapis.com/dotpict-images/web/event/result/light_frame.png"
          css={frameStyle}
        />
        <div css={imageStyle}>
          <WorkThumbnail
            work={work}
            imageSize={imageSize * (344 / 392)}
            isVisibleLike={false}
            isDeepLink={fromApp}
          />
        </div>
      </Box>
      <Text
        text={work.title}
        textStyle={DotpictTextStyle.BOLD12}
        colorStyle={DotpictColorStyle.WHITE}
      />
      <Spacer height={8} />
      <Row verticalGravity={VerticalGravity.center} innerPadding={8}>
        <PixelArtComponent src={work.thumbnailImageUrl} alt={work.title} width={24} height={24} />
        <Text
          text={work.user.name}
          textStyle={DotpictTextStyle.REGULAR12}
          colorStyle={DotpictColorStyle.WHITE}
        />
      </Row>
      <Spacer height={32} />
    </Column>
  );
};

const OfficialEventResult = () => {
  const {
    fromApp,
    teamOneWorks,
    teamTwoWorks,
    description,
    closingTexts,
    shareTitle,
    twitterShareUrl,
    facebookShareUrl,
    bannerImageUrl,
    works,
    isLoadingWorks,
    isVisibleEnd,
  } = useContext(OfficialEventResultContext);
  const { layoutParams } = useContext(LayoutContext);
  const { language } = useContext(LanguageContext);

  const imageSize = layoutParams.fullWidth < 192 * 3 + 16 ? (layoutParams.fullWidth - 16) / 3 : 192;

  const gridStyle = css({
    display: 'grid',
    justifyContent: 'center',
    gridGap: '8px',
    gridTemplateColumns: `repeat(auto-fill, ${imageSize}px)`,
  });

  const renderOgp = () => (
    <Helmet>
      <meta property="twitter:title" content="dotpict公式企画「天使VS悪魔」結果発表👼💥👿 " />
      <meta
        property="twitter:description"
        content="最終日まで大接戦が続く中、勝利したチームは…！？"
      />
      <meta
        property="twitter:image"
        content="https://storage.googleapis.com/dotpict-images/official_events/banner/Angel_vs_Devil_Result_ja.png"
      />
      <meta property="og:title" content="dotpict公式企画「天使VS悪魔」結果発表👼💥👿 " />
      <meta property="og:description" content="最終日まで大接戦が続く中、勝利したチームは…！？" />
      <meta
        property="og:image"
        content="https://storage.googleapis.com/dotpict-images/official_events/banner/Angel_vs_Devil_Result_ja.png"
      />
    </Helmet>
  );

  if (isLoadingWorks && works.length === 0) return <Loading />;

  return (
    <>
      {renderOgp()}
      <Column width={layoutParams.contentWidth} horizontalGravity={HorizontalGravity.center}>
        <Spacer height={8} />
        <PixelArtComponent
          alt="header_image"
          width={layoutParams.contentWidth}
          height={layoutParams.contentWidth / (1024 / 592)}
          src={bannerImageUrl}
        />
        <Column width={layoutParams.contentWidth} background="#744C79">
          <Column background={DotpictColorStyle.PRIMARY} padding="0px 24px 12px 24px">
            <Column
              background={DotpictColorStyle.WHITE}
              horizontalGravity={HorizontalGravity.center}
              padding={12}
            >
              <Text
                text={description}
                textStyle={DotpictTextStyle.BOLD12}
                colorStyle={DotpictColorStyle.BASE}
              />
              <Spacer height={12} />
              <Row verticalGravity={VerticalGravity.center}>
                <PixelArtComponent
                  src="https://storage.googleapis.com/dotpict-images/web/share_event.png"
                  alt="title"
                  width={89}
                  height={30}
                />
                <Spacer width={12} />
                <TwitterShareButton url={twitterShareUrl} title={shareTitle}>
                  <PixelArtComponent
                    src="https://storage.googleapis.com/dotpict-images/web/twitter.png"
                    alt="title"
                    width={44}
                    height={44}
                  />
                </TwitterShareButton>
                <Spacer width={12} />
                <FacebookShareButton url={facebookShareUrl} title={shareTitle}>
                  <PixelArtComponent
                    src="https://storage.googleapis.com/dotpict-images/web/facebook.png"
                    alt="title"
                    width={44}
                    height={44}
                  />
                </FacebookShareButton>
              </Row>
            </Column>
          </Column>
          <Column width="100%" horizontalGravity={HorizontalGravity.center} background="#371C3B">
            <Gradient1>
              <Column horizontalGravity={HorizontalGravity.center}>
                <PixelArtComponent
                  alt="result"
                  width={layoutParams.contentWidth / 2.3}
                  height={(layoutParams.contentWidth / 2.3) * (40 / 165)}
                  src={`https://storage.googleapis.com/dotpict-images/web/event/result/result_${language}.png`}
                />
                <PixelArtComponent
                  alt="bar"
                  width={layoutParams.contentWidth - 48}
                  height={(layoutParams.contentWidth - 48) * (30 / 343)}
                  src="https://storage.googleapis.com/dotpict-images/web/event/result/bar.png"
                />
              </Column>
              <Spacer height={48} />
              <Row width="100%" innerPadding={24} horizontalGravity={HorizontalGravity.center}>
                <PixelArtComponent
                  alt="teamOnePoint"
                  width={(layoutParams.contentWidth - 24 * 3) / 2}
                  height={((layoutParams.contentWidth - 24 * 3) / 2) * (95 / 170)}
                  src={`https://storage.googleapis.com/dotpict-images/web/event/result/angel_point_${language}.png`}
                />
                <PixelArtComponent
                  alt="teamOnePoint"
                  width={(layoutParams.contentWidth - 24 * 3) / 2}
                  height={((layoutParams.contentWidth - 24 * 3) / 2) * (95 / 170)}
                  src={`https://storage.googleapis.com/dotpict-images/web/event/result/devil_point_${language}.png`}
                />
              </Row>
            </Gradient1>
          </Column>
          <Column width="100%" horizontalGravity={HorizontalGravity.center} background="#4D2952">
            <Gradient2>
              <Spacer height={32} />
              <Column horizontalGravity={HorizontalGravity.center}>
                <PixelArtComponent
                  alt="pick_up"
                  width={201}
                  height={48}
                  src="https://storage.googleapis.com/dotpict-images/web/event/result/pick_up.png"
                />
              </Column>
              <Spacer height={24} />
              <Row width="100%" horizontalGravity={HorizontalGravity.center}>
                <PixelArtComponent
                  alt="angel"
                  width={layoutParams.contentWidth / 2}
                  height={(layoutParams.contentWidth / 2) * (44 / 161)}
                  src={`https://storage.googleapis.com/dotpict-images/web/event/result/angel_${language}.png`}
                />
                <PixelArtComponent
                  alt="devil"
                  width={layoutParams.contentWidth / 2}
                  height={(layoutParams.contentWidth / 2) * (44 / 161)}
                  src={`https://storage.googleapis.com/dotpict-images/web/event/result/devil_${language}.png`}
                />
              </Row>
            </Gradient2>
            <Row>
              <Grid>
                {teamOneWorks.map((work) => (
                  <PickUpImage
                    fromApp={fromApp}
                    work={work}
                    imageSize={layoutParams.contentWidth / 2}
                  />
                ))}
              </Grid>
              <Grid>
                {teamTwoWorks.map((work) => (
                  <PickUpImage
                    fromApp={fromApp}
                    work={work}
                    imageSize={layoutParams.contentWidth / 2}
                  />
                ))}
              </Grid>
            </Row>
            <Column horizontalGravity={HorizontalGravity.center}>
              <Spacer height={12} />
              {closingTexts.map((closingText) => (
                <Text
                  text={closingText}
                  textStyle={DotpictTextStyle.REGULAR16}
                  colorStyle={DotpictColorStyle.WHITE}
                />
              ))}
            </Column>
          </Column>
          <Gradient3>
            <Spacer height={62} />
            <Column horizontalGravity={HorizontalGravity.center}>
              <PixelArtComponent
                src="https://storage.googleapis.com/dotpict-images/web/everyone_pixelart_dark.png"
                alt="title"
                width={277}
                height={30}
              />
            </Column>
          </Gradient3>
          <Spacer height={8} />
          <Works
            works={works}
            isLoading={isLoadingWorks}
            isVisibleEnd={isVisibleEnd}
            isVisibleAds={false}
            isVisibleLike={false}
            isDeepLink={fromApp}
            gridStyle={gridStyle}
            imageSize={imageSize}
            dummyItemCount={defaultDummyItemCount}
          />
          <Spacer height={128} />
        </Column>
      </Column>
    </>
  );
};

export default OfficialEventResult;
