import { MouseEventHandler } from 'react';
import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import { css } from '@emotion/react';
import { Row } from '@/app/component/base/layout/Layout';

export type TagProps = {
  fixed: boolean;
  tagName: string;
  onClick?: MouseEventHandler;
};

const styledButton = css`
  background-color: transparent;
  border: none;
  overflow: hidden;
  outline: none;
`;

const Tag = ({ fixed, tagName, onClick }: TagProps) => (
  <Row>
    <Text
      text={tagName}
      textStyle={DotpictTextStyle.REGULAR14}
      colorStyle={DotpictColorStyle.PRIMARY}
    />
    {!fixed && (
      <button css={styledButton} type="button" onClick={onClick}>
        <PixelArtComponent
          alt="close"
          src="https://storage.googleapis.com/dotpict-images/web/ic_delete.svg"
          width={16}
          height={16}
        />
      </button>
    )}
  </Row>
);

export default Tag;
