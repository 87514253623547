import emptyTeam, { BattleTeamType } from '@/app/model/BattleTeamType';

export type BattleInfoType = {
  description: string;
  teamOne: BattleTeamType;
  teamTwo: BattleTeamType;
};

const defaultBattleInfo: BattleInfoType = {
  description: '',
  teamOne: emptyTeam,
  teamTwo: emptyTeam,
};

export default defaultBattleInfo;
