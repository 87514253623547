import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Column, HorizontalGravity } from '@/app/component/base/layout/Layout';
import TranslationKeys from '@/app/translation/TranslationKeys';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import useTranslator from '@/app/hooks/useTranslator';
import FileSelectButton from '@/app/component/dotpict/work/upload/fileselectbutton/FileSelectButton';

export type FileSelectorProps = {
  handleFileSelect: (file: File | undefined) => void;
};

const Border = styled.div`
  width: 100%;
  border: 2px dashed ${DotpictColorStyle.GRAY_DEEP};
`;

const description = css`
  white-space: pre-line;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
`;

const FileSelector = ({ handleFileSelect }: FileSelectorProps) => {
  const translator = useTranslator();

  return (
    <Border>
      <Column horizontalGravity={HorizontalGravity.center}>
        <Spacer height={40} />
        <PixelArtComponent
          src="https://storage.googleapis.com/dotpict-images/web/ic_file.svg"
          alt="file"
          width={36}
          height={36}
        />
        <Spacer height={16} />
        <FileSelectButton handleFileSelect={handleFileSelect} />
        <p css={description}>{translator(TranslationKeys.SizeLimit)}</p>
        <Spacer height={24} />
      </Column>
    </Border>
  );
};

export default FileSelector;
