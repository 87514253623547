import { useContext, useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { LanguageContext } from '@/app/component/RootComponent';
import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import HorizontalContainerStyle from '@/app/style/HorizontalContainerStyle';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import TranslationKeys from '@/app/translation/TranslationKeys';
import VerticalContainerStyle from '@/app/style/VerticalContainerStyle';
import ExternalLinkComponent from '@/app/component/ExternalLinkComponent';

const FooterComponent = () => {
  const intl = useIntl();
  const { language, setLanguage } = useContext(LanguageContext);
  const [renderHeader, setRenderHeader] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setRenderHeader(params.get('noHeader') !== 'true');
  }, [location]);

  const handleClickEnglish = () => {
    setLanguage('en');
  };

  const handleClickJapanese = () => {
    setLanguage('ja');
  };

  if (!renderHeader) {
    return null;
  }

  return (
    <div css={VerticalContainerStyle}>
      <div css={HorizontalContainerStyle}>
        <Button onClick={handleClickEnglish}>
          <Text
            text="English"
            textStyle={DotpictTextStyle.REGULAR13}
            colorStyle={language === 'en' ? DotpictColorStyle.PRIMARY : DotpictColorStyle.BASE}
          />
        </Button>
        <Button onClick={handleClickJapanese}>
          <Text
            text="日本語"
            textStyle={DotpictTextStyle.REGULAR13}
            colorStyle={language === 'ja' ? DotpictColorStyle.PRIMARY : DotpictColorStyle.BASE}
          />
        </Button>
      </div>
      <SquareSpaceComponent size={4} />
      <div css={HorizontalContainerStyle}>
        <SquareSpaceComponent size={8} />
        <Link to="/guidelines">
          <Text
            text={intl.formatMessage({ id: TranslationKeys.GuidelineTitle })}
            textStyle={DotpictTextStyle.REGULAR13}
            colorStyle={DotpictColorStyle.BASE}
          />
        </Link>
        <SquareSpaceComponent size={8} />
        <Link to="/privacy">
          <Text
            text={intl.formatMessage({ id: TranslationKeys.PrivacyTitle })}
            textStyle={DotpictTextStyle.REGULAR13}
            colorStyle={DotpictColorStyle.BASE}
          />
        </Link>
        <SquareSpaceComponent size={8} />
        <ExternalLinkComponent
          text={intl.formatMessage({ id: TranslationKeys.Company })}
          url="https://dotpict.com"
          textStyle={DotpictTextStyle.REGULAR13}
          colorStyle={DotpictColorStyle.BASE}
        />
      </div>
      <SquareSpaceComponent size={12} />
      <div css={HorizontalContainerStyle}>
        <SquareSpaceComponent size={8} />
        <Text
          text="@dotpict"
          textStyle={DotpictTextStyle.REGULAR13}
          colorStyle={DotpictColorStyle.BASE}
        />
      </div>
      <SquareSpaceComponent size={48} />
    </div>
  );
};

export default FooterComponent;
