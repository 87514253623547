import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import {
  Column,
  Grid,
  HorizontalGravity,
  Row,
  VerticalGravity,
} from '@/app/component/base/layout/Layout';
import DotpictLink from '@/app/component/DotpictLinkComponent';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import Like from '@/app/component/Like';

export type TimelineWorkBindModel = {
  workId: number;
  title: string;
  caption: string;
  imageUrl: string;
  tags: string[];
  isLike: boolean;
  userId: number;
  userName: string;
  profileImageUrl: string;
};

export type TimelineWorkProps = {
  bindModel: TimelineWorkBindModel;
  handleClick?: () => void;
};

const TimelineWork = ({ bindModel, handleClick }: TimelineWorkProps) => {
  const [imageSize, setImageSize] = useState<number>(0);

  useEffect(() => {
    setImageSize(
      document.documentElement.clientWidth > 512
        ? 512 - 24 * 2
        : document.documentElement.clientWidth - 24 * 2,
    );
  }, [document.documentElement.clientWidth]);

  const WorkContainer = styled.div`
    background: ${DotpictColorStyle.WHITE};
    width: ${imageSize};
    padding: 24px;
  `;

  return (
    <WorkContainer>
      <Column>
        <Column horizontalGravity={HorizontalGravity.center}>
          <PixelArtComponent
            alt={`${bindModel.workId}`}
            src={bindModel.imageUrl}
            width={imageSize}
            height={imageSize}
            handleClick={handleClick}
          />
        </Column>
        <Spacer height={24} />
        <Row horizontalGravity={HorizontalGravity.spaceBetween}>
          <Grid verticalGravity={VerticalGravity.center}>
            <Text
              text={bindModel.title}
              textStyle={DotpictTextStyle.BOLD16}
              colorStyle={DotpictColorStyle.BASE}
            />
            {bindModel.tags.map((tag) => (
              <React.Fragment key={tag}>
                <Spacer width={8} />
                <DotpictLink to={`/search/works/tag/${tag}`}>
                  <Text
                    text={`#${tag}`}
                    textStyle={DotpictTextStyle.BOLD16}
                    colorStyle={DotpictColorStyle.PRIMARY}
                  />
                </DotpictLink>
              </React.Fragment>
            ))}
          </Grid>
          <Like workId={bindModel.workId} isLike={bindModel.isLike} size={36} />
        </Row>
        {bindModel.caption.length > 0 && (
          <Text
            text={bindModel.caption}
            textStyle={DotpictTextStyle.REGULAR12}
            colorStyle={DotpictColorStyle.TEXT_GRAY}
          />
        )}
        <Spacer height={12} />
        <DotpictLink to={`/users/${bindModel.userId}`}>
          <Row verticalGravity={VerticalGravity.center}>
            <PixelArtComponent
              alt={bindModel.userName}
              src={bindModel.profileImageUrl}
              width={48}
              height={48}
            />
            <Spacer width={12} />
            <Text
              text={bindModel.userName}
              textStyle={DotpictTextStyle.BOLD12}
              colorStyle={DotpictColorStyle.BASE}
            />
          </Row>
        </DotpictLink>
      </Column>
    </WorkContainer>
  );
};

export default TimelineWork;
