import { useContext, useEffect, useLayoutEffect } from 'react';
import { APIContext, GlobalContext } from '@/app/component/RootComponent';
import shouldFetchNext from '@/app/common/Common';
import { IOfficialEventDetailState } from '@/app/state/IOfficialEventDetailState';
import { useNavigate } from 'react-router-dom';
import { useCreateCanvasData } from '@/app/component/page/draw/hooks/storage';
import useAnalytics from '@/app/hooks/useAnalytics';
import rgbToHex from '@dotpict-lib/util/rgbToHex';

const useOfficialEvent = () => {
  const { client } = useContext(APIContext);
  const { createCanvasData } = useCreateCanvasData();
  const { globalState, dispatch } = useContext(GlobalContext);
  const navigator = useNavigate();
  const analytics = useAnalytics();

  const fetchOfficialEventWorks = async (currentState: IOfficialEventDetailState) => {
    dispatch({
      type: 'UPDATE_OFFICIAL_EVENT_DETAIL_STATE',
      payload: { officialEventDetailState: currentState },
    });
    const response = await client.fetchWorks(currentState.nextUrl);
    const newWorks = currentState.works.concat(response.works);
    const newNextUrl = response.nextUrl;
    const newState: IOfficialEventDetailState = {
      ...currentState,
      isLoadingWorks: false,
      isVisibleEnd: newNextUrl === '',
      works: newWorks,
      nextUrl: newNextUrl,
    };
    dispatch({
      type: 'UPDATE_OFFICIAL_EVENT_DETAIL_STATE',
      payload: {
        officialEventDetailState: newState,
      },
    });
    if (shouldFetchNext(newNextUrl)) {
      fetchOfficialEventWorks(newState);
    }
  };

  const handleDraw = () => {
    const { officialEvent } = globalState.officialEventDetailState;
    const image = new Image();
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d')!;
      canvas.width = image.width;
      canvas.height = image.height;
      context.drawImage(image, 0, 0, image.width, image.height);
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      const newPixels = Array.from({ length: image.height }, (_, h) => {
        return Array.from({ length: image.width }, (_, w) =>
          imageData.data[h * imageData.width * 4 + w * 4 + 2] === 0
            ? 'FFFFFF'
            : rgbToHex(
                imageData.data[h * imageData.width * 4 + w * 4],
                imageData.data[h * imageData.width * 4 + w * 4 + 1],
                imageData.data[h * imageData.width * 4 + w * 4 + 2],
              ),
        );
      });
      const newCanvasId = createCanvasData(
        { width: image.width, height: image.height },
        officialEvent.colorCodes,
        newPixels,
        officialEvent.tag,
        officialEvent.battleInfo.teamOne.tag !== ''
          ? officialEvent.battleInfo.teamOne.tag
          : undefined,
      );
      analytics('draw_official_event', { official_event_id: `${officialEvent.id}` });
      navigator(`/draw?current=${newCanvasId}`);
    };
    image.setAttribute('crossOrigin', '');
    image.src = officialEvent.layerImageUrl0;
  };

  // @ts-ignore
  const handlePost = () => {
    navigator(`/upload?officialEventTag=${globalState.officialEventDetailState.tag}`);
  };

  const handleJoinTeam = (teamTag: string) => {
    navigator(
      `/upload?officialBattleEventTag=${globalState.officialEventDetailState.tag}&officialBattleEventTeam=${teamTag}`,
    );
  };

  const handleScroll = () => {
    if (
      globalState.officialEventDetailState.isLoadingWorks ||
      !shouldFetchNext(globalState.officialEventDetailState.nextUrl) ||
      globalState.officialEventDetailState.works.length === 0
    ) {
      return;
    }
    fetchOfficialEventWorks({
      ...globalState.officialEventDetailState,
      isLoadingWorks: true,
    });
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    if (globalState.officialEventDetailState.works.length !== 0) return;
    const newState: IOfficialEventDetailState = {
      ...globalState.officialEventDetailState,
      nextUrl: `${client.getBaseUrl}/official_events/${globalState.officialEventDetailState.officialEvent.id}/works`,
      works: [],
      isLoadingWorks: true,
    };
    fetchOfficialEventWorks(newState);
  }, []);

  return {
    ...globalState.officialEventDetailState,
    isVisibleTemplate: globalState.officialEventResultState.officialEvent.activeLayerIndex > 0,
    handlePost,
    handleDraw,
    handleJoinTeam,
  };
};

export default useOfficialEvent;
