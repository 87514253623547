import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import Text from '@/app/component/Text';

interface IProps {
  text: string;
  url: string;
  textStyle: DotpictTextStyle;
  colorStyle: DotpictColorStyle;
}

const ExternalLinkComponent = (props: IProps) => {
  const { text, url, textStyle, colorStyle } = props;

  return (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <a href={url}>
      <Text text={text} textStyle={textStyle} colorStyle={colorStyle} />
    </a>
  );
};

export default ExternalLinkComponent;
