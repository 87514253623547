import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import {
  Column,
  Grid,
  HorizontalGravity,
  Row,
  VerticalGravity,
} from '@/app/component/base/layout/Layout';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import TranslationKeys from '@/app/translation/TranslationKeys';
import ButtonComponent from '@/app/component/ButtonComponent';
import ExternalLinkComponent from '@/app/component/ExternalLinkComponent';
import Colors from '@/app/component/dotpict/colors/Colors';
import EventColorCode from '@/app/component/dotpict/event/colorcode/EventColorCode';
import useTranslator from '@/app/hooks/useTranslator';
import { useState } from 'react';
import useDownloadPalette from '@/app/component/page/eventdetail/useDownloadPalette';

export type HowToUsePaletteProps = {
  paletteName: string;
  colors: string[];
};

const HowToUsePalette = ({ paletteName, colors }: HowToUsePaletteProps) => {
  const translator = useTranslator();
  const [colorCodeOpen, setColorCodeOpen] = useState(false);

  const downloadPalette = useDownloadPalette({ paletteName, colors });

  const handleClickColorCodeToggle = () => {
    setColorCodeOpen(!colorCodeOpen);
  };

  return (
    <Column width="100%" horizontalGravity={HorizontalGravity.center}>
      <Spacer height={24} />
      <Row
        width="100%"
        horizontalGravity={HorizontalGravity.spaceBetween}
        verticalGravity={VerticalGravity.center}
      >
        <Row>
          <Spacer width={48} />
          <Column width={240}>
            <Text
              text={translator(TranslationKeys.UsePalette)}
              textStyle={DotpictTextStyle.BOLD20}
              colorStyle={DotpictColorStyle.BASE}
            />
            <Spacer height={8} />
            <Text
              text={translator(TranslationKeys.EnableUseOtherColors)}
              textStyle={DotpictTextStyle.REGULAR13}
              colorStyle={DotpictColorStyle.BASE}
            />
            <Spacer height={13} />
            <ButtonComponent
              width={240}
              height={48}
              text={translator(TranslationKeys.Download)}
              textStyle={DotpictTextStyle.BOLD16}
              isFilled
              handleClick={() => downloadPalette()}
            />
            <Spacer height={13} />
            <ExternalLinkComponent
              text={translator(TranslationKeys.HowToUsePaletteInAseprite)}
              url="https://dotpict.notion.site/Web-Aseprite-dotpict-ff18d7f2d7314bc9a208b504bca8b771"
              textStyle={DotpictTextStyle.REGULAR13}
              colorStyle={DotpictColorStyle.PRIMARY}
            />
            <Spacer height={13} />
          </Column>
        </Row>
        <Row>
          <Column width={256} horizontalGravity={HorizontalGravity.end}>
            <Column
              background={DotpictColorStyle.PALE_LIGHTER}
              width="100%"
              horizontalGravity={HorizontalGravity.center}
            >
              <Spacer height={12} />
              <Colors colors={colors} colorWidth={16} colorHeight={16} />
              <Spacer height={12} />
            </Column>
            <Spacer height={20} />
            <Text
              text={translator(
                colorCodeOpen ? TranslationKeys.HideColorCodes : TranslationKeys.ShowColorCodes,
              )}
              textStyle={DotpictTextStyle.BOLD14}
              colorStyle={DotpictColorStyle.PRIMARY}
              handleClick={() => handleClickColorCodeToggle()}
            />
          </Column>
          <Spacer width={48} />
        </Row>
      </Row>
      {colorCodeOpen && (
        <>
          <Spacer height={24} />
          <Grid width={584}>
            {colors.map((color, index) => (
              <Column key={color}>
                <Spacer height={16} />
                <Row>
                  <EventColorCode color={color} />
                  {index !== colors.length - 1 && <Spacer width={20} />}
                </Row>
              </Column>
            ))}
          </Grid>
        </>
      )}
    </Column>
  );
};

export default HowToUsePalette;
