enum DotpictColorStyle {
  PRIMARY = '#F15D94',
  PRIMARY_DEEP = '#D24474',
  PRIMARY_PALE = '#FDD3E1',
  BASE = '#333333',
  TEXT_GRAY = '#777777',
  GRAY_DEEP = '#B2B2B2',
  WHITE = '#FFFFFF',
  WHITE_GRAY = '#F4F6F9',
  BLUE = '#00C2FF',
  NIGHT_BACKGROUND = '#313235',
  ERROR = '#DD5757',
  PALE_LIGHTER = '#FAFAFA',
  ACTIVE = '#5EBCF3',
}

export default DotpictColorStyle;
