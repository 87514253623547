import { useRef, useContext, forwardRef } from 'react';
import styled from '@emotion/styled';
import { ToolContext, ToolsMap } from '@/app/component/page/draw/Context/ToolContext';
import CanvasGrid from './CanvasGrid';
import MoveTool from './MoveTool';
import {
  useCanvasContext,
  useCanvasSizes,
  useCanvasEvents,
  useTempPixels,
  useDetectScrollOnTouchDevice,
} from './hooks';
import { useToolsAction, useMoveTool } from './hooks/tools';
import { CanvasHistoryType, PixelsType } from './hooks/types';

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  // 位置調整のエレメントが画面全体に覆っていてnoneにしているのでここで戻す
  pointer-events: all;
`;

const StyledCanvas = styled.canvas`
  position: relative;
  border: 2px solid #000;
  cursor: pointer;
`;

type Props = {
  pushHistory(history: CanvasHistoryType): void;
  setPixels: (pixels: PixelsType, ignoreHistory?: boolean) => void;
};

const Canvas = forwardRef<HTMLCanvasElement, Props>(({ pushHistory, setPixels }, canvasRef) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const tool = useContext(ToolContext);
  const [context] = useCanvasContext(canvasRef);
  const canvasSizes = useCanvasSizes(context, canvasRef, containerRef);
  const [tempPixels, setTempPixels] = useTempPixels(context, canvasSizes, pushHistory, setPixels);

  const [movePosition, handleClickMove] = useMoveTool(canvasSizes);
  const [
    start,
    end,
    withMove,
    withLeave,
    handlePointerDown,
    handlePointerMove,
    handlePointerUp,
    handleRightMouseDown,
  ] = useCanvasEvents(canvasSizes);

  // iPadやスマホだとキャンバスでMoveを行ったときに画面がスクロールしてしまうので防ぐ
  useDetectScrollOnTouchDevice(canvasRef);

  // ツールを用いて何か行ったときに処理する。エディタとしてのコアはここ。
  useToolsAction(
    canvasSizes,
    tempPixels,
    setTempPixels,
    start,
    end,
    movePosition,
    withMove,
    withLeave,
  );

  return (
    <Container ref={containerRef}>
      <StyledCanvas
        ref={canvasRef}
        width={canvasSizes.canvasRenderSize}
        height={canvasSizes.canvasRenderSize}
        onPointerDown={handlePointerDown}
        onPointerMove={handlePointerMove}
        onPointerUp={handlePointerUp}
        onContextMenu={handleRightMouseDown}
      />
      <CanvasGrid canvasSizes={canvasSizes} />
      {tool === ToolsMap.Move && (
        <MoveTool onClickMove={handleClickMove} canvasSizes={canvasSizes} />
      )}
    </Container>
  );
});

export default Canvas;
