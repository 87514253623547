import { useContext } from 'react';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import TranslationKeys from '@/app/translation/TranslationKeys';
import { LayoutContext, MeContext } from '@/app/component/RootComponent';
import {
  Column,
  HorizontalGravity,
  Row,
  VerticalGravity,
} from '@/app/component/base/layout/Layout';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import useTranslator from '@/app/hooks/useTranslator';
import DotpictLink from '@/app/component/DotpictLinkComponent';
import ButtonComponent from '@/app/component/ButtonComponent';
import RequestSend from '@/app/component/page/requestbox/send';
import FollowButtonComponent from '@/app/component/FollowButtonComponent';
import useUserDetailHeader from '@/app/component/page/user/detail/useUserDetailHeader';
import { UserType } from '@/app/model/UserType';

export type UserDetailHeaderProps = {
  user: UserType;
};

export type UserDetailHeaderHeaderBindModel = {
  id: number;
  name: string;
  account: string;
  headerImageUrl: string;
  profileImageUrl: string;
  url: string;
  text: string;
  followedCount: number;
  followerCount: number;
  isFollowed: boolean;
  isOpenedRequestBox: boolean;
  registrationDate: string;
  birthDate: string;
  contentWidth: number;
};

const UserDetailHeader = (props: UserDetailHeaderProps) => {
  const meState = useContext(MeContext);
  const { user } = props;
  const { bindModel } = useUserDetailHeader({ user });
  const translator = useTranslator();
  const { layoutParams } = useContext(LayoutContext);

  return (
    <Column background={DotpictColorStyle.WHITE} width={layoutParams.contentWidth}>
      {bindModel.headerImageUrl !== '' && (
        <>
          <PixelArtComponent
            alt="header"
            src={bindModel.headerImageUrl}
            width={bindModel.contentWidth}
            height={bindModel.contentWidth / 3}
            objectFit="cover"
          />
          <Spacer height={24} />
        </>
      )}
      <Spacer height={24} />
      <Column width="100%">
        <Row width="100%">
          <Spacer width={24} />
          <PixelArtComponent
            alt={bindModel.name}
            src={bindModel.profileImageUrl}
            width={96}
            height={96}
          />
          <Spacer width={16} />
          <Column width={layoutParams.contentWidth - 24 * 2 - 96 - 16}>
            <Row width="100%" horizontalGravity={HorizontalGravity.spaceBetween}>
              <Column>
                <Text
                  text={bindModel.name}
                  textStyle={DotpictTextStyle.BOLD16}
                  colorStyle={DotpictColorStyle.BASE}
                />
                {bindModel.account.length !== 0 && (
                  <Text
                    text={`@${bindModel.account}`}
                    textStyle={DotpictTextStyle.REGULAR13}
                    colorStyle={DotpictColorStyle.TEXT_GRAY}
                  />
                )}
              </Column>
              <FollowButtonComponent userId={bindModel.id} isFollowed={bindModel.isFollowed} />
            </Row>
            <Spacer height={8} />
            <Row>
              <DotpictLink to={`/users/${bindModel.id}/followedUsers`}>
                <Row verticalGravity={VerticalGravity.center}>
                  <Text
                    text={`${bindModel.followedCount}`}
                    textStyle={DotpictTextStyle.BOLD14}
                    colorStyle={DotpictColorStyle.BASE}
                  />
                  &nbsp;
                  <Text
                    text={`${translator(TranslationKeys.Followed)}`}
                    textStyle={DotpictTextStyle.REGULAR12}
                    colorStyle={DotpictColorStyle.BASE}
                  />
                </Row>
              </DotpictLink>
              <Spacer width={24} />
              <DotpictLink to={`/users/${bindModel.id}/followerUsers`}>
                <Row verticalGravity={VerticalGravity.center}>
                  <Text
                    text={`${bindModel.followerCount}`}
                    textStyle={DotpictTextStyle.BOLD14}
                    colorStyle={DotpictColorStyle.BASE}
                  />
                  &nbsp;
                  <Text
                    text={translator(TranslationKeys.Follower)}
                    textStyle={DotpictTextStyle.REGULAR12}
                    colorStyle={DotpictColorStyle.BASE}
                  />
                </Row>
              </DotpictLink>
            </Row>
          </Column>
        </Row>
        <Spacer height={16} />
        <Row>
          <Spacer width={24} />
          <a href={bindModel.url}>
            <Text
              text={bindModel.url}
              textStyle={DotpictTextStyle.BOLD12}
              colorStyle={DotpictColorStyle.PRIMARY}
            />
          </a>
        </Row>
        <Spacer height={8} />
        <Row>
          <Spacer width={24} />
          <Text
            text={bindModel.registrationDate}
            textStyle={DotpictTextStyle.REGULAR11}
            colorStyle={DotpictColorStyle.TEXT_GRAY}
          />
        </Row>
        <Spacer height={8} />
        <Row>
          <Spacer width={24} />
          <Text
            text={bindModel.text}
            textStyle={DotpictTextStyle.REGULAR14}
            colorStyle={DotpictColorStyle.BASE}
            flex={1}
          />
          <Spacer width={24} />
        </Row>
        {meState.isLoggedIn && bindModel.isOpenedRequestBox && (
          <Column horizontalGravity={HorizontalGravity.center}>
            <Spacer height={24} />
            <RequestSend user={user}>
              <ButtonComponent
                width={240}
                height={32}
                text={translator(TranslationKeys.RequestBoxSend)}
                icon="https://storage.googleapis.com/dotpict-images/web/request-box/request.svg"
                isFilled={false}
                isBorder
              />
            </RequestSend>
          </Column>
        )}
        <Spacer height={24} />
      </Column>
    </Column>
  );
};

export default UserDetailHeader;
