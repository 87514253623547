import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import { Column, HorizontalGravity } from '@/app/component/base/layout/Layout';
import { CircularProgress } from '@material-ui/core';

const Loading = () => (
  <Column width="100%" horizontalGravity={HorizontalGravity.center}>
    <SquareSpaceComponent size={40} />
    <CircularProgress />
    <SquareSpaceComponent size={40} />
  </Column>
);

export default Loading;
