import HorizontalContainerStyle from '@/app/style/HorizontalContainerStyle';
import TwitterShareComponent from '@/app/component/TwitterShareComponent';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import FacebookShareComponent from '@/app/component/FacebookShareComponent';

const itemWidth = 102;
const itemHeight = 28;

interface IProps {
  url: string;
  title: string;
}

const ShareComponent = (props: IProps) => {
  const { url, title } = props;

  return (
    <div css={HorizontalContainerStyle}>
      <TwitterShareComponent url={url} title={title} width={itemWidth} height={itemHeight} />
      <SquareSpaceComponent size={12} />
      <FacebookShareComponent url={url} title={title} width={itemWidth} height={itemHeight} />
    </div>
  );
};

export default ShareComponent;
