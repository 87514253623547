import React, { MouseEventHandler } from 'react';

type Props = {
  onClick?: MouseEventHandler | undefined;
  children: React.ReactNode;
};

const Button = ({ onClick, children }: Props) => {
  const buttonStyle = {
    backgroundColor: 'transparent',
    backgroundRepeat: 'no-repeat',
    border: 'none',
    cursor: 'pointer',
    overflow: 'hidden',
    outline: 'none',
  };

  return (
    <button type="button" style={buttonStyle} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
