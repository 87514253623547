import { Button } from '@material-ui/core';
import { css } from '@emotion/react';
import CenterHorizontalContainerStyle from '@/app/style/CenterHorizontalContainerStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import { useIntl } from 'react-intl';
import TranslationKeys from '@/app/translation/TranslationKeys';
import { MouseEventHandler } from 'react';

interface IProps {
  isLiked: boolean;
  handleClick: MouseEventHandler;
}

const MakingLikeComponent = (props: IProps) => {
  const { isLiked, handleClick } = props;
  const intl = useIntl();

  const containerStyle = css(CenterHorizontalContainerStyle, {
    width: 240,
    height: 52,
    background: isLiked ? DotpictColorStyle.PRIMARY_PALE : DotpictColorStyle.PRIMARY,
  });

  return (
    <Button onClick={handleClick} disabled={isLiked}>
      <div css={containerStyle}>
        <Text
          text={intl.formatMessage({ id: TranslationKeys.MakingLike })}
          textStyle={DotpictTextStyle.BOLD16}
          colorStyle={DotpictColorStyle.WHITE}
        />
      </div>
    </Button>
  );
};

export default MakingLikeComponent;
