import React, { useContext, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import VerticalContainerStyle from '@/app/style/VerticalContainerStyle';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import {
  APIContext,
  LanguageContext,
  LayoutContext,
  MeContext,
} from '@/app/component/RootComponent';
import {
  articleContentBottomSpace,
  articleContentTopSpace,
  articleTitleBottomSpace,
} from '@/app/common/Constant';
import { ArticleComponentType } from '@/app/article/ArticleComponentType';
import { IArticleTextComponent } from '@/app/article/IArticleTextComponent';
import CenterHorizontalContainerStyle from '@/app/style/CenterHorizontalContainerStyle';
import ImageComponent from '@/app/component/ImageComponent';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import ExternalLinkComponent from '@/app/component/ExternalLinkComponent';

const ArticleDetailComponent = () => {
  const { articleId } = useParams();
  const { layoutParams } = useContext(LayoutContext);
  const [renderTitle, setRenderTitle] = useState(true);
  const [isNightMode, setNightMode] = useState(false);
  const location = useLocation();
  const meState = useContext(MeContext);
  const { language } = useContext(LanguageContext);
  const [title, setTitle] = useState<string>('');
  const [articleComponents, setArticleComponents] = useState<ArticleComponentType[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const { client } = useContext(APIContext);

  const contentContainerStyle = css(VerticalContainerStyle, {
    backgroundColor: isNightMode ? DotpictColorStyle.NIGHT_BACKGROUND : DotpictColorStyle.WHITE,
    padding: '0 32',
    width: layoutParams.contentWidth - 32 * 2,
  });

  const imageContainerStyle = css({
    border: `solid 2px ${DotpictColorStyle.BASE}`,
  });

  const fetchData = async () => {
    if (articleId === undefined) return;
    setLoading(true);
    const articleResponse = await client.fetchArticle(meState.token, language, articleId);
    setTitle(articleResponse.title);
    setArticleComponents(articleResponse.components);
    setLoading(false);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setRenderTitle(params.get('noTitle') !== 'true' || title === '');
    setNightMode(params.get('isNightMode') === 'true');
    setTitle('');
    setArticleComponents([]);
    fetchData();
  }, [articleId, language]);

  const renderContent = (component: ArticleComponentType) => {
    switch (component.kind) {
      case 'text':
        return (
          <Text
            text={component.text}
            textStyle={
              (component as IArticleTextComponent).isBold
                ? DotpictTextStyle.BOLD14
                : DotpictTextStyle.REGULAR14
            }
            colorStyle={isNightMode ? DotpictColorStyle.WHITE : DotpictColorStyle.BASE}
          />
        );
      case 'internal_link':
        return (
          <Link to={component.path}>
            <Text
              text={component.text}
              textStyle={DotpictTextStyle.REGULAR14}
              colorStyle={DotpictColorStyle.PRIMARY}
            />
          </Link>
        );
      case 'external_link':
        return (
          <ExternalLinkComponent
            text={component.text}
            url={component.path}
            textStyle={DotpictTextStyle.BOLD14}
            colorStyle={DotpictColorStyle.PRIMARY}
          />
        );
      case 'image':
        return (
          <div css={CenterHorizontalContainerStyle}>
            <div css={imageContainerStyle}>
              <ImageComponent
                alt="article_image"
                width={component.width}
                height={component.height}
                src={component.imageUrl}
              />
            </div>
          </div>
        );
      case 'pixelart':
        return (
          <div css={CenterHorizontalContainerStyle}>
            <div css={imageContainerStyle}>
              <PixelArtComponent
                alt="article_image"
                width={component.width}
                height={component.height}
                src={component.imageUrl}
              />
            </div>
          </div>
        );
      case 'space':
        return <SquareSpaceComponent size={component.size} />;
      case 'button':
        return (
          <div css={CenterHorizontalContainerStyle}>
            <ExternalLinkComponent
              text={component.text}
              url={component.url}
              textStyle={DotpictTextStyle.BOLD14}
              colorStyle={DotpictColorStyle.PRIMARY}
            />
          </div>
        );
      default:
        return '';
    }
  };

  return (
    <div css={VerticalContainerStyle}>
      {renderTitle && <SquareSpaceComponent size={layoutParams.contentTopSpace} />}
      <div css={contentContainerStyle}>
        <SquareSpaceComponent size={articleContentTopSpace} />
        {renderTitle && (
          <>
            <Text
              text={title}
              textStyle={DotpictTextStyle.BOLD20}
              colorStyle={DotpictColorStyle.BASE}
            />
            <SquareSpaceComponent size={articleTitleBottomSpace} />
          </>
        )}
        {isLoading && (
          <div css={VerticalContainerStyle}>
            <SquareSpaceComponent size={40} />
            <CircularProgress />
            <SquareSpaceComponent size={40} />
          </div>
        )}
        {articleComponents.map((articleComponent: ArticleComponentType, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>{renderContent(articleComponent)}</React.Fragment>
        ))}
        <SquareSpaceComponent size={articleContentBottomSpace} />
      </div>
      {renderTitle && <SquareSpaceComponent size={layoutParams.contentBottomSpace} />}
    </div>
  );
};

export default ArticleDetailComponent;
