import { css } from '@emotion/react';

const CenterHorizontalContainerStyle = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
});

export default CenterHorizontalContainerStyle;
