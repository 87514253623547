import { Column, HorizontalGravity, Row } from '@/app/component/base/layout/Layout';
import Text from '@/app/component/Text';
import useTranslator from '@/app/hooks/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import GooglePlay from '@/app/component/part/app/googleplay/GooglePlay';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import AppStore from '@/app/component/part/app/appstore/AppStore';
import PixelArtComponent from '@/app/component/PixelArtComponent';

const AboutDotpict = () => {
  const translator = useTranslator();
  return (
    <Column
      padding="12px 16px"
      background={DotpictColorStyle.WHITE}
      horizontalGravity={HorizontalGravity.center}
    >
      <PixelArtComponent
        src="https://storage.googleapis.com/dotpict-images/web/logo.svg"
        alt="logo"
        width={106}
        height={19}
      />
      <Spacer height={12} />
      <Text
        text={translator(TranslationKeys.AboutDotpictText1)}
        textStyle={DotpictTextStyle.REGULAR11}
        colorStyle={DotpictColorStyle.BASE}
      />
      <Spacer height={16} />
      <Text
        text={translator(TranslationKeys.AboutDotpictText2)}
        textStyle={DotpictTextStyle.BOLD12}
        colorStyle={DotpictColorStyle.PRIMARY}
      />
      <Spacer height={4} />
      <Row>
        <GooglePlay />
        <SquareSpaceComponent size={4} />
        <AppStore />
      </Row>
      <Spacer height={4} />
      <Text
        text={translator(TranslationKeys.AboutDotpictText3)}
        textStyle={DotpictTextStyle.REGULAR11}
        colorStyle={DotpictColorStyle.BASE}
      />
    </Column>
  );
};

export default AboutDotpict;
