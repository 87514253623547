import { Column, HorizontalGravity, Row } from '@/app/component/base/layout/Layout';
import Text from '@/app/component/Text';
import TranslationKeys from '@/app/translation/TranslationKeys';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import PageControl from '@/app/component/base/pagecontrol/PageControl';
import ButtonComponent from '@/app/component/ButtonComponent';
import useTranslator from '@/app/hooks/useTranslator';
import { useState } from 'react';
import TutorialItem, {
  TutorialItemProps,
} from '@/app/component/part/work/upload/tutorial/TutorialItem';

export type TutorialItemsProps = {
  handleEnd: () => void;
};

const TutorialItems = ({ handleEnd }: TutorialItemsProps) => {
  const translator = useTranslator();
  const [activeStep, setActiveStep] = useState(0);

  const tutorialItems: TutorialItemProps[] = [
    {
      imageUrl: 'https://storage.googleapis.com/dotpict-images/web/post-tutorial/tutorial01@2x.png',
      text: translator(TranslationKeys.PostTutorial1),
    },
    {
      imageUrl: 'https://storage.googleapis.com/dotpict-images/web/post-tutorial/tutorial02@2x.png',
      text: translator(TranslationKeys.PostTutorial2),
    },
    {
      imageUrl: 'https://storage.googleapis.com/dotpict-images/web/post-tutorial/tutorial03@2x.png',
      text: translator(TranslationKeys.PostTutorial3),
    },
    {
      imageUrl: 'https://storage.googleapis.com/dotpict-images/web/post-tutorial/tutorial04@2x.png',
      text: translator(TranslationKeys.PostTutorial4),
    },
    {
      imageUrl: 'https://storage.googleapis.com/dotpict-images/web/post-tutorial/tutorial05@2x.png',
      text: translator(TranslationKeys.PostTutorial5),
    },
  ];

  const handleNext = () => {
    if (activeStep === tutorialItems.length - 1) {
      handleEnd();
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) return;
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Column horizontalGravity={HorizontalGravity.center}>
      <Text
        text={translator(TranslationKeys.PostTutorialTitle)}
        textStyle={DotpictTextStyle.BOLD20}
        colorStyle={DotpictColorStyle.BASE}
      />
      <Text
        text={translator(TranslationKeys.PostTutorialTitle2)}
        textStyle={DotpictTextStyle.BOLD20}
        colorStyle={DotpictColorStyle.BASE}
      />
      <Spacer height={24} />
      <Row>
        <Spacer width={60} />
        <TutorialItem
          imageUrl={tutorialItems[activeStep].imageUrl}
          text={tutorialItems[activeStep].text}
        />
        <Spacer width={60} />
      </Row>
      <Spacer height={42} />
      <PageControl selectedIndex={activeStep} numberOfItems={tutorialItems.length} />
      <Spacer height={38} />
      <ButtonComponent
        width={240}
        height={52}
        text={translator(
          activeStep < tutorialItems.length - 1
            ? TranslationKeys.Next
            : TranslationKeys.AgreeTheGuideline,
        )}
        isFilled={activeStep === tutorialItems.length - 1}
        isBorder
        handleClick={handleNext}
      />
      {activeStep > 0 && (
        <ButtonComponent
          width={240}
          height={52}
          text={translator(TranslationKeys.Back)}
          isFilled={false}
          handleClick={handleBack}
        />
      )}
    </Column>
  );
};

export default TutorialItems;
