import { useContext, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import ButtonComponent from '@/app/component/ButtonComponent';
import FileUploadComponent from '@/app/component/page/editprofile/FileUploadComponent';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import { APIContext, GlobalContext, MeContext } from '@/app/component/RootComponent';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import Text from '@/app/component/Text';
import { MeResponseType } from '@/app/model/responsetype/MeResponseType';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import TranslationKeys from '@/app/translation/TranslationKeys';
import { DotpictAction } from '@/app/reducer/reducer';
import useTranslator from '@/app/hooks/useTranslator';
import {
  Column,
  HorizontalGravity,
  Row,
  VerticalGravity,
} from '@/app/component/base/layout/Layout';
import TextFieldComponent from '@/app/component/TextFieldComponent';
import { Spacer } from '@/app/component/base/spacer/Spacer';

const HeaderContainer = styled.div`
  position: relative;
  width: 100%;
`;

const HeaderUploadButton = styled.div`
  position: absolute;
  top: 168px;
  left: 55%;
`;

const EditProfile = () => {
  const { dispatch } = useContext(GlobalContext);
  const [modalOpen, setModalOpen] = useState(false);
  const fullScreen = useMediaQuery('(max-width: 680px)');
  const meState = useContext(MeContext);
  const translator = useTranslator();
  const { client } = useContext(APIContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [headerImage, setHeaderImage] = useState<File>();
  const [headerImageUrl, setHeaderImageUrl] = useState<string>('');
  const [profileName, setProfileName] = useState<string>('');
  const [profileAccount, setProfileAccount] = useState<string>('');
  const [profileUrl, setProfileUrl] = useState<string>('');
  const [profileText, setProfileText] = useState<string>('');
  const [profileImage, setProfileImage] = useState<File>();
  const [profileImageUrl, setProfileImageUrl] = useState<string>('');
  const [accountErrorMessage, setAccountErrorMessage] = useState<string>('');
  const [headerErrorMessage, setHeaderErrorMessage] = useState<string>('');

  const profileFileReader = new FileReader();
  profileFileReader.onload = (v) => {
    const result = v.target?.result;
    if (result == null) return;
    setProfileImageUrl(result as string);
  };

  const headerFileReader = new FileReader();
  headerFileReader.onload = (v) => {
    setHeaderErrorMessage('');
    const result = v.target?.result;
    if (result == null) return;
    const img = new Image();
    img.src = result as string;
    img.onload = () => {
      if (img.width > 480 || img.height > 480) {
        setHeaderErrorMessage(translator(TranslationKeys.ErrorImageSizeTooLarge));
        setHeaderImageUrl('');
        return;
      }
      setHeaderImageUrl(result as string);
    };
  };

  const handleOpen = () => {
    setHeaderImageUrl(meState.user.headerImageUrl);
    setProfileName(meState.user.name);
    setProfileAccount(meState.user.account);
    setProfileUrl(meState.user.url);
    setProfileText(meState.user.text);
    setProfileImageUrl(meState.user.profileImageUrl);
    setAccountErrorMessage('');
    setModalOpen(true);
  };

  const handleClose = () => {
    if (loading) return;
    setModalOpen(false);
  };

  const save = async () => {
    if (loading) return;
    setLoading(true);
    setAccountErrorMessage('');
    let hasError = false;
    if (
      profileAccount !== undefined &&
      profileAccount !== '' &&
      meState.user.account !== profileAccount
    ) {
      await client.postAccount(meState.token, profileAccount).catch((error) => {
        setAccountErrorMessage(error.message);
        hasError = true;
      });
      // アカウント重複が一番多そうなエラーなので失敗したら差し戻しする
      if (hasError) {
        setLoading(false);
        return;
      }
    }
    if (headerImage !== undefined) {
      await client.postHeaderImage(meState.token, headerImage);
    }
    if (profileImage !== undefined) {
      await client.postProfileImage(meState.token, profileImage);
    }
    if (profileName !== undefined && profileName !== '') {
      await client.postName(meState.token, profileName);
    }
    await client.postText(meState.token, profileText);
    await client.postUrl(meState.token, profileUrl);

    const response: MeResponseType = await client.fetchMe(meState.token);
    const action: DotpictAction = {
      type: 'UPDATE_ME_STATE',
      payload: {
        meState: { ...meState, user: response.user },
      },
    };
    dispatch(action);
    setLoading(false);
    if (!hasError) {
      setModalOpen(false);
    }
  };

  return (
    <>
      <ButtonComponent
        width={112}
        height={32}
        text={translator(TranslationKeys.Edit)}
        isFilled={false}
        isBorder
        handleClick={handleOpen}
      />
      <Dialog
        maxWidth="sm"
        fullWidth
        fullScreen={fullScreen}
        open={modalOpen}
        onClose={handleClose}
        scroll="body"
      >
        <Column horizontalGravity={HorizontalGravity.center}>
          <Spacer height={20} />
          <Text
            text={translator(TranslationKeys.EditProfile)}
            textStyle={DotpictTextStyle.BOLD20}
            colorStyle={DotpictColorStyle.BASE}
          />
          <Spacer height={20} />
          <HeaderContainer>
            {headerImageUrl !== '' && (
              <Column
                height={228}
                background={DotpictColorStyle.WHITE_GRAY}
                verticalGravity={VerticalGravity.center}
              >
                <PixelArtComponent
                  alt="headerImage"
                  src={headerImageUrl}
                  width="100%"
                  height={228}
                  objectFit="cover"
                />
                <HeaderUploadButton>
                  {/* div直下だとFileUploadComponentのレイアウトが崩れたのでRowをはさむ */}
                  <Row>
                    <FileUploadComponent
                      id="header-image"
                      width={228}
                      height={40}
                      text={translator(TranslationKeys.ChangeMyPageHeaderImage)}
                      onChange={(file) => {
                        setHeaderImage(file);
                        headerFileReader.readAsDataURL(file);
                      }}
                    />
                  </Row>
                </HeaderUploadButton>
              </Column>
            )}
            {headerImageUrl === '' && (
              <Column
                height={228}
                background={DotpictColorStyle.WHITE_GRAY}
                horizontalGravity={HorizontalGravity.center}
              >
                <Spacer height={26} />
                <PixelArtComponent
                  alt="not_found"
                  src="https://storage.googleapis.com/dotpict-images/web/not_found.svg"
                  width={40}
                  height={51}
                />
                <Spacer height={8} />
                <Text
                  text={translator(TranslationKeys.MyPageHeaderSettingTitle)}
                  textStyle={DotpictTextStyle.BOLD12}
                  colorStyle={DotpictColorStyle.TEXT_GRAY}
                />
                <Spacer height={8} />
                <Text
                  text={translator(TranslationKeys.MyPageHeaderNote)}
                  textStyle={DotpictTextStyle.REGULAR11}
                  colorStyle={DotpictColorStyle.TEXT_GRAY}
                />
                <Spacer height={8} />
                <Link to="/imageGuideline">
                  <Text
                    text={translator(TranslationKeys.ViewImageGuideline)}
                    textStyle={DotpictTextStyle.REGULAR11}
                    colorStyle={DotpictColorStyle.BLUE}
                  />
                </Link>
                <HeaderUploadButton>
                  <Row>
                    <FileUploadComponent
                      id="header-image"
                      width={228}
                      height={40}
                      text={translator(TranslationKeys.AddMyPageHeaderImage)}
                      onChange={(file) => {
                        setHeaderImage(file);
                        headerFileReader.readAsDataURL(file);
                      }}
                    />
                  </Row>
                </HeaderUploadButton>
              </Column>
            )}
          </HeaderContainer>
          {headerErrorMessage.length !== 0 && (
            <>
              <SquareSpaceComponent size={4} />
              <Text
                text={headerErrorMessage}
                textStyle={DotpictTextStyle.REGULAR12}
                colorStyle={DotpictColorStyle.ERROR}
              />
            </>
          )}
          <Spacer height={24} />
          <Column width={320}>
            <Text
              text={translator(TranslationKeys.EditProfile)}
              textStyle={DotpictTextStyle.BOLD14}
              colorStyle={DotpictColorStyle.BASE}
            />
            <Spacer height={16} />
            <Row verticalGravity={VerticalGravity.center}>
              <PixelArtComponent alt="test" src={profileImageUrl} width={72} height={72} />
              <Spacer width={16} />
              <FileUploadComponent
                id="profile-image"
                width={150}
                height={40}
                text={translator(TranslationKeys.ChangeImage)}
                onChange={(file) => {
                  setProfileImage(file);
                  profileFileReader.readAsDataURL(file);
                }}
              />
            </Row>
            <Spacer height={24} />
            <TextFieldComponent
              id="UserName"
              label={translator(TranslationKeys.UserName)}
              placeholder={translator(TranslationKeys.UserNamePlaceholder)}
              value={profileName}
              onChangeValue={(text) => setProfileName(text)}
            />
            <Spacer height={32} />
            <TextFieldComponent
              id="AccountName"
              label={translator(TranslationKeys.AccountName)}
              placeholder={translator(TranslationKeys.AccountNamePlaceholder)}
              value={profileAccount}
              onChangeValue={(text) => setProfileAccount(text)}
            />
            {accountErrorMessage.length !== 0 && (
              <>
                <SquareSpaceComponent size={4} />
                <Text
                  text={accountErrorMessage}
                  textStyle={DotpictTextStyle.REGULAR12}
                  colorStyle={DotpictColorStyle.ERROR}
                />
              </>
            )}
            <Spacer height={32} />
            <TextFieldComponent
              id="Text"
              label={translator(TranslationKeys.Text)}
              placeholder={translator(TranslationKeys.TextPlaceholder)}
              value={profileText}
              multiline
              rows={8}
              onChangeValue={(text) => setProfileText(text)}
            />
            <Spacer height={32} />
            <TextFieldComponent
              id="WebSite"
              label={translator(TranslationKeys.WebSite)}
              placeholder={translator(TranslationKeys.WebSitePlaceholder)}
              value={profileUrl}
              onChangeValue={(text) => setProfileUrl(text)}
            />
            <Spacer height={40} />
          </Column>
          <ButtonComponent
            width={240}
            height={52}
            text={translator(TranslationKeys.Save)}
            isFilled
            handleClick={save}
          />
          <ButtonComponent
            width={240}
            height={52}
            text={translator(TranslationKeys.Cancel)}
            isFilled={false}
            handleClick={handleClose}
          />
        </Column>
      </Dialog>
    </>
  );
};

export default EditProfile;
