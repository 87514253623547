import { ThreadType } from '@/app/model/ThreadType';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import { Link } from 'react-router-dom';
import { Column, Row, VerticalGravity } from '@/app/component/base/layout/Layout';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import useThread from '@/app/component/part/work/thread/thread/useThread';
import ThreadLike from '@/app/component/part/work/thread/like/ThreadLike';
import { WorkType } from '../../../../../model/WorkType';

type Props = {
  isVisibleReply: boolean;
  work: WorkType;
  thread: ThreadType;
};

export type ThreadState = {
  replyText: string;
  replyTextStyle: DotpictTextStyle;
};

const Thread = ({ isVisibleReply, work, thread }: Props) => {
  const { replyText, replyTextStyle } = useThread({ thread });

  return (
    <Row>
      <Link to={`/users/${thread.user.id}`}>
        <PixelArtComponent
          alt={thread.user.name}
          src={thread.user.profileImageUrl}
          width={64}
          height={64}
        />
      </Link>
      <SquareSpaceComponent size={16} />
      <Column>
        <Link to={`/users/${thread.user.id}`}>
          <Text
            text={thread.user.name}
            textStyle={DotpictTextStyle.BOLD14}
            colorStyle={DotpictColorStyle.BASE}
          />
        </Link>
        <SquareSpaceComponent size={4} />
        <Text
          text={thread.text}
          textStyle={DotpictTextStyle.REGULAR14}
          colorStyle={DotpictColorStyle.BASE}
        />
        <SquareSpaceComponent size={4} />
        <Row verticalGravity={VerticalGravity.center}>
          <Text
            text={thread.createdAt}
            textStyle={DotpictTextStyle.REGULAR12}
            colorStyle={DotpictColorStyle.GRAY_DEEP}
          />
          <ThreadLike work={work} thread={thread} />
          {isVisibleReply && (
            <>
              <Spacer width={13} />
              <Link to={`/works/${thread.workId}/threads/${thread.id}`}>
                <Text
                  text={replyText}
                  textStyle={replyTextStyle}
                  colorStyle={DotpictColorStyle.PRIMARY}
                />
              </Link>
            </>
          )}
        </Row>
      </Column>
    </Row>
  );
};

export default Thread;
