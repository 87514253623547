import { defaultGlobalState, IGlobalState } from '@/app/state/IGlobalState';
import { UserSummaryType } from '@/app/model/UserSummaryType';
import { WorksStateType } from '@/app/state/WorksStateType';
import { ISearchWorksState } from '@/app/state/ISearchWorksState';
import { ISearchUsersState } from '@/app/state/ISearchUsersState';
import { ILikeUsersState } from '@/app/state/ILikeUsersState';
import { IFollowedUsersState } from '@/app/state/IFollowedUsersState';
import { IFollowerUsersState } from '@/app/state/IFollowerUsersState';
import { IWorkDetailState } from '@/app/state/IWorkDetailState';
import { UserDetailStateType } from '@/app/state/UserDetailStateType';
import { MyPageStateType } from '@/app/state/MyPageState';
import { IMeState } from '@/app/state/IMeState';
import { ITemplateResultState } from '@/app/state/ITemplateResultState';
import { INotificationsState } from '@/app/state/INotificationsState';
import { ThreadType } from '@/app/model/ThreadType';
import { IRepliesState } from '@/app/state/IRepliesState';
import { IHeldOfficialEventState } from '@/app/state/IHeldOfficialEventState';
import { IOfficialEventDetailState } from '@/app/state/IOfficialEventDetailState';
import { OfficialEventResultStateType } from '@/app/state/OfficialEventResultStateType';
import { UserType } from '../model/UserType';
import { WorkType } from '../model/WorkType';

export type DotpictAction =
  | { type: 'UPDATE_TIMELINE_STATE'; payload: { timelineState: WorksStateType } }
  | { type: 'UPDATE_PICKUP_WORKS_STATE'; payload: { pickUpWorksState: WorksStateType } }
  | { type: 'UPDATE_NEWEST_WORKS_STATE'; payload: { newestWorksState: WorksStateType } }
  | { type: 'UPDATE_NOTIFICATIONS_STATE'; payload: { notificationsState: INotificationsState } }
  | { type: 'UPDATE_SEARCH_WORKS_STATE'; payload: { searchWorksState: ISearchWorksState } }
  | { type: 'UPDATE_SEARCH_USERS_STATE'; payload: { searchUsersState: ISearchUsersState } }
  | {
      type: 'UPDATE_OFFICIAL_EVENT_DETAIL_STATE';
      payload: { officialEventDetailState: IOfficialEventDetailState };
    }
  | {
      type: 'UPDATE_OFFICIAL_EVENT_RESULT_STATE';
      payload: { officialEventResultState: OfficialEventResultStateType };
    }
  | { type: 'UPDATE_LIKE_USERS_STATE'; payload: { likeUsersState: ILikeUsersState } }
  | { type: 'UPDATE_FOLLOWED_USERS_STATE'; payload: { followedUsersState: IFollowedUsersState } }
  | { type: 'UPDATE_FOLLOWER_USERS_STATE'; payload: { followerUsersState: IFollowerUsersState } }
  | { type: 'UPDATE_WORK_DETAIL_STATE'; payload: { workDetailState: IWorkDetailState } }
  | { type: 'UPDATE_WORK'; payload: { work: WorkType } }
  | { type: 'UPDATE_FOLLOW_STATE'; payload: { user: UserType } }
  | { type: 'UPDATE_USER_DETAIL_STATE'; payload: { userDetailState: UserDetailStateType } }
  | { type: 'UPDATE_MY_PAGE_STATE'; payload: { myPageState: MyPageStateType } }
  | { type: 'UPDATE_ME_STATE'; payload: { meState: IMeState } }
  | {
      type: 'UPDATE_HELD_OFFICIAL_EVENT_STATE';
      payload: { heldOfficialEventState: IHeldOfficialEventState };
    }
  | {
      type: 'UPDATE_TEMPLATE_RESULT_STATE';
      payload: { templateResultState: ITemplateResultState };
    }
  | { type: 'UPDATE_THREAD_STATE'; payload: { thread: ThreadType } }
  | { type: 'UPDATE_REPLIES_STATE'; payload: { repliesState: IRepliesState } }
  | { type: 'POSTED_THREAD'; payload: { newThread: ThreadType } };

export type DotpictReducer<S, A extends DotpictAction> = (state: S, action: A) => S;

const reducer: DotpictReducer<IGlobalState, DotpictAction> = (
  state: IGlobalState,
  action: DotpictAction,
) => {
  switch (action.type) {
    case 'UPDATE_TIMELINE_STATE': {
      const { timelineState } = action.payload;
      return {
        ...state,
        timelineState,
      };
    }
    case 'UPDATE_NEWEST_WORKS_STATE': {
      const { newestWorksState } = action.payload;
      return {
        ...state,
        newestWorksState,
      };
    }
    case 'UPDATE_NOTIFICATIONS_STATE': {
      const { notificationsState } = action.payload;
      return {
        ...state,
        notificationsState,
      };
    }
    case 'UPDATE_PICKUP_WORKS_STATE': {
      const { pickUpWorksState } = action.payload;
      return {
        ...state,
        pickUpWorksState,
      };
    }
    case 'UPDATE_SEARCH_WORKS_STATE': {
      const { searchWorksState } = action.payload;
      return {
        ...state,
        searchWorksState,
      };
    }
    case 'UPDATE_SEARCH_USERS_STATE': {
      const { searchUsersState } = action.payload;
      return {
        ...state,
        searchUsersState,
      };
    }
    case 'UPDATE_OFFICIAL_EVENT_DETAIL_STATE': {
      const { officialEventDetailState } = action.payload;
      return {
        ...state,
        officialEventDetailState,
      };
    }
    case 'UPDATE_OFFICIAL_EVENT_RESULT_STATE': {
      const { officialEventResultState } = action.payload;
      return {
        ...state,
        officialEventResultState,
      };
    }
    case 'UPDATE_USER_DETAIL_STATE': {
      const { userDetailState } = action.payload;
      return {
        ...state,
        userDetailState,
      };
    }
    case 'UPDATE_TEMPLATE_RESULT_STATE': {
      const { templateResultState } = action.payload;
      return {
        ...state,
        templateResultState,
      };
    }
    case 'UPDATE_ME_STATE': {
      const { meState } = action.payload;
      if (state.meState.isLoggedIn === meState.isLoggedIn) {
        return {
          ...state,
          meState,
        };
      }
      return {
        ...defaultGlobalState,
        meState,
      };
    }
    case 'UPDATE_HELD_OFFICIAL_EVENT_STATE': {
      const { heldOfficialEventState } = action.payload;
      return {
        ...state,
        heldOfficialEventState,
      };
    }
    case 'UPDATE_MY_PAGE_STATE': {
      const { myPageState } = action.payload;
      return {
        ...state,
        myPageState,
      };
    }
    case 'UPDATE_WORK_DETAIL_STATE': {
      const { workDetailState } = action.payload;
      return {
        ...state,
        workDetailState,
      };
    }
    case 'UPDATE_THREAD_STATE': {
      const { thread } = action.payload;
      return {
        ...state,
        workDetailState: {
          ...state.workDetailState,
          threads: state.workDetailState.threads.map((v: ThreadType) =>
            v.id === thread.id ? thread : v,
          ),
        },
      };
    }
    case 'UPDATE_WORK': {
      const { work: targetWork } = action.payload;
      const timelineState = {
        ...state.timelineState,
        works: state.timelineState.works.map((work: WorkType) =>
          work.id === targetWork.id ? targetWork : work,
        ),
      };
      const newestWorksState = {
        ...state.newestWorksState,
        works: state.newestWorksState.works.map((work: WorkType) =>
          work.id === targetWork.id ? targetWork : work,
        ),
      };
      const pickUpWorksState = {
        ...state.pickUpWorksState,
        works: state.pickUpWorksState.works.map((work: WorkType) =>
          work.id === targetWork.id ? targetWork : work,
        ),
      };
      const searchWorksState = {
        ...state.searchWorksState,
        works: state.searchWorksState.works.map((work: WorkType) =>
          work.id === targetWork.id ? targetWork : work,
        ),
      };
      const searchUsersState = {
        ...state.searchUsersState,
        userSummaries: state.searchUsersState.userSummaries.map((userSummary: UserSummaryType) => ({
          ...userSummary,
          works: userSummary.works.map((work: WorkType) =>
            work.id === targetWork.id ? targetWork : work,
          ),
        })),
      };
      const likeUsersState = {
        ...state.likeUsersState,
        userSummaries: state.likeUsersState.userSummaries.map((userSummary: UserSummaryType) => ({
          ...userSummary,
          works: userSummary.works.map((work: WorkType) =>
            work.id === targetWork.id ? targetWork : work,
          ),
        })),
      };
      const userDetailState = {
        ...state.userDetailState,
        works: state.userDetailState.works.map((work: WorkType) =>
          work.id === targetWork.id ? targetWork : work,
        ),
      };
      const myPageState = {
        ...state.myPageState,
        works: state.myPageState.works.map((work: WorkType) =>
          work.id === targetWork.id ? targetWork : work,
        ),
      };
      const workDetailState = {
        ...state.workDetailState,
        work: {
          ...(state.workDetailState.work.id === targetWork.id
            ? targetWork
            : state.workDetailState.work),
          user: state.workDetailState.work.user, // workはフォロー状態とってなくてリセットされるのでそのままにしておく
        },
        userWorks: state.workDetailState.userWorks.map((work: WorkType) =>
          work.id === targetWork.id ? targetWork : work,
        ),
      };
      const officialEventResultState = {
        ...state.officialEventResultState,
        teamOneWorks: state.officialEventResultState.teamOneWorks.map((work: WorkType) =>
          work.id === targetWork.id ? targetWork : work,
        ),
        teamTwoWorks: state.officialEventResultState.teamTwoWorks.map((work: WorkType) =>
          work.id === targetWork.id ? targetWork : work,
        ),
        works: state.officialEventResultState.works.map((work: WorkType) =>
          work.id === targetWork.id ? targetWork : work,
        ),
      };
      const officialEventDetailState = {
        ...state.officialEventDetailState,
        works: state.officialEventDetailState.works.map((work: WorkType) =>
          work.id === targetWork.id ? targetWork : work,
        ),
      };
      return {
        ...state,
        timelineState,
        newestWorksState,
        pickUpWorksState,
        searchWorksState,
        searchUsersState,
        likeUsersState,
        userDetailState,
        myPageState,
        workDetailState,
        officialEventResultState,
        officialEventDetailState,
      };
    }
    case 'UPDATE_FOLLOW_STATE': {
      const { user: targetUser } = action.payload;
      const userDetailState = {
        ...state.userDetailState,
        user:
          state.userDetailState.user.id === targetUser.id ? targetUser : state.userDetailState.user,
      };
      const workDetailState = {
        ...state.workDetailState,
        work: {
          ...state.workDetailState.work,
          isFollowed:
            state.workDetailState.work.user.id === targetUser.id
              ? targetUser.isFollowed
              : state.workDetailState.work.user.isFollowed,
        },
      };
      return {
        ...state,
        userDetailState,
        workDetailState,
      };
    }
    case 'UPDATE_LIKE_USERS_STATE': {
      const { likeUsersState } = action.payload;
      return {
        ...state,
        likeUsersState,
      };
    }
    case 'UPDATE_FOLLOWED_USERS_STATE': {
      const { followedUsersState } = action.payload;
      return {
        ...state,
        followedUsersState,
      };
    }
    case 'UPDATE_FOLLOWER_USERS_STATE': {
      const { followerUsersState } = action.payload;
      return {
        ...state,
        followerUsersState,
      };
    }
    case 'UPDATE_REPLIES_STATE': {
      const { repliesState } = action.payload;
      return {
        ...state,
        repliesState,
      };
    }
    case 'POSTED_THREAD': {
      const { newThread } = action.payload;
      return {
        ...state,
        repliesState: {
          ...state.repliesState,
          threads: state.repliesState.threads.concat(Array(newThread)),
        },
        workDetailState: {
          ...state.workDetailState,
          threads:
            newThread.parentId > 0
              ? state.workDetailState.threads.map((thread) =>
                  thread.id === newThread.parentId
                    ? {
                        ...thread,
                        threadCount: thread.threadCount + 1,
                      }
                    : thread,
                )
              : Array(newThread).concat(state.workDetailState.threads),
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
