import { ThreadType } from '@/app/model/ThreadType';
import defaultUserEvent, { UserEventType } from '@/app/model/UserEventType';
import defaultWork, { WorkType } from '../model/WorkType';

export interface IWorkDetailState {
  work: WorkType;
  userEvent: UserEventType;
  userWorks: WorkType[];
  threads: ThreadType[];
  threadNextUrl: string;
  shouldReload: boolean; // 一覧から移動した場合に強制reload
}

export const defaultWorkDetailState: IWorkDetailState = {
  work: defaultWork,
  userEvent: defaultUserEvent,
  userWorks: [],
  threads: [],
  threadNextUrl: '',
  shouldReload: false,
};
