import { defaultFollowedUsersState, IFollowedUsersState } from '@/app/state/IFollowedUsersState';
import { defaultFollowerUsersState, IFollowerUsersState } from '@/app/state/IFollowerUsersState';
import { defaultLikeUsersState, ILikeUsersState } from '@/app/state/ILikeUsersState';
import { defaultMeState, IMeState } from '@/app/state/IMeState';
import { defaultSearchUsersState, ISearchUsersState } from '@/app/state/ISearchUsersState';
import { defaultSearchWorksState, ISearchWorksState } from '@/app/state/ISearchWorksState';
import { defaultTemplateResultState, ITemplateResultState } from '@/app/state/ITemplateResultState';
import { defaultUserDetailState, UserDetailStateType } from '@/app/state/UserDetailStateType';
import { defaultWorkDetailState, IWorkDetailState } from '@/app/state/IWorkDetailState';
import { defaultWorksState, WorksStateType } from '@/app/state/WorksStateType';
import { defaultMyPageState, MyPageStateType } from '@/app/state/MyPageState';
import { defaultNotificationsState, INotificationsState } from '@/app/state/INotificationsState';
import { defaultRepliesState, IRepliesState } from '@/app/state/IRepliesState';
import {
  defaultHeldOfficialEventState,
  IHeldOfficialEventState,
} from '@/app/state/IHeldOfficialEventState';
import {
  defaultOfficialEventDetailState,
  IOfficialEventDetailState,
} from '@/app/state/IOfficialEventDetailState';
import {
  defaultOfficialEventResultState,
  OfficialEventResultStateType,
} from '@/app/state/OfficialEventResultStateType';

export interface IGlobalState {
  timelineState: WorksStateType;
  newestWorksState: WorksStateType;
  pickUpWorksState: WorksStateType;
  searchWorksState: ISearchWorksState;
  searchUsersState: ISearchUsersState;
  officialEventDetailState: IOfficialEventDetailState;
  officialEventResultState: OfficialEventResultStateType;
  likeUsersState: ILikeUsersState;
  followedUsersState: IFollowedUsersState;
  followerUsersState: IFollowerUsersState;
  userDetailState: UserDetailStateType;
  meState: IMeState;
  myPageState: MyPageStateType;
  workDetailState: IWorkDetailState;
  templateResultState: ITemplateResultState;
  notificationsState: INotificationsState;
  repliesState: IRepliesState;
  heldOfficialEventState: IHeldOfficialEventState;
}

export const defaultGlobalState: IGlobalState = {
  timelineState: defaultWorksState,
  newestWorksState: defaultWorksState,
  pickUpWorksState: defaultWorksState,
  searchWorksState: defaultSearchWorksState,
  searchUsersState: defaultSearchUsersState,
  officialEventDetailState: defaultOfficialEventDetailState,
  officialEventResultState: defaultOfficialEventResultState,
  likeUsersState: defaultLikeUsersState,
  followedUsersState: defaultFollowedUsersState,
  followerUsersState: defaultFollowerUsersState,
  userDetailState: defaultUserDetailState,
  meState: defaultMeState,
  myPageState: defaultMyPageState,
  workDetailState: defaultWorkDetailState,
  templateResultState: defaultTemplateResultState,
  notificationsState: defaultNotificationsState,
  repliesState: defaultRepliesState,
  heldOfficialEventState: defaultHeldOfficialEventState,
};

export default defaultGlobalState;
