import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import BadgeComponent from '@/app/component/BadgeComponent';

interface IProps {
  text: string;
  color: string;
}

const MediumBadgeComponent = (props: IProps) => {
  const { text, color } = props;

  return (
    <BadgeComponent
      text={text}
      color={color}
      width={120}
      height={32}
      textStyle={DotpictTextStyle.BOLD14}
    />
  );
};

export default MediumBadgeComponent;
