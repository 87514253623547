import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import InputComponent from '@/app/component/InputComponent';
import ButtonComponent from '@/app/component/ButtonComponent';
import TranslationKeys from '@/app/translation/TranslationKeys';
import { Column, HorizontalGravity } from '@/app/component/base/layout/Layout';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import useTranslator from '@/app/hooks/useTranslator';
import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import useLogin from '@/app/component/page/login/useLogin';

export type LoginState = {
  mailAddress: string;
  password: string;
  errorMessage: string;
};

export type LoginProps = LoginState & {
  handleMailAddress: (newMailAddress: string) => void;
  handlePassword: (newPassword: string) => void;
  handleLoginButtonClick: () => void;
};

const Login = () => {
  const translator = useTranslator();

  const {
    mailAddress,
    password,
    errorMessage,
    handleMailAddress,
    handlePassword,
    handleLoginButtonClick,
  } = useLogin();

  return (
    <Column horizontalGravity={HorizontalGravity.center}>
      <Spacer height={40} />
      <Column
        horizontalGravity={HorizontalGravity.center}
        width={400}
        background={DotpictColorStyle.WHITE}
      >
        <Spacer height={32} />
        <InputComponent
          width={320}
          height={48}
          placeHolder={translator(TranslationKeys.Email)}
          isHidden={false}
          value={mailAddress}
          onTextChange={(mailAddress: string) => handleMailAddress(mailAddress)}
        />
        <Spacer height={20} />
        <InputComponent
          width={320}
          height={48}
          placeHolder={translator(TranslationKeys.Password)}
          isHidden
          value={password}
          onTextChange={(password: string) => handlePassword(password)}
        />
        <Spacer height={32} />
        <ButtonComponent
          width={320}
          height={48}
          text={translator(TranslationKeys.Login)}
          isFilled
          handleClick={handleLoginButtonClick}
        />
        {errorMessage !== '' && (
          <>
            <Spacer height={8} />
            <Text
              text={errorMessage}
              textStyle={DotpictTextStyle.BOLD12}
              colorStyle={DotpictColorStyle.ERROR}
            />
          </>
        )}
        <Spacer height={32} />
      </Column>
    </Column>
  );
};

export default Login;
