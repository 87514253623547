import { useContext, useState, useCallback } from 'react';
import styled from '@emotion/styled';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import Text from '@/app/component/Text';
import { SizeType } from '@/app/component/page/draw/Context/SizeContext';
import { TitleContext } from '@/app/component/page/draw/Context/TitleContext';
import CanvasSizeSelector from '@/app/component/page/draw/Header/CanvasSizeSelector';
import TitleEditor from '@/app/component/page/draw/Header/TitleEditor';

const TitleAndSizeContainer = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: auto auto auto;
`;

const TitleContainer = styled.div``;

type Props = {
  selectSize: (selected: SizeType) => () => void;
  editTitle(title: string): void;
};

const TitleAndSizeSelector = ({ selectSize, editTitle }: Props) => {
  const [titleEditMode, setTitleEditMode] = useState(false);
  const title = useContext(TitleContext);

  const handleClickContainer = useCallback(() => {
    setTitleEditMode(true);
  }, []);

  const handleCloseEditor = useCallback(() => {
    setTitleEditMode(false);
  }, []);

  if (titleEditMode) {
    return <TitleEditor onClose={handleCloseEditor} editTitle={editTitle} />;
  }

  return (
    <TitleAndSizeContainer>
      <TitleContainer onClick={handleClickContainer}>
        <Text
          text={title}
          textStyle={DotpictTextStyle.BOLD16}
          colorStyle={DotpictColorStyle.BASE}
        />
      </TitleContainer>
      {/* TODO: →はsvgにする */}／
      <CanvasSizeSelector selectSize={selectSize} />
    </TitleAndSizeContainer>
  );
};

export default TitleAndSizeSelector;
