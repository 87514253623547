import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Button } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import VerticalContainerStyle from '@/app/style/VerticalContainerStyle';
import Works from '@/app/component/Works';
import HorizontalContainerStyle from '@/app/style/HorizontalContainerStyle';
import DefaultGridStyle from '@/app/style/DefaultGridStyle';
import { APIContext, GlobalContext, LayoutContext } from '@/app/component/RootComponent';
import { UserDetailStateType } from '@/app/state/UserDetailStateType';
import { defaultDummyItemCount } from '@/app/common/Constant';
import shouldFetchNext from '@/app/common/Common';
import Grid from '@/app/image/grid.svg';
import GridSelected from '@/app/image/grid_selected.svg';
import List from '@/app/image/list.svg';
import ListSelected from '@/app/image/list_selected.svg';
import useLocalStorage from '@/app/customhook/useLocalStorage';
import PortfolioProfileHeaderComponent from '@/app/component/PortfolioProfileHeaderComponent';
import { DotpictAction } from '@/app/reducer/reducer';

const UserInfoContainerStyle = css(VerticalContainerStyle, {
  justifyContent: 'center',
  alignItems: 'center',
});

const IconStyle = css({
  width: 24,
  height: 24,
});

const PortfolioUserDetailComponent = () => {
  const accountName = window.location.host.split('.')[0];
  const { globalState, dispatch } = useContext(GlobalContext);
  const { layoutParams } = useContext(LayoutContext);
  const { client } = useContext(APIContext);

  const [loading, setLoading] = useState(false);
  const [isGrid, setGrid] = useLocalStorage('isGrid', false);

  const listImageSize = layoutParams.isMobile ? 320 : 394;

  const GridStyle = css(DefaultGridStyle, {
    gridGap: layoutParams.thumbnailImageGridGap,
    gridTemplateColumns: `repeat(${Math.floor(
      layoutParams.contentWidth / layoutParams.thumbnailImageSize,
    )}, ${layoutParams.thumbnailImageSize}px)`,
  });

  const ListStyle = css(DefaultGridStyle, {
    gridGap: '64px',
    gridTemplateColumns: `repeat(1, ${listImageSize}px)`,
  });

  const renderOgp = () => (
    <Helmet>
      <meta property="twitter:title" content={globalState.userDetailState.user.name} />
      <meta property="twitter:description" content={globalState.userDetailState.user.text} />
      <meta property="twitter:image" content={globalState.userDetailState.user.profileImageUrl} />
      <meta property="og:title" content={globalState.userDetailState.user.name} />
      <meta property="og:description" content={globalState.userDetailState.user.text} />
      <meta property="og:image" content={globalState.userDetailState.user.profileImageUrl} />
    </Helmet>
  );

  const fetchData = async (currentState: UserDetailStateType) => {
    setLoading(true);
    const userWorksResponse = await client.fetchUserWorks({ nextUrl: currentState.nextUrl });
    const action: DotpictAction = {
      type: 'UPDATE_USER_DETAIL_STATE',
      payload: {
        userDetailState: {
          user: userWorksResponse.user,
          works: currentState.works.concat(userWorksResponse.works),
          nextUrl: userWorksResponse.nextUrl,
        },
      },
    };
    dispatch(action);
    setLoading(false);
  };

  const fetchDataByAccountName = async () => {
    setLoading(true);
    const user = await client.fetchUsersByAccountName(accountName);

    const userWorksResponse = await client.fetchUserWorks({
      nextUrl: `${client.getBaseUrl}/users/${user.id}/works`,
    });
    const action: DotpictAction = {
      type: 'UPDATE_USER_DETAIL_STATE',
      payload: {
        userDetailState: {
          user: userWorksResponse.user,
          works: userWorksResponse.works,
          nextUrl: userWorksResponse.nextUrl,
        },
      },
    };
    dispatch(action);
    setLoading(false);
  };

  const handleScroll = () => {
    if (!loading && shouldFetchNext(globalState.userDetailState.nextUrl)) {
      fetchData(globalState.userDetailState);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    if (accountName === globalState.userDetailState.user.account) return;
    window.scrollTo(0, 0);
    fetchDataByAccountName();
  }, [accountName]);

  const handleGridClick = () => {
    setGrid(true);
  };

  const handleListClick = () => {
    setGrid(false);
  };

  return (
    <>
      {renderOgp()}
      <div css={UserInfoContainerStyle}>
        <SquareSpaceComponent size={layoutParams.contentTopSpace} />
        <PortfolioProfileHeaderComponent user={globalState.userDetailState.user} />
        <SquareSpaceComponent size={32} />
        <div css={HorizontalContainerStyle}>
          <Button onClick={handleListClick}>
            <img css={IconStyle} src={isGrid ? List : ListSelected} alt="list" />
          </Button>
          <Button onClick={handleGridClick}>
            <img css={IconStyle} src={isGrid ? GridSelected : Grid} alt="grid" />
          </Button>
        </div>
        <SquareSpaceComponent size={32} />
      </div>
      <Works
        works={globalState.userDetailState.works}
        isLoading={loading}
        isVisibleEnd={globalState.userDetailState.nextUrl === ''}
        isVisibleAds={false}
        isVisibleLike={false}
        gridStyle={isGrid ? GridStyle : ListStyle}
        imageSize={isGrid ? layoutParams.thumbnailImageSize : listImageSize}
        dummyItemCount={defaultDummyItemCount}
      />
    </>
  );
};

export default PortfolioUserDetailComponent;
