import { ThreadType } from '@/app/model/ThreadType';
import { DotpictAction } from '@/app/reducer/reducer';
import { useContext } from 'react';
import { APIContext, GlobalContext, MeContext } from '@/app/component/RootComponent';
import {
  ThreadLikeAction,
  ThreadLikeState,
} from '@/app/component/part/work/thread/like/ThreadLike';
import { WorkType } from '@/app/model/WorkType';

type Props = {
  work: WorkType;
  thread: ThreadType;
};

const useThreadLike = ({ work, thread }: Props): ThreadLikeState & ThreadLikeAction => {
  const meState = useContext(MeContext);
  const { dispatch } = useContext(GlobalContext);
  const isMyWork = meState.user.id > 0 && meState.user.id === work.user.id;
  const isMyThread = meState.user.id > 0 && meState.user.id === thread.user.id;
  const { client } = useContext(APIContext);

  const request = async () => {
    const newThread = thread.isLikedByAuthor
      ? await client.deleteLikeThread(meState.token, thread.workId, thread.id)
      : await client.postLikeThread(meState.token, thread.workId, thread.id);
    const action: DotpictAction = {
      type: 'UPDATE_THREAD_STATE',
      payload: { thread: newThread },
    };
    dispatch(action);
  };

  const handleClickLike = () => {
    request();
  };

  return {
    workOwnerProfileImageUrl: work.user.profileImageUrl,
    isVisibleLikeButton: isMyWork && !isMyThread && !thread.isLikedByAuthor,
    isVisibleAuthorProfileImageOnLikeButton: thread.isLikedByAuthor,
    handleClickLike,
  };
};

export default useThreadLike;
