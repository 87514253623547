import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import Works from '@/app/component/Works';
import { APIContext, GlobalContext } from '@/app/component/RootComponent';
import { css } from '@emotion/react';
import { defaultDummyItemCount } from '@/app/common/Constant';
import shouldFetchNext from '@/app/common/Common';
import { DotpictAction } from '@/app/reducer/reducer';
import { WorkType } from '../model/WorkType';

const imageSize = 128;

const gridStyle = css({
  display: 'grid',
  justifyContent: 'center',
  gridGap: '8px',
  gridTemplateColumns: `repeat(auto-fill, ${imageSize}px)`,
});

const NewestWorksComponent = () => {
  const { globalState, dispatch } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const { client } = useContext(APIContext);

  const fetchData = async (currentWorks: WorkType[], currentNextUrl: string | null) => {
    setLoading(true);
    const nextUrl = currentNextUrl || `${client.getBaseUrl}/v2/works`;
    const response = await client.fetchWorks(nextUrl);
    const works = currentWorks.concat(response.works);
    const action: DotpictAction = {
      type: 'UPDATE_NEWEST_WORKS_STATE',
      payload: {
        newestWorksState: {
          works,
          nextUrl: response.nextUrl,
        },
      },
    };
    dispatch(action);
    setLoading(false);
    if (shouldFetchNext(response.nextUrl)) {
      fetchData(works, response.nextUrl);
    }
  };

  const handleScroll = () => {
    if (!loading && shouldFetchNext(globalState.newestWorksState.nextUrl)) {
      fetchData(globalState.newestWorksState.works, globalState.newestWorksState.nextUrl);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    if (globalState.newestWorksState.works.length !== 0) return;
    fetchData([], null);
  }, []);

  return (
    <Works
      works={globalState.newestWorksState.works}
      isLoading={loading}
      isVisibleEnd={globalState.newestWorksState.nextUrl === ''}
      isVisibleAds
      isVisibleLike
      gridStyle={gridStyle}
      imageSize={imageSize}
      dummyItemCount={defaultDummyItemCount}
    />
  );
};

export default NewestWorksComponent;
