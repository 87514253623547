import { ColorCodeType } from '@/app/model/ColorCodeType';
import emptyUser, { UserType } from './UserType';

export type CropRectType = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export type WorkType = {
  id: number;
  title: string;
  caption: string;
  tags: string[];
  likeCount: number;
  thumbnailImageUrl: string;
  imageUrl: string;
  ogpImageUrl: string;
  width: number;
  height: number;
  user: UserType;
  isLike: boolean;
  createdAt: string;
  allowThread: boolean;
  isAnimation: boolean;
  userEventId: number;
  officialEventId: number;
  shareUrl: string;
  colorCodes: ColorCodeType[];
  cropRect: CropRectType | null;
};

const defaultWork: WorkType = {
  id: 0,
  title: '',
  caption: '',
  tags: [],
  likeCount: 0,
  thumbnailImageUrl: '',
  imageUrl: '',
  ogpImageUrl: '',
  width: 0,
  height: 0,
  user: emptyUser,
  isLike: false,
  createdAt: '',
  allowThread: false,
  isAnimation: false,
  userEventId: 0,
  officialEventId: 0,
  shareUrl: '',
  colorCodes: [],
  cropRect: null,
};

export default defaultWork;
