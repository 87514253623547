import TranslationKeys from '@/app/translation/TranslationKeys';
import { articleLineSpace } from '@/app/common/Constant';
import { IOldArticle } from '@/app/article/IOldArticle';
import ArticleContentType from '@/app/article/ArticleContentType';

const PrivacyArticle: IOldArticle = {
  titleTranslationKey: TranslationKeys.PrivacyTitle,
  contents: [
    { type: ArticleContentType.TEXT, content: TranslationKeys.PrivacyText1 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.PrivacyText2 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.PrivacyText3 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.PrivacyText4 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.PrivacyText5 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.PrivacyText6 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.PrivacyText7 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.PrivacyText8 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.PrivacyText9 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.PrivacyText10 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.PrivacyText11 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.PrivacyText12 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.PrivacyText13 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.PrivacyText14 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.PrivacyText15 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.PrivacyText16 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.PrivacyText17 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.PrivacyText18 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.PrivacyText19 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.PrivacyText20 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.PrivacyText21 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.PrivacyText22 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.PrivacyText23 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.PrivacyText24 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.PrivacyText25 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.PrivacyText26 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.PrivacyText27 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.PrivacyText28 },
    { type: ArticleContentType.SPACE, content: articleLineSpace },
    { type: ArticleContentType.TEXT, content: TranslationKeys.PrivacyText29 },
    { type: ArticleContentType.TEXT, content: TranslationKeys.PrivacyText30 },
  ],
};

export default PrivacyArticle;
