import styled from '@emotion/styled';

export type ColorRectProps = {
  color: string;
  width: number;
  height: number;
  borderWidth?: number;
  borderColor?: string;
};

const ColorRect = ({ color, width, height, borderWidth, borderColor }: ColorRectProps) => {
  const ColorRect = styled.div({
    border: `solid ${borderWidth ?? 0}px #${borderColor ?? 'F4F6F9'}`,
    background: `#${color}`,
    width,
    height,
  });

  return <ColorRect />;
};

export default ColorRect;
