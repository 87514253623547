import { NotificationType } from '@/app/model/NotificationType';
import { Column } from '@/app/component/base/layout/Layout';
import Notification from '@/app/component/part/notification/Notification';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import DotpictLink from '@/app/component/DotpictLinkComponent';
import End from '@/app/component/dotpict/end/End';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import Text from '@/app/component/Text';
import useTranslator from '@/app/hooks/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import useNotifications from '@/app/component/page/notifications/useNotifications';
import Loading from '@/app/component/dotpict/loading/Loading';

export type NotificationsBindModel = {
  notifications: NotificationType[];
  isLoading: boolean;
  isVisibleEnd: boolean;
};

const Notifications = () => {
  const translator = useTranslator();
  const { bindModel } = useNotifications();

  return (
    <div>
      <Column>
        <Spacer height={40} />
        <Text
          text={translator(TranslationKeys.Notifications)}
          textStyle={DotpictTextStyle.BOLD20}
          colorStyle={DotpictColorStyle.BASE}
        />
        <Spacer height={16} />
        {bindModel.notifications.map((notification) => (
          <>
            <DotpictLink to={`${notification.url?.replace('https://dotpict.net', '')}`}>
              <Notification notification={notification} background={DotpictColorStyle.WHITE} />
            </DotpictLink>
            <Spacer height={1} />
          </>
        ))}
        {bindModel.isLoading && <Loading />}
        {bindModel.isVisibleEnd && <End />}
      </Column>
    </div>
  );
};

export default Notifications;
