import { Column, HorizontalGravity, Row } from '@/app/component/base/layout/Layout';
import Text from '@/app/component/Text';
import useTranslator from '@/app/hooks/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import GooglePlay from '@/app/component/part/app/googleplay/GooglePlay';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import AppStore from '@/app/component/part/app/appstore/AppStore';
import ExternalLinkComponent from '@/app/component/ExternalLinkComponent';

const DrawTrial = () => {
  const translator = useTranslator();
  return (
    <Column
      padding={24}
      background={DotpictColorStyle.WHITE}
      horizontalGravity={HorizontalGravity.center}
    >
      <Text
        text={translator(TranslationKeys.TrialTitle)}
        textStyle={DotpictTextStyle.BOLD16}
        colorStyle={DotpictColorStyle.BASE}
      />
      <Spacer height={16} />
      <Text
        text={translator(TranslationKeys.TrialMessage1)}
        textStyle={DotpictTextStyle.REGULAR13}
        colorStyle={DotpictColorStyle.BASE}
      />
      <Spacer height={16} />
      <Row>
        <GooglePlay />
        <SquareSpaceComponent size={4} />
        <AppStore />
      </Row>
      <Spacer height={16} />
      <Text
        text={translator(TranslationKeys.TrialMessage2)}
        textStyle={DotpictTextStyle.REGULAR13}
        colorStyle={DotpictColorStyle.BASE}
      />
      <Spacer height={16} />
      <Text
        text={translator(TranslationKeys.TrialMessage3)}
        textStyle={DotpictTextStyle.REGULAR13}
        colorStyle={DotpictColorStyle.BASE}
      />
      <Spacer height={16} />
      <ExternalLinkComponent
        text={translator(TranslationKeys.SendFeedback)}
        url="https://docs.google.com/forms/d/e/1FAIpQLSdgTBP6YblQ3FYUSqztdSci8L0zcbgNfcyiAg7rjQOrzgVjPg/viewform"
        textStyle={DotpictTextStyle.REGULAR13}
        colorStyle={DotpictColorStyle.PRIMARY}
      />
    </Column>
  );
};

export default DrawTrial;
