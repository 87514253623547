import { GetThreadsService } from '@/app/service/GetThreads/GetThreadsService';
import { defaultThread } from '@/app/model/ThreadType';

const dummyGetThreadsService: GetThreadsService = (_: string) => {
  return Promise.resolve({
    threads: [
      {
        ...defaultThread,
        text: 'dummyText',
      },
    ],
    nextUrl: '',
  });
};

export default dummyGetThreadsService;
