import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { GlobalContext } from '@/app/component/RootComponent';
import { WorkDetailProps } from '@/app/component/page/work/detail/WorkDetail';
import { defaultWorkDetailState, IWorkDetailState } from '@/app/state/IWorkDetailState';
import shouldFetchNext from '@/app/common/Common';
import { useNavigate } from 'react-router-dom';
import { WorkType } from '@/app/model/WorkType';
import { GetWorkDetailService } from '@/app/service/GetWorkDetail/GetWorkDetailService';
import dummyGetWorkDetailService from '@/app/service/GetWorkDetail/dummyGetWorkDetailService';
import { GetThreadsService } from '@/app/service/GetThreads/GetThreadsService';
import dummyGetThreadsService from '@/app/service/GetThreads/dummyGetThreadsService';
import { GetUserWorksService } from '@/app/service/GetUserWorks/GetUserWorksService';
import dummyGetUserWorksService from '@/app/service/GetUserWorks/dummyGetUserWorksService';
import { GetUserEventDetailService } from '@/app/service/GetUserEventDetail/GetUserEventDetailService';
import dummyGetUserEventDetailService from '@/app/service/GetUserEventDetail/dummyGetUserEventDetailService';
import usePagingBuilder from '@/app/apiclient/PagingBuilder';

const userWorksItemCount = 4;

export type Dependencies = {
  getWorkDetailService: GetWorkDetailService;
  getThreadsService: GetThreadsService;
  getUserWorksService: GetUserWorksService;
  getUserEventDetailService: GetUserEventDetailService;
};

export const WorkDetailContext = createContext<Dependencies>({
  getWorkDetailService: dummyGetWorkDetailService,
  getThreadsService: dummyGetThreadsService,
  getUserWorksService: dummyGetUserWorksService,
  getUserEventDetailService: dummyGetUserEventDetailService,
});

type Props = {
  workId: number;
};

const useWorkDetail = ({ workId }: Props): WorkDetailProps => {
  const { globalState, dispatch } = useContext(GlobalContext);
  const [isLoadingThread, setLoadingThread] = useState(false);
  const [isLoadingUserWorks, setLoadingUserWorks] = useState(false);
  const navigator = useNavigate();
  const pagingBuilder = usePagingBuilder();
  const {
    getWorkDetailService,
    getThreadsService,
    getUserWorksService,
    getUserEventDetailService,
  } = useContext(WorkDetailContext);

  const reloadThread = async (workDetailState: IWorkDetailState, threadNextUrl: string) => {
    setLoadingThread(true);
    const threadsResponse = await getThreadsService(threadNextUrl);
    dispatch({
      type: 'UPDATE_WORK_DETAIL_STATE',
      payload: {
        workDetailState: {
          ...workDetailState,
          threads: workDetailState.threads.concat(threadsResponse.threads),
          threadNextUrl: threadsResponse.nextUrl,
        },
      },
    });
    setLoadingThread(false);
  };

  const reload = async () => {
    if (workId === 0) return;

    setLoadingUserWorks(true);

    const work = await getWorkDetailService(workId);
    const threadNextUrl = pagingBuilder(`/works/${workId}/threads`);
    const workDetailState = {
      ...defaultWorkDetailState,
      work,
      threadNextUrl,
    };
    dispatch({
      type: 'UPDATE_WORK_DETAIL_STATE',
      payload: {
        workDetailState,
      },
    });

    const userEvent =
      work.userEventId > 0 ? await getUserEventDetailService(`${work.userEventId}`) : null;

    const userWorksResponse = await getUserWorksService({
      nextUrl: pagingBuilder(`/users/${work.user.id.toString()}/works`),
    });
    const workDetailStateWithUserWorksAndUserEvents = {
      ...workDetailState,
      work: {
        ...workDetailState.work,
        user: userWorksResponse.user,
      },
      userWorks: userWorksResponse.works
        .filter((userWork: WorkType) => userWork.id !== work.id)
        .slice(0, userWorksItemCount),
      userEvent: userEvent ?? workDetailState.userEvent,
    };
    dispatch({
      type: 'UPDATE_WORK_DETAIL_STATE',
      payload: {
        workDetailState: workDetailStateWithUserWorksAndUserEvents,
      },
    });

    setLoadingUserWorks(false);

    if (work.allowThread) {
      reloadThread(workDetailStateWithUserWorksAndUserEvents, threadNextUrl);
    }
  };

  const handleScroll = useCallback(() => {
    if (
      !globalState.workDetailState.work.allowThread ||
      isLoadingThread ||
      !shouldFetchNext(globalState.workDetailState.threadNextUrl, window.innerHeight / 2)
    ) {
      return;
    }
    reloadThread(globalState.workDetailState, globalState.workDetailState.threadNextUrl);
  }, [globalState.workDetailState, isLoadingThread]);

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    const storedWorkId = globalState.workDetailState.work.id;
    if (workId === storedWorkId && !globalState.workDetailState.shouldReload) {
      return;
    }
    dispatch({
      type: 'UPDATE_WORK_DETAIL_STATE',
      payload: {
        workDetailState:
          workId === storedWorkId ? globalState.workDetailState : defaultWorkDetailState,
      },
    });
    window.scrollTo(0, 0);
    reload();
  }, [workId]);

  const handleClickEditWork = () => {
    navigator(`/works/${workId}/edit`);
  };

  return {
    work: globalState.workDetailState.work,
    ogpImageUrl: globalState.workDetailState.work.ogpImageUrl,
    workTitle: globalState.workDetailState.work.title,
    workCaption: globalState.workDetailState.work.caption,
    workImageUrl: globalState.workDetailState.work.imageUrl,
    tags: globalState.workDetailState.work.tags,
    isLiked: globalState.workDetailState.work.isLike,
    likeCount: globalState.workDetailState.work.likeCount,
    width: globalState.workDetailState.work.width,
    height: globalState.workDetailState.work.height,
    createdAt: globalState.workDetailState.work.createdAt,
    shareUrl: globalState.workDetailState.work.shareUrl,
    threads: globalState.workDetailState.threads,
    threadNextUrl: globalState.workDetailState.threadNextUrl,
    isAllowThread: globalState.workDetailState.work.allowThread,
    userId: globalState.workDetailState.work.user.id,
    user: globalState.workDetailState.work.user,
    userName: globalState.workDetailState.work.user.name,
    isFollowed: globalState.workDetailState.work.user.isFollowed,
    userWorks: globalState.workDetailState.userWorks,
    userEventId: globalState.workDetailState.userEvent.id,
    isVisibleEvent: globalState.workDetailState.userEvent.id > 0,
    eventTitle: globalState.workDetailState.userEvent.title,
    isVisibleEdit:
      globalState.meState.user.id > 0 &&
      globalState.meState.user.id === globalState.workDetailState.work.user.id,
    isLoadingThread,
    isLoadingUserWorks,
    handleClickEditWork,
  };
};

export default useWorkDetail;
