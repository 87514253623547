import { defaultThread, ThreadType } from '@/app/model/ThreadType';
import defaultWork, { WorkType } from '../model/WorkType';

export type IRepliesState = {
  work: WorkType;
  parentThread: ThreadType;
  threads: ThreadType[];
  requestedUserId: number;
};

export const defaultRepliesState: IRepliesState = {
  work: defaultWork,
  parentThread: defaultThread,
  threads: [],
  requestedUserId: 0,
};
