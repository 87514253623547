import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useTranslator from '@/app/hooks/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';
import { SizeType } from '@/app/component/page/draw/Context/SizeContext';
import { v4 as uuidv4 } from 'uuid';
import { PixelsType, CanvasData, StorageDrawData, PaletteType } from './types';

const convertStorageDrawDateToCanvasData = (
  id: number,
  storageDrawData: StorageDrawData,
): CanvasData => {
  const width = Number(storageDrawData.width);
  const height = Number(storageDrawData.height);
  const flatColorCodes = storageDrawData.pixels.split(',');
  const pixels = Array.from({ length: height }, () => flatColorCodes.splice(0, width));
  return {
    id,
    title: storageDrawData.title,
    canvasSize: { width, height },
    palette: storageDrawData.palette.split(','),
    pixels,
    officialEventInfo:
      storageDrawData.officialEventInfoJson !== undefined
        ? JSON.parse(storageDrawData.officialEventInfoJson)
        : undefined,
    synchronizedAt: new Date(storageDrawData.synchronizedAt),
    updatedAt: new Date(storageDrawData.updatedAt),
  };
};

/*
  これから描くキャンバスのIDを取得するhooks
  クエリによってどれを描画中かの切り替えを行う
  /draw にキャンバス一覧ページからクエリつきで遷移してきたときに切り替える設計

  クエリのパターンは以下の通り
  * /draw?current=[:id] でアクセスしたときはcurrentを :id に変更（編集のケース）
  * /draw でアクセスしたときはcurrentがlocalStorageにあればそのキャンバスから再開。localStorageになければnewと同様
*/
export const useCurrentStorageKey = () => {
  const storageKey = 'draw_current_id';
  const navigate = useNavigate();
  const location = useLocation();

  const currentId = useMemo(() => {
    const params = new URLSearchParams(location.search);
    const rawCurrentId = localStorage.getItem(storageKey);
    // 基本はLocalStorageのcurrentIdを引き継ぎ、クエリにcurrentがある場合にcurrentに変更
    const currentId = params.has('current') ? Number(params.get('current')) : Number(rawCurrentId);
    // LocalStorageに保存しておく
    localStorage.setItem(storageKey, `${currentId}`);
    return currentId;
  }, [location]);

  useEffect(() => {
    // 不要になったクエリはURL欄から削除しておく
    if (location.search !== '') {
      navigate(location.pathname, { replace: true });
    }
  }, [location]);

  return currentId;
};

// キャンバスのデータをLocalStorageで管理するhooks
// ページ初期化時のキャンバス復元と、ピクセル追加時のデータ上書きで用いる
export const useCurrentCanvasData = () => {
  const currentId = useCurrentStorageKey();
  const storageKey = `draw_data_${currentId}`;

  const rawStorageData = localStorage.getItem(storageKey);
  const storageData =
    rawStorageData !== null ? (JSON.parse(rawStorageData) as StorageDrawData) : null;

  const setStorageData = useCallback(
    (canvasSize: SizeType, pixels: PixelsType, palette: PaletteType) => {
      if (storageData === null) return;
      const newStorageData: StorageDrawData = {
        uuid: storageData.uuid,
        title: storageData.title,
        width: canvasSize.width.toString(),
        height: canvasSize.height.toString(),
        palette: palette.join(),
        pixels: pixels.join(),
        officialEventInfoJson: storageData.officialEventInfoJson,
        synchronizedAt: storageData.synchronizedAt,
        updatedAt: new Date().toUTCString(),
      };

      localStorage.setItem(storageKey, JSON.stringify(newStorageData));
    },
    [storageKey, storageData],
  );

  const setStorageDataForTitle = useCallback(
    (title: string) => {
      if (storageData === null) return;
      const newStorageData: StorageDrawData = {
        uuid: storageData.uuid,
        title,
        width: storageData.width,
        height: storageData.height,
        palette: storageData.palette,
        pixels: storageData.pixels,
        officialEventInfoJson: storageData.officialEventInfoJson,
        synchronizedAt: storageData.synchronizedAt,
        updatedAt: new Date().toUTCString(),
      };

      localStorage.setItem(storageKey, JSON.stringify(newStorageData));
    },
    [storageKey, storageData],
  );

  const setStorageDataForPalette = useCallback(
    (palette: PaletteType) => {
      if (storageData === null) return;
      const newStorageData: StorageDrawData = {
        uuid: storageData.uuid,
        title: storageData.title,
        width: storageData.width,
        height: storageData.height,
        palette: palette.join(),
        pixels: storageData.pixels,
        officialEventInfoJson: storageData.officialEventInfoJson,
        synchronizedAt: storageData.synchronizedAt,
        updatedAt: new Date().toUTCString(),
      };

      localStorage.setItem(storageKey, JSON.stringify(newStorageData));
    },
    [storageKey, storageData],
  );

  return [
    storageData !== null ? convertStorageDrawDateToCanvasData(currentId, storageData) : null,
    setStorageData,
    setStorageDataForTitle,
    setStorageDataForPalette,
  ] as [
    CanvasData,
    typeof setStorageData,
    typeof setStorageDataForTitle,
    typeof setStorageDataForPalette,
  ];
};

// キャンバス一覧を取得
export const useCanvasesData = () => {
  const [storageData, setStorageData] = useState<CanvasData[]>([]);

  useEffect(() => {
    // TODO: 更新日順に並び替えを行う
    const keys = Object.keys(localStorage).filter((key) => /^draw_data_\d+$/.test(key));
    const canvasItems = keys.map<CanvasData | null>((key) => {
      const match = key.match(/^draw_data_(\d+)$/);
      if (match === null) {
        return null;
      }
      const id = Number(match[1]);
      const storageRawData = localStorage.getItem(key);
      if (storageRawData === null) {
        return null;
      }
      return convertStorageDrawDateToCanvasData(id, JSON.parse(storageRawData));
    });

    setStorageData(canvasItems.filter((item): item is NonNullable<typeof item> => item !== null));
  }, []);

  return [storageData] as [typeof storageData];
};

export const useCanvasActions = () => {
  // TODO
  const postData = useCallback(() => {}, []);

  const deleteData = useCallback((id: number) => {
    localStorage.removeItem(`draw_data_${id}`);
  }, []);

  return [postData, deleteData] as [typeof postData, typeof deleteData];
};

export const useCreateCanvasData = () => {
  const currentId = useCurrentStorageKey();
  const translator = useTranslator();

  const createCanvasData = (
    canvasSize: SizeType,
    palette: PaletteType,
    pixels: PixelsType,
    officialEventTag: string | undefined = undefined,
    officialEventTeamTag: string | undefined = undefined,
  ): number => {
    const newCanvasId = Number(currentId) + 1;
    localStorage.setItem(
      `draw_data_${newCanvasId}`,
      JSON.stringify({
        uuid: uuidv4(),
        title: `${translator(TranslationKeys.DefaultCanvasTitle)}${newCanvasId}`,
        width: canvasSize.width,
        height: canvasSize.height,
        palette: palette.join(),
        pixels: pixels.join(),
        officialEventInfoJson:
          officialEventTag !== undefined
            ? JSON.stringify({
                officialEventTag,
                officialEventTeamTag,
              })
            : undefined,
        synchronizedAt: new Date(0).toUTCString(), // デフォルト値としてUnixTimeの初期時間入れておく
        updatedAt: new Date().toUTCString(),
      }),
    );
    return newCanvasId;
  };

  return { createCanvasData };
};
