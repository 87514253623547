import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import { Skeleton } from '@material-ui/lab';
import { Helmet } from 'react-helmet';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import VerticalContainerStyle from '@/app/style/VerticalContainerStyle';
import HorizontalContainerStyle from '@/app/style/HorizontalContainerStyle';
import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import { APIContext, GlobalContext, LayoutContext, MeContext } from '@/app/component/RootComponent';
import { defaultWorkDetailState } from '@/app/state/IWorkDetailState';
import ShareComponent from '@/app/component/ShareComponent';
import PortfolioProfileHeaderComponent from '@/app/component/PortfolioProfileHeaderComponent';
import { DotpictAction } from '@/app/reducer/reducer';

const contentCenterItemsCenterVerticalContainerStyle = css(VerticalContainerStyle, {
  justifyContent: 'center',
  alignItems: 'center',
});

const contentStartItemsEndHorizontalContainerStyle = css(HorizontalContainerStyle, {
  justifyContent: 'start',
  alignItems: 'flex-end',
});

const contentStartItemsCenterHorizontalContainerStyle = css(HorizontalContainerStyle, {
  justifyContent: 'start',
  alignItems: 'center',
});

const contentSpaceBetweenItemsStartHorizontalContainerStyle = css(HorizontalContainerStyle, {
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'start',
});

const PortfolioWorkDetailComponent = () => {
  const { workId } = useParams();
  const { globalState, dispatch } = useContext(GlobalContext);
  const meState = useContext(MeContext);
  const { layoutParams } = useContext(LayoutContext);
  const { client } = useContext(APIContext);

  const detailImageSize = layoutParams.isMobile ? 320 : 384;

  const detailImageStyle = css({
    width: detailImageSize,
    height: detailImageSize,
    imageRendering: ['pixelated', 'crisp-edges'],
    objectFit: 'contain',
  });

  const workInfoVerticalContainerStyle = css(VerticalContainerStyle, {
    justifyContent: 'start',
    alignItems: 'start',
    padding: 24,
    background: '#FFFFFF',
    width: layoutParams.contentWidth - 24 * 2,
  });

  const renderOgp = () => (
    <Helmet>
      <meta property="twitter:title" content={globalState.workDetailState.work.title} />
      <meta
        property="twitter:description"
        content={`${globalState.workDetailState.work.title} - ${globalState.workDetailState.work.user.name}`}
      />
      <meta property="twitter:image" content={globalState.workDetailState.work.ogpImageUrl} />
      <meta property="og:title" content={globalState.workDetailState.work.title} />
      <meta
        property="og:description"
        content={`${globalState.workDetailState.work.title} - ${globalState.workDetailState.work.user.name}`}
      />
      <meta property="og:image" content={globalState.workDetailState.work.ogpImageUrl} />
    </Helmet>
  );

  useEffect(() => {
    if (workId === undefined) return;
    const storedWorkId = `${globalState.workDetailState.work.id}`;

    if (workId !== storedWorkId) {
      window.scrollTo(0, 0);
      const resetAction: DotpictAction = {
        type: 'UPDATE_WORK_DETAIL_STATE',
        payload: {
          workDetailState: defaultWorkDetailState,
        },
      };
      dispatch(resetAction);
    }

    if (workId !== storedWorkId) {
      const fetchData = async () => {
        const work = await client.fetchWorkDetail(Number(workId));
        const updateWorkDetailAction: DotpictAction = {
          type: 'UPDATE_WORK_DETAIL_STATE',
          payload: {
            workDetailState: {
              ...defaultWorkDetailState,
              work,
            },
          },
        };
        dispatch(updateWorkDetailAction);
      };
      fetchData();
    }
  }, [workId, meState]);

  return (
    <>
      {renderOgp()}
      <div css={contentCenterItemsCenterVerticalContainerStyle}>
        <SquareSpaceComponent size={layoutParams.contentTopSpace} />
        {globalState.workDetailState.work.imageUrl === '' ? (
          <Skeleton variant="rect" width={detailImageSize} height={detailImageSize} />
        ) : (
          <img
            alt={globalState.workDetailState.work.title}
            src={globalState.workDetailState.work.imageUrl}
            css={detailImageStyle}
          />
        )}
        <SquareSpaceComponent size={40} />
        <div css={workInfoVerticalContainerStyle}>
          <div css={contentSpaceBetweenItemsStartHorizontalContainerStyle}>
            <div css={VerticalContainerStyle}>
              <div css={contentStartItemsEndHorizontalContainerStyle}>
                <Text
                  text={globalState.workDetailState.work.title}
                  textStyle={DotpictTextStyle.BOLD20}
                  colorStyle={DotpictColorStyle.BASE}
                />
                {globalState.workDetailState.work.tags.map((tag) => (
                  <React.Fragment key={tag}>
                    <SquareSpaceComponent size={12} />
                    <Text
                      text={`#${tag}`}
                      textStyle={DotpictTextStyle.BOLD16}
                      colorStyle={DotpictColorStyle.PRIMARY}
                    />
                  </React.Fragment>
                ))}
              </div>
              <SquareSpaceComponent size={12} />
              <div css={contentStartItemsCenterHorizontalContainerStyle}>
                <Text
                  text={`${globalState.workDetailState.work.width}x${globalState.workDetailState.work.height}`}
                  textStyle={DotpictTextStyle.REGULAR14}
                  colorStyle={DotpictColorStyle.BASE}
                />
                <SquareSpaceComponent size={12} />
                <Text
                  text={globalState.workDetailState.work.createdAt}
                  textStyle={DotpictTextStyle.REGULAR14}
                  colorStyle={DotpictColorStyle.BASE}
                />
              </div>
              <SquareSpaceComponent size={16} />
              <ShareComponent
                url={`${window.location.protocol}//${window.location.host}/works/${workId}`}
                title={`${globalState.workDetailState.work.title} #dotpict`}
              />
            </div>
          </div>
        </div>
        <PortfolioProfileHeaderComponent user={globalState.workDetailState.work.user} />
        <SquareSpaceComponent size={40} />
      </div>
    </>
  );
};

export default PortfolioWorkDetailComponent;
