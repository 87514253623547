import defaultBattleInfo, { BattleInfoType } from '@/app/model/BattleInfoType';

export type OfficialEventType = {
  id: number;
  imageUrl: string;
  layerImageUrl0: string;
  layerImageUrl1: string;
  layerImageUrl2: string;
  activeLayerIndex: number;
  colorCodes: string[];
  title: string;
  canvasTitle: string;
  tag: string;
  backgroundColorCode: string;
  startAt: string;
  endAt: string;
  isBattleEvent: boolean;
  battleInfo: BattleInfoType;
  createdAt: string;
  bannerImageUrl: string;
  shareText: string;
  shareUrl: string;
};

const defaultOfficialEvent: OfficialEventType = {
  id: 0,
  imageUrl: '',
  layerImageUrl0: '',
  layerImageUrl1: '',
  layerImageUrl2: '',
  activeLayerIndex: 0,
  colorCodes: [],
  title: '',
  canvasTitle: '',
  tag: '',
  backgroundColorCode: '',
  startAt: '',
  endAt: '',
  isBattleEvent: false,
  battleInfo: defaultBattleInfo,
  createdAt: '',
  bannerImageUrl: '',
  shareText: '',
  shareUrl: '',
};

export default defaultOfficialEvent;
