import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { APIContext, GlobalContext, MeContext } from '@/app/component/RootComponent';
import { IOfficialEventDetailState } from '@/app/state/IOfficialEventDetailState';
import TranslationKeys from '@/app/translation/TranslationKeys';
import useTranslator from '@/app/hooks/useTranslator';

const useOfficialEventContainer = () => {
  const { officialEventTag } = useParams();
  const { client } = useContext(APIContext);
  const meState = useContext(MeContext);
  const { globalState, dispatch } = useContext(GlobalContext);
  const translator = useTranslator();

  const fetchOfficialEvent = async () => {
    if (officialEventTag === undefined) return;
    const officialEvent = await client.fetchOfficialEventDetail(officialEventTag);
    const newState: IOfficialEventDetailState = {
      officialEvent,
      mainImageUrl: officialEvent.imageUrl,
      tag: officialEvent.tag,
      templateImageUrl: officialEvent.layerImageUrl0,
      colors: officialEvent.colorCodes,
      shareTitle: officialEvent.shareText,
      twitterShareUrl: officialEvent.shareUrl,
      facebookShareUrl: officialEvent.shareUrl,
      bannerImageUrl: officialEvent.bannerImageUrl,
      isLoadingWorks: false,
      isVisibleEnd: false,
      works: [],
      nextUrl: `${client.getBaseUrl}/official_events/${officialEvent.id}/works`,
      requestedUserId: meState.user.id,
      isBattleEvent: officialEvent.isBattleEvent,
      description: officialEvent.battleInfo.description ?? '',
      teamOneText: translator(TranslationKeys.JoinTeam, {
        value: officialEvent.battleInfo.teamOne.name,
      }),
      teamOneTag: officialEvent.battleInfo.teamOne.tag,
      teamOneScore: officialEvent.battleInfo.teamOne.point,
      teamOneColor: `#${officialEvent.battleInfo.teamOne.colorCode}`,
      teamTwoText: translator(TranslationKeys.JoinTeam, {
        value: officialEvent.battleInfo.teamTwo.name,
      }),
      teamTwoTag: officialEvent.battleInfo.teamTwo.tag,
      teamTwoScore: officialEvent.battleInfo.teamTwo.point,
      teamTwoColor: `#${officialEvent.battleInfo.teamTwo.colorCode}`,
    };
    dispatch({
      type: 'UPDATE_OFFICIAL_EVENT_DETAIL_STATE',
      payload: { officialEventDetailState: newState },
    });
  };

  useEffect(() => {
    if (officialEventTag === globalState.officialEventDetailState.tag) return;
    fetchOfficialEvent();
  }, [officialEventTag]);

  return globalState.officialEventDetailState;
};

export default useOfficialEventContainer;
