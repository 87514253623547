import { Column, HorizontalGravity, Row } from '@/app/component/base/layout/Layout';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import Text from '@/app/component/Text';
import TranslationKeys from '@/app/translation/TranslationKeys';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import { Tooltip } from '@material-ui/core';
import ImageComponent from '@/app/component/ImageComponent';
import DotpictLinkComponent from '@/app/component/DotpictLinkComponent';
import styled from '@emotion/styled';
import useTranslator from '@/app/hooks/useTranslator';

const Note = styled.div`
  padding: 24px;
  background: #fafafa;
`;

const ExampleRoot = styled.div`
  position: relative;
`;

const ExampleIcon = styled.div`
  left: 0;
  top: 0;
  position: absolute;
`;

const GuideSection = () => {
  const translator = useTranslator();

  return (
    <Note>
      <Row>
        <PixelArtComponent
          alt="caution"
          src="https://storage.googleapis.com/dotpict-images/web/caution.svg"
          width={24}
          height={24}
        />
        <Spacer width={10} />
        <Text
          text={translator(TranslationKeys.PostCautionTitle)}
          textStyle={DotpictTextStyle.BOLD20}
          colorStyle={DotpictColorStyle.BASE}
        />
      </Row>
      <Spacer height={10} />
      <Text
        text={translator(TranslationKeys.PostCautionSummary)}
        textStyle={DotpictTextStyle.REGULAR14}
        colorStyle={DotpictColorStyle.BASE}
      />
      <Spacer height={24} />
      <Text
        text={translator(TranslationKeys.PostCautionExample)}
        textStyle={DotpictTextStyle.BOLD14}
        colorStyle={DotpictColorStyle.BASE}
      />
      <Spacer height={4} />
      <Text
        text={translator(TranslationKeys.PostCautionExampleDetail)}
        textStyle={DotpictTextStyle.REGULAR14}
        colorStyle={DotpictColorStyle.BASE}
      />
      <Spacer height={24} />
      <Row width="100%" horizontalGravity={HorizontalGravity.center}>
        <Tooltip title={translator(TranslationKeys.PostCautionPixelart)}>
          <ExampleRoot>
            <Column horizontalGravity={HorizontalGravity.center}>
              <PixelArtComponent
                alt="example_pixelart"
                src="https://storage.googleapis.com/dotpict-images/web/guideline/picko_pixelart.png"
                width={144}
                height={144}
              />
              <Spacer height={4} />
              <Text
                text={translator(TranslationKeys.Pixelart)}
                textStyle={DotpictTextStyle.REGULAR11}
                colorStyle={DotpictColorStyle.BASE}
              />
            </Column>
            <ExampleIcon>
              <PixelArtComponent
                alt="example_pixelart"
                src="https://storage.googleapis.com/dotpict-images/web/ok_pixelart.svg"
                width={24}
                height={24}
              />
            </ExampleIcon>
          </ExampleRoot>
        </Tooltip>
        <Spacer width={24} />
        <Tooltip title={translator(TranslationKeys.PostCautionBinaryart)}>
          <ExampleRoot>
            <Column horizontalGravity={HorizontalGravity.center}>
              <PixelArtComponent
                alt="example_pixelart"
                src="https://storage.googleapis.com/dotpict-images/web/guideline/picko_binaryart.png"
                width={144}
                height={144}
              />
              <Spacer height={4} />
              <Text
                text={translator(TranslationKeys.Binaryart)}
                textStyle={DotpictTextStyle.REGULAR11}
                colorStyle={DotpictColorStyle.BASE}
              />
            </Column>
            <ExampleIcon>
              <PixelArtComponent
                alt="example_pixelart"
                src="https://storage.googleapis.com/dotpict-images/web/ok_pixelart.svg"
                width={24}
                height={24}
              />
            </ExampleIcon>
          </ExampleRoot>
        </Tooltip>
        <Spacer width={24} />
        <Tooltip title={translator(TranslationKeys.PostCautionConvert)}>
          <ExampleRoot>
            <Column horizontalGravity={HorizontalGravity.center}>
              <PixelArtComponent
                alt="example_pixelart"
                src="https://storage.googleapis.com/dotpict-images/web/guideline/picko_converter.png"
                width={144}
                height={144}
              />
              <Spacer height={4} />
              <Text
                text={translator(TranslationKeys.Convert)}
                textStyle={DotpictTextStyle.REGULAR11}
                colorStyle={DotpictColorStyle.BASE}
              />
            </Column>
            <ExampleIcon>
              <PixelArtComponent
                alt="example_pixelart"
                src="https://storage.googleapis.com/dotpict-images/web/not_pixelart.svg"
                width={24}
                height={24}
              />
            </ExampleIcon>
          </ExampleRoot>
        </Tooltip>
      </Row>
      <Spacer height={24} />
      <Row width="100%" horizontalGravity={HorizontalGravity.center}>
        <Tooltip title={translator(TranslationKeys.ImageReductionDetail)}>
          <ExampleRoot>
            <Column horizontalGravity={HorizontalGravity.center}>
              <ImageComponent
                alt="example_pixelart"
                src="https://storage.googleapis.com/dotpict-images/web/guideline/picko_image_reduction.png"
                width={144}
                height={144}
              />
              <Spacer height={4} />
              <Text
                text={translator(TranslationKeys.ImageReduction)}
                textStyle={DotpictTextStyle.REGULAR11}
                colorStyle={DotpictColorStyle.BASE}
              />
            </Column>
            <ExampleIcon>
              <PixelArtComponent
                alt="example_pixelart"
                src="https://storage.googleapis.com/dotpict-images/web/not_pixelart.svg"
                width={24}
                height={24}
              />
            </ExampleIcon>
          </ExampleRoot>
        </Tooltip>
        <Spacer width={24} />
        <Tooltip title={translator(TranslationKeys.PostCautionOekaki)}>
          <ExampleRoot>
            <Column horizontalGravity={HorizontalGravity.center}>
              <ImageComponent
                alt="example_pixelart"
                src="https://storage.googleapis.com/dotpict-images/web/guideline/picko_oekaki.png"
                width={144}
                height={144}
              />
              <Spacer height={4} />
              <Text
                text={translator(TranslationKeys.Oekaki)}
                textStyle={DotpictTextStyle.REGULAR11}
                colorStyle={DotpictColorStyle.BASE}
              />
            </Column>
            <ExampleIcon>
              <PixelArtComponent
                alt="example_pixelart"
                src="https://storage.googleapis.com/dotpict-images/web/not_pixelart.svg"
                width={24}
                height={24}
              />
            </ExampleIcon>
          </ExampleRoot>
        </Tooltip>
        <Spacer width={24} />
        <Tooltip title={translator(TranslationKeys.PostCautionPicture)}>
          <ExampleRoot>
            <Column horizontalGravity={HorizontalGravity.center}>
              <ImageComponent
                alt="example_pixelart"
                src="https://storage.googleapis.com/dotpict-images/web/guideline/picko_picture.png"
                width={144}
                height={144}
              />
              <Spacer height={4} />
              <Text
                text={translator(TranslationKeys.Picture)}
                textStyle={DotpictTextStyle.REGULAR11}
                colorStyle={DotpictColorStyle.BASE}
              />
            </Column>
            <ExampleIcon>
              <PixelArtComponent
                alt="example_pixelart"
                src="https://storage.googleapis.com/dotpict-images/web/not_pixelart.svg"
                width={24}
                height={24}
              />
            </ExampleIcon>
          </ExampleRoot>
        </Tooltip>
      </Row>
      <Spacer height={24} />
      <Text
        text={translator(TranslationKeys.PostCautionExampleDetail2)}
        textStyle={DotpictTextStyle.REGULAR11}
        colorStyle={DotpictColorStyle.BASE}
      />
      <Spacer height={16} />
      <Text
        text={translator(TranslationKeys.PostCautionSummary2)}
        textStyle={DotpictTextStyle.REGULAR14}
        colorStyle={DotpictColorStyle.BASE}
      />
      <Spacer height={4} />
      <DotpictLinkComponent to="/guidelines">
        <Text
          text={translator(TranslationKeys.Guidelines)}
          textStyle={DotpictTextStyle.REGULAR14}
          colorStyle={DotpictColorStyle.PRIMARY}
        />
      </DotpictLinkComponent>
    </Note>
  );
};

export default GuideSection;
