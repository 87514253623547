import emptyUser, { UserType } from './UserType';

export type ThreadType = {
  id: number;
  parentId: number;
  workId: number;
  user: UserType;
  text: string;
  threadCount: number;
  isLikedByAuthor: boolean;
  createdAt: string;
};

export const defaultThread: ThreadType = {
  id: 0,
  parentId: 0,
  workId: 0,
  user: emptyUser,
  text: '',
  threadCount: 0,
  isLikedByAuthor: false,
  createdAt: '',
};
