import HorizontalContainerStyle from '@/app/style/HorizontalContainerStyle';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import VerticalContainerStyle from '@/app/style/VerticalContainerStyle';
import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import { css } from '@emotion/react';
import { useContext } from 'react';
import { LayoutContext } from '@/app/component/RootComponent';
import { Link } from 'react-router-dom';
import { UserType } from '../model/UserType';

const ContentSpaceBetweenItemsStartHorizontalContainerStyle = css(HorizontalContainerStyle, {
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'start',
});

interface IProps {
  user: UserType;
}

const PortfolioProfileHeaderComponent = (props: IProps) => {
  const { user } = props;
  const { layoutParams } = useContext(LayoutContext);

  const UserInfoVerticalContainerStyle = css(VerticalContainerStyle, {
    justifyContent: 'start',
    alignItems: 'start',
    padding: 24,
    background: '#FFFFFF',
    width: layoutParams.contentWidth - 24 * 2,
  });

  return (
    <div css={UserInfoVerticalContainerStyle}>
      <div css={ContentSpaceBetweenItemsStartHorizontalContainerStyle}>
        <div css={HorizontalContainerStyle}>
          <Link to="/">
            <PixelArtComponent alt={user.name} src={user.profileImageUrl} width={96} height={96} />
          </Link>
          <SquareSpaceComponent size={16} />
          <div css={VerticalContainerStyle}>
            <Link to="/">
              <Text
                text={user.name}
                textStyle={DotpictTextStyle.BOLD16}
                colorStyle={DotpictColorStyle.BASE}
              />
            </Link>
            <SquareSpaceComponent size={12} />
            <a href={user.url}>
              <Text
                text={user.url}
                textStyle={DotpictTextStyle.BOLD12}
                colorStyle={DotpictColorStyle.PRIMARY}
              />
            </a>
          </div>
        </div>
      </div>
      <SquareSpaceComponent size={16} />
      <Text
        text={user.text}
        textStyle={DotpictTextStyle.REGULAR14}
        colorStyle={DotpictColorStyle.BASE}
      />
    </div>
  );
};

export default PortfolioProfileHeaderComponent;
