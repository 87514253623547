import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import {
  Column,
  HorizontalGravity,
  Row,
  VerticalGravity,
} from '@/app/component/base/layout/Layout';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import ButtonComponent from '@/app/component/ButtonComponent';
import TranslationKeys from '@/app/translation/TranslationKeys';
import useTranslator from '@/app/hooks/useTranslator';
import useDownloadableImage from '@/app/component/page/eventdetail/useDownloadableImage';
import { useNavigate } from 'react-router-dom';
import HowToUsePalette from '@/app/component/dotpict/howtousepalette/HowToUsePalette';

export type HowToJoinEventProps = {
  mainImageUrl: string;
  tag: string;
  isVisibleTemplate: boolean;
  templateImageUrl: string;
  colors: string[];
};

const HowToJoinEvent = ({
  mainImageUrl,
  tag,
  isVisibleTemplate,
  templateImageUrl,
  colors,
}: HowToJoinEventProps) => {
  const translator = useTranslator();
  const { renderImage, downloadImage } = useDownloadableImage({
    imageName: tag,
    imageUrl: templateImageUrl,
  });
  const navigator = useNavigate();

  const handlePost = () => {
    navigator('/upload');
  };

  const renderTemplate = () => (
    <Column width="100%" horizontalGravity={HorizontalGravity.center}>
      <Spacer height={24} />
      <Row
        width="100%"
        horizontalGravity={HorizontalGravity.spaceBetween}
        verticalGravity={VerticalGravity.center}
      >
        <Row>
          <Spacer width={48} />
          <Column width={240}>
            <Text
              text={translator(TranslationKeys.DownloadTemplate)}
              textStyle={DotpictTextStyle.BOLD20}
              colorStyle={DotpictColorStyle.BASE}
            />
            <Spacer height={32} />
            {renderImage()}
            <ButtonComponent
              width={240}
              height={48}
              text={translator(TranslationKeys.Download)}
              textStyle={DotpictTextStyle.BOLD16}
              isFilled
              handleClick={() => downloadImage()}
            />
          </Column>
        </Row>
        <Row>
          <PixelArtComponent src={templateImageUrl} alt="template_image" width={256} height={256} />
          <Spacer width={48} />
        </Row>
      </Row>
      <Spacer height={24} />
      <PixelArtComponent
        src="https://storage.googleapis.com/dotpict-images/web/arrow_bottom_content.svg"
        alt="arrow_bottom_content"
        width={40}
        height={40}
      />
    </Column>
  );

  const renderPost = () => (
    <Column width="100%" horizontalGravity={HorizontalGravity.center}>
      <Row
        width="100%"
        horizontalGravity={HorizontalGravity.spaceBetween}
        verticalGravity={VerticalGravity.center}
      >
        <Row>
          <Spacer width={48} />
          <Column width={240}>
            <Text
              text={translator(TranslationKeys.PostToDotpict)}
              textStyle={DotpictTextStyle.BOLD20}
              colorStyle={DotpictColorStyle.BASE}
            />
            <Spacer height={32} />
            <ButtonComponent
              width={240}
              height={48}
              text={translator(TranslationKeys.Post)}
              textStyle={DotpictTextStyle.BOLD16}
              isFilled
              handleClick={() => handlePost()}
            />
          </Column>
        </Row>
        <Row>
          <PixelArtComponent src={mainImageUrl} alt="main_image" width={256} height={256} />
          <Spacer width={48} />
        </Row>
      </Row>
      <Spacer height={24} />
    </Column>
  );

  return (
    <Column
      width={680}
      background={DotpictColorStyle.WHITE}
      horizontalGravity={HorizontalGravity.center}
    >
      <Spacer height={20} />
      <Text
        text={translator(TranslationKeys.HowToPost)}
        textStyle={DotpictTextStyle.BOLD20}
        colorStyle={DotpictColorStyle.BASE}
      />
      <Spacer height={30} />
      {isVisibleTemplate && renderTemplate()}
      <HowToUsePalette paletteName={tag} colors={colors} />
      <Spacer height={24} />
      <PixelArtComponent
        src="https://storage.googleapis.com/dotpict-images/web/arrow_bottom_content.svg"
        alt="arrow_bottom_content"
        width={40}
        height={40}
      />
      <Spacer height={24} />
      {renderPost()}
    </Column>
  );
};

export default HowToJoinEvent;
