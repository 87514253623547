import { useContext } from 'react';
import { MyPageHeaderHeaderBindModel } from '@/app/component/page/mypage/MyPageHeader';
import { LayoutContext } from '@/app/component/RootComponent';
import TranslationKeys from '@/app/translation/TranslationKeys';
import useTranslator from '@/app/hooks/useTranslator';
import { UserType } from '../../../model/UserType';

type InputProps = {
  user: UserType;
};

export type OutputProps = {
  bindModel: MyPageHeaderHeaderBindModel;
};

const useMyPageHeader = ({ user }: InputProps): OutputProps => {
  const translator = useTranslator();
  const { layoutParams } = useContext(LayoutContext);

  return {
    bindModel: {
      id: user.id,
      name: user.name,
      account: user.account,
      headerImageUrl: user.headerImageUrl,
      profileImageUrl: user.profileImageUrl,
      url: user.url,
      text: user.text,
      followedCount: user.followedCount,
      followerCount: user.followerCount,
      registerAt: translator(TranslationKeys.RegistrationDate) + user.registerAt,
      birthDate: user.birthDate,
      contentWidth: layoutParams.contentWidth,
    },
  };
};

export default useMyPageHeader;
