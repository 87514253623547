import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import { css } from '@emotion/react';
import Works from '@/app/component/Works';
import DefaultGridStyle from '@/app/style/DefaultGridStyle';
import {
  APIContext,
  GlobalContext,
  LanguageContext,
  LayoutContext,
  MeContext,
} from '@/app/component/RootComponent';
import { defaultDummyItemCount } from '@/app/common/Constant';
import shouldFetchNext from '@/app/common/Common';
import { Helmet } from 'react-helmet';
import { defaultTemplateResultState, ITemplateResultState } from '@/app/state/ITemplateResultState';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import CenterVerticalContainerStyle from '@/app/style/CenterVerticalContainerStyle';
import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import { DotpictAction } from '@/app/reducer/reducer';

const TemplateEventComponent = () => {
  const { eventName } = useParams();
  const { globalState, dispatch } = useContext(GlobalContext);
  const { layoutParams } = useContext(LayoutContext);
  const meState = useContext(MeContext);
  const { language } = useContext(LanguageContext);
  const { client } = useContext(APIContext);

  const [loading, setLoading] = useState(false);

  const GridStyle = css(DefaultGridStyle, {
    gridGap: layoutParams.thumbnailImageGridGap,
    gridTemplateColumns: `repeat(${Math.floor(
      layoutParams.contentWidth / layoutParams.thumbnailImageSize,
    )}, ${layoutParams.thumbnailImageSize}px)`,
  });

  const TemplateHeaderContainerStyle = css(CenterVerticalContainerStyle, {
    padding: '0 48',
    background: DotpictColorStyle.WHITE,
    width: layoutParams.contentWidth - 48 * 2,
  });

  const renderOgp = () => (
    <Helmet>
      <meta property="twitter:title" content={globalState.templateResultState.title} />
      <meta property="twitter:description" content={globalState.templateResultState.description} />
      <meta property="twitter:image" content={globalState.templateResultState.ogpImageUrl} />
      <meta property="og:title" content={globalState.templateResultState.title} />
      <meta property="og:description" content={globalState.templateResultState.description} />
      <meta property="og:image" content={globalState.templateResultState.ogpImageUrl} />
    </Helmet>
  );

  const fetchData = async (currentState: ITemplateResultState) => {
    if (eventName === undefined) return;
    setLoading(true);
    const requestedUserId = meState.user.id;
    const response = await client.fetchTemplateResult(meState.token, currentState.nextUrl);
    const action: DotpictAction = {
      type: 'UPDATE_TEMPLATE_RESULT_STATE',
      payload: {
        templateResultState: {
          requestedUserId,
          imageUrl: response.imageUrl,
          ogpImageUrl: response.ogpImageUrl,
          eventName,
          title: response.title,
          description: response.description,
          works: currentState.works.concat(response.works),
          nextUrl: response.nextUrl,
        },
      },
    };
    dispatch(action);
    setLoading(false);
  };

  const handleScroll = () => {
    if (!loading && shouldFetchNext(globalState.templateResultState.nextUrl)) {
      fetchData(globalState.templateResultState);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    if (
      eventName !== globalState.templateResultState.eventName ||
      meState.user.id !== globalState.templateResultState.requestedUserId
    ) {
      window.scrollTo(0, 0);
      const state: ITemplateResultState = defaultTemplateResultState;
      state.nextUrl = `${client.getBaseUrl}/template_canvas_result/${eventName}?lang=${language}`;
      const action: DotpictAction = {
        type: 'UPDATE_TEMPLATE_RESULT_STATE',
        payload: { templateResultState: state },
      };
      dispatch(action);
      fetchData(state);
    }
  }, [eventName, meState.token]);

  return (
    <>
      {renderOgp()}
      <div css={CenterVerticalContainerStyle}>
        <SquareSpaceComponent size={layoutParams.contentTopSpace} />
        <div css={TemplateHeaderContainerStyle}>
          <SquareSpaceComponent size={48} />
          <PixelArtComponent
            alt="banner_image_url"
            src={globalState.templateResultState.imageUrl}
            width={layoutParams.isMobile ? 256 : 384}
            height={layoutParams.isMobile ? 128 : 192}
          />
          <SquareSpaceComponent size={32} />
          <Text
            text={globalState.templateResultState.title}
            textStyle={DotpictTextStyle.BOLD16}
            colorStyle={DotpictColorStyle.BASE}
          />
          <SquareSpaceComponent size={32} />
          <Text
            text={globalState.templateResultState.description}
            textStyle={DotpictTextStyle.REGULAR16}
            colorStyle={DotpictColorStyle.BASE}
          />
          <SquareSpaceComponent size={48} />
        </div>
        <SquareSpaceComponent size={32} />
      </div>
      <Works
        works={globalState.templateResultState.works}
        isLoading={loading}
        isVisibleEnd={globalState.templateResultState.nextUrl === ''}
        isVisibleAds={false}
        isVisibleLike={false}
        gridStyle={GridStyle}
        imageSize={layoutParams.thumbnailImageSize}
        dummyItemCount={defaultDummyItemCount}
      />
    </>
  );
};

export default TemplateEventComponent;
