import { css } from '@emotion/react';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import React from 'react';
import ButtonComponent from '@/app/component/ButtonComponent';
import Text from '@/app/component/Text';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import TranslationKeys from '@/app/translation/TranslationKeys';
import useTranslator from '@/app/hooks/useTranslator';
import usePage from '@/app/component/page/requestbox/settings/usePage';
import { Column, HorizontalGravity, Row } from '@/app/component/base/layout/Layout';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import TextFieldComponent from '@/app/component/TextFieldComponent';
import Loading from '@/app/component/dotpict/loading/Loading';

const ModalInnerStyle = css`
  background-color: white;
  margin-left: auto;
  margin-right: auto;

  padding: 32px 0 16px 0;

  /* モバイルは幅いっぱいまで */
  @media screen and (max-width: 680px) {
    padding: 20px;
  }
`;

export type RequestBoxSettingsBindModel = {
  isOpenModal: boolean;
  isLoading: boolean;
  isOpenedRequestBox: boolean;
  text: string;
};

export const InitialRequestBoxSettingsBindModel: RequestBoxSettingsBindModel = {
  isOpenModal: false,
  isLoading: false,
  isOpenedRequestBox: false,
  text: '',
};

type Props = {
  children: React.ReactNode;
};

const RequestBoxSettings = ({ children }: Props) => {
  const fullScreen = useMediaQuery('(max-width: 680px)');
  const translator = useTranslator();

  const {
    bindModel,
    handleClickOpen,
    handleClickClose,
    onChangeOpenRequestBox,
    onChangeText,
    handleClickSave,
  } = usePage();

  const NoPaddingButton = styled(Button)({
    display: 'inline-block',
    padding: 0,
    minHeight: 0,
    minWidth: 0,
  });

  const renderDialogContent = () => (
    <Row>
      <Spacer width={24} />
      <Column width="100%" horizontalGravity={HorizontalGravity.center}>
        <Text
          text={translator(TranslationKeys.RequestBoxSettings)}
          textStyle={DotpictTextStyle.BOLD20}
          colorStyle={DotpictColorStyle.BASE}
        />
        <Spacer height={20} />
        <Row width="100%" horizontalGravity={HorizontalGravity.spaceBetween}>
          <Text
            text={translator(TranslationKeys.RequestBoxEnabled)}
            textStyle={DotpictTextStyle.BOLD14}
            colorStyle={DotpictColorStyle.BASE}
          />
          <Switch
            checked={bindModel.isOpenedRequestBox}
            onChange={(value) => onChangeOpenRequestBox(value.target.checked)}
          />
        </Row>
        <Spacer height={26} />
        <Column width="100%">
          <TextFieldComponent
            id="text"
            label={translator(TranslationKeys.RequestBoxSummaryTitle)}
            placeholder={translator(TranslationKeys.RequestBoxSummaryHint)}
            value={bindModel.text}
            onChangeValue={(text) => onChangeText(text)}
            multiline
            showMaxLength
            maxLength={500}
            rows={4}
          />
        </Column>
        <Spacer height={40} />
        <ButtonComponent
          width={240}
          height={52}
          text={translator(TranslationKeys.Save)}
          isFilled
          handleClick={() => handleClickSave()}
        />
        <Spacer height={8} />
        <ButtonComponent
          width={240}
          height={52}
          text={translator(TranslationKeys.Cancel)}
          isFilled={false}
          handleClick={() => handleClickClose()}
        />
      </Column>
      <Spacer width={24} />
    </Row>
  );

  return (
    <>
      <NoPaddingButton onClick={() => handleClickOpen()}>{children}</NoPaddingButton>
      <Dialog
        maxWidth="sm"
        fullWidth
        fullScreen={fullScreen}
        open={bindModel.isOpenModal}
        onClose={() => handleClickClose()}
        scroll="body"
      >
        <div css={ModalInnerStyle}>{bindModel.isLoading ? <Loading /> : renderDialogContent()}</div>
      </Dialog>
    </>
  );
};

export default RequestBoxSettings;
