import { useContext, useState } from 'react';
import { APIContext, GlobalContext, MeContext } from '@/app/component/RootComponent';
import {
  ThreadInputAction,
  ThreadInputState,
} from '@/app/component/part/work/thread/input/ThreadInput';
import { DotpictAction } from '@/app/reducer/reducer';

type Props = {
  workId: number;
  parentThreadId?: number;
};

const useThreadInput = ({
  workId,
  parentThreadId,
}: Props): ThreadInputState & ThreadInputAction => {
  const meState = useContext(MeContext);
  const { dispatch } = useContext(GlobalContext);
  const [inputComment, setInputComment] = useState('');
  const { client } = useContext(APIContext);

  const postThread = async () => {
    const newThread = await client.postThread(
      meState.token,
      `${workId}`,
      `${parentThreadId ?? 0}`,
      inputComment,
    );
    setInputComment('');
    const action: DotpictAction = {
      type: 'POSTED_THREAD',
      payload: {
        newThread,
      },
    };
    dispatch(action);
  };

  const handleInputCommentChange = (comment: string) => {
    setInputComment(comment);
  };

  const handleSendCommentClick = () => {
    if (inputComment === '') return;
    postThread();
  };

  return {
    profileImageUrl: meState.user.profileImageUrl,
    inputComment,
    handleInputCommentChange,
    handleSendCommentClick,
  };
};

export default useThreadInput;
