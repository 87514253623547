import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';

export type HeadlineProps = {
  text: string;
};

const MakingHeadline = ({ text }: HeadlineProps) => (
  <Text text={text} textStyle={DotpictTextStyle.BOLD20} colorStyle={DotpictColorStyle.BASE} />
);

export default MakingHeadline;
