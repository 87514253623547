import { defaultMaking, MakingType } from '@/app/making/MakingType';
import { useContext, useEffect, useState } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import VerticalContainerStyle from '@/app/style/VerticalContainerStyle';
import { APIContext, LayoutContext, MeContext } from '@/app/component/RootComponent';
import { useLocation, useParams } from 'react-router-dom';
import useLocalStorage from '@/app/customhook/useLocalStorage';
import {
  MakingDetailAction,
  MakingDetailState,
} from '@/app/component/page/making/detail/MakingDetail';

const useMakingDetail = (): MakingDetailState & MakingDetailAction => {
  const { makingId } = useParams();
  const [making, setMaking] = useState<MakingType>(defaultMaking);
  const [anotherMakings, setAnotherMakings] = useState<MakingType[]>([]);
  const [isLiked, setIsLiked] = useState<boolean>(false);
  const { client } = useContext(APIContext);
  const meState = useContext(MeContext);
  const [likedMakingIds, setLikedMakingIds] = useLocalStorage<string[]>('liked_making_ids', []);
  const [fromApp, setFromApp] = useState(false);
  const location = useLocation();
  const [mainImageSize, setMainImageSize] = useState<number>(320);
  const [rootContainerStyle, setRootContainerStyle] =
    useState<SerializedStyles>(VerticalContainerStyle);
  const { layoutParams } = useContext(LayoutContext);

  const postLike = async () => {
    if (makingId === undefined) return;
    await client.postMakingLike(meState.token, makingId);
  };

  const handleClickLike = () => {
    setLikedMakingIds(likedMakingIds.concat([makingId]));
    setIsLiked(true);
    postLike();
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setFromApp(params.get('fromApp') === 'true');
  }, [location]);

  const reload = async () => {
    if (makingId === undefined) return;
    const targetMaking = await client.fetchMaking(meState.token, makingId);
    setIsLiked(likedMakingIds.includes(makingId));
    setMaking(targetMaking);
    const makings = await client.fetchMakings(meState.token);
    setAnotherMakings(makings.filter((making) => making.key !== makingId));
  };

  useEffect(() => {
    reload();
    window.scrollTo(0, 0);
  }, [makingId]);

  useEffect(() => {
    setMainImageSize(
      layoutParams.contentWidth - 16 * 2 < making.mainImageSize
        ? layoutParams.contentWidth - 16 * 2
        : making.mainImageSize,
    );
    setRootContainerStyle(
      css(VerticalContainerStyle, {
        width: layoutParams.contentWidth,
      }),
    );
  }, [making, layoutParams]);

  return {
    making,
    isLiked,
    fromApp,
    mainImageSize,
    rootContainerStyle,
    anotherMakings,
    handleClickLike,
  };
};

export default useMakingDetail;
