import { useParams } from 'react-router-dom';
import { APIContext, GlobalContext, LayoutContext, MeContext } from '@/app/component/RootComponent';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import VerticalContainerStyle from '@/app/style/VerticalContainerStyle';
import { useState, useLayoutEffect, useContext, useEffect } from 'react';
import shouldFetchNext from '@/app/common/Common';
import UsersComponent from '@/app/component/UsersComponent';
import SearchHeaderComponent from '@/app/component/SearchHeaderComponent';
import { defaultSearchUsersState } from '@/app/state/ISearchUsersState';
import { UserSummaryType } from '@/app/model/UserSummaryType';
import { DotpictAction } from '@/app/reducer/reducer';

const SearchUserResultComponent = () => {
  const { query } = useParams();
  const { layoutParams } = useContext(LayoutContext);
  const { globalState, dispatch } = useContext(GlobalContext);
  const meState = useContext(MeContext);
  const [loading, setLoading] = useState(false);
  const { client } = useContext(APIContext);

  const fetchData = async (
    currentUserSummaries: UserSummaryType[],
    currentNextUrl: string | null,
  ) => {
    if (query === undefined) return;
    setLoading(true);
    const requestedUserId = meState.user.id;
    const nextUrl =
      currentNextUrl || `${client.getBaseUrl}/users/search_name?name=${query}&work_count=6`;
    const response = await client.fetchUsers(meState.token, nextUrl);
    const userSummaries = currentUserSummaries.concat(response.userSummaries);
    const action: DotpictAction = {
      type: 'UPDATE_SEARCH_USERS_STATE',
      payload: {
        searchUsersState: {
          query,
          userSummaries,
          nextUrl: response.nextUrl,
          requestedUserId,
        },
      },
    };
    dispatch(action);
    setLoading(false);
    if (shouldFetchNext(response.nextUrl)) {
      fetchData(userSummaries, response.nextUrl);
    }
  };

  const handleScroll = () => {
    if (!loading && shouldFetchNext(globalState.searchUsersState.nextUrl)) {
      fetchData(globalState.searchUsersState.userSummaries, globalState.searchUsersState.nextUrl);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    if (globalState.searchUsersState.query !== query) {
      window.scrollTo(0, 0);
      const resetAction: DotpictAction = {
        type: 'UPDATE_SEARCH_USERS_STATE',
        payload: { searchUsersState: defaultSearchUsersState },
      };
      dispatch(resetAction);
    }

    if (
      globalState.searchUsersState.query !== query ||
      globalState.searchUsersState.requestedUserId !== meState.user.id
    ) {
      fetchData([], null);
    }
  }, [query, meState.token]);

  return (
    <div css={VerticalContainerStyle}>
      <SquareSpaceComponent size={layoutParams.contentTopSpace} />
      <SearchHeaderComponent type="user" query={query} />
      <SquareSpaceComponent size={16} />
      <UsersComponent
        userSummaries={globalState.searchUsersState.userSummaries}
        isLoading={loading}
        isVisibleEnd={globalState.searchUsersState.nextUrl === ''}
        isVisibleAds
        dummyItemCount={20}
      />
    </div>
  );
};

export default SearchUserResultComponent;
