import { Column, Grid, Row } from '@/app/component/base/layout/Layout';
import EventColorCode from '@/app/component/dotpict/event/colorcode/EventColorCode';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import Text from '@/app/component/Text';
import TranslationKeys from '@/app/translation/TranslationKeys';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import useTranslator from '@/app/hooks/useTranslator';
import ButtonComponent from '@/app/component/ButtonComponent';
import useDownloadPalette from '@/app/component/page/eventdetail/useDownloadPalette';

export type EventColorCodesProps = {
  paletteName: string;
  colors: string[];
};

const EventColorCodes = ({ paletteName, colors }: EventColorCodesProps) => {
  const translator = useTranslator();
  const downloadPaletteImage = useDownloadPalette({ paletteName, colors });

  return (
    <Column>
      <Text
        text={translator(TranslationKeys.EventColorCode)}
        textStyle={DotpictTextStyle.BOLD16}
        colorStyle={DotpictColorStyle.BASE}
      />
      <Spacer height={16} />
      <Grid>
        {colors.map((color, index) => (
          <Column key={color}>
            <Spacer height={16} />
            <Row>
              <EventColorCode color={color} />
              {index !== colors.length - 1 && <Spacer width={20} />}
            </Row>
          </Column>
        ))}
      </Grid>
      <Spacer height={20} />
      <ButtonComponent
        width={280}
        height={40}
        text={translator(TranslationKeys.EventDownloadColorCodes)}
        isBorder
        isFilled={false}
        handleClick={() => downloadPaletteImage()}
      />
    </Column>
  );
};

export default EventColorCodes;
