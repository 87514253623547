import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import { HorizontalGravity, Row, VerticalGravity } from '@/app/component/base/layout/Layout';
import TranslationKeys from '@/app/translation/TranslationKeys';
import { css } from '@emotion/react';
import useTranslator from '@/app/hooks/useTranslator';
import PixelArtComponent from '@/app/component/PixelArtComponent';

export type FileSelectButtonProps = {
  handleFileSelect: (file: File | undefined) => void;
};

const styledLabel = css`
  width: 120px;
  height: 32px;
`;

const styledInput = css`
  display: none;
`;

const OverlayFileSelectButton = ({ handleFileSelect }: FileSelectButtonProps) => {
  const translator = useTranslator();

  return (
    <label css={styledLabel} htmlFor="upload-file">
      <Row
        width={120}
        height={32}
        innerPadding={8}
        background="rgba(0, 0, 0, 0.8)"
        horizontalGravity={HorizontalGravity.center}
        verticalGravity={VerticalGravity.center}
      >
        <PixelArtComponent
          alt="image"
          src="https://storage.googleapis.com/dotpict-images/web/ic_image_white.svg"
          width={15}
          height={15}
        />
        <Text
          text={translator(TranslationKeys.ChangeImage)}
          textStyle={DotpictTextStyle.REGULAR14}
          colorStyle={DotpictColorStyle.WHITE}
        />
      </Row>
      <input
        id="upload-file"
        accept="image/png,image/gif"
        css={styledInput}
        type="file"
        onChange={(event) => {
          const file = event.target.files?.item(0);
          if (!file) return;
          handleFileSelect(file);
        }}
      />
    </label>
  );
};

export default OverlayFileSelectButton;
