import { HomeProps } from '@/app/component/page/home/index';
import { useContext, useEffect, useState } from 'react';
import {
  APIContext,
  GlobalContext,
  LanguageContext,
  LayoutContext,
  MeContext,
} from '@/app/component/RootComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import TranslationKeys from '@/app/translation/TranslationKeys';
import useTranslator from '@/app/hooks/useTranslator';
import useLocalStorage from '@/app/customhook/useLocalStorage';

const useHome = (): HomeProps => {
  const meState = useContext(MeContext);
  const { globalState, dispatch } = useContext(GlobalContext);
  const navigator = useNavigate();
  const location = useLocation();
  const translator = useTranslator();
  const [tabItems, setTabItems] = useState<string[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [homeType, setHomeType] = useLocalStorage('homeType', 'pickup');
  const { language } = useContext(LanguageContext);
  const { layoutParams } = useContext(LayoutContext);
  const { client } = useContext(APIContext);

  const types = meState.isLoggedIn ? ['timeline', 'pickup', 'newest'] : ['pickup', 'newest'];

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const type = params.get('type');
    if (type !== null && types.includes(type)) {
      setHomeType(type);
    }
  }, [location]);

  const reloadOfficialEvent = async () => {
    const response = await client.fetchNeta(meState.token);
    dispatch({
      type: 'UPDATE_HELD_OFFICIAL_EVENT_STATE',
      payload: {
        heldOfficialEventState: {
          officialEvent: response.officialEvent,
        },
      },
    });
  };

  useEffect(() => {
    reloadOfficialEvent();
  }, []);

  const moveToOfficialEvent = () => {
    navigator(`/officialEvents/${globalState.heldOfficialEventState.officialEvent.tag}`);
  };

  const handleClickTagItem = (index: number) => {
    setHomeType(types[index]);
  };

  useEffect(() => {
    if (!meState.isLoggedIn) {
      setTabItems([
        translator(TranslationKeys.PickupWorks),
        translator(TranslationKeys.NewestWorks),
      ]);
    } else {
      setTabItems([
        translator(TranslationKeys.FollowingWorks),
        translator(TranslationKeys.PickupWorks),
        translator(TranslationKeys.NewestWorks),
      ]);
    }
    const newIndex = types.indexOf(homeType);
    setSelectedIndex(newIndex >= 0 ? newIndex : 0);
  }, [meState.isLoggedIn, homeType, language]);

  return {
    isVisibleOfficialEvent: globalState.heldOfficialEventState.officialEvent.id > 0,
    isVisibleAboutDotpict: !meState.isLoggedIn,
    bannerImageUrl: globalState.heldOfficialEventState.officialEvent.bannerImageUrl,
    bannerWidth: (layoutParams.contentWidth * 2) / 3,
    bannerHeight: ((layoutParams.contentWidth * 2) / 3) * (592 / 1024),
    selectedIndex,
    types,
    tabItems,
    moveToOfficialEvent,
    handleClickTagItem,
  };
};

export default useHome;
