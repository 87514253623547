import styled from '@emotion/styled';
import { HorizontalGravity, Row, VerticalGravity } from '@/app/component/base/layout/Layout';
import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import useTranslator from '@/app/hooks/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';
import { Spacer } from '@/app/component/base/spacer/Spacer';

const ScoreRateContainer = styled.div`
  position: relative;
  width: 100%;
`;

const LeftUpBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

const LeftDownBackground = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`;

const RightUpBackground = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

const RightDownBackground = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`;

export type ScoreRateComponentProps = {
  teamOneScore: number;
  teamOneColor: string;
  teamTwoScore: number;
  teamTwoColor: string;
  contentWidth: number;
};

const ScoreRateComponent = (props: ScoreRateComponentProps) => {
  const { teamOneScore, teamOneColor, teamTwoScore, teamTwoColor, contentWidth } = props;
  const scoreSum = teamOneScore + teamTwoScore;
  const translator = useTranslator();

  const TeamOneColorRect = styled.div({
    background: teamOneColor,
  });

  const TeamTwoColorRect = styled.div({
    background: teamTwoColor,
  });

  return (
    <Row width={contentWidth} height={40}>
      <ScoreRateContainer>
        <LeftUpBackground>
          <Row width={8} height={8} background={DotpictColorStyle.WHITE_GRAY} />
        </LeftUpBackground>
        <LeftDownBackground>
          <Row width={8} height={8} background={DotpictColorStyle.WHITE_GRAY} />
        </LeftDownBackground>
        <RightUpBackground>
          <Row width={8} height={8} background={DotpictColorStyle.WHITE_GRAY} />
        </RightUpBackground>
        <RightDownBackground>
          <Row width={8} height={8} background={DotpictColorStyle.WHITE_GRAY} />
        </RightDownBackground>
        <Row>
          <TeamOneColorRect>
            <Row
              width={(contentWidth * teamOneScore) / scoreSum}
              height={40}
              horizontalGravity={HorizontalGravity.start}
              verticalGravity={VerticalGravity.center}
            >
              <Spacer width={10} />
              <Text
                text={teamOneScore.toString()}
                textStyle={DotpictTextStyle.BOLD20}
                colorStyle={DotpictColorStyle.WHITE}
              />
              <Text
                text={`${translator(TranslationKeys.Point)}`}
                textStyle={DotpictTextStyle.BOLD16}
                colorStyle={DotpictColorStyle.WHITE}
              />
            </Row>
          </TeamOneColorRect>
          <TeamTwoColorRect>
            <Row
              width={(contentWidth * teamTwoScore) / scoreSum}
              height={40}
              horizontalGravity={HorizontalGravity.end}
              verticalGravity={VerticalGravity.center}
            >
              <Text
                text={teamTwoScore.toString()}
                textStyle={DotpictTextStyle.BOLD20}
                colorStyle={DotpictColorStyle.WHITE}
              />
              <Text
                text={`${translator(TranslationKeys.Point)}`}
                textStyle={DotpictTextStyle.BOLD16}
                colorStyle={DotpictColorStyle.WHITE}
              />
              <Spacer width={24} />
            </Row>
          </TeamTwoColorRect>
        </Row>
      </ScoreRateContainer>
    </Row>
  );
};

export default ScoreRateComponent;
