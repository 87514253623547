import React, { useContext, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import VerticalContainerStyle from '@/app/style/VerticalContainerStyle';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import { useIntl } from 'react-intl';
import { LayoutContext } from '@/app/component/RootComponent';
import { IOldArticle } from '@/app/article/IOldArticle';
import ArticleContentType from '@/app/article/ArticleContentType';
import {
  articleContentBottomSpace,
  articleContentTopSpace,
  articleTitleBottomSpace,
} from '@/app/common/Constant';
import { useLocation } from 'react-router-dom';
import ImageComponent from '@/app/component/ImageComponent';
import CenterHorizontalContainerStyle from '@/app/style/CenterHorizontalContainerStyle';
import { IArticleImageContent } from '@/app/article/IArticleImageContent';
import { IOldArticleContent } from '@/app/article/IOldArticleContent';

interface IProps {
  article: IOldArticle;
}

const ArticleComponent = (props: IProps) => {
  const { article } = props;
  const intl = useIntl();
  const { layoutParams } = useContext(LayoutContext);
  const [renderTitle, setRenderTitle] = useState(true);
  const [isNightMode, setNightMode] = useState(false);
  const location = useLocation();

  const contentContainerStyle = css(VerticalContainerStyle, {
    backgroundColor: isNightMode ? DotpictColorStyle.NIGHT_BACKGROUND : DotpictColorStyle.WHITE,
    padding: '0 32',
    width: layoutParams.contentWidth - 32 * 2,
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setRenderTitle(params.get('noTitle') !== 'true');
    setNightMode(params.get('isNightMode') === 'true');
  }, [location]);

  const renderContent = (content: IOldArticleContent) => {
    switch (content.type) {
      case ArticleContentType.HEADLINE:
        return (
          <Text
            text={intl.formatMessage({ id: content.content })}
            textStyle={DotpictTextStyle.BOLD16}
            colorStyle={isNightMode ? DotpictColorStyle.WHITE : DotpictColorStyle.BASE}
          />
        );
      case ArticleContentType.TEXT:
        return (
          <Text
            text={intl.formatMessage({ id: content.content })}
            textStyle={DotpictTextStyle.REGULAR14}
            colorStyle={isNightMode ? DotpictColorStyle.WHITE : DotpictColorStyle.BASE}
          />
        );
      case ArticleContentType.BOLD_TEXT:
        return (
          <Text
            text={intl.formatMessage({ id: content.content })}
            textStyle={DotpictTextStyle.BOLD14}
            colorStyle={isNightMode ? DotpictColorStyle.WHITE : DotpictColorStyle.BASE}
          />
        );
      case ArticleContentType.SPACE:
        return <SquareSpaceComponent size={content.content} />;
      case ArticleContentType.IMAGE:
        return (
          <div css={CenterHorizontalContainerStyle}>
            <ImageComponent
              alt="article_image"
              width={(content as IArticleImageContent).width}
              height={(content as IArticleImageContent).height}
              src={content.content}
            />
          </div>
        );
      default:
        return '';
    }
  };

  return (
    <div css={VerticalContainerStyle}>
      {renderTitle && <SquareSpaceComponent size={layoutParams.contentTopSpace} />}
      <div css={contentContainerStyle}>
        <SquareSpaceComponent size={articleContentTopSpace} />
        {renderTitle && (
          <>
            <Text
              text={intl.formatMessage({ id: article.titleTranslationKey })}
              textStyle={DotpictTextStyle.BOLD20}
              colorStyle={DotpictColorStyle.BASE}
            />
            <SquareSpaceComponent size={articleTitleBottomSpace} />
          </>
        )}
        {article.contents.map((content: IOldArticleContent, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>{renderContent(content)}</React.Fragment>
        ))}
        <SquareSpaceComponent size={articleContentBottomSpace} />
      </div>
      {renderTitle && <SquareSpaceComponent size={layoutParams.contentBottomSpace} />}
    </div>
  );
};

export default ArticleComponent;
