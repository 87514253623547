import { Column, HorizontalGravity } from '@/app/component/base/layout/Layout';
import { imageContainerStyle } from '@/app/component/page/making/detail/styles';
import PixelArtComponent from '@/app/component/PixelArtComponent';

export type MakingPixelartProps = {
  imageUrl: string;
  width: number;
  height: number;
};

const MakingPixelart = ({ imageUrl, width, height }: MakingPixelartProps) => (
  <Column horizontalGravity={HorizontalGravity.center}>
    <div css={imageContainerStyle}>
      <PixelArtComponent alt="making_pixelart" src={imageUrl} width={width} height={height} />
    </div>
  </Column>
);

export default MakingPixelart;
