import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import { Column, Row, VerticalGravity } from '@/app/component/base/layout/Layout';
import { PaletteType } from '@/app/model/PaletteType';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import Colors from '@/app/component/dotpict/colors/Colors';

export type PaletteProps = {
  palette: PaletteType;
};

const Palette = ({ palette }: PaletteProps) => (
  <Row width="100%" background={DotpictColorStyle.WHITE}>
    <PixelArtComponent
      lazyLoad={false}
      alt={palette.title}
      width={96}
      height={96}
      src={palette.imageUrl}
    />
    <Column padding={8} verticalGravity={VerticalGravity.spaceBetween}>
      <Column>
        <Text
          text={palette.title}
          textStyle={DotpictTextStyle.REGULAR11}
          colorStyle={DotpictColorStyle.BASE}
        />
        <Spacer height={6} />
        <Text
          text={palette.text}
          textStyle={DotpictTextStyle.REGULAR11}
          colorStyle={DotpictColorStyle.BASE}
        />
      </Column>
      <Colors colors={palette.colorCodes} colorWidth={10} colorHeight={10} />
    </Column>
  </Row>
);

export default Palette;
