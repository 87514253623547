import { Column } from '@/app/component/base/layout/Layout';
import Text from '@/app/component/Text';
import useTranslator from '@/app/hooks/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import { Spacer } from '@/app/component/base/spacer/Spacer';

export type EventSectionProps = {
  eventTitle: string;
};

const EventSection = ({ eventTitle }: EventSectionProps) => {
  const translator = useTranslator();

  return (
    <Column>
      <Text
        text={translator(TranslationKeys.JoiningEvent)}
        textStyle={DotpictTextStyle.BOLD14}
        colorStyle={DotpictColorStyle.BASE}
      />
      <Spacer height={10} />
      <Text
        text={eventTitle}
        textStyle={DotpictTextStyle.REGULAR14}
        colorStyle={DotpictColorStyle.BASE}
      />
    </Column>
  );
};

export default EventSection;
