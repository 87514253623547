import { Column, Row } from '@/app/component/base/layout/Layout';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import { NotificationType } from '@/app/model/NotificationType';
import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';

export type NotificationProps = {
  notification: NotificationType;
  background?: DotpictColorStyle;
};

const Notification = ({ notification, background }: NotificationProps) => (
  <Column width="100%" background={background}>
    <Spacer height={12} />
    <Row>
      <Spacer width={20} />
      <div style={{ width: 48, height: 48 }}>
        <PixelArtComponent alt="profile image" src={notification.imageUrl} width={48} height={48} />
      </div>
      <Spacer width={12} />
      <Column>
        <Text
          text={notification.title}
          textStyle={DotpictTextStyle.REGULAR12}
          colorStyle={DotpictColorStyle.BASE}
        />
        <Spacer height={12} />
        <Text
          text={notification.createdAt}
          textStyle={DotpictTextStyle.REGULAR12}
          colorStyle={DotpictColorStyle.TEXT_GRAY}
        />
      </Column>
      <Spacer width={20} />
    </Row>
    <Spacer height={12} />
  </Column>
);

export default Notification;
