import { css } from '@emotion/react';
import { Skeleton } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import HorizontalContainerStyle from '@/app/style/HorizontalContainerStyle';
import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import { UserType } from '../model/UserType';

const profileImageSize = 64;
const betweenImageAndNameMargin = 16;

const contentStartItemsCenterHorizontalContainerStyle = css(HorizontalContainerStyle, {
  justifyContent: 'start',
  alignItems: 'center',
});

interface IProps {
  user: UserType;
}

const UserComponent = (props: IProps) => {
  const { user } = props;

  return (
    <Link to={`/users/${user.id}`}>
      <div css={contentStartItemsCenterHorizontalContainerStyle}>
        {user.profileImageUrl === '' ? (
          <Skeleton variant="rect" width={profileImageSize} height={profileImageSize} />
        ) : (
          <PixelArtComponent
            alt={user.name}
            src={user.profileImageUrl}
            width={profileImageSize}
            height={profileImageSize}
          />
        )}
        <SquareSpaceComponent size={betweenImageAndNameMargin} />
        <Text
          text={user.name}
          textStyle={DotpictTextStyle.BOLD14}
          colorStyle={DotpictColorStyle.BASE}
        />
      </div>
    </Link>
  );
};

export const DummyUserComponent = () => (
  <div css={contentStartItemsCenterHorizontalContainerStyle}>
    <Skeleton variant="rect" width={profileImageSize} height={profileImageSize} />
    <SquareSpaceComponent size={betweenImageAndNameMargin} />
    <Skeleton variant="rect" width={128} height={14} />
  </div>
);

export default UserComponent;
