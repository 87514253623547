import { css } from '@emotion/react';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import { Row, VerticalGravity } from '@/app/component/base/layout/Layout';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import TranslationKeys from '@/app/translation/TranslationKeys';
import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import useTranslator from '@/app/hooks/useTranslator';
import useThreadInput from '@/app/component/part/work/thread/input/useThreadInput';

export type ThreadInputProps = {
  workId: number;
  parentThreadId?: number;
};

export type ThreadInputState = {
  profileImageUrl: string;
  inputComment: string;
};

export type ThreadInputAction = {
  handleInputCommentChange: (comment: string) => void;
  handleSendCommentClick: () => void;
};

const ThreadInput = ({ workId, parentThreadId }: ThreadInputProps) => {
  const translator = useTranslator();
  const { profileImageUrl, inputComment, handleInputCommentChange, handleSendCommentClick } =
    useThreadInput({
      workId,
      parentThreadId,
    });

  const commentFieldStyle = css({
    width: 630,
  });

  return (
    <Row width="100%" verticalGravity={VerticalGravity.center}>
      <PixelArtComponent alt="profile_image_url" src={profileImageUrl} width={64} height={64} />
      <SquareSpaceComponent size={16} />
      <TextField
        value={inputComment}
        onChange={(e) => handleInputCommentChange(e.target.value)}
        css={commentFieldStyle}
        id="commentForm"
        label={translator(TranslationKeys.CommentHint)}
        variant="outlined"
        rows={1}
        multiline
      />
      <SquareSpaceComponent size={16} />
      <Button onClick={handleSendCommentClick}>
        <Text
          text={translator(TranslationKeys.Send)}
          textStyle={DotpictTextStyle.BOLD16}
          colorStyle={DotpictColorStyle.PRIMARY}
        />
      </Button>
    </Row>
  );
};

export default ThreadInput;
