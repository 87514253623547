import React from 'react';
import { css } from '@emotion/react';
import ButtonComponent from '@/app/component/ButtonComponent';
import Text from '@/app/component/Text';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import TranslationKeys from '@/app/translation/TranslationKeys';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTranslator from '@/app/hooks/useTranslator';
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Column, HorizontalGravity, Row } from '@/app/component/base/layout/Layout';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import TextFieldComponent from '@/app/component/TextFieldComponent';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import usePage from '@/app/component/page/requestbox/send/usePage';
import { UserType } from '../../../../model/UserType';

const ModalInnerStyle = css`
  background-color: white;
  margin-left: auto;
  margin-right: auto;

  padding: 32px 0 16px 0;

  /* モバイルは幅いっぱいまで */
  @media screen and (max-width: 680px) {
    padding: 20px;
  }
`;

export type RequestSendBindModel = {
  isOpenModal: boolean;
  profileImageUrl: string;
  requestBoxText: string;
  text: string;
};

export const InitialRequestSendBindModel: RequestSendBindModel = {
  isOpenModal: false,
  profileImageUrl: '',
  requestBoxText: '',
  text: '',
};

type Props = {
  user: UserType;
  children: React.ReactNode;
};

const RequestSend = ({ user, children }: Props) => {
  const fullScreen = useMediaQuery('(max-width: 680px)');
  const translator = useTranslator();

  const { bindModel, handleClickOpen, handleClickClose, onChangeText, handleClickSave } = usePage({
    user,
  });

  const NoPaddingButton = styled(Button)({
    display: 'inline-block',
    padding: 0,
    minHeight: 0,
    minWidth: 0,
  });

  const renderDialogContent = () => (
    <Row>
      <Spacer width={24} />
      <Column width="100%" horizontalGravity={HorizontalGravity.center}>
        <Text
          text={translator(TranslationKeys.RequestBoxSend)}
          textStyle={DotpictTextStyle.BOLD20}
          colorStyle={DotpictColorStyle.BASE}
        />
        <Spacer height={20} />
        <Row>
          <PixelArtComponent
            alt="profileImageUrl"
            src={bindModel.profileImageUrl}
            width={48}
            height={48}
          />
          <Spacer width={12} />
          <Text
            text={bindModel.requestBoxText}
            textStyle={DotpictTextStyle.REGULAR12}
            colorStyle={DotpictColorStyle.BASE}
            flex={1}
          />
        </Row>
        <Spacer height={26} />
        <Column width="100%">
          <TextFieldComponent
            id="text"
            placeholder={translator(TranslationKeys.RequestBoxSendHint)}
            value={bindModel.text}
            onChangeValue={(text) => onChangeText(text)}
            multiline
            showMaxLength
            maxLength={500}
            rows={4}
          />
        </Column>
        <Spacer height={40} />
        <ButtonComponent
          width={240}
          height={52}
          text={translator(TranslationKeys.RequestSend)}
          isFilled
          handleClick={() => handleClickSave()}
        />
        <Spacer height={8} />
        <ButtonComponent
          width={240}
          height={52}
          text={translator(TranslationKeys.Cancel)}
          isFilled={false}
          handleClick={() => handleClickClose()}
        />
      </Column>
      <Spacer width={24} />
    </Row>
  );

  return (
    <>
      <NoPaddingButton onClick={() => handleClickOpen()}>{children}</NoPaddingButton>
      <Dialog
        maxWidth="sm"
        fullWidth
        fullScreen={fullScreen}
        open={bindModel.isOpenModal}
        onClose={() => handleClickClose()}
        scroll="body"
      >
        <div css={ModalInnerStyle}>{renderDialogContent()}</div>
      </Dialog>
    </>
  );
};

export default RequestSend;
