import { useContext, useEffect, useState } from 'react';
import { APIContext, GlobalContext, MeContext } from '@/app/component/RootComponent';
import { DotpictAction } from '@/app/reducer/reducer';
import Lottie, { Options } from 'react-lottie';
import likeAnimationData from '@/assets/lottie/BtnLikeOn.json';
import dislikeAnimationData from '@/assets/lottie/BtnLikeOff.json';
import { css } from '@emotion/react';
import Button from '@/app/component/dotpict/Button';
import { WorkType } from '../model/WorkType';

type Props = {
  workId: number;
  isLike: boolean;
  size: number;
};

const Like = ({ workId, isLike, size }: Props) => {
  // デフォルト状態は未いいね状態
  // アニメーションで未いいね状態 -> いいね状態に変換
  const likeAnimationOptions: Options = {
    loop: false,
    autoplay: false,
    animationData: likeAnimationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet',
    },
  };

  // デフォルト状態はいいね状態
  // アニメーションでいいね状態 -> 未いいね状態に変換
  const dislikeAnimationOptions: Options = {
    loop: false,
    autoplay: false,
    animationData: dislikeAnimationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet',
    },
  };

  const { dispatch } = useContext(GlobalContext);
  const meState = useContext(MeContext);
  const [options, setOptions] = useState<Options>(likeAnimationOptions);
  const [isStopped, setStopped] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const { client } = useContext(APIContext);

  // Lottieファイルの縦幅に占めるいいねアイコンサイズの割合
  const likeIconRatioInLottie = 0.4545;
  const width = size;
  // eslint-disable-next-line no-mixed-operators
  const height = (size * 24) / 26;

  const rootStyle = css({
    position: 'relative',
    width: size,
    height: size,
  });

  // いいねアイコンの幅 : それ以外の余白 = size : x
  const likeStyle = css({
    position: 'absolute',
    top: -(((1 - likeIconRatioInLottie) * size) / likeIconRatioInLottie / 2),
    left: -(((1 - likeIconRatioInLottie) * size) / likeIconRatioInLottie / 2) + size * 0.09, // なぜか横ずれるので微調整の0.09
  });

  useEffect(() => {
    if (!isLoading && isStopped) {
      setOptions(isLike ? dislikeAnimationOptions : likeAnimationOptions);
    }
  }, [isLike, isStopped, isLoading]);

  const handleClick = async () => {
    if (!meState.isLoggedIn) {
      // eslint-disable-next-line no-alert
      window.alert('ログインが必要です');
      return;
    }
    if (isLoading) {
      return;
    }
    setStopped(false);
    setLoading(true);
    const request = async (): Promise<WorkType> => {
      const work = isLike
        ? await client.deleteLike(meState.token, `${workId}`)
        : await client.postLike(meState.token, `${workId}`);
      const action: DotpictAction = {
        type: 'UPDATE_WORK',
        payload: { work },
      };
      dispatch(action);
      return work;
    };
    const work = await request();
    setLoading(false);
    setStopped(true);
    setOptions(work.isLike ? dislikeAnimationOptions : likeAnimationOptions);
  };

  return (
    <div css={rootStyle}>
      <Button onClick={handleClick}>
        <div css={likeStyle}>
          <Lottie
            options={options}
            height={width / likeIconRatioInLottie}
            width={height / likeIconRatioInLottie}
            isClickToPauseDisabled
            isStopped={isStopped}
          />
        </div>
      </Button>
    </div>
  );
};

export default Like;
