import React, { createContext, Dispatch, useEffect, useMemo, useReducer, useState } from 'react';
import { BrowserRouter, Route, Routes, Outlet } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { css } from '@emotion/react';
import { useMediaQuery } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import defaultGlobalState, { IGlobalState } from '@/app/state/IGlobalState';
import reducer, { DotpictAction } from '@/app/reducer/reducer';
import HeaderComponent from '@/app/component/HeaderComponent';
import { dotpictFirebaseAuth } from '@/app/library/dotpictFirebaseAuth';
import { MeResponseType } from '@/app/model/responsetype/MeResponseType';
import { DotpictClient, IDotpictClient } from '@/app/apiclient/DotpictApiClient';
import PickUpWorks from '@/app/component/PickUpWorks';
import NewestWorksComponent from '@/app/component/NewestWorksComponent';
import { defaultMeState, IMeState } from '@/app/state/IMeState';
import VerticalContainerStyle from '@/app/style/VerticalContainerStyle';
import ja from '@/app/translation/ja';
import en from '@/app/translation/en';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import HorizontalContainerStyle from '@/app/style/HorizontalContainerStyle';
import SideComponent from '@/app/component/SideComponent';
import { defaultLayoutParams, ILayoutParams } from '@/app/constant/ILayoutParams';
import SearchWorkResultComponent from '@/app/component/SearchWorkResultComponent';
import LikeUsersComponent from '@/app/component/LikeUsersComponent';
import FollowedUsersComponent from '@/app/component/FollowedUsersComponent';
import FollowerUsersComponent from '@/app/component/FollowerUsersComponent';
import SearchUserResultComponent from '@/app/component/SearchUserResultComponent';
import {
  defaultSmallThumbnailImageGridGap,
  defaultThumbnailImageGridGap,
} from '@/app/common/Constant';
import PrivacyComponent from '@/app/component/PrivacyComponent';
import GuidelineComponent from '@/app/component/GuidelineComponent';
import FooterComponent from '@/app/component/FooterComponent';
import CenterVerticalContainerStyle from '@/app/style/CenterVerticalContainerStyle';
import TemplateEventComponent from '@/app/component/TemplateEventComponent';
import SignUpComponent from '@/app/component/SignUpComponent';
import SixArticleComponent from '@/app/component/SixArticleComponent';
import ArticleDetailComponent from '@/app/component/ArticleDetailComponent';
import DeepLinkTestComponent from '@/app/component/DeepLinkTestComponent';
import PortfolioUserDetailComponent from '@/app/component/PortfolioUserDetailComponent';
import PortfolioHeaderComponent from '@/app/component/PortfolioHeaderComponent';
import PortfolioWorkDetailComponent from '@/app/component/PortfolioWorkDetailComponent';
import { MyPageLikedWorks, MyPagePostedWorks } from '@/app/component/page/mypage';
import useLocalStorage from '@/app/customhook/useLocalStorage';
import { Language } from '@/app/model/Language';
import Notifications from '@/app/component/page/notifications';
import RequestBox from '@/app/component/page/requestbox';
import Home from '@/app/component/page/home';
import Replies from '@/app/component/page/replies';
import EventDetail from '@/app/component/page/eventdetail';
import LoginPage from '@/app/component/page/login';
import UploadWorkPage from '@/app/component/page/work/upload';
import DrawPage from '@/app/component/page/draw';
import CanvasesPage from '@/app/component/page/canvases';
import UserDetail from '@/app/component/page/user/detail/UserDetail';
import WorkDetailPage from '@/app/component/page/work/detail';
import NotFound from '@/app/component/NotFound';
import ImageGuideline from '@/app/component/page/imageguideline';
import { User } from '@firebase/auth';
import OfficialEventContainerPage from '@/app/component/page/officialevent/container';
import EditWorkPage from '@/app/component/page/work/edit';
import MakingDetail from '@/app/component/page/making/detail/MakingDetail';
import OfficialEventResultPage from '@/app/component/page/officialevent/result';
import { Column, HorizontalGravity, VerticalGravity } from '@/app/component/base/layout/Layout';
import AppIcon from '@/app/image/app_icon.svg';
import Loading from '@/app/component/dotpict/loading/Loading';

export interface IGlobalContext {
  globalState: IGlobalState;
  dispatch: Dispatch<DotpictAction>;
}

export type IAPIContext = {
  client: IDotpictClient;
};

export interface ILanguageContext {
  language: Language;
  setLanguage: Dispatch<React.SetStateAction<Language>>;
}

export interface ILayoutContext {
  layoutParams: ILayoutParams;
}

export const GlobalContext = createContext<IGlobalContext>({} as IGlobalContext);
export const APIContext = createContext<IAPIContext>({} as IAPIContext);
export const LayoutContext = createContext<ILayoutContext>({} as ILayoutContext);
export const LanguageContext = createContext<ILanguageContext>({} as ILanguageContext);
export const MeContext = createContext({} as IMeState);

declare global {
  interface Window {
    gtag?: (
      key: string,
      trackingId: string,
      // eslint-disable-next-line camelcase
      config: { page_path: string },
    ) => void;
  }
}

export const createDesktopLayoutParams = (
  contentWidth: number,
  hasSideBar: boolean,
): ILayoutParams => {
  return {
    contentTopSpace: 40,
    contentBottomSpace: 72,
    rectangleAdsWidth: 336,
    rectangleAdsHeight: 280,
    contentWidth,
    mainContainerWidth: hasSideBar ? 832 : contentWidth, // 792 + 20 * 2
    betweenContentAndSideSpace: hasSideBar ? 64 : 0,
    sideComponentWidth: hasSideBar ? 336 : 0,
    fullWidth: hasSideBar ? 832 + 64 + 336 : contentWidth,
    thumbnailImageSize: 192,
    smallThumbnailImageSize: 128,
    thumbnailImageGridGap: defaultThumbnailImageGridGap,
    smallThumbnailImageGridGap: defaultSmallThumbnailImageGridGap,
    homeSectionInfoSideMargin: 0,
    makingCarouselPadding: Math.floor(contentWidth / 6),
    makingCarouselItemSpace: 16,
    isMobile: false,
  };
};

export const createMobileLLayoutParams = (contentWidth: number): ILayoutParams => {
  const thumbnailImageGridGap = 4;
  const smallThumbnailImageGridGap = 2;
  return {
    contentTopSpace: 24,
    contentBottomSpace: 24,
    rectangleAdsWidth: 336,
    rectangleAdsHeight: 280,
    contentWidth,
    mainContainerWidth: contentWidth,
    betweenContentAndSideSpace: 0,
    sideComponentWidth: 0,
    fullWidth: contentWidth,
    thumbnailImageSize: Math.floor((contentWidth - thumbnailImageGridGap) / 2),
    smallThumbnailImageSize: Math.floor((contentWidth - smallThumbnailImageGridGap * 2) / 3),
    thumbnailImageGridGap,
    smallThumbnailImageGridGap,
    homeSectionInfoSideMargin: 16,
    makingCarouselPadding: 0,
    makingCarouselItemSpace: 0,
    isMobile,
  };
};

const WithHeaderLayout = (
  hasSubDomain: boolean,
  isFullSize: boolean,
  layoutParams: ILayoutParams,
  children: React.ReactNode,
) => {
  const contentContainerStyle = css(HorizontalContainerStyle, {
    width:
      layoutParams.mainContainerWidth +
      layoutParams.betweenContentAndSideSpace +
      layoutParams.sideComponentWidth,
  });

  const mainContentContainerStyle = css(VerticalContainerStyle, {
    width: layoutParams.mainContainerWidth,
  });

  return (
    <>
      {!hasSubDomain ? <HeaderComponent /> : <PortfolioHeaderComponent />}
      <div css={contentContainerStyle}>
        <div css={mainContentContainerStyle}>
          {/* Routeコンポーネントの中身がここに描画される */}
          {children}

          {!isFullSize && (
            <div css={CenterVerticalContainerStyle}>
              <FooterComponent />
            </div>
          )}
        </div>
        {isFullSize && !hasSubDomain && (
          <>
            <SquareSpaceComponent size={layoutParams.betweenContentAndSideSpace} />
            <SideComponent />
          </>
        )}
      </div>
    </>
  );
};

export const apiBaseUrl = 'https://api.dotpicko.net';

const RootComponent = () => {
  const [globalState, dispatch] = useReducer(reducer, defaultGlobalState);
  // FYI: https://qiita.com/shogo82148/items/548a6c9904eb19269f8c TODO: IE対応のやつの変数がなかったからIEで動くか調べる
  const browserLanguage =
    (window.navigator.languages && window.navigator.languages[0]) || window.navigator.language;
  const [language, setLanguage] = useLocalStorage<Language>(
    'lang',
    browserLanguage.includes('ja') ? 'ja' : 'en',
  );
  const [isLoadingFirebaseAuth, setLoadingFirebaseAuth] = useState(true);
  const [client, setClient] = useState<DotpictClient>(new DotpictClient(apiBaseUrl, language));
  const [layoutParams, setLayoutParams] = useState(defaultLayoutParams);
  const isFullSize = useMediaQuery('(min-width:1232px)');
  const hasSubDomain =
    window.location.host !== 'dotpict.net' &&
    window.location.host !== 'dev.dotpict.net' &&
    window.location.host !== 'localhost:4567';

  const createLayoutParams = (): ILayoutParams => {
    if (isFullSize) {
      return createDesktopLayoutParams(792, !hasSubDomain);
    }
    return createMobileLLayoutParams(document.documentElement.clientWidth);
  };

  // キャンバス一覧とDraw画面があるのでRootで管理
  useEffect(() => {
    localStorage.setItem('draw_version', '1');
  }, []);

  useEffect(() => {
    const refreshData = (user: User | null) => {
      if (user === null) {
        const action: DotpictAction = {
          type: 'UPDATE_ME_STATE',
          payload: { meState: defaultMeState },
        };
        dispatch(action);
      } else {
        const fetchData = async () => {
          const token = await user?.getIdToken(false);
          if (token === undefined) return;
          dispatch({
            type: 'UPDATE_ME_STATE',
            payload: { meState: { isLoggedIn: true, token, user: globalState.meState.user } },
          });
          const response: MeResponseType = await client.fetchMe(token);
          dispatch({
            type: 'UPDATE_ME_STATE',
            payload: { meState: { isLoggedIn: true, token, user: response.user } },
          });
        };
        fetchData();
      }
    };
    // tokenが1hourでexpireしたとき用
    const unsubscribeIdTokenChanged = dotpictFirebaseAuth.onIdTokenChanged((user: User | null) => {
      refreshData(user);
    });
    // login済みかどうか
    const unsubscribeAuthStateChanged = dotpictFirebaseAuth.onAuthStateChanged((_) => {
      setLoadingFirebaseAuth(false);
    });
    return function cleanup() {
      unsubscribeIdTokenChanged();
      unsubscribeAuthStateChanged();
    };
  }, [setLoadingFirebaseAuth]);

  useEffect(() => {
    setLayoutParams(createLayoutParams);
  }, [isFullSize]);

  useEffect(() => {
    setClient(new DotpictClient(apiBaseUrl, language));
  }, [language]);

  const globalValue = useMemo(() => {
    return { globalState, dispatch };
  }, [globalState, dispatch]);

  const apiValue = useMemo(() => {
    return { client };
  }, [client]);

  const languageValue = useMemo(() => {
    return { language, setLanguage };
  }, [language, setLanguage]);

  const layoutValue = useMemo(() => {
    return { layoutParams };
  }, [layoutParams]);

  if (isLoadingFirebaseAuth) {
    return (
      <Column
        width="100%"
        height="100%"
        verticalGravity={VerticalGravity.center}
        horizontalGravity={HorizontalGravity.center}
      >
        <img width={144} height={144} src={AppIcon} alt="app icon" />
        <Loading />
      </Column>
    );
  }

  return (
    <BrowserRouter>
      <IntlProvider locale={language} messages={language === 'ja' ? ja : en}>
        <GlobalContext.Provider value={globalValue}>
          <APIContext.Provider value={apiValue}>
            <LanguageContext.Provider value={languageValue}>
              <MeContext.Provider value={globalState.meState}>
                <LayoutContext.Provider value={layoutValue}>
                  <Column horizontalGravity={HorizontalGravity.center}>
                    <Routes>
                      <Route path="/draw" element={<DrawPage />} />
                      {/* element配下に置くと@がmatchingしないので暫定対応 */}
                      <Route
                        path="/@:accountName"
                        element={WithHeaderLayout(
                          hasSubDomain,
                          isFullSize,
                          layoutParams,
                          <UserDetail />,
                        )}
                      />
                      <Route
                        element={WithHeaderLayout(
                          hasSubDomain,
                          isFullSize,
                          layoutParams,
                          <Outlet />,
                        )}
                      >
                        {hasSubDomain ? (
                          <>
                            <Route path="/" element={<PortfolioUserDetailComponent />} />
                            <Route
                              path="/works/:workId"
                              element={<PortfolioWorkDetailComponent />}
                            />
                          </>
                        ) : (
                          <>
                            <Route path="/" element={<Home />} />
                            <Route path="/pickUpWorks" element={<PickUpWorks />} />
                            <Route path="/newestWorks" element={<NewestWorksComponent />} />
                            <Route path="/notifications" element={<Notifications />} />
                            <Route path="/works/:workId" element={<WorkDetailPage />} />
                            <Route path="/users/:userId" element={<UserDetail />} />
                            <Route path="/login" element={<LoginPage />} />
                            <Route path="/signup" element={<SignUpComponent />} />
                            <Route path="/making/:makingId" element={<MakingDetail />} />
                            <Route
                              path="/articles/:articleId"
                              element={<ArticleDetailComponent />}
                            />
                            <Route
                              path="/search/users/:query"
                              element={<SearchUserResultComponent />}
                            />
                            <Route
                              path="/search/works/:type/:query"
                              element={<SearchWorkResultComponent />}
                            />
                            <Route path="/works/:workId/threads/:threadId" element={<Replies />} />
                            <Route
                              path="/works/:workId/likeUsers"
                              element={<LikeUsersComponent />}
                            />
                            <Route
                              path="/users/:userId/followedUsers"
                              element={<FollowedUsersComponent />}
                            />
                            <Route
                              path="/users/:userId/followerUsers"
                              element={<FollowerUsersComponent />}
                            />
                            <Route path="/upload" element={<UploadWorkPage />} />
                            <Route path="/privacy" element={<PrivacyComponent />} />
                            <Route path="/guidelines" element={<GuidelineComponent />} />
                            <Route path="/deep_link_test" element={<DeepLinkTestComponent />} />
                            <Route path="/6th" element={<SixArticleComponent />} />
                            <Route path="/me/requestBox" element={<RequestBox />} />
                            <Route path="/me/postedWorks" element={<MyPagePostedWorks />} />
                            <Route path="/me/likedWorks" element={<MyPageLikedWorks />} />
                            <Route
                              path="/template_events/:eventName"
                              element={<TemplateEventComponent />}
                            />
                            <Route path="/userEvents/:userEventId" element={<EventDetail />} />
                            <Route path="/imageGuideline" element={<ImageGuideline />} />
                            <Route
                              path="/officialEvents/:officialEventTag"
                              element={<OfficialEventContainerPage />}
                            />
                            <Route
                              path="/officialEvents/:officialEventTag/result"
                              element={<OfficialEventResultPage />}
                            />
                            <Route path="/canvases" element={<CanvasesPage />} />
                            <Route path="/works/:workId/edit" element={<EditWorkPage />} />
                            <Route element={<NotFound />} />
                          </>
                        )}
                      </Route>
                    </Routes>
                  </Column>
                </LayoutContext.Provider>
              </MeContext.Provider>
            </LanguageContext.Provider>
          </APIContext.Provider>
        </GlobalContext.Provider>
      </IntlProvider>
    </BrowserRouter>
  );
};

export default RootComponent;
