import emptyUser, { UserType } from './UserType';

export type UserEventType = {
  id: number;
  user: UserType;
  title: string;
  text: string;
  tag: string;
  imageUrl: string;
  width: number;
  height: number;
  createdAt: string;
  templateCanvasColorCodes: string[];
  templateCanvasImageUrl: string;
};

const defaultUserEvent: UserEventType = {
  id: 0,
  user: emptyUser,
  title: '',
  text: '',
  tag: '',
  imageUrl: '',
  width: 0,
  height: 0,
  createdAt: '',
  templateCanvasColorCodes: [],
  templateCanvasImageUrl: '',
};

export default defaultUserEvent;
