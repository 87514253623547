import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { styled } from '@material-ui/core/styles';
import useTranslator from '@/app/hooks/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';

export type ConfirmationDialogProps = {
  title: string;
  message: string;
  handleClickOk: () => void;
  handleClickCancel?: () => void;
  children: React.ReactNode;
};

const ConfirmationDialog = ({
  title,
  message,
  handleClickOk,
  handleClickCancel,
  children,
}: ConfirmationDialogProps) => {
  const [open, setOpen] = useState(false);
  const translator = useTranslator();

  const NoPaddingButton = styled(Button)({
    display: 'inline-block',
    padding: 0,
    minHeight: 0,
    minWidth: 0,
  });

  // eslint-disable-next-line no-underscore-dangle
  const _handleClickOk = () => {
    setOpen(false);
    handleClickOk();
  };

  // eslint-disable-next-line no-underscore-dangle
  const _handleClickCancel = () => {
    setOpen(false);
    if (handleClickCancel) {
      handleClickCancel();
    }
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <NoPaddingButton onClick={handleClickOpen}>{children}</NoPaddingButton>
      <Dialog
        open={open}
        onClose={handleClickClose}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description"
      >
        <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmation-dialog-description">{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={_handleClickCancel} color="primary">
            {translator(TranslationKeys.Cancel)}
          </Button>
          <Button onClick={_handleClickOk} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmationDialog;
