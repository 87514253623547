enum DotpictTextStyle {
  REGULAR11,
  REGULAR12,
  REGULAR13,
  REGULAR14,
  REGULAR16,
  BOLD11,
  BOLD12,
  BOLD14,
  BOLD16,
  BOLD20,
}

export default DotpictTextStyle;
