import defaultOfficialEvent, { OfficialEventType } from '@/app/model/OfficialEventType';
import { WorkType } from '../model/WorkType';

export type OfficialEventResultStateType = {
  fromApp: boolean;
  officialEvent: OfficialEventType;
  teamOneWorks: WorkType[];
  teamTwoWorks: WorkType[];
  description: string;
  closingTexts: string[];
  shareTitle: string;
  twitterShareUrl: string;
  facebookShareUrl: string;
  bannerImageUrl: string;
  works: WorkType[];
  nextUrl: string;
  isLoadingWorks: boolean;
  isVisibleEnd: boolean;
  requestedUserId: number;
};

export const defaultOfficialEventResultState: OfficialEventResultStateType = {
  fromApp: false,
  officialEvent: defaultOfficialEvent,
  teamOneWorks: [],
  teamTwoWorks: [],
  description: '',
  closingTexts: [],
  shareTitle: '',
  twitterShareUrl: '',
  facebookShareUrl: '',
  bannerImageUrl: '',
  works: [],
  nextUrl: '',
  isLoadingWorks: true,
  isVisibleEnd: false,
  requestedUserId: 0,
};
