import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import { css } from '@emotion/react';
import { MouseEventHandler } from 'react';
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

interface IProps {
  text: string;
  textStyle: DotpictTextStyle;
  colorStyle: DotpictColorStyle;
  flex?: number | string;
  handleClick?: MouseEventHandler;
}

const Text = (props: IProps) => {
  const { text, textStyle, colorStyle, flex, handleClick } = props;

  // textでしか使わないからここに書いても良いかな、、、
  const getTextStyle = (dotpictTextStyle: DotpictTextStyle) => {
    // noinspection JSRedundantSwitchStatement
    switch (dotpictTextStyle) {
      case DotpictTextStyle.REGULAR11:
        return css({
          fontSize: '0.6875rem',
          fontWeight: 'normal',
        });
      case DotpictTextStyle.REGULAR12:
        return css({
          fontSize: '0.75rem',
          fontWeight: 'normal',
        });
      case DotpictTextStyle.REGULAR13:
        return css({
          fontSize: '0.8125rem',
          fontWeight: 'normal',
        });
      case DotpictTextStyle.REGULAR14:
        return css({
          fontSize: '0.875rem',
          lineHeight: '1.375rem',
          fontWeight: 'normal',
        });
      case DotpictTextStyle.REGULAR16:
        return css({
          fontSize: '1rem',
          fontWeight: 'normal',
        });
      case DotpictTextStyle.BOLD11:
        return css({
          fontSize: '0.6875rem',
          fontWeight: 'bold',
        });
      case DotpictTextStyle.BOLD12:
        return css({
          fontSize: '0.75rem',
          fontWeight: 'bold',
        });
      case DotpictTextStyle.BOLD14:
        return css({
          fontSize: '0.875rem',
          fontWeight: 'bold',
        });
      case DotpictTextStyle.BOLD16:
        return css({
          fontSize: '1rem',
          fontWeight: 'bold',
        });
      case DotpictTextStyle.BOLD20:
        return css({
          fontSize: '1.25rem',
          fontWeight: 'bold',
        });
      default:
        return css({
          fontSize: '0.875rem',
          fontWeight: 'normal',
        });
    }
  };

  const style = css(getTextStyle(textStyle), {
    color: colorStyle,
    overflowWrap: 'anywhere',
    whiteSpace: 'normal',
    flex: flex ?? '0 1 auto',
  });

  const NoPaddingButton = styled(Button)({
    display: 'inline-block',
    padding: 0,
    minHeight: 0,
    minWidth: 0,
  });

  if (handleClick === undefined) {
    return <div css={style}>{text}</div>;
  }

  return (
    <NoPaddingButton
      onClick={(e) => {
        handleClick(e);
      }}
    >
      <div css={style}>{text}</div>
    </NoPaddingButton>
  );
};

export default Text;
