import ExternalLinkComponent from '@/app/component/ExternalLinkComponent';
import VerticalContainerStyle from '@/app/style/VerticalContainerStyle';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';

const DeepLinkTestComponent = () => (
  <div css={VerticalContainerStyle}>
    <ExternalLinkComponent
      text="dotpict://dotpict.net/works/1"
      url="dotpict://dotpict.net/works/1"
      textStyle={DotpictTextStyle.BOLD14}
      colorStyle={DotpictColorStyle.PRIMARY}
    />
    <SquareSpaceComponent size={12} />
    <ExternalLinkComponent
      text="dotpict://dotpict.net/users/1"
      url="dotpict://dotpict.net/users/1"
      textStyle={DotpictTextStyle.BOLD14}
      colorStyle={DotpictColorStyle.PRIMARY}
    />
    <SquareSpaceComponent size={12} />
    <ExternalLinkComponent
      text="dotpict://dotpict.net/users/1/works"
      url="dotpict://dotpict.net/users/1/works"
      textStyle={DotpictTextStyle.BOLD14}
      colorStyle={DotpictColorStyle.PRIMARY}
    />
  </div>
);

export default DeepLinkTestComponent;
