import { Box } from '@material-ui/core';

export interface SpacerProps {
  width?: number;
  height?: number;
}

export const Spacer = (props: SpacerProps) => {
  const { width, height } = props;

  return <Box width={width} height={height} />;
};
