import { css } from '@emotion/react';
import { Helmet } from 'react-helmet';
import Works from '@/app/component/Works';
import DefaultGridStyle from '@/app/style/DefaultGridStyle';
import { defaultDummyItemCount } from '@/app/common/Constant';
import UserDetailHeader from '@/app/component/page/user/detail/UserDetailHeader';
import { Column, HorizontalGravity } from '@/app/component/base/layout/Layout';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import useUserDetail from '@/app/component/page/user/detail/useUserDetail';

export type UserDetailBindModel = {
  userId: number;
  isFollowed: boolean;
  isLoading: boolean;
  isVisibleEnd: boolean;
  thumbnailImageGridGap: number;
  contentWidth: number;
  thumbnailImageSize: number;
};

export type UserDetailOgp = {
  title: string;
  description: string;
  image: string;
};

const UserDetail = () => {
  const { bindModel, ogp, user, userWorks } = useUserDetail();

  const GridStyle = css(DefaultGridStyle, {
    gridGap: bindModel.thumbnailImageGridGap,
    gridTemplateColumns: `repeat(${Math.floor(
      bindModel.contentWidth / bindModel.thumbnailImageSize,
    )}, ${bindModel.thumbnailImageSize}px)`,
  });

  const renderOgp = () => (
    <Helmet>
      <meta property="twitter:title" content={ogp.title} />
      <meta property="twitter:description" content={ogp.description} />
      <meta property="twitter:image" content={ogp.image} />
      <meta property="og:title" content={ogp.title} />
      <meta property="og:description" content={ogp.description} />
      <meta property="og:image" content={ogp.image} />
    </Helmet>
  );

  return (
    <>
      {renderOgp()}
      <Column horizontalGravity={HorizontalGravity.center}>
        <UserDetailHeader user={user} />
        <Spacer height={32} />
      </Column>
      <Works
        works={userWorks}
        isLoading={bindModel.isLoading}
        isVisibleEnd={bindModel.isVisibleEnd}
        isVisibleAds={false}
        isVisibleLike={false}
        gridStyle={GridStyle}
        imageSize={bindModel.thumbnailImageSize}
        dummyItemCount={defaultDummyItemCount}
      />
    </>
  );
};

export default UserDetail;
