import { useContext, useEffect, useReducer } from 'react';
import { APIContext, MeContext } from '@/app/component/RootComponent';
import {
  InitialRequestSendBindModel,
  RequestSendBindModel,
} from '@/app/component/page/requestbox/send';
import { UserType } from '@/app/model/UserType';

type Action =
  | { type: 'SETUP_USER'; payload: { user: UserType } }
  | { type: 'OPEN_SETTINGS'; payload: {} }
  | { type: 'CLOSE_SETTINGS'; payload: {} }
  | { type: 'CHANGE_TEXT'; payload: { text: string } }
  | { type: 'CHANGE_OPEN_REQUEST_BOX'; payload: { isOpened: boolean } }
  | { type: 'SEND_REQUEST'; payload: {} }
  | { type: 'SENT_REQUEST'; payload: {} };

type State = {
  bindModel: RequestSendBindModel;
  isSending: boolean;
};

const reducer = (prev: State, action: Action): State => {
  switch (action.type) {
    case 'SETUP_USER': {
      const { user } = action.payload;
      return {
        ...prev,
        bindModel: {
          ...prev.bindModel,
          profileImageUrl: user.profileImageUrl,
          requestBoxText: user.requestBoxText,
          text: '',
        },
      };
    }
    case 'OPEN_SETTINGS': {
      return {
        ...prev,
        bindModel: {
          ...prev.bindModel,
          isOpenModal: true,
        },
      };
    }
    case 'CLOSE_SETTINGS': {
      return {
        ...prev,
        bindModel: {
          ...prev.bindModel,
          isOpenModal: false,
        },
      };
    }
    case 'CHANGE_TEXT': {
      return {
        ...prev,
        bindModel: {
          ...prev.bindModel,
          text: action.payload.text,
        },
      };
    }
    case 'SEND_REQUEST': {
      return {
        ...prev,
        isSending: true,
      };
    }
    case 'SENT_REQUEST': {
      return {
        ...prev,
        bindModel: {
          ...prev.bindModel,
          isOpenModal: false,
        },
        isSending: false,
      };
    }
    default:
      return prev;
  }
};

type Props = {
  user: UserType;
};

export const usePage = ({ user }: Props) => {
  const meState = useContext(MeContext);
  const [state, dispatch] = useReducer(reducer, {
    bindModel: InitialRequestSendBindModel,
    isSending: false,
  });
  const { client } = useContext(APIContext);

  useEffect(() => {
    dispatch({ type: 'SETUP_USER', payload: { user } });
  }, [dispatch, user]);

  const handleClickOpen = () => {
    dispatch({ type: 'OPEN_SETTINGS', payload: {} });
  };

  const handleClickClose = () => {
    dispatch({ type: 'CLOSE_SETTINGS', payload: {} });
  };

  const onChangeOpenRequestBox = (isOpened: boolean) => {
    dispatch({ type: 'CHANGE_OPEN_REQUEST_BOX', payload: { isOpened } });
  };

  const onChangeText = (text: string) => {
    dispatch({ type: 'CHANGE_TEXT', payload: { text } });
  };

  const handleClickSave = async () => {
    if (state.isSending) return;
    dispatch({ type: 'SEND_REQUEST', payload: {} });
    await client.sendRequest(meState.token, user.id, state.bindModel.text);
    dispatch({ type: 'SENT_REQUEST', payload: {} });
  };

  return {
    bindModel: state.bindModel,
    handleClickOpen,
    handleClickClose,
    onChangeOpenRequestBox,
    onChangeText,
    handleClickSave,
  };
};

export default usePage;
