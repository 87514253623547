import { useContext, useEffect, useState, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import { APIContext, GlobalContext, LayoutContext, MeContext } from '@/app/component/RootComponent';
import { defaultLikeUsersState } from '@/app/state/ILikeUsersState';
import UsersComponent from '@/app/component/UsersComponent';
import VerticalContainerStyle from '@/app/style/VerticalContainerStyle';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import shouldFetchNext from '@/app/common/Common';
import { UserSummaryType } from '@/app/model/UserSummaryType';
import { DotpictAction } from '@/app/reducer/reducer';

const LikeUsersComponent = () => {
  const { workId } = useParams();
  const { globalState, dispatch } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const { layoutParams } = useContext(LayoutContext);
  const meState = useContext(MeContext);
  const { client } = useContext(APIContext);

  const fetchData = async (
    currentUserSummaries: UserSummaryType[],
    currentNextUrl: string | null,
  ) => {
    if (workId === undefined) return;
    setLoading(true);
    const requestedUserId = meState.user.id;
    const nextUrl =
      currentNextUrl || `${client.getBaseUrl}/v2/works/${workId}/like_users?work_count=6`;
    const usersResponse = await client.fetchUsers(meState.token, nextUrl);
    const action: DotpictAction = {
      type: 'UPDATE_LIKE_USERS_STATE',
      payload: {
        likeUsersState: {
          workId: parseInt(workId, 10),
          userSummaries: currentUserSummaries.concat(usersResponse.userSummaries),
          nextUrl: usersResponse.nextUrl,
          requestedUserId,
        },
      },
    };
    dispatch(action);
    setLoading(false);
  };

  const handleScroll = () => {
    if (!loading && shouldFetchNext(globalState.likeUsersState.nextUrl)) {
      fetchData(globalState.likeUsersState.userSummaries, globalState.likeUsersState.nextUrl);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    const storedWorkId = `${globalState.likeUsersState.workId}`;

    if (workId !== storedWorkId) {
      window.scrollTo(0, 0);
      const resetAction: DotpictAction = {
        type: 'UPDATE_LIKE_USERS_STATE',
        payload: { likeUsersState: defaultLikeUsersState },
      };
      dispatch(resetAction);
    }

    if (workId !== storedWorkId || globalState.likeUsersState.requestedUserId !== meState.user.id) {
      fetchData([], null);
    }
  }, [workId, meState.token]);

  return (
    <div css={VerticalContainerStyle}>
      <SquareSpaceComponent size={layoutParams.contentTopSpace} />
      <UsersComponent
        userSummaries={globalState.likeUsersState.userSummaries}
        isLoading={loading}
        isVisibleEnd={globalState.likeUsersState.nextUrl === ''}
        isVisibleAds
        dummyItemCount={20}
      />
      <SquareSpaceComponent size={layoutParams.contentBottomSpace} />
    </div>
  );
};

export default LikeUsersComponent;
