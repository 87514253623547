import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import { Column, HorizontalGravity } from '@/app/component/base/layout/Layout';
import PixelArtComponent from '@/app/component/PixelArtComponent';

const End = () => (
  <Column width="100%" horizontalGravity={HorizontalGravity.center}>
    <SquareSpaceComponent size={40} />
    <PixelArtComponent
      alt="end"
      width={26}
      height={30}
      src="https://storage.googleapis.com/dotpict-images/web/end.svg"
    />
    <SquareSpaceComponent size={40} />
  </Column>
);

export default End;
