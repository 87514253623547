import { ThreadType } from '@/app/model/ThreadType';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import { Link } from 'react-router-dom';
import { Column, Row, VerticalGravity } from '@/app/component/base/layout/Layout';
import ThreadLike from '@/app/component/part/work/thread/like/ThreadLike';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import { WorkType } from '@/app/model/WorkType';

export type IProps = {
  work: WorkType;
  thread: ThreadType;
};

const ChildThread = ({ work, thread }: IProps) => (
  <Row>
    <Spacer width={38} />
    <PixelArtComponent
      src="https://storage.googleapis.com/dotpict-images/web/thread/thread_reply.svg"
      alt="thread_reply"
      width={14}
      height={14}
    />
    <Spacer width={24} />
    <Link to={`/users/${thread.user.id}`}>
      <PixelArtComponent
        alt={thread.user.name}
        src={thread.user.profileImageUrl}
        width={48}
        height={48}
      />
    </Link>
    <SquareSpaceComponent size={16} />
    <Column>
      <Link to={`/users/${thread.user.id}`}>
        <Text
          text={thread.user.name}
          textStyle={DotpictTextStyle.BOLD14}
          colorStyle={DotpictColorStyle.BASE}
        />
      </Link>
      <SquareSpaceComponent size={4} />
      <Text
        text={thread.text}
        textStyle={DotpictTextStyle.REGULAR14}
        colorStyle={DotpictColorStyle.BASE}
      />
      <SquareSpaceComponent size={4} />
      <Row verticalGravity={VerticalGravity.center}>
        <Text
          text={thread.createdAt}
          textStyle={DotpictTextStyle.REGULAR12}
          colorStyle={DotpictColorStyle.GRAY_DEEP}
        />
        <ThreadLike work={work} thread={thread} />
      </Row>
    </Column>
  </Row>
);

export default ChildThread;
