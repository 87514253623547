import { css } from '@emotion/react';
import { useEffect } from 'react';
import DotpictAdsType from '@/app/ads/DotpictAdsType';

interface IProps {
  adsType: DotpictAdsType;
}

const AdsComponent = (props: IProps) => {
  const { adsType } = props;

  const getStyle = (type: DotpictAdsType) => {
    switch (type) {
      case DotpictAdsType.RECTANGLE_LARGE:
        return css({
          width: 300,
          height: 250,
        });
      case DotpictAdsType.RECTANGLE_BANNER:
        return css({
          width: 320,
          height: 50,
        });
      default:
        return css({});
    }
  };

  const adsContainerStyle = css(getStyle(adsType), {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#E5E5E5',
    justifyContent: 'center',
    alignItems: 'center',
  });

  const getUniqueStr = Math.floor(100000 * Math.random()).toString();

  const getScript = (type: DotpictAdsType) => {
    switch (type) {
      case DotpictAdsType.RECTANGLE_LARGE:
        return `//i.socdm.com/sdk/js/adg-script-loader.js?id=116578&targetID=adg_116578&displayid=2&adType=RECT&async=true&tagver=2.0.0`;
      case DotpictAdsType.RECTANGLE_BANNER:
        return `//i.socdm.com/sdk/js/adg-script-loader.js?id=116577&targetID=adg_116577&displayid=1&adType=SP&async=true&tagver=2.0.0`;
      default:
        return '';
    }
  };

  useEffect(() => {
    const script = document.createElement('script');

    script.src = getScript(adsType);
    script.async = true;

    const id = `ads${getUniqueStr}`;
    // eslint-disable-next-line no-unused-expressions
    document.getElementById(id)?.appendChild(script);
  }, []);

  return (
    <div css={adsContainerStyle}>
      <div id={`ads${getUniqueStr}`} />
    </div>
  );
};

export default AdsComponent;
