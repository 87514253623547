import { RequestType } from '@/app/model/RequestType';
import { Link } from 'react-router-dom';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import { Column, Row, VerticalGravity } from '@/app/component/base/layout/Layout';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import ConfirmationDialog from '@/app/component/dotpict/dialog/confirmation/ConfirmationDialog';
import useTranslator from '@/app/hooks/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';

type Props = {
  request: RequestType;
  handleClickComplete: (request: RequestType) => void;
  handleClickDelete: (request: RequestType) => void;
};

const Request = ({ request, handleClickComplete, handleClickDelete }: Props) => {
  const translator = useTranslator();

  return (
    <Column width="100%" background={DotpictColorStyle.WHITE}>
      <Spacer height={16} />
      <Row>
        <Spacer width={24} />
        <Link to={`/users/${request.sentUser.id}`}>
          <PixelArtComponent
            alt={request.sentUser.name}
            src={request.sentUser.profileImageUrl}
            width={48}
            height={48}
          />
        </Link>
        <SquareSpaceComponent size={16} />
        <Column>
          <Link to={`/users/${request.sentUser.id}`}>
            <Text
              text={request.sentUser.name}
              textStyle={DotpictTextStyle.BOLD14}
              colorStyle={DotpictColorStyle.BASE}
            />
          </Link>
          <SquareSpaceComponent size={4} />
          <Text
            text={request.text}
            textStyle={DotpictTextStyle.REGULAR14}
            colorStyle={DotpictColorStyle.BASE}
          />
          <SquareSpaceComponent size={16} />
          <Row verticalGravity={VerticalGravity.center}>
            <Text
              text={request.createdAt}
              textStyle={DotpictTextStyle.REGULAR13}
              colorStyle={DotpictColorStyle.GRAY_DEEP}
            />
            <Spacer width={24} />
            <PixelArtComponent
              src={`https://storage.googleapis.com/dotpict-images/web/request-box/request_${
                request.isCompleted ? 'complete' : 'incomplete'
              }.svg`}
              alt="complete"
              width={24}
              height={24}
              handleClick={() => handleClickComplete(request)}
            />
            <Spacer width={24} />
            <ConfirmationDialog
              title={translator(TranslationKeys.RequestDeleteTitle)}
              message={translator(TranslationKeys.RequestDeleteMessage)}
              handleClickOk={() => handleClickDelete(request)}
            >
              <PixelArtComponent
                src="https://storage.googleapis.com/dotpict-images/web/request-box/request_remove.svg"
                alt="complete"
                width={24}
                height={24}
              />
            </ConfirmationDialog>
          </Row>
        </Column>
        <Spacer width={24} />
      </Row>
      <Spacer height={16} />
    </Column>
  );
};

export default Request;
