import { useContext, useEffect, useLayoutEffect, useReducer } from 'react';
import { APIContext, MeContext } from '@/app/component/RootComponent';
import {
  EventDetailBindModel,
  InitialEventDetailBindModel,
} from '@/app/component/page/eventdetail';
import { UserEventType } from '@/app/model/UserEventType';
import { useNavigate, useParams } from 'react-router-dom';
import shouldFetchNext from '@/app/common/Common';
import { WorkType } from '@/app/model/WorkType';

type Action =
  | { type: 'START_FETCH_USER_EVENT'; payload: {} }
  | { type: 'END_FETCH_USER_EVENT'; payload: { userEvent: UserEventType } }
  | { type: 'START_FETCH_USER_EVENT_WORKS'; payload: { works: WorkType[] } }
  | { type: 'END_FETCH_USER_EVENT_WORKS'; payload: { works: WorkType[]; nextUrl: string } };

type State = {
  bindModel: EventDetailBindModel;
  nextUrl: string;
};

const reducer = (prev: State, action: Action): State => {
  switch (action.type) {
    case 'START_FETCH_USER_EVENT': {
      return {
        ...prev,
        bindModel: {
          ...prev.bindModel,
          isLoadingUserEvent: true,
        },
      };
    }
    case 'END_FETCH_USER_EVENT': {
      const { payload } = action;
      const { userEvent } = payload;
      return {
        ...prev,
        bindModel: {
          ...prev.bindModel,
          isLoadingUserEvent: false,
          title: userEvent.title,
          tags: userEvent.tag === '' ? [] : [userEvent.tag],
          user: userEvent.user,
          mainImageUrl: userEvent.imageUrl,
          templateImageUrl: userEvent.templateCanvasImageUrl,
          text: userEvent.text,
          colors: userEvent.templateCanvasColorCodes,
        },
      };
    }
    case 'START_FETCH_USER_EVENT_WORKS': {
      return {
        ...prev,
        bindModel: {
          ...prev.bindModel,
          isLoadingWorks: true,
        },
      };
    }
    case 'END_FETCH_USER_EVENT_WORKS': {
      const { payload } = action;
      return {
        ...prev,
        bindModel: {
          ...prev.bindModel,
          isLoadingWorks: false,
          isVisibleEnd: payload.nextUrl === '',
          works: payload.works,
        },
        nextUrl: payload.nextUrl,
      };
    }
    default:
      return prev;
  }
};

export const useEventDetail = () => {
  const { userEventId } = useParams();
  const navigator = useNavigate();
  const meState = useContext(MeContext);
  const [state, dispatch] = useReducer(reducer, {
    bindModel: InitialEventDetailBindModel,
    nextUrl: '',
  });
  const { client } = useContext(APIContext);

  const fetchUserEvent = async () => {
    if (userEventId === undefined) return;
    dispatch({ type: 'START_FETCH_USER_EVENT', payload: {} });
    const userEvent = await client.fetchUserEventDetail(userEventId);
    dispatch({ type: 'END_FETCH_USER_EVENT', payload: { userEvent } });
  };

  const fetchUserEventWorks = async (currentWorks: WorkType[], currentNextUrl: string) => {
    dispatch({ type: 'START_FETCH_USER_EVENT_WORKS', payload: { works: currentWorks } });
    const response = await client.fetchWorks(currentNextUrl);
    const newWorks = currentWorks.concat(response.works);
    const newNextUrl = response.nextUrl;
    dispatch({
      type: 'END_FETCH_USER_EVENT_WORKS',
      payload: { works: newWorks, nextUrl: newNextUrl },
    });
    if (shouldFetchNext(newNextUrl)) {
      fetchUserEventWorks(newWorks, newNextUrl);
    }
  };

  const handleScroll = () => {
    if (!state.bindModel.isLoadingWorks && shouldFetchNext(state.nextUrl)) {
      fetchUserEventWorks(state.bindModel.works, state.nextUrl);
    }
  };

  const handleClickPost = () => {
    if (!meState.isLoggedIn) {
      // eslint-disable-next-line no-alert
      window.alert('ログインが必要です');
      return;
    }
    navigator(`/upload?userEventId=${userEventId}`);
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    fetchUserEvent();
    fetchUserEventWorks([], `${client.getBaseUrl}/user_events/${userEventId}/works`);
  }, [dispatch]);

  return {
    bindModel: state.bindModel,
    handleClickPost,
  };
};

export default useEventDetail;
