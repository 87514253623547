import defaultOfficialEvent, { OfficialEventType } from '@/app/model/OfficialEventType';
import { WorkType } from '../model/WorkType';

export interface IOfficialEventDetailState {
  officialEvent: OfficialEventType;
  mainImageUrl: string;
  tag: string;
  templateImageUrl: string;
  colors: string[];
  shareTitle: string;
  twitterShareUrl: string;
  facebookShareUrl: string;
  bannerImageUrl: string;
  isLoadingWorks: boolean;
  isVisibleEnd: boolean;
  works: WorkType[];
  nextUrl: string;
  requestedUserId: number;
  isBattleEvent: boolean;
  description: string;
  teamOneText: string;
  teamOneTag: string;
  teamOneScore: number;
  teamOneColor: string;
  teamTwoText: string;
  teamTwoTag: string;
  teamTwoScore: number;
  teamTwoColor: string;
}

export const defaultOfficialEventDetailState: IOfficialEventDetailState = {
  officialEvent: defaultOfficialEvent,
  mainImageUrl: '',
  tag: '',
  templateImageUrl: '',
  colors: [],
  shareTitle: '',
  twitterShareUrl: '',
  facebookShareUrl: '',
  bannerImageUrl: '',
  isLoadingWorks: false,
  isVisibleEnd: false,
  works: [],
  nextUrl: '',
  requestedUserId: 0,
  isBattleEvent: false,
  description: '',
  teamOneText: '',
  teamOneTag: '',
  teamOneScore: 0,
  teamOneColor: '',
  teamTwoTag: '',
  teamTwoText: '',
  teamTwoScore: 0,
  teamTwoColor: '',
};
