import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { forceCheck } from 'react-lazyload';
import { APIContext, GlobalContext, MeContext } from '@/app/component/RootComponent';
import useLocalStorage from '@/app/customhook/useLocalStorage';
import { DotpictAction } from '@/app/reducer/reducer';
import { defaultWorksState } from '@/app/state/WorksStateType';
import { UploadWorkProps } from '@/app/component/page/work/upload/UploadWork';
import useLoadCanvas from '@/app/component/page/work/upload/useLoadCanvas';
import { Rect } from '@/app/component/page/work/upload/types';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const useUploadWork = (): UploadWorkProps => {
  const query = useQuery();
  const navigator = useNavigate();
  const { dispatch } = useContext(GlobalContext);
  const meState = useContext(MeContext);
  const [agree, setAgree] = useLocalStorage('isAgreedGuideline', false);
  const [modalOpen, setModalOpen] = useState(!agree);
  const [title, setTitle] = useState('');
  const [joiningEventTitle, setJoiningEventTitle] = useState('');
  const [joiningEventId, setJoiningEventId] = useState<number>(0);
  const [caption, setCaption] = useState('');
  const [fixedTag, setFixedTag] = useState<string>('');
  const [tags, setTags] = useState<string[]>([]);
  const [allowComment, setAllowComment] = useState(true);
  const [imageFile, setImageFile] = useState<File | undefined>();
  const [loading, setLoading] = useState(false);
  const [isVisibleError, setVisibleError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [cropRect, setCropRect] = useState<Rect | undefined>();
  const { canvasFile, colors, officialEventInfo, loadCanvas } = useLoadCanvas();
  const { client } = useContext(APIContext);

  const fetchUserEvent = async (userEventId: string) => {
    const eventDetail = await client.fetchUserEventDetail(userEventId);
    setJoiningEventTitle(eventDetail.title);
    setFixedTag(eventDetail.tag);
    if (eventDetail.tag !== '') {
      setTags([eventDetail.tag]);
    }
  };

  const fetchOfficialEvent = async (officialEventTag: string) => {
    const eventDetail = await client.fetchOfficialEventDetail(officialEventTag);
    setJoiningEventTitle(eventDetail.title);
    setJoiningEventId(eventDetail.id);
  };

  useEffect(() => {
    const userEventId = query.get('userEventId');
    if (userEventId === null || userEventId === '') return;
    fetchUserEvent(userEventId);
  }, [query.get('userEventId')]);

  useEffect(() => {
    const officialEventTag = query.get('officialEventTag') ?? officialEventInfo?.officialEventTag;
    if (officialEventTag === null || officialEventTag === '' || officialEventTag === undefined)
      return;
    setFixedTag(officialEventTag);
    setTags([officialEventTag]);
    fetchOfficialEvent(officialEventTag);
  }, [query.get('officialEventTag'), officialEventInfo]);

  useEffect(() => {
    const officialBattleEventTag =
      query.get('officialBattleEventTag') ?? officialEventInfo?.officialEventTag;
    const officialBattleEventTeam =
      query.get('officialBattleEventTeam') ?? officialEventInfo?.officialEventTeamTag;
    if (
      officialBattleEventTag === null ||
      officialBattleEventTag === '' ||
      officialBattleEventTag === undefined ||
      officialBattleEventTeam === null ||
      officialBattleEventTeam === '' ||
      officialBattleEventTeam === undefined
    ) {
      return;
    }
    setFixedTag(officialBattleEventTeam);
    setTags([officialBattleEventTeam]);
    fetchOfficialEvent(officialBattleEventTag);
  }, [
    query.get('officialBattleEventTag'),
    query.get('officialBattleEventTeam'),
    officialEventInfo,
  ]);

  useEffect(() => {
    const canvasId = Number(query.get('canvasId'));
    if (canvasId === 0) return;
    loadCanvas(canvasId);
  }, [query.get('canvasId')]);

  useEffect(() => {
    if (!canvasFile) return;
    setImageFile(canvasFile);
  }, [canvasFile]);

  useEffect(() => {
    // なぜかoekakiと写真が読み込まれないのでforcecheck
    setTimeout(forceCheck, 50);
  }, []);

  const handleCloseTutorial = () => {
    if (!agree) {
      navigator('/');
    }
    setModalOpen(false);
  };

  const handleEndTutorial = () => {
    setAgree(true);
    setModalOpen(false);
  };

  const handleFileChanged = (file: File | undefined) => {
    setImageFile(file);
    if (!file) {
      setCropRect(undefined);
    }
  };

  const handleTitleChanged = (newTitle: string) => {
    setTitle(newTitle);
  };

  const handleTagsChanged = (newTags: string[]) => {
    setTags(newTags);
  };

  const handleCaptionChanged = (newCaption: string) => {
    setCaption(newCaption);
  };

  const handleAllowCommentChanged = (newAllowComment: boolean) => {
    setAllowComment(newAllowComment);
  };

  const handleCloseError = () => {
    setVisibleError(false);
    setErrorMessage('');
  };

  const handleLogin = () => {
    navigator('/login');
  };

  const handleDecideCrop = (croppedRect: Rect | undefined) => {
    setCropRect(croppedRect);
  };

  const handleClickPost = async () => {
    if (!imageFile) {
      return;
    }
    setLoading(true);
    const response = await client
      .postWork(
        meState.token,
        title,
        caption,
        imageFile,
        colors,
        tags,
        allowComment,
        query.get('userEventId'),
        joiningEventId > 0 ? `${joiningEventId}` : null,
        cropRect,
      )
      .catch((reason: string) => {
        setErrorMessage(reason);
        setVisibleError(true);
      });
    setLoading(false);
    if (!response) {
      return;
    }
    const action: DotpictAction = {
      type: 'UPDATE_NEWEST_WORKS_STATE',
      payload: {
        newestWorksState: defaultWorksState,
      },
    };
    dispatch(action);
    navigator('/?type=newest');
  };

  return {
    imageFile,
    modalOpen,
    title,
    caption,
    fixedTag,
    tags,
    allowComment,
    loading,
    isVisibleJoiningEvent: joiningEventTitle !== '',
    joiningEventTitle,
    isVisibleError,
    isVisiblePleaseLogin: meState.token === '',
    errorMessage,
    handleFileChanged,
    handleTitleChanged,
    handleTagsChanged,
    handleCaptionChanged,
    handleAllowCommentChanged,
    handleClickPost,
    handleCloseTutorial,
    handleEndTutorial,
    handleCloseError,
    handleLogin,
    handleDecideCrop,
  };
};

export default useUploadWork;
