import { RefObject, useCallback, useContext, useEffect, useState } from 'react';
import { ToolContext, ToolsMap } from '@/app/component/page/draw/Context/ToolContext';
import { SizeContext, SizeType } from '@/app/component/page/draw/Context/SizeContext';
import useTranslator from '@/app/hooks/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';
import useAnalytics from '@/app/hooks/useAnalytics';
import { ColorContext } from '@/app/component/page/draw/Context/ColorContext';
import { CanvasSizes, MoveToolDirection, PixelsType, Position } from './types';
import { usePaintCanvas } from '.';

// そのPointがどのマスに当たるかを計算する
const useCalculateGrid = (canvasSizes: CanvasSizes) => {
  const calculateGrid = useCallback(
    (position: number, direction: 'column' | 'row') => {
      const pixelSize = direction === 'column' ? canvasSizes.pixelWidth : canvasSizes.pixelHeight;
      return Math.floor(position / pixelSize);
    },
    [canvasSizes],
  );

  const calculateGridColumn = useCallback(
    (position: number) => calculateGrid(position, 'column'),
    [canvasSizes],
  );

  const calculateGridRow = useCallback(
    (position: number) => calculateGrid(position, 'row'),
    [canvasSizes],
  );

  return [calculateGridColumn, calculateGridRow];
};

// 直線を引く関数
// プレゼンハムのアルゴリズムそのまま（をコピーしたアプリのコードを写経した）
// https://ja.wikipedia.org/wiki/%E3%83%96%E3%83%AC%E3%82%BC%E3%83%B3%E3%83%8F%E3%83%A0%E3%81%AE%E3%82%A2%E3%83%AB%E3%82%B4%E3%83%AA%E3%82%BA%E3%83%A0#%E5%8D%98%E7%B4%94%E5%8C%96
const useDrawLine = (canvasSizes: CanvasSizes) => {
  const canvasSize = useContext(SizeContext);
  const [calculateGridColumn, calculateGridRow] = useCalculateGrid(canvasSizes);

  const drawLine = useCallback(
    (tempPixels: PixelsType, color: string, lineStart: Position, lineEnd: Position) => {
      const newPixels = tempPixels.map((pixel) => [...pixel]);

      // プレゼンハムのアルゴリズムに通す前にピクセルからグリッド単位に整形する
      const startColumn = calculateGridColumn(lineStart.x);
      const startRow = calculateGridRow(lineStart.y);
      const endColumn = calculateGridColumn(lineEnd.x);
      const endRow = calculateGridRow(lineEnd.y);

      const dx = Math.abs(endColumn - startColumn);
      const dy = Math.abs(endRow - startRow);

      const sx = startColumn < endColumn ? 1 : -1;
      const sy = startRow < endRow ? 1 : -1;

      let err = dx - dy;
      let x = startColumn;
      let y = startRow;

      // 開始地点をまず描画
      if (y >= 0 && y < canvasSize.height && x >= 0 && x < canvasSize.width) {
        newPixels[y][x] = color;
      }

      while (x !== endColumn || y !== endRow) {
        const e2 = err * 2;
        if (-dy < e2) {
          err -= dy;
          x += sx;
        }
        if (e2 < dx) {
          err += dx;
          y += sy;
        }

        if (y >= 0 && y < canvasSize.height && x >= 0 && x < canvasSize.width) {
          newPixels[y][x] = color;
        }
      }
      return newPixels;
    },
    [canvasSizes, canvasSize],
  );

  return [drawLine];
};

// ペンツールと消しゴムツールの処理
const useToolsActionForPenAndEraser = (
  canvasSizes: CanvasSizes,
  pixels: PixelsType | undefined,
  setPixels: (newPixels: PixelsType, ignoreHistory?: boolean) => void,
  withMove: boolean,
  withLeave: boolean,
) => {
  const { color: selectedColor } = useContext(ColorContext);
  const tool = useContext(ToolContext);
  const [prevStartPosition, setPrevStartPosition] = useState<Position>({ x: 0, y: 0 });
  const [prevEndPosition, setPrevEndPosition] = useState<Position | null>(null);
  const [calculateGridColumn, calculateGridRow] = useCalculateGrid(canvasSizes);
  const [drawLine] = useDrawLine(canvasSizes);

  // マウスがキャンバス外に出たら描画処理を中断する
  useEffect(() => {
    if (withLeave) {
      setPrevStartPosition({ x: 0, y: 0 });
      setPrevEndPosition(null);
    }
  }, [withLeave]);

  const action = (startPosition: Position, endPosition: Position) => {
    if (pixels === undefined) {
      return;
    }

    let ignoreHistory = true;
    let column;
    let row;
    if (startPosition.x !== prevStartPosition.x && startPosition.y !== prevStartPosition.y) {
      column = calculateGridColumn(startPosition.x);
      row = calculateGridRow(startPosition.y);
      setPrevStartPosition(startPosition);
    } else {
      column = calculateGridColumn(endPosition.x);
      row = calculateGridRow(endPosition.y);
      if (withMove) {
        setPrevEndPosition(endPosition);
      } else {
        // MouseUpのタイミングでHistoryに追加する
        ignoreHistory = false;
        setPrevEndPosition(null);
      }
    }
    let tempPixels = pixels.map((pixel) => [...pixel]);

    const color = tool === ToolsMap.Eraser ? 'ffffff' : selectedColor;
    tempPixels[row][column] = color;

    // マウスを高速に動かしたときに間隔が飛んでしまうので埋める
    if (withMove) {
      // 初回はStartPositionを基準にし、それ以降は直前のMoveのPositionを基準にする
      const position = prevEndPosition !== null ? prevEndPosition : startPosition;
      const prevEndColumn = calculateGridColumn(position.x);
      const prevEndRow = calculateGridRow(position.y);
      // Moveにより2マス以上飛んでる場合は間を埋める
      if (Math.abs(prevEndColumn - column) >= 2 || Math.abs(prevEndRow - row) >= 2) {
        tempPixels = drawLine(tempPixels, color, position, endPosition);
      }
    }

    setPixels(tempPixels, ignoreHistory);
  };

  return [action];
};

// バケツツールの処理
const useToolsActionForBucket = (
  canvasSizes: CanvasSizes,
  pixels: PixelsType | undefined,
  setPixels: (newPixels: PixelsType) => void,
) => {
  const { color: selectedColor } = useContext(ColorContext);
  const canvasSize = useContext(SizeContext);
  const [calculateGridColumn, calculateGridRow] = useCalculateGrid(canvasSizes);
  let tempPixels: PixelsType;
  let targetColor: string;

  // 再帰で隣接するマスがbeforeColorと同じなら塗りつぶしていく
  const recursiveBucketFill = (row: number, column: number) => {
    // 枠外に出たら終了
    if (row < 0 || row >= canvasSize.height || column < 0 || column >= canvasSize.width) {
      return;
    }

    // 隣接マスが別の色になっていたら終了
    const beforeColor = tempPixels[row][column];
    if (beforeColor !== targetColor) {
      return;
    }

    // 隣接していたマスがクリックされた位置のマスと同じ色だった場合は塗りつぶす
    tempPixels[row][column] = selectedColor;

    // 隣接マスも調べる
    recursiveBucketFill(row + 1, column);
    recursiveBucketFill(row - 1, column);
    recursiveBucketFill(row, column + 1);
    recursiveBucketFill(row, column - 1);
  };

  const action = (startPosition: Position) => {
    if (pixels === undefined) {
      return;
    }

    const column = calculateGridColumn(startPosition.x);
    const row = calculateGridRow(startPosition.y);
    targetColor = pixels[row][column];
    tempPixels = pixels.map((pixel) => [...pixel]);

    if (selectedColor !== targetColor) {
      recursiveBucketFill(row, column);
    }
    setPixels(tempPixels);
  };

  return [action];
};

// 直線ツールの処理
const useToolsActionForLine = (
  canvasSizes: CanvasSizes,
  pixels: PixelsType | undefined,
  setPixels: (newPixels: PixelsType, ignoreHistory?: boolean) => void,
  withMove: boolean,
  withLeave: boolean,
) => {
  const { color: selectedColor } = useContext(ColorContext);
  const [prevStartPosition, setPrevStartPosition] = useState<Position>({ x: 0, y: 0 });
  const [originalPixels, setOriginalPixels] = useState<PixelsType>(); // 仮描画から復活させるために必要
  const [drawLine] = useDrawLine(canvasSizes);

  const action = (startPosition: Position, endPosition: Position) => {
    if (pixels === undefined) {
      return;
    }
    let tempPixels = pixels.map((pixel) => [...pixel]);
    let isPreviewMode = true;

    if (startPosition.x !== prevStartPosition.x && startPosition.y !== prevStartPosition.y) {
      setPrevStartPosition(startPosition);

      // ドラッグ中は直線を仮描画するので復元できるよう開始前のキャンバスの状態を保持しておく
      setOriginalPixels(tempPixels.map((pixel) => [...pixel]));
      return;
    }

    if (originalPixels !== undefined) {
      if (!withMove || withLeave) {
        // 直線ツール確定時・枠外に出たときはプレビュー前の状態を破棄する
        setOriginalPixels(undefined);
        setPrevStartPosition({ x: 0, y: 0 });
        isPreviewMode = false;
      } else {
        // プレビュー中であれば直線ツール使用前の状態を復元してから直線を引く
        tempPixels = originalPixels.map((pixel) => [...pixel]);
      }
    }

    tempPixels = drawLine(tempPixels, selectedColor, startPosition, endPosition);

    // キャンバスに反映。プレビュー中はHistoryには残さない。
    setPixels(tempPixels, isPreviewMode);
  };

  return [action];
};

// 全体移動ツールの処理
const useToolsActionForMove = (
  pixels: PixelsType | undefined,
  setPixels: (newPixels: PixelsType) => void,
) => {
  const tool = useContext(ToolContext);
  const canvasSize = useContext(SizeContext);
  const [originalPixels, setOriginalPixels] = useState<PixelsType>(); // 仮描画から復活させるために必要

  // 全体移動ツール実行中にキャンバスサイズが変わる場合は参照元がおかしくなるのでキャンバスをリセットする
  useEffect(() => {
    setOriginalPixels(undefined);
  }, [canvasSize]);

  // 全体移動終了時に元の状態を初期化しておく
  useEffect(() => {
    if (originalPixels !== undefined && tool !== ToolsMap.Move) {
      setOriginalPixels(undefined);
    }
  }, [tool]);

  const action = (movePosition: Position | undefined) => {
    if (pixels === undefined) {
      return;
    }

    if (movePosition === undefined) {
      return;
    }

    const tempPixels = pixels.map((pixel) => [...pixel]);

    if (originalPixels === undefined) {
      // 全体移動開始前の状態を保持しておく
      setOriginalPixels(tempPixels.map((pixel) => [...pixel]));
    }

    const { x, y } = movePosition;
    // eslint-disable-next-line no-plusplus
    for (let row = 0; row < canvasSize.height; row++) {
      // eslint-disable-next-line no-plusplus
      for (let column = 0; column < canvasSize.width; column++) {
        if (
          row - y < 0 ||
          row - y >= canvasSize.height ||
          column - x < 0 ||
          column - x >= canvasSize.width
        ) {
          // 移動によりキャンバス外からピクセルを持ってくる場合は背景色にしておく
          tempPixels[row][column] = 'ffffff';
        } else {
          tempPixels[row][column] =
            originalPixels !== undefined
              ? originalPixels[row - y][column - x]
              : // 初回だけoriginalPixelsがundefinedなのでpixelsを参照する
                pixels[row - y][column - x];
        }
      }
    }

    setPixels(tempPixels);
  };

  return [action];
};

// 全体移動ツールだけキャンバスをクリックしないので、クリックしたときの処理を別に切り分けている
export const useMoveTool = ({
  canvasRenderSize,
  canvasOffsetTop,
  canvasOffsetLeft,
}: CanvasSizes) => {
  const canvasSize = useContext(SizeContext);
  const tool = useContext(ToolContext);
  const [movePosition, setMovePosition] = useState<Position>();

  const handleMove = useCallback(
    (direction: MoveToolDirection) => {
      const { x, y } = movePosition === undefined ? { x: 0, y: 0 } : movePosition;
      if (direction === 'top') {
        setMovePosition({ x, y: y - 1 });
      } else if (direction === 'right') {
        setMovePosition({ x: x + 1, y });
      } else if (direction === 'left') {
        setMovePosition({ x: x - 1, y });
      } else {
        setMovePosition({ x, y: y + 1 });
      }
    },
    [movePosition, canvasRenderSize, canvasOffsetTop, canvasOffsetLeft],
  );

  useEffect(() => {
    if (movePosition !== null && tool !== ToolsMap.Move) {
      setMovePosition(undefined);
    }
  }, [tool]);

  // 全体移動ツール実行中にキャンバスサイズが変わる場合は移動距離をリセットする
  useEffect(() => {
    setMovePosition(undefined);
  }, [canvasSize]);

  const handleKeydown = (event: KeyboardEvent) => {
    if (tool !== ToolsMap.Move) return;
    const keyValue = event.key.toLowerCase();
    if (keyValue === 'arrowright') {
      handleMove('right');
    } else if (keyValue === 'arrowleft') {
      handleMove('left');
    } else if (keyValue === 'arrowup') {
      handleMove('top');
    } else if (keyValue === 'arrowdown') {
      handleMove('bottom');
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown);
    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [handleKeydown]);

  return [movePosition, handleMove] as [Position, typeof handleMove];
};

// ツールを用いて何か行ったときの処理を行う（例：ペンツールでmouseDownを行ってドットを追加）
export const useToolsAction = (
  canvasSizes: CanvasSizes,
  pixels: PixelsType | undefined, // 一時的なキャンバスのピクセルデータ
  setPixels: (newPixels: PixelsType, ignoreHistory?: boolean) => void, // 各ツールで最終的にこの関数を叩き、描画後のピクセルデータをキャンバスに反映する
  startPosition: Position,
  endPosition: Position,
  movePosition: Position | undefined,
  withMove: boolean,
  withLeave: boolean,
) => {
  const tool = useContext(ToolContext);

  // 改行されてしまうと見づらくなってしまうので例外的にprettierを無効化している
  // prettier-ignore
  const [actionForPenAndEraser] = useToolsActionForPenAndEraser(canvasSizes, pixels, setPixels, withMove, withLeave);
  const [actionForBucket] = useToolsActionForBucket(canvasSizes, pixels, setPixels);
  // prettier-ignore
  const [actionForLine] = useToolsActionForLine(canvasSizes, pixels, setPixels, withMove, withLeave);
  const [actionForMove] = useToolsActionForMove(pixels, setPixels);

  // 描画開始・終了が行われたらツールごとに処理を行う
  useEffect(() => {
    if (pixels === undefined) {
      return;
    }

    if (tool === ToolsMap.Pen || tool === ToolsMap.Eraser) {
      actionForPenAndEraser(startPosition, endPosition);
    } else if (tool === ToolsMap.Bucket) {
      actionForBucket(startPosition);
    } else if (tool === ToolsMap.Line) {
      actionForLine(startPosition, endPosition);
    }
  }, [startPosition, endPosition]);

  // 全体移動ツールを用いて動かしたとき
  useEffect(() => {
    if (tool === ToolsMap.Move) {
      actionForMove(movePosition);
    }
  }, [movePosition]);
};

export const useDownloadTool = () => {
  const [paint] = usePaintCanvas();
  const translator = useTranslator();
  const analytics = useAnalytics();

  return (
    canvasRef: RefObject<HTMLCanvasElement>,
    canvasSize: SizeType,
    title: string | undefined,
    pixels: PixelsType | undefined,
    resizeRate: number,
  ) => {
    if (canvasRef.current === null || pixels === undefined) {
      return;
    }

    const { width, height } = canvasSize;

    // 一時的なキャンバスを作成しそこに描画することで固定サイズの画像をダウンロードさせる
    const resizedCanvas = document.createElement('canvas');
    const resizedContext = resizedCanvas.getContext('2d')!;

    resizedCanvas.height = height * resizeRate;
    resizedCanvas.width = width * resizeRate;
    paint(resizedContext, resizeRate, resizeRate, pixels);

    const base64 = resizedCanvas.toDataURL('image/png');

    analytics('draw_download_canvas_image');
    const link = document.createElement('a');
    link.href = base64;
    link.setAttribute('download', title ?? translator(TranslationKeys.DefaultCanvasTitle));
    link.click();
  };
};
