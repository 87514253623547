import { Column, HorizontalGravity } from '@/app/component/base/layout/Layout';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import Text from '@/app/component/Text';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';

export type TutorialItemProps = {
  imageUrl: string;
  text: string;
};

const TutorialItem = ({ imageUrl, text }: TutorialItemProps) => {
  return (
    <Column horizontalGravity={HorizontalGravity.center}>
      <PixelArtComponent alt="tutorial_image" src={imageUrl} width={180} height={180} />
      <Spacer height={60} />
      <Text
        text={text}
        textStyle={DotpictTextStyle.REGULAR16}
        colorStyle={DotpictColorStyle.BASE}
      />
    </Column>
  );
};

export default TutorialItem;
