import { ThreadType } from '@/app/model/ThreadType';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import { css } from '@emotion/react';
import useThreadLike from '@/app/component/part/work/thread/like/useThreadLike';
import { WorkType } from '../../../../../model/WorkType';

export type ThreadLikeProps = {
  work: WorkType;
  thread: ThreadType;
};

const relativeWrapper = css`
  position: relative;
  width: 18px;
  height: 18px;
`;

const absoluteImage = css`
  position: absolute;
  top: 6px;
  left: 11px;
`;

export type ThreadLikeState = {
  workOwnerProfileImageUrl: string;
  isVisibleLikeButton: boolean;
  isVisibleAuthorProfileImageOnLikeButton: boolean;
};

export type ThreadLikeAction = {
  handleClickLike: () => void;
};

const ThreadLike = ({ work, thread }: ThreadLikeProps) => {
  const {
    workOwnerProfileImageUrl,
    isVisibleLikeButton,
    isVisibleAuthorProfileImageOnLikeButton,
    handleClickLike,
  } = useThreadLike({ work, thread });

  if (isVisibleLikeButton) {
    return (
      <>
        <Spacer width={8} />
        <PixelArtComponent
          alt="author_like"
          src="https://storage.googleapis.com/dotpict-images/web/thread/thread_like.svg"
          width={18}
          height={18}
          handleClick={() => handleClickLike()}
        />
      </>
    );
  }

  if (isVisibleAuthorProfileImageOnLikeButton) {
    return (
      <>
        <Spacer width={8} />
        <div css={relativeWrapper}>
          <PixelArtComponent
            alt="author_profile_image_url"
            src={workOwnerProfileImageUrl}
            width={18}
            height={18}
          />
          <div css={absoluteImage}>
            <PixelArtComponent
              alt="author_like"
              src="https://storage.googleapis.com/dotpict-images/web/thread/thread_liked.svg"
              width={13}
              height={12}
              handleClick={() => handleClickLike()}
            />
          </div>
        </div>
      </>
    );
  }

  return null;
};

export default ThreadLike;
