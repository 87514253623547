import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import HorizontalContainerStyle from '@/app/style/HorizontalContainerStyle';
import TranslationKeys from '@/app/translation/TranslationKeys';

type IProps = {
  type: string | undefined;
  query: string | undefined;
};

const SearchHeaderComponent = (props: IProps) => {
  const { type, query } = props;
  const intl = useIntl();

  return (
    <div>
      <Text
        text={`「${query}」の検索結果`}
        textStyle={DotpictTextStyle.BOLD16}
        colorStyle={DotpictColorStyle.BASE}
      />
      <SquareSpaceComponent size={16} />
      <div css={HorizontalContainerStyle}>
        <Link to={`/search/works/title/${query}`}>
          <Text
            text={intl.formatMessage({ id: TranslationKeys.Title })}
            textStyle={DotpictTextStyle.BOLD20}
            colorStyle={type === 'title' ? DotpictColorStyle.PRIMARY : DotpictColorStyle.BASE}
          />
        </Link>
        <SquareSpaceComponent size={24} />
        <Link to={`/search/works/tag/${query}`}>
          <Text
            text={intl.formatMessage({ id: TranslationKeys.Tag })}
            textStyle={DotpictTextStyle.BOLD20}
            colorStyle={type === 'tag' ? DotpictColorStyle.PRIMARY : DotpictColorStyle.BASE}
          />
        </Link>
        <SquareSpaceComponent size={24} />
        <Link to={`/search/users/${query}`}>
          <Text
            text={intl.formatMessage({ id: TranslationKeys.User })}
            textStyle={DotpictTextStyle.BOLD20}
            colorStyle={type === 'user' ? DotpictColorStyle.PRIMARY : DotpictColorStyle.BASE}
          />
        </Link>
      </div>
    </div>
  );
};

export default SearchHeaderComponent;
