import { useCallback } from 'react';
import styled from '@emotion/styled';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import Text from '@/app/component/Text';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import useTranslator from '@/app/hooks/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  // ボーダー分引く
  width: ${128 - 4}px;
  height: ${128 - 4}px;
  border: 2px solid ${DotpictColorStyle.PRIMARY};
  background: ${DotpictColorStyle.WHITE};
  cursor: pointer;
`;

const TextContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  grid-gap: 8px;
`;

type Props = {
  setOpenNewCanvas: (open: boolean) => void;
};

const DrawCanvas = ({ setOpenNewCanvas }: Props) => {
  const translator = useTranslator();

  const handleClick = useCallback(() => {
    setOpenNewCanvas(true);
  }, []);

  return (
    <Container onClick={handleClick}>
      <TextContainer>
        <PixelArtComponent
          alt="add"
          src="https://storage.googleapis.com/dotpict-images/web/ic_add_canvas.svg"
          width={12}
          height={12}
        />

        <Text
          text={translator(TranslationKeys.NewCanvas)}
          textStyle={DotpictTextStyle.REGULAR14}
          colorStyle={DotpictColorStyle.PRIMARY}
        />
      </TextContainer>
    </Container>
  );
};

export default DrawCanvas;
