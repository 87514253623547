import { useContext, useEffect, useState, useLayoutEffect } from 'react';
import { APIContext, GlobalContext, LayoutContext, MeContext } from '@/app/component/RootComponent';
import { useParams } from 'react-router-dom';
import UsersComponent from '@/app/component/UsersComponent';
import VerticalContainerStyle from '@/app/style/VerticalContainerStyle';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import shouldFetchNext from '@/app/common/Common';
import { UserSummaryType } from '@/app/model/UserSummaryType';
import { defaultFollowerUsersState } from '@/app/state/IFollowerUsersState';
import { DotpictAction } from '@/app/reducer/reducer';

const FollowerUsersComponent = () => {
  const { userId } = useParams();
  const { globalState, dispatch } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const { layoutParams } = useContext(LayoutContext);
  const meState = useContext(MeContext);
  const { client } = useContext(APIContext);

  const fetchData = async (
    currentUserSummaries: UserSummaryType[],
    currentNextUrl: string | null,
  ) => {
    if (userId === undefined) return;
    setLoading(true);
    const requestedUserId = meState.user.id;
    const nextUrl =
      currentNextUrl || `${client.getBaseUrl}/v2/users/${userId}/follower?work_count=6`;
    const usersResponse = await client.fetchUsers(meState.token, nextUrl);
    const action: DotpictAction = {
      type: 'UPDATE_FOLLOWER_USERS_STATE',
      payload: {
        followerUsersState: {
          userId: parseInt(userId, 10),
          userSummaries: currentUserSummaries.concat(usersResponse.userSummaries),
          nextUrl: usersResponse.nextUrl,
          requestedUserId,
        },
      },
    };
    dispatch(action);
    setLoading(false);
  };

  const handleScroll = () => {
    if (!loading && shouldFetchNext(globalState.followerUsersState.nextUrl)) {
      fetchData(
        globalState.followerUsersState.userSummaries,
        globalState.followerUsersState.nextUrl,
      );
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    const storedUserId = `${globalState.followerUsersState.userId}`;

    if (userId !== storedUserId) {
      window.scrollTo(0, 0);
      const resetAction: DotpictAction = {
        type: 'UPDATE_FOLLOWER_USERS_STATE',
        payload: { followerUsersState: defaultFollowerUsersState },
      };
      dispatch(resetAction);
    }

    if (
      userId !== storedUserId ||
      globalState.followedUsersState.requestedUserId !== meState.user.id
    ) {
      fetchData([], null);
    }
  }, [userId, meState.token]);

  return (
    <div css={VerticalContainerStyle}>
      <SquareSpaceComponent size={layoutParams.contentTopSpace} />
      <UsersComponent
        userSummaries={globalState.followerUsersState.userSummaries}
        isLoading={loading}
        isVisibleEnd={globalState.followerUsersState.nextUrl === ''}
        isVisibleAds
        dummyItemCount={20}
      />
      <SquareSpaceComponent size={layoutParams.contentBottomSpace} />
    </div>
  );
};

export default FollowerUsersComponent;
