import { GetUserWorksService } from '@/app/service/GetUserWorks/GetUserWorksService';
import { PagingType } from '@/app/model/PagingType';
import defaultUser from '@/app/model/UserType';
import defaultWork from '@/app/model/WorkType';

const dummyGetUserWorksService: GetUserWorksService = (_: PagingType) => {
  return Promise.resolve({
    user: {
      ...defaultUser,
      name: 'dummyUser',
    },
    works: [
      {
        ...defaultWork,
        imageUrl: 'https://storage.googleapis.com/dotpict-images/official_events/cd.png',
      },
    ],
    nextUrl: '',
  });
};

export default dummyGetUserWorksService;
