import { Grid } from '@/app/component/base/layout/Layout';
import ColorRect from '@/app/component/dotpict/colorrect/ColorRect';

export type ColorsProps = {
  colors: string[];
  colorWidth: number;
  colorHeight: number;
};

const Colors = ({ colors, colorWidth, colorHeight }: ColorsProps) => (
  <Grid>
    {colors.map((color) => (
      <ColorRect key={color} color={color} width={colorWidth} height={colorHeight} />
    ))}
  </Grid>
);

export default Colors;
