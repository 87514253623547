import React from 'react';
import { Grid, VerticalGravity } from '@/app/component/base/layout/Layout';
import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import { Link } from 'react-router-dom';

export type TitleAndTagsProps = {
  title: string;
  tags: string[];
};

const TitleAndTags = ({ title, tags }: TitleAndTagsProps) => {
  return (
    <Grid verticalGravity={VerticalGravity.center}>
      <Text text={title} textStyle={DotpictTextStyle.BOLD20} colorStyle={DotpictColorStyle.BASE} />
      {tags.map((tag) => (
        <React.Fragment key={tag}>
          <SquareSpaceComponent size={12} />
          <Link to={`/search/works/tag/${tag}`}>
            <Text
              text={`#${tag}`}
              textStyle={DotpictTextStyle.BOLD16}
              colorStyle={DotpictColorStyle.PRIMARY}
            />
          </Link>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default TitleAndTags;
