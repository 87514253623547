import { Button } from '@material-ui/core';
import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import { Row } from '@/app/component/base/layout/Layout';
import { styled } from '@material-ui/core/styles';

export interface TabLayoutProps {
  items: string[];
  selectedIndex: number;
  onClick: (index: number) => void;
}

export const TabLayout = (props: TabLayoutProps) => {
  const { items, selectedIndex, onClick } = props;

  const NoPaddingButton = styled(Button)({
    display: 'inline-block',
    padding: 0,
    minHeight: 0,
    minWidth: 0,
  });

  return (
    <Row>
      {items.map((item: string, index: number) => (
        <Row key={item}>
          <NoPaddingButton onClick={() => onClick(index)}>
            <Text
              text={item}
              textStyle={DotpictTextStyle.BOLD16}
              colorStyle={
                index === selectedIndex ? DotpictColorStyle.PRIMARY : DotpictColorStyle.BASE
              }
            />
          </NoPaddingButton>
          {index < items.length - 1 && <SquareSpaceComponent size={24} />}
        </Row>
      ))}
    </Row>
  );
};
