import { TwitterIcon, TwitterShareButton } from 'react-share';
import { css } from '@emotion/react';
import { useIntl } from 'react-intl';
import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import TranslationKeys from '@/app/translation/TranslationKeys';
import CenterHorizontalContainerStyle from '@/app/style/CenterHorizontalContainerStyle';

interface IProps {
  url: string;
  title: string;
  width: number;
  height: number;
}

const TwitterShareComponent = (props: IProps) => {
  const { url, title, width, height } = props;
  const intl = useIntl();

  const containerStyle = css(CenterHorizontalContainerStyle, {
    background: '#00aced',
    width,
    height,
  });

  return (
    <TwitterShareButton url={url} title={title}>
      <div css={containerStyle}>
        <TwitterIcon size={24} />
        <Text
          text={intl.formatMessage({ id: TranslationKeys.Tweet })}
          textStyle={DotpictTextStyle.BOLD11}
          colorStyle={DotpictColorStyle.WHITE}
        />
        <SquareSpaceComponent size={8} />
      </div>
    </TwitterShareButton>
  );
};

export default TwitterShareComponent;
