import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import { Column, HorizontalGravity, VerticalGravity } from '@/app/component/base/layout/Layout';
import TranslationKeys from '@/app/translation/TranslationKeys';
import { css } from '@emotion/react';
import useTranslator from '@/app/hooks/useTranslator';

export type FileSelectButtonProps = {
  handleFileSelect: (file: File | undefined) => void;
};

const styledLabel = css`
  width: 320px;
  height: 48px;
`;

const styledInput = css`
  display: none;
`;

const FileSelectButton = ({ handleFileSelect }: FileSelectButtonProps) => {
  const translator = useTranslator();

  return (
    <label css={styledLabel} htmlFor="upload-file">
      <Column
        width={320}
        height={48}
        background={DotpictColorStyle.PRIMARY}
        horizontalGravity={HorizontalGravity.center}
        verticalGravity={VerticalGravity.center}
      >
        <Text
          text={translator(TranslationKeys.AddFile)}
          textStyle={DotpictTextStyle.BOLD14}
          colorStyle={DotpictColorStyle.WHITE}
        />
      </Column>
      <input
        id="upload-file"
        accept="image/png,image/gif"
        css={styledInput}
        type="file"
        onChange={(event) => {
          const file = event.target.files?.item(0);
          if (!file) return;
          handleFileSelect(file);
        }}
      />
    </label>
  );
};

export default FileSelectButton;
