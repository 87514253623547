import HorizontalContainerStyle from '@/app/style/HorizontalContainerStyle';
import { css } from '@emotion/react';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import VerticalContainerStyle from '@/app/style/VerticalContainerStyle';
import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import MediumBadgeComponent from '@/app/component/MediumBadgeComponent';
import { useIntl } from 'react-intl';
import TranslationKeys from '@/app/translation/TranslationKeys';
import { MakingType } from '../making/MakingType';

const infoContainerPadding = 16;

interface IProps {
  making: MakingType;
  width: number;
  height: number; // height = imageSize
}

const MakingThumbnailComponent = (props: IProps) => {
  const intl = useIntl();
  const { making, width, height } = props;

  const rootContainerStyle = css(HorizontalContainerStyle, {
    backgroundColor: DotpictColorStyle.WHITE,
    width,
    height,
  });

  const infoContainerStyle = css(VerticalContainerStyle, {
    padding: infoContainerPadding,
    width: width - height - infoContainerPadding * 2,
    height: '100%',
    justifyContent: 'space-between',
  });

  return (
    <div css={rootContainerStyle}>
      <PixelArtComponent alt="making" src={making.mainImage} width={height} height={height} />
      <div css={VerticalContainerStyle}>
        <div css={infoContainerStyle}>
          <MediumBadgeComponent
            text={intl.formatMessage({ id: TranslationKeys.Making })}
            color={DotpictColorStyle.BLUE}
          />
          <div style={{ textAlign: 'center' }}>
            <Text
              text={intl.formatMessage({ id: making.theme })}
              textStyle={DotpictTextStyle.BOLD16}
              colorStyle={DotpictColorStyle.BASE}
            />
          </div>
          <div style={{ textAlign: 'end' }}>
            <Text
              text={`by ${intl.formatMessage({ id: making.authorName })}`}
              textStyle={DotpictTextStyle.BOLD12}
              colorStyle={DotpictColorStyle.BASE}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MakingThumbnailComponent;
