import { css, SerializedStyles } from '@emotion/react';
import { Skeleton } from '@material-ui/lab';
import LazyLoad from 'react-lazyload';
import { Box } from '@material-ui/core';
import { MouseEventHandler } from 'react';

type Props = {
  alt: string;
  src?: string;
  width: number | string;
  height: number | string;
  className?: SerializedStyles;
  objectFit?: 'contain' | 'cover';
  lazyLoad?: boolean;
  handleClick?: MouseEventHandler;
};

const PixelArtComponent = ({
  alt,
  src,
  width,
  height,
  className,
  objectFit = 'contain',
  lazyLoad = true,
  handleClick,
}: Props) => {
  const containerStyle = css({
    position: 'relative',
    width,
    height,
  });

  const imageStyle = css(className, {
    position: 'absolute',
    top: 0,
    left: 0,
    width,
    height,
    imageRendering: ['pixelated', 'crisp-edges'],
    objectFit,
  });

  const overlayStyle = css({
    position: 'absolute',
    top: 0,
    left: 0,
    width,
    height,
  });

  if (src === '') {
    return <Skeleton variant="rect" width={width} height={height} />;
  }
  return (
    <Box css={containerStyle} onClick={handleClick}>
      {lazyLoad ? (
        <LazyLoad height={height}>
          <img alt={alt} src={src} css={[imageStyle, className]} />
        </LazyLoad>
      ) : (
        <img alt={alt} src={src} css={[imageStyle, className]} />
      )}
      <img
        alt={alt}
        src="https://storage.googleapis.com/dotpict-images/web/image.png"
        css={overlayStyle}
      />
    </Box>
  );
};

export default PixelArtComponent;
