import { useIntl } from 'react-intl';
import { useCallback } from 'react';

type PrimitiveType = string | number | boolean | null | undefined | Date;

const useTranslator = () => {
  const intl = useIntl();
  return useCallback(
    (key: string, values?: Record<string, PrimitiveType>) => {
      return intl.formatMessage({ id: key }, values);
    },
    [intl],
  );
};

export default useTranslator;
