import { useContext } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import VerticalContainerStyle from '@/app/style/VerticalContainerStyle';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import { Link } from 'react-router-dom';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import { MakingContentType, MakingImageContentType } from '@/app/making/MakingContentType';
import TranslationKeys from '@/app/translation/TranslationKeys';
import LargeBadgeComponent from '@/app/component/LargeBadgeComponent';
import { LayoutContext } from '@/app/component/RootComponent';
import HorizontalContainerStyle from '@/app/style/HorizontalContainerStyle';
import ShareComponent from '@/app/component/ShareComponent';
import { makingContentBottomSpace, shareBaseUrl } from '@/app/common/Constant';
import CenterVerticalContainerStyle from '@/app/style/CenterVerticalContainerStyle';
import MakingLikeComponent from '@/app/component/MakingLikeComponent';
import DefaultGridStyle from '@/app/style/DefaultGridStyle';
import MakingThumbnailComponent from '@/app/component/MakingThumbnailComponent';
import { Helmet } from 'react-helmet';
import { MakingLinkType } from '@/app/making/MakingLinkType';
import ExternalLinkComponent from '@/app/component/ExternalLinkComponent';
import AdsComponent from '@/app/component/AdsComponent';
import DotpictAdsType from '@/app/ads/DotpictAdsType';
import useTranslator from '@/app/hooks/useTranslator';
import MakingImage from '@/app/component/page/making/detail/image/MakingImage';
import MakingHeadline from '@/app/component/page/making/detail/headline/MakingHeadline';
import MakingText from '@/app/component/page/making/detail/text/MakingText';
import MakingPixelart from '@/app/component/page/making/detail/pixelart/MakingPixelart';
import { MakingType } from '@/app/making/MakingType';
import MakingContentEnum from '@/app/making/MakingContentEnum';
import useMakingDetail from '@/app/component/page/making/detail/useMakingDetail';

const profileImageSize = 128;
const anotherMakingGap = 16;

const infoContainerStyle = css(VerticalContainerStyle, {
  backgroundColor: DotpictColorStyle.WHITE_GRAY,
  width: '100%',
});

export type MakingDetailState = {
  making: MakingType;
  isLiked: boolean;
  fromApp: boolean;
  mainImageSize: number;
  rootContainerStyle: SerializedStyles;
  anotherMakings: MakingType[];
};

export type MakingDetailAction = {
  handleClickLike: () => void;
};

const MakingDetail = () => {
  const translator = useTranslator();
  const { layoutParams } = useContext(LayoutContext);
  const {
    making,
    isLiked,
    fromApp,
    mainImageSize,
    rootContainerStyle,
    anotherMakings,
    handleClickLike,
  } = useMakingDetail();

  const contentContainerStyle = css(VerticalContainerStyle, {
    backgroundColor: DotpictColorStyle.WHITE,
    padding: '0 32',
    width: layoutParams.contentWidth - 32 * 2,
  });

  const anotherMakingColumnCount = layoutParams.isMobile ? 1 : 2;
  const anotherMakingItemWidth = layoutParams.isMobile
    ? layoutParams.contentWidth
    : (layoutParams.contentWidth - anotherMakingGap) / 2;
  const anotherMakingItemHeight = anotherMakingItemWidth / 2;

  const anotherMakingGridStyle = css(DefaultGridStyle, {
    gridGap: anotherMakingGap,
    gridTemplateColumns: `repeat(${anotherMakingColumnCount}, ${anotherMakingItemWidth}px)`,
  });

  const renderOgp = () => {
    const title = translator(TranslationKeys.MakingOgpTitle, {
      authorName: translator(making.authorName),
    });
    const description = translator(making.theme);
    const ogpImageUrl = making.ogpImage;
    return (
      <Helmet>
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={ogpImageUrl} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={ogpImageUrl} />
      </Helmet>
    );
  };

  const instansOfIMakingImageContent = (arg: any) =>
    arg !== null &&
    typeof arg === 'object' &&
    typeof arg.width === 'number' &&
    typeof arg.height === 'number';

  const renderContent = (content: MakingContentType) => {
    switch (content.type) {
      case MakingContentEnum.HEADLINE:
        return <MakingHeadline text={content.content} />;
      case MakingContentEnum.TEXT:
        return <MakingText text={content.content} />;
      case MakingContentEnum.PIXELART:
        return instansOfIMakingImageContent(content) ? (
          <MakingPixelart
            imageUrl={content.content}
            width={(content as MakingImageContentType).width}
            height={(content as MakingImageContentType).height}
          />
        ) : (
          <MakingPixelart imageUrl={content.content} width={mainImageSize} height={mainImageSize} />
        );
      case MakingContentEnum.IMAGE:
        return (
          <MakingImage
            imageUrl={content.content}
            width={(content as MakingImageContentType).width}
            height={(content as MakingImageContentType).height}
          />
        );
      case MakingContentEnum.SPACE:
        return <SquareSpaceComponent size={content.content} />;
      default:
        return '';
    }
  };

  const renderShare = () => (
    <ShareComponent
      url={`${shareBaseUrl}/making/${making.key}`}
      title={translator(TranslationKeys.MakingShareText, {
        authorName: translator(making.authorName),
        theme: translator(making.theme),
      })}
    />
  );

  return (
    <>
      {renderOgp()}
      <div css={rootContainerStyle}>
        <div css={CenterVerticalContainerStyle}>
          <SquareSpaceComponent size={layoutParams.contentTopSpace} />
          <PixelArtComponent
            alt="main_image"
            src={making.mainImage}
            width={mainImageSize}
            height={mainImageSize}
          />
          <SquareSpaceComponent size={40} />
          <div css={contentContainerStyle}>
            <SquareSpaceComponent size={32} />
            <LargeBadgeComponent
              text={translator(TranslationKeys.Making)}
              color={DotpictColorStyle.BLUE}
            />
            <SquareSpaceComponent size={32} />
            <div css={HorizontalContainerStyle}>
              <Text
                text={translator(TranslationKeys.MakingTitle, {
                  authorName: translator(making.authorName),
                })}
                textStyle={DotpictTextStyle.BOLD20}
                colorStyle={DotpictColorStyle.BASE}
              />
            </div>
            <SquareSpaceComponent size={16} />
            {renderShare()}
            <SquareSpaceComponent size={32} />
            <Text
              text={translator(TranslationKeys.MakingDescription, {
                authorName: translator(making.authorName),
              })}
              textStyle={DotpictTextStyle.REGULAR16}
              colorStyle={DotpictColorStyle.BASE}
            />
            <SquareSpaceComponent size={24} />
            <div css={infoContainerStyle}>
              <SquareSpaceComponent size={24} />
              <div css={HorizontalContainerStyle}>
                <SquareSpaceComponent size={24} />
                <Text
                  text={translator(TranslationKeys.TableOfContents)}
                  textStyle={DotpictTextStyle.BOLD16}
                  colorStyle={DotpictColorStyle.BASE}
                />
              </div>
              <SquareSpaceComponent size={12} />
              <ul>
                {making.tableOfContents.map((textId) => (
                  <li>
                    <Text
                      text={translator(textId)}
                      textStyle={DotpictTextStyle.REGULAR16}
                      colorStyle={DotpictColorStyle.BASE}
                    />
                  </li>
                ))}
              </ul>
              <SquareSpaceComponent size={12} />
            </div>
          </div>
          <div css={contentContainerStyle}>
            {making.contents.map((content) => (
              <>{renderContent(content)}</>
            ))}
            <div css={CenterVerticalContainerStyle}>
              <Text
                text={translator(TranslationKeys.MakingShare)}
                textStyle={DotpictTextStyle.BOLD16}
                colorStyle={DotpictColorStyle.BASE}
              />
              <SquareSpaceComponent size={16} />
              {renderShare()}
              <SquareSpaceComponent size={makingContentBottomSpace} />
            </div>
          </div>
          <div css={contentContainerStyle}>
            <div css={infoContainerStyle}>
              <SquareSpaceComponent size={24} />
              <div css={CenterVerticalContainerStyle}>
                <PixelArtComponent
                  alt="profile_image"
                  src={making.profileImage}
                  width={profileImageSize}
                  height={profileImageSize}
                />
                <SquareSpaceComponent size={24} />
                <Text
                  text={translator(making.authorName)}
                  textStyle={DotpictTextStyle.BOLD20}
                  colorStyle={DotpictColorStyle.BASE}
                />
              </div>
              <SquareSpaceComponent size={16} />
              <div css={HorizontalContainerStyle}>
                <SquareSpaceComponent size={32} />
                <div css={VerticalContainerStyle}>
                  {making.profileTexts.map((profileText: string) => (
                    <Text
                      text={translator(profileText)}
                      textStyle={DotpictTextStyle.REGULAR16}
                      colorStyle={DotpictColorStyle.BASE}
                    />
                  ))}
                  {making.links.map((link: MakingLinkType) => (
                    <div css={HorizontalContainerStyle}>
                      <Text
                        text={`${link.serviceName}:`}
                        textStyle={DotpictTextStyle.REGULAR16}
                        colorStyle={DotpictColorStyle.BASE}
                      />
                      <SquareSpaceComponent size={8} />
                      <ExternalLinkComponent
                        text={link.url}
                        url={link.url}
                        textStyle={DotpictTextStyle.REGULAR16}
                        colorStyle={DotpictColorStyle.BLUE}
                      />
                    </div>
                  ))}
                </div>
                <SquareSpaceComponent size={32} />
              </div>
              {fromApp ? (
                <SquareSpaceComponent size={24} />
              ) : (
                <>
                  <SquareSpaceComponent size={32} />
                  <div css={CenterVerticalContainerStyle}>
                    <Link to={`/users/${making.userId}`}>
                      <LargeBadgeComponent
                        text={translator(TranslationKeys.ViewWorks)}
                        color={DotpictColorStyle.PRIMARY}
                      />
                    </Link>
                  </div>
                  <SquareSpaceComponent size={40} />
                </>
              )}
            </div>
            <SquareSpaceComponent size={56} />
            <div css={CenterVerticalContainerStyle}>
              <Text
                text={translator(
                  isLiked ? TranslationKeys.MakingLikedTitle : TranslationKeys.MakingLikeTitle,
                )}
                textStyle={DotpictTextStyle.BOLD16}
                colorStyle={DotpictColorStyle.BASE}
              />
              <SquareSpaceComponent size={16} />
              <MakingLikeComponent isLiked={isLiked} handleClick={handleClickLike} />
            </div>
            <SquareSpaceComponent size={40} />
          </div>
        </div>
        {anotherMakings.length !== 0 && !fromApp && (
          <>
            <SquareSpaceComponent size={32} />
            <div css={CenterVerticalContainerStyle}>
              <AdsComponent adsType={DotpictAdsType.RECTANGLE_LARGE} />
            </div>
            <SquareSpaceComponent size={32} />
            <Text
              text={translator(TranslationKeys.AnotherMakings)}
              textStyle={DotpictTextStyle.BOLD20}
              colorStyle={DotpictColorStyle.BASE}
            />
            <SquareSpaceComponent size={16} />
            <div css={anotherMakingGridStyle}>
              {anotherMakings
                .filter((value: MakingType) => value.key !== making.key)
                .map((value: MakingType) => (
                  <Link key={value.key} to={`/making/${value.key}`}>
                    <MakingThumbnailComponent
                      making={value}
                      width={anotherMakingItemWidth}
                      height={anotherMakingItemHeight}
                    />
                  </Link>
                ))}
            </div>
          </>
        )}
        {!fromApp && (
          <>
            <SquareSpaceComponent size={40} />
            <div css={CenterVerticalContainerStyle}>
              <AdsComponent adsType={DotpictAdsType.RECTANGLE_LARGE} />
            </div>
          </>
        )}
        <SquareSpaceComponent size={40} />
      </div>
    </>
  );
};

export default MakingDetail;
