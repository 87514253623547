import { useParams } from 'react-router-dom';
import { useState, useLayoutEffect, useContext, useEffect } from 'react';
import { css } from '@emotion/react';
import { APIContext, GlobalContext, LayoutContext, MeContext } from '@/app/component/RootComponent';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import VerticalContainerStyle from '@/app/style/VerticalContainerStyle';
import shouldFetchNext from '@/app/common/Common';
import Works from '@/app/component/Works';
import { defaultDummyItemCount } from '@/app/common/Constant';
import SearchHeaderComponent from '@/app/component/SearchHeaderComponent';
import { defaultSearchWorksState } from '@/app/state/ISearchWorksState';
import { DotpictAction } from '@/app/reducer/reducer';
import { WorkType } from '../model/WorkType';

const imageSize = 128;

const gridStyle = css({
  display: 'grid',
  justifyContent: 'center',
  gridGap: '8px',
  gridTemplateColumns: `repeat(auto-fill, ${imageSize}px)`,
});

const SearchWorkResultComponent = () => {
  const { type, query } = useParams();
  const { layoutParams } = useContext(LayoutContext);
  const { globalState, dispatch } = useContext(GlobalContext);
  const meState = useContext(MeContext);
  const [loading, setLoading] = useState(false);
  const { client } = useContext(APIContext);

  const fetchData = async (currentWorks: WorkType[], currentNextUrl: string | null) => {
    if (type === undefined || query === undefined) return;
    setLoading(true);
    const requestedUserId = meState.user.id;
    const nextUrl = currentNextUrl || `${client.getBaseUrl}/works/search_${type}?${type}=${query}`;
    const response = await client.fetchWorks(nextUrl);
    const works = currentWorks.concat(response.works);
    const action: DotpictAction = {
      type: 'UPDATE_SEARCH_WORKS_STATE',
      payload: {
        searchWorksState: {
          type,
          query,
          works,
          nextUrl: response.nextUrl,
          requestedUserId,
        },
      },
    };
    dispatch(action);
    setLoading(false);
    if (shouldFetchNext(response.nextUrl)) {
      fetchData(works, response.nextUrl);
    }
  };

  const handleScroll = () => {
    if (!loading && shouldFetchNext(globalState.searchWorksState.nextUrl)) {
      fetchData(globalState.searchWorksState.works, globalState.searchWorksState.nextUrl);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    if (type === undefined) return;

    const isDifferentTypeOrQuery =
      globalState.searchWorksState.type !== type || globalState.searchWorksState.query !== query;

    if (isDifferentTypeOrQuery) {
      window.scrollTo(0, 0);
      const resetAction: DotpictAction = {
        type: 'UPDATE_SEARCH_WORKS_STATE',
        payload: {
          searchWorksState: {
            ...defaultSearchWorksState,
            type,
          },
        },
      };
      dispatch(resetAction);
    }

    if (
      isDifferentTypeOrQuery ||
      globalState.searchWorksState.requestedUserId !== meState.user.id
    ) {
      fetchData([], null);
    }
  }, [type, query, meState.token]);

  return (
    <div css={VerticalContainerStyle}>
      <SquareSpaceComponent size={layoutParams.contentTopSpace} />
      <SearchHeaderComponent type={type} query={query} />
      <SquareSpaceComponent size={16} />
      <Works
        works={globalState.searchWorksState.works}
        isLoading={loading}
        isVisibleEnd={globalState.searchWorksState.nextUrl === ''}
        isVisibleAds
        isVisibleLike
        gridStyle={gridStyle}
        imageSize={imageSize}
        dummyItemCount={defaultDummyItemCount}
      />
    </div>
  );
};

export default SearchWorkResultComponent;
