import { TabLayout } from '@/app/component/base/tablayout/TabLayout';
import NewestWorksComponent from '@/app/component/NewestWorksComponent';
import PickUpWorks from '@/app/component/PickUpWorks';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import { Column, HorizontalGravity, Row } from '@/app/component/base/layout/Layout';
import AboutDotpict from '@/app/component/dotpict/aboutdotpict/AboutDotpict';
import Timeline from '@/app/component/page/timeline';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import useHome from '@/app/component/page/home/useHome';
import { Helmet } from 'react-helmet';

export type HomeState = {
  isVisibleOfficialEvent: boolean;
  isVisibleAboutDotpict: boolean;
  bannerImageUrl: string;
  bannerWidth: number;
  bannerHeight: number;
  selectedIndex: number;
  types: string[];
  tabItems: string[];
};

export type HomeProps = HomeState & {
  moveToOfficialEvent: () => void;
  handleClickTagItem: (index: number) => void;
};

const Home = () => {
  const {
    isVisibleOfficialEvent,
    isVisibleAboutDotpict,
    bannerImageUrl,
    bannerWidth,
    bannerHeight,
    selectedIndex,
    types,
    tabItems,
    moveToOfficialEvent,
    handleClickTagItem,
  } = useHome();

  const renderContent = (index: number) => {
    switch (types[index]) {
      case 'timeline':
        return <Timeline />;
      case 'pickup':
        return <PickUpWorks />;
      default:
        return <NewestWorksComponent />;
    }
  };

  const renderMeta = () => (
    <Helmet>
      <meta
        name="description"
        content="dotpictはドット絵好きのためのコミュニティサイトです！dotpictでドット絵を作ることもできます ！"
      />
      <meta
        name="keywords"
        content="ドット絵,ドットピクト,dotpict,アニメーション,ドット,パレット,イラスト,illustration,pixelart"
      />
    </Helmet>
  );

  return (
    <>
      {renderMeta()}
      <Column>
        <Spacer height={28} />
        {isVisibleAboutDotpict && (
          <>
            <AboutDotpict />
            <Spacer height={28} />
          </>
        )}
        {isVisibleOfficialEvent && (
          <Column horizontalGravity={HorizontalGravity.center}>
            <PixelArtComponent
              alt="official_event"
              src={bannerImageUrl}
              width={bannerWidth}
              height={bannerHeight}
              handleClick={moveToOfficialEvent}
            />
          </Column>
        )}
        <Spacer height={28} />
        <Row horizontalGravity={HorizontalGravity.center}>
          <TabLayout items={tabItems} selectedIndex={selectedIndex} onClick={handleClickTagItem} />
        </Row>
        <Spacer height={28} />
        {renderContent(selectedIndex)}
      </Column>
    </>
  );
};

export default Home;
