import { useContext, useEffect, useReducer } from 'react';
import {
  InitialRequestBoxSettingsBindModel,
  RequestBoxSettingsBindModel,
} from '@/app/component/page/requestbox/settings/index';
import { APIContext, MeContext } from '@/app/component/RootComponent';
import { RequestBoxSettingsType } from '@/app/model/RequestBoxSettingsType';

type Action =
  | { type: 'START_FETCH_SETTINGS'; payload: {} }
  | { type: 'END_FETCH_SETTINGS'; payload: { requestBoxSettings: RequestBoxSettingsType } }
  | { type: 'OPEN_SETTINGS'; payload: {} }
  | { type: 'CLOSE_SETTINGS'; payload: {} }
  | { type: 'CHANGE_TEXT'; payload: { text: string } }
  | { type: 'CHANGE_OPEN_REQUEST_BOX'; payload: { isOpened: boolean } }
  | { type: 'SAVED_SETTINGS'; payload: {} };

type State = {
  bindModel: RequestBoxSettingsBindModel;
};

const reducer = (prev: State, action: Action): State => {
  switch (action.type) {
    case 'START_FETCH_SETTINGS': {
      return {
        ...prev,
        bindModel: {
          ...prev.bindModel,
          isOpenedRequestBox: false,
          text: '',
          isLoading: true,
        },
      };
    }
    case 'END_FETCH_SETTINGS': {
      const { payload } = action;
      return {
        ...prev,
        bindModel: {
          ...prev.bindModel,
          isOpenedRequestBox: payload.requestBoxSettings.isOpened,
          text: payload.requestBoxSettings.text,
          isLoading: false,
        },
      };
    }
    case 'OPEN_SETTINGS': {
      return {
        ...prev,
        bindModel: {
          ...prev.bindModel,
          isOpenModal: true,
        },
      };
    }
    case 'CLOSE_SETTINGS': {
      return {
        ...prev,
        bindModel: {
          ...prev.bindModel,
          isOpenModal: false,
        },
      };
    }
    case 'CHANGE_OPEN_REQUEST_BOX': {
      return {
        ...prev,
        bindModel: {
          ...prev.bindModel,
          isOpenedRequestBox: action.payload.isOpened,
        },
      };
    }
    case 'CHANGE_TEXT': {
      return {
        ...prev,
        bindModel: {
          ...prev.bindModel,
          text: action.payload.text,
        },
      };
    }
    case 'SAVED_SETTINGS': {
      return {
        ...prev,
        bindModel: {
          ...prev.bindModel,
          isOpenModal: false,
        },
      };
    }
    default:
      return prev;
  }
};

export const usePage = () => {
  const meState = useContext(MeContext);
  const [state, dispatch] = useReducer(reducer, {
    bindModel: InitialRequestBoxSettingsBindModel,
  });
  const { client } = useContext(APIContext);

  const fetchData = async () => {
    if (meState.token === '') return;
    dispatch({ type: 'START_FETCH_SETTINGS', payload: {} });
    const requestBoxSettings = await client.fetchRequestBoxSettings(meState.token);
    dispatch({ type: 'END_FETCH_SETTINGS', payload: { requestBoxSettings } });
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, meState]);

  const handleClickOpen = () => {
    dispatch({ type: 'OPEN_SETTINGS', payload: {} });
  };

  const handleClickClose = () => {
    dispatch({ type: 'CLOSE_SETTINGS', payload: {} });
  };

  const onChangeOpenRequestBox = (isOpened: boolean) => {
    dispatch({ type: 'CHANGE_OPEN_REQUEST_BOX', payload: { isOpened } });
  };

  const onChangeText = (text: string) => {
    dispatch({ type: 'CHANGE_TEXT', payload: { text } });
  };

  const handleClickSave = async () => {
    if (state.bindModel.isLoading) return;
    await client.saveRequestBoxSettings(
      meState.token,
      state.bindModel.isOpenedRequestBox,
      state.bindModel.text,
    );
    dispatch({ type: 'SAVED_SETTINGS', payload: {} });
  };

  return {
    bindModel: state.bindModel,
    handleClickOpen,
    handleClickClose,
    onChangeOpenRequestBox,
    onChangeText,
    handleClickSave,
  };
};

export default usePage;
