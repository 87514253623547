import { ThreadType } from '@/app/model/ThreadType';
import useTranslator from '@/app/hooks/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import { ThreadState } from '@/app/component/part/work/thread/thread/Thread';

type Props = {
  thread: ThreadType;
};

const useThread = ({ thread }: Props): ThreadState => {
  const translator = useTranslator();
  return {
    replyText:
      thread.threadCount === 0
        ? translator(TranslationKeys.DoReply)
        : translator(TranslationKeys.ViewReply, { value: thread.threadCount }),
    replyTextStyle: thread.threadCount === 0 ? DotpictTextStyle.REGULAR12 : DotpictTextStyle.BOLD12,
  };
};

export default useThread;
