import { createContext, useContext } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ButtonComponent from '@/app/component/ButtonComponent';
import TextFieldComponent from '@/app/component/TextFieldComponent';
import TranslationKeys from '@/app/translation/TranslationKeys';
import { Column, HorizontalGravity, Row } from '@/app/component/base/layout/Layout';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import Loading from '@/app/component/dotpict/loading/Loading';
import TagSection from '@/app/component/part/work/upload/tagsection/TagSection';
import useTranslator from '@/app/hooks/useTranslator';
import EventSection from '@/app/component/part/work/upload/eventsection/EventSection';
import AlertDialog from '@/app/component/dotpict/dialog/alert/AlertDialog';
import EditWorkUploadBoxComponent from '@/app/component/page/work/edit/EditWorkUploadBoxComponent';
import { Media } from '@/app/component/page/work/upload/CropDialog';
import { CropArea, Rect } from '@/app/component/page/work/upload/types';

const MultiSectionWrapper = styled.div`
  padding: 32px;
  background: #ffffff;
`;

const styledLabel = css`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
`;

export type EditWorkState = {
  defaultMedia: Media;
  defaultCropArea: CropArea | undefined;
  title: string;
  caption: string;
  fixedTag: string;
  tags: string[];
  allowComment: boolean;
  loading: boolean;
  isVisibleJoiningEvent: boolean;
  joiningEventTitle: string;
  isVisibleError: boolean;
  errorMessage: string;
};

export type EditWorkProps = EditWorkState & {
  handleFileChanged: (file: File | undefined) => void;
  handleTitleChanged: (newTitle: string) => void;
  handleTagsChanged: (newTags: string[]) => void;
  handleCaptionChanged: (newCaption: string) => void;
  handleAllowCommentChanged: (newAllowComment: boolean) => void;
  handleClickPost: () => void;
  handleCloseError: () => void;
  handleDecideCrop: (croppedRect: Rect | undefined) => void;
};

export const EditWorkContext = createContext<EditWorkProps>({} as EditWorkProps);

const EditWork = () => {
  const translator = useTranslator();

  const {
    defaultMedia,
    defaultCropArea,
    title,
    caption,
    fixedTag,
    tags,
    allowComment,
    loading,
    isVisibleJoiningEvent,
    joiningEventTitle,
    isVisibleError,
    errorMessage,
    handleFileChanged,
    handleTitleChanged,
    handleTagsChanged,
    handleCaptionChanged,
    handleAllowCommentChanged,
    handleClickPost,
    handleCloseError,
    handleDecideCrop,
  } = useContext(EditWorkContext);

  if (loading) {
    return <Loading />;
  }

  return (
    <Column>
      <Spacer height={32} />
      <MultiSectionWrapper>
        <Text
          text={translator(TranslationKeys.EditWorkInfo)}
          textStyle={DotpictTextStyle.BOLD20}
          colorStyle={DotpictColorStyle.BASE}
        />
        <Spacer height={16} />
        <EditWorkUploadBoxComponent
          defaultMedia={defaultMedia}
          defaultCropArea={defaultCropArea}
          handleDecideCrop={handleDecideCrop}
          handleFileChange={handleFileChanged}
        />
        <Spacer height={16} />
      </MultiSectionWrapper>
      <Spacer height={24} />
      <MultiSectionWrapper>
        <TextFieldComponent
          id="title"
          label={translator(TranslationKeys.Title)}
          placeholder={translator(TranslationKeys.Title)}
          value={title}
          onChangeValue={handleTitleChanged}
          showMaxLength
          maxLength={32}
        />
        <Spacer height={40} />
        <TagSection fixedTag={fixedTag} tags={tags} handleTagsChanged={handleTagsChanged} />
        {isVisibleJoiningEvent && (
          <>
            <Spacer height={40} />
            <EventSection eventTitle={joiningEventTitle} />
          </>
        )}
        <Spacer height={40} />
        <TextFieldComponent
          id="caption"
          label={translator(TranslationKeys.Caption)}
          placeholder={translator(TranslationKeys.CaptionHint)}
          value={caption}
          onChangeValue={handleCaptionChanged}
          multiline
          showMaxLength
          maxLength={500}
          rows={4}
        />
        <Spacer height={40} />
        <div css={styledLabel}>{translator(TranslationKeys.OtherSettings)}</div>
        <Row>
          <FormControlLabel
            value="allowComment"
            control={<Switch />}
            label={translator(TranslationKeys.AllowComments)}
            checked={allowComment}
            onChange={(_, checked) => handleAllowCommentChanged(checked)}
            labelPlacement="start"
          />
        </Row>
        <Spacer height={32} />
        <Column horizontalGravity={HorizontalGravity.center}>
          <ButtonComponent
            width={240}
            height={52}
            text={translator(TranslationKeys.UpdateWorkInfo)}
            isFilled
            handleClick={() => handleClickPost()}
          />
        </Column>
      </MultiSectionWrapper>
      <AlertDialog
        title={translator(TranslationKeys.FailedToUpload)}
        message={errorMessage}
        open={isVisibleError}
        handleClose={handleCloseError}
      />
      <Spacer height={120} />
    </Column>
  );
};

export default EditWork;
