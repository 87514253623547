import { useParams } from 'react-router-dom';
import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { APIContext, GlobalContext, LayoutContext } from '@/app/component/RootComponent';
import { UserDetailBindModel, UserDetailOgp } from '@/app/component/page/user/detail/UserDetail';
import { defaultUserDetailState, UserDetailStateType } from '@/app/state/UserDetailStateType';
import { DotpictAction } from '@/app/reducer/reducer';
import shouldFetchNext from '@/app/common/Common';
import { UserType } from '@/app/model/UserType';
import { WorkType } from '@/app/model/WorkType';

type OutProps = {
  bindModel: UserDetailBindModel;
  ogp: UserDetailOgp;
  user: UserType; // ProfileHeaderのuser依存で仕方なく渡している
  userWorks: WorkType[];
};

const useUserDetail = (): OutProps => {
  const { userId, accountName } = useParams();
  const { globalState, dispatch } = useContext(GlobalContext);
  const { layoutParams } = useContext(LayoutContext);
  const [isLoading, setLoading] = useState(false);
  const { client } = useContext(APIContext);

  const fetchData = async (currentState: UserDetailStateType) => {
    setLoading(true);
    const userWorksResponse = await client.fetchUserWorks({ nextUrl: currentState.nextUrl });
    const action: DotpictAction = {
      type: 'UPDATE_USER_DETAIL_STATE',
      payload: {
        userDetailState: {
          user: userWorksResponse.user,
          works: currentState.works.concat(userWorksResponse.works),
          nextUrl: userWorksResponse.nextUrl,
        },
      },
    };
    dispatch(action);
    setLoading(false);
  };

  const fetchDataByAccountName = async () => {
    if (accountName === undefined) return;
    setLoading(true);
    const user = await client.fetchUsersByAccountName(accountName);
    const userWorksResponse = await client.fetchUserWorks({
      nextUrl: `${client.getBaseUrl}/users/${user.id}/works`,
    });
    const action: DotpictAction = {
      type: 'UPDATE_USER_DETAIL_STATE',
      payload: {
        userDetailState: {
          user: userWorksResponse.user,
          works: userWorksResponse.works,
          nextUrl: userWorksResponse.nextUrl,
        },
      },
    };
    dispatch(action);
    setLoading(false);
  };

  const handleScroll = () => {
    if (!isLoading && shouldFetchNext(globalState.userDetailState.nextUrl)) {
      fetchData(globalState.userDetailState);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    if (userId === undefined || userId === `${globalState.userDetailState.user.id}`) {
      return;
    }
    window.scrollTo(0, 0);
    const userDetailState: UserDetailStateType = defaultUserDetailState;
    userDetailState.nextUrl = `${client.getBaseUrl}/users/${userId}/works`;
    const action: DotpictAction = {
      type: 'UPDATE_USER_DETAIL_STATE',
      payload: { userDetailState },
    };
    dispatch(action);
    fetchData(userDetailState);
  }, [userId]);

  useEffect(() => {
    if (accountName === undefined || accountName === globalState.userDetailState.user.account) {
      return;
    }
    window.scrollTo(0, 0);
    fetchDataByAccountName();
  }, [accountName]);

  return {
    bindModel: {
      userId: globalState.userDetailState.user.id,
      isFollowed: globalState.userDetailState.user.isFollowed,
      isLoading,
      isVisibleEnd: globalState.userDetailState.nextUrl === '',
      thumbnailImageGridGap: layoutParams.thumbnailImageGridGap,
      contentWidth: layoutParams.contentWidth,
      thumbnailImageSize: layoutParams.thumbnailImageSize,
    },
    ogp: {
      title: globalState.userDetailState.user.name,
      description: globalState.userDetailState.user.text,
      image: globalState.userDetailState.user.profileImageUrl,
    },
    user: globalState.userDetailState.user,
    userWorks: globalState.userDetailState.works,
  };
};

export default useUserDetail;
