import { useState, createContext, useEffect } from 'react';
import useAnalytics from '@/app/hooks/useAnalytics';

export const ToolsMap = {
  Pen: 'pen',
  Eraser: 'eraser',
  Bucket: 'bucket',
  Line: 'line',
  Move: 'move',
  Redo: 'redo',
  Undo: 'undo',
  Clear: 'clear',
  EditColor: 'editColor',
} as const;

export type ToolType = typeof ToolsMap[keyof typeof ToolsMap];

export const ToolContext = createContext<ToolType>(ToolsMap.Pen);

export const useTool = (redo: () => void, undo: () => void, initializePixels: () => void) => {
  const analytics = useAnalytics();
  const [tool, setTool] = useState<ToolType>(ToolsMap.Pen);

  // ツールの選択アクション
  const selectTool = (selected: ToolType) => {
    return () => {
      if (selected === ToolsMap.Redo) {
        analytics('draw_redo');
        redo();
      } else if (selected === ToolsMap.Undo) {
        analytics('draw_undo');
        undo();
      } else if (selected === ToolsMap.Clear) {
        analytics('draw_clear');
        initializePixels();
      } else if (selected === ToolsMap.EditColor) {
        analytics('draw_edit_color');
      } else {
        analytics(`draw_${selected}_selected`);
        setTool(selected);
      }
    };
  };

  const handleKeydown = (event: KeyboardEvent) => {
    const keyValue = event.key.toLowerCase();
    if (keyValue === 'p') {
      selectTool(ToolsMap.Pen)();
    } else if (keyValue === 'e') {
      selectTool(ToolsMap.Eraser)();
    } else if (keyValue === 'b') {
      selectTool(ToolsMap.Bucket)();
    } else if (keyValue === 'l') {
      selectTool(ToolsMap.Line)();
    } else if (keyValue === 'm') {
      selectTool(ToolsMap.Move)();
    } else if (keyValue === 'y') {
      selectTool(ToolsMap.Redo)();
    } else if (keyValue === 'z') {
      selectTool(ToolsMap.Undo)();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown);
    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [handleKeydown]);

  return [tool, selectTool] as [typeof tool, typeof selectTool];
};
