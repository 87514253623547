import { createContext, useContext, useState } from 'react';
import {
  Column,
  Grid,
  HorizontalGravity,
  Row,
  VerticalGravity,
} from '@/app/component/base/layout/Layout';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import { LayoutContext } from '@/app/component/RootComponent';
import Works from '@/app/component/Works';
import { defaultDummyItemCount } from '@/app/common/Constant';
import { css } from '@emotion/react';
import ButtonComponent from '@/app/component/ButtonComponent';
import TranslationKeys from '@/app/translation/TranslationKeys';
import useTranslator from '@/app/hooks/useTranslator';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { Dialog } from '@material-ui/core';
import HowToJoinEvent from '@/app/component/dotpict/howtojoinevent/HowToJoinEvent';
import { IOfficialEventDetailState } from '@/app/state/IOfficialEventDetailState';
import { Helmet } from 'react-helmet';
import Text from '@/app/component/Text';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import ScoreRateComponent from '@/app/component/page/officialevent/battleevent/ScoreRateComponent';
import HowToUsePalette from '@/app/component/dotpict/howtousepalette/HowToUsePalette';

export type OfficialEventDetailProps = IOfficialEventDetailState & {
  isVisibleTemplate: boolean;
  handlePost: () => void;
  handleDraw: () => void;
  handleJoinTeam: (teamTag: string) => void;
};

export const OfficialEventContext = createContext<OfficialEventDetailProps>(
  {} as OfficialEventDetailProps,
);

const OfficialEvent = () => {
  const translator = useTranslator();
  const {
    mainImageUrl,
    tag,
    templateImageUrl,
    colors,
    shareTitle,
    twitterShareUrl,
    facebookShareUrl,
    bannerImageUrl,
    isLoadingWorks,
    isVisibleEnd,
    works,
    isBattleEvent,
    description,
    teamOneTag,
    teamOneText,
    teamTwoText,
    teamOneScore,
    teamOneColor,
    teamTwoTag,
    teamTwoScore,
    teamTwoColor,
    isVisibleTemplate,
    handlePost,
    handleDraw,
    handleJoinTeam,
  } = useContext(OfficialEventContext);
  const { layoutParams } = useContext(LayoutContext);
  const [modalOpen, setModalOpen] = useState(false);

  const imageSize = layoutParams.fullWidth < 192 * 2 + 8 ? (layoutParams.fullWidth - 8) / 2 : 192;

  const gridStyle = css({
    display: 'grid',
    justifyContent: 'center',
    gridGap: '8px',
    gridTemplateColumns: `repeat(auto-fill, ${imageSize}px)`,
  });

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleHowToJoin = () => {
    setModalOpen(true);
  };

  const renderOgp = () => (
    <Helmet>
      <meta property="twitter:title" content={shareTitle} />
      <meta property="twitter:description" content={shareTitle} />
      <meta property="twitter:image" content={bannerImageUrl} />
      <meta property="og:title" content={shareTitle} />
      <meta property="og:description" content={shareTitle} />
      <meta property="og:image" content={bannerImageUrl} />
    </Helmet>
  );

  const renderNormalEventHeader = () => (
    <Row width="100%" verticalGravity={VerticalGravity.top}>
      <Column horizontalGravity={HorizontalGravity.center}>
        <ButtonComponent
          width={240}
          height={48}
          text={translator(TranslationKeys.Post)}
          textStyle={DotpictTextStyle.BOLD16}
          isFilled
          handleClick={handlePost}
        />
        <Spacer height={8} />
        <Text
          text={translator(TranslationKeys.HowToPost)}
          textStyle={DotpictTextStyle.REGULAR13}
          colorStyle={DotpictColorStyle.PRIMARY}
          handleClick={handleHowToJoin}
        />
      </Column>
      <Spacer width={28} />
      <ButtonComponent
        width={240}
        height={48}
        text={translator(TranslationKeys.DrawPixelart)}
        textStyle={DotpictTextStyle.BOLD16}
        isFilled={false}
        isBorder
        handleClick={handleDraw}
      />
      <Spacer width={52} />
      <Row verticalGravity={VerticalGravity.center}>
        <PixelArtComponent
          src="https://storage.googleapis.com/dotpict-images/web/share_event.png"
          alt="title"
          width={89}
          height={30}
        />
        <Spacer width={12} />
        <TwitterShareButton url={twitterShareUrl} title={shareTitle}>
          <PixelArtComponent
            src="https://storage.googleapis.com/dotpict-images/web/twitter.png"
            alt="title"
            width={44}
            height={44}
          />
        </TwitterShareButton>
        <Spacer width={12} />
        <FacebookShareButton url={facebookShareUrl} title={shareTitle}>
          <PixelArtComponent
            src="https://storage.googleapis.com/dotpict-images/web/facebook.png"
            alt="title"
            width={44}
            height={44}
          />
        </FacebookShareButton>
      </Row>
    </Row>
  );

  const renderBattleEventHeader = () => (
    <Column horizontalGravity={HorizontalGravity.center}>
      <Text
        text={description}
        textStyle={DotpictTextStyle.REGULAR12}
        colorStyle={DotpictColorStyle.BASE}
      />
      <Spacer height={24} />
      <Text
        text={`${translator(TranslationKeys.CurrentScoreRate)}`}
        textStyle={DotpictTextStyle.BOLD14}
        colorStyle={DotpictColorStyle.BASE}
      />
      <Spacer height={12} />
      <ScoreRateComponent
        teamOneScore={teamOneScore}
        teamOneColor={teamOneColor}
        teamTwoScore={teamTwoScore}
        teamTwoColor={teamTwoColor}
        contentWidth={layoutParams.contentWidth - 52}
      />
      <Spacer height={6} />
      <Text
        text={`${translator(TranslationKeys.BattleEventPointRule1)}`}
        textStyle={DotpictTextStyle.REGULAR12}
        colorStyle={DotpictColorStyle.TEXT_GRAY}
      />
      <Spacer height={2} />
      <Text
        text={`${translator(TranslationKeys.BattleEventPointRule2)}`}
        textStyle={DotpictTextStyle.REGULAR12}
        colorStyle={DotpictColorStyle.TEXT_GRAY}
      />
      <Spacer height={24} />
      <Text
        text={`${translator(TranslationKeys.WhichJoin)}`}
        textStyle={DotpictTextStyle.BOLD14}
        colorStyle={DotpictColorStyle.BASE}
      />
      <Spacer height={10} />
      <Grid innerPadding={12}>
        <ButtonComponent
          handleClick={() => handleJoinTeam(teamOneTag)}
          width={320}
          height={52}
          text={teamOneText}
          isFilled
          color={teamOneColor}
        />
        <ButtonComponent
          handleClick={() => handleJoinTeam(teamTwoTag)}
          width={320}
          height={52}
          text={teamTwoText}
          isFilled
          color={teamTwoColor}
        />
      </Grid>
      <Spacer height={28} />
      <Column background={DotpictColorStyle.WHITE} width="100%" padding="24px 48px">
        <HowToUsePalette paletteName={tag} colors={colors} />
      </Column>
    </Column>
  );

  return (
    <>
      {renderOgp()}
      <Column width={layoutParams.contentWidth}>
        <Spacer height={40} />
        <PixelArtComponent
          alt="header_image"
          width={layoutParams.contentWidth}
          height={layoutParams.contentWidth / (1024 / 592)}
          src={bannerImageUrl}
        />
        <Spacer height={28} />
        {isBattleEvent ? renderBattleEventHeader() : renderNormalEventHeader()}
        <Spacer height={52} />
        <Column width="100%" horizontalGravity={HorizontalGravity.center}>
          <PixelArtComponent
            src="https://storage.googleapis.com/dotpict-images/web/everyone_pixelart.png"
            alt="title"
            width={277}
            height={30}
          />
        </Column>
        <Spacer height={28} />
        <Works
          works={works}
          isLoading={isLoadingWorks}
          isVisibleEnd={isVisibleEnd}
          isVisibleAds
          isVisibleLike
          gridStyle={gridStyle}
          imageSize={imageSize}
          dummyItemCount={defaultDummyItemCount}
        />
        <Spacer height={128} />
      </Column>
      <Dialog maxWidth="xl" open={modalOpen} onClose={handleClose} scroll="body">
        <HowToJoinEvent
          mainImageUrl={mainImageUrl}
          tag={tag}
          isVisibleTemplate={isVisibleTemplate}
          templateImageUrl={templateImageUrl}
          colors={colors}
        />
      </Dialog>
    </>
  );
};

export default OfficialEvent;
