import { useContext } from 'react';
import ButtonComponent from '@/app/component/ButtonComponent';
import Text from '@/app/component/Text';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import TranslationKeys from '@/app/translation/TranslationKeys';
import useTranslator from '@/app/hooks/useTranslator';
import { Column, Row, VerticalGravity } from '@/app/component/base/layout/Layout';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import TitleAndTags from '@/app/component/part/work/titleandtags/TitleAndTags';
import UserComponent from '@/app/component/UserComponent';
import EventColorCodes from '@/app/component/dotpict/event/colorcodes/EventColorCodes';
import Loading from '@/app/component/dotpict/loading/Loading';
import useEventDetail from '@/app/component/page/eventdetail/useEventDetail';
import Works from '@/app/component/Works';
import { defaultDummyItemCount } from '@/app/common/Constant';
import { css } from '@emotion/react';
import { LayoutContext } from '@/app/component/RootComponent';
import useDownloadableImage from '@/app/component/page/eventdetail/useDownloadableImage';
import { WorkType } from '../../../model/WorkType';
import emptyUser, { UserType } from '../../../model/UserType';

export type EventDetailBindModel = {
  isLoadingUserEvent: boolean;
  title: string;
  tags: string[];
  user: UserType;
  mainImageUrl: string;
  templateImageUrl: string;
  text: string;
  colors: string[];
  isLoadingWorks: boolean;
  isVisibleEnd: boolean;
  works: WorkType[];
};

export const InitialEventDetailBindModel: EventDetailBindModel = {
  isLoadingUserEvent: false,
  title: '',
  tags: [],
  user: emptyUser,
  mainImageUrl: '',
  templateImageUrl: '',
  text: '',
  colors: [],
  isLoadingWorks: false,
  isVisibleEnd: false,
  works: [],
};

const EventDetail = () => {
  const translator = useTranslator();
  const { layoutParams } = useContext(LayoutContext);
  const { bindModel, handleClickPost } = useEventDetail();
  const { renderImage, downloadImage } = useDownloadableImage({
    imageName: bindModel.title,
    imageUrl: bindModel.templateImageUrl,
  });

  const imageSize = layoutParams.fullWidth < 192 * 2 + 8 ? (layoutParams.fullWidth - 8) / 2 : 192;

  const gridStyle = css({
    display: 'grid',
    justifyContent: 'center',
    gridGap: '8px',
    gridTemplateColumns: `repeat(auto-fill, ${imageSize}px)`,
  });

  if (bindModel.isLoadingUserEvent) return <Loading />;

  const renderTemplate = () => (
    <Column>
      <Text
        text={translator(TranslationKeys.EventTemplate)}
        textStyle={DotpictTextStyle.BOLD16}
        colorStyle={DotpictColorStyle.BASE}
      />
      <Spacer height={16} />
      <Row verticalGravity={VerticalGravity.center}>
        {renderImage()}
        <PixelArtComponent
          src={bindModel.templateImageUrl}
          alt="template_image"
          width={128}
          height={128}
        />
        <Spacer width={24} />
        <ButtonComponent
          width={280}
          height={40}
          text={translator(TranslationKeys.EventDownloadTemplate)}
          isBorder
          isFilled={false}
          handleClick={() => downloadImage()}
        />
      </Row>
    </Column>
  );

  return (
    <Column>
      <Spacer height={32} />
      <Column background={DotpictColorStyle.WHITE} padding={24}>
        <Text
          text={translator(TranslationKeys.UserEventDescription1)}
          textStyle={DotpictTextStyle.BOLD20}
          colorStyle={DotpictColorStyle.BASE}
        />
        <Spacer height={16} />
        <Text
          text={translator(TranslationKeys.UserEventDescription2)}
          textStyle={DotpictTextStyle.REGULAR14}
          colorStyle={DotpictColorStyle.BASE}
        />
        <Spacer height={8} />
        <Text
          text={translator(TranslationKeys.UserEventDescription3)}
          textStyle={DotpictTextStyle.REGULAR14}
          colorStyle={DotpictColorStyle.BASE}
        />
        <Spacer height={8} />
        <Text
          text={translator(TranslationKeys.UserEventDescription4)}
          textStyle={DotpictTextStyle.REGULAR14}
          colorStyle={DotpictColorStyle.BASE}
        />
      </Column>
      <Spacer height={32} />
      <Column background={DotpictColorStyle.WHITE} padding={24}>
        <PixelArtComponent src={bindModel.mainImageUrl} alt="main_image" width={192} height={192} />
        <Spacer height={32} />
        <TitleAndTags title={bindModel.title} tags={bindModel.tags} />
        <Spacer height={16} />
        <UserComponent user={bindModel.user} />
        <Spacer height={16} />
        <Text
          text={bindModel.text}
          textStyle={DotpictTextStyle.REGULAR14}
          colorStyle={DotpictColorStyle.BASE}
        />
        {bindModel.templateImageUrl !== '' && (
          <>
            <Spacer height={32} />
            {renderTemplate()}
          </>
        )}
        <Spacer height={32} />
        <EventColorCodes paletteName={bindModel.title} colors={bindModel.colors} />
        <Spacer height={32} />
        <ButtonComponent
          width="100%"
          height={50}
          text={translator(TranslationKeys.EventPostTitle)}
          isFilled
          handleClick={() => handleClickPost()}
        />
      </Column>
      <Spacer height={32} />
      <Text
        text={translator(TranslationKeys.ParticipatingWorks)}
        textStyle={DotpictTextStyle.BOLD20}
        colorStyle={DotpictColorStyle.BASE}
      />
      <Spacer height={20} />
      <Works
        works={bindModel.works}
        isLoading={bindModel.isLoadingWorks}
        isVisibleEnd={bindModel.isVisibleEnd}
        isVisibleAds
        isVisibleLike
        gridStyle={gridStyle}
        imageSize={imageSize}
        dummyItemCount={defaultDummyItemCount}
      />
      <Spacer height={128} />
    </Column>
  );
};

export default EventDetail;
