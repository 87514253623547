import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import { Row, VerticalGravity } from '@/app/component/base/layout/Layout';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import styled from '@emotion/styled';
import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import Button from '@material-ui/core/Button';

export type EventColorCodeProps = {
  color: string;
};

const EventColorCode = ({ color }: EventColorCodeProps) => {
  const itemHeight = 32;
  const borderWidth = 2;

  const ColorRect = styled.div({
    border: `solid ${borderWidth}px ${DotpictColorStyle.WHITE_GRAY}`,
    background: `#${color}`,
    width: itemHeight - borderWidth * 2,
    height: itemHeight - borderWidth * 2,
  });

  const NoPaddingButton = styled(Button)({
    display: 'inline-block',
    padding: 0,
    minHeight: 32,
    minWidth: 32,
  });

  const handleClickCopy = () => {
    navigator.clipboard.writeText(color);
  };

  return (
    <Row verticalGravity={VerticalGravity.center}>
      <ColorRect />
      <Spacer width={6} />
      <Row
        background={DotpictColorStyle.WHITE_GRAY}
        height={itemHeight}
        verticalGravity={VerticalGravity.center}
      >
        <Spacer width={4} />
        <Text
          text={`#${color}`}
          textStyle={DotpictTextStyle.REGULAR11}
          colorStyle={DotpictColorStyle.BASE}
        />
        <Spacer width={4} />
      </Row>
      <Spacer width={4} />
      <NoPaddingButton>
        <Row padding={4}>
          <PixelArtComponent
            src="https://storage.googleapis.com/dotpict-images/web/event/copy.svg"
            alt="copy_color_code"
            width={24}
            height={24}
            handleClick={() => handleClickCopy()}
          />
        </Row>
      </NoPaddingButton>
    </Row>
  );
};

export default EventColorCode;
