import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';

export type MakingTextProps = {
  text: string;
};

const MakingText = ({ text }: MakingTextProps) => (
  <Text text={text} textStyle={DotpictTextStyle.REGULAR16} colorStyle={DotpictColorStyle.BASE} />
);

export default MakingText;
