import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { APIContext, MeContext } from '@/app/component/RootComponent';
import shouldFetchNext from '@/app/common/Common';
import { Column, HorizontalGravity } from '@/app/component/base/layout/Layout';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import { CircularProgress } from '@material-ui/core';
import End from '@/app/component/dotpict/end/End';
import AdsComponent from '@/app/component/AdsComponent';
import DotpictAdsType from '@/app/ads/DotpictAdsType';
import { RequestType } from '@/app/model/RequestType';
import Request from '@/app/component/page/requestbox/Request';

const adsLineCount = 10; // 何行あたりに広告を出すか

const ReceivedRequests = () => {
  const [nextUrl, setNextUrl] = useState<string>('');
  const [requests, setRequests] = useState<RequestType[]>([]);
  const [loading, setLoading] = useState(false);
  const [visibleEnd, setVisibleEnd] = useState(false);
  const meState = useContext(MeContext);
  const { client } = useContext(APIContext);

  const fetchData = async (currentRequests: RequestType[], currentNextUrl: string) => {
    setLoading(true);
    setVisibleEnd(false);
    const response = await client.fetchRequests(meState.token, currentNextUrl);
    const newNextUrl = response.nextUrl;
    const newRequests = currentRequests.concat(response.requests);
    setRequests(newRequests);
    setNextUrl(newNextUrl);
    setVisibleEnd(newNextUrl === '');
    setLoading(false);
    if (shouldFetchNext(newNextUrl)) {
      fetchData(newRequests, newNextUrl);
    }
  };

  const handleClickComplete = async (request: RequestType) => {
    await client.postComplete(meState.token, request.id);
    setRequests(requests.filter((v) => v.id !== request.id));
  };

  const handleClickDelete = async (request: RequestType) => {
    await client.deleteRequest(meState.token, request.id);
    setRequests(requests.filter((v) => v.id !== request.id));
  };

  const handleScroll = () => {
    if (!loading && shouldFetchNext(nextUrl)) {
      fetchData(requests, nextUrl);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    setRequests([]);
    const newNextUrl = `${client.getBaseUrl}/me/requests?is_completed=false`;
    const newRequests: RequestType[] = [];
    setNextUrl(newNextUrl);
    setRequests(newRequests);
    fetchData(newRequests, newNextUrl);
  }, [meState]);

  return (
    <Column width="100%" horizontalGravity={HorizontalGravity.center}>
      {requests.map((request: RequestType, index: number) => (
        <Column width="100%" horizontalGravity={HorizontalGravity.center} key={request.id}>
          <Request
            request={request}
            handleClickComplete={handleClickComplete}
            handleClickDelete={handleClickDelete}
          />
          <Spacer height={2} />
          {index !== 0 && index % adsLineCount === 0 && (
            <>
              <AdsComponent adsType={DotpictAdsType.RECTANGLE_LARGE} />
              <SquareSpaceComponent size={20} />
            </>
          )}
        </Column>
      ))}

      {loading && <CircularProgress />}

      {visibleEnd && <End />}
    </Column>
  );
};

export default ReceivedRequests;
