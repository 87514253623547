import React, { Children } from 'react';
import { css } from '@emotion/react';
import { Spacer } from '@/app/component/base/spacer/Spacer';

export const HorizontalGravity = {
  start: 'flex-start',
  center: 'center',
  end: 'flex-end',
  spaceBetween: 'space-between',
} as const;
type LinearLayoutHorizontalGravity = typeof HorizontalGravity[keyof typeof HorizontalGravity];

export const VerticalGravity = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
  spaceBetween: 'space-between',
} as const;
type LinearLayoutVerticalGravity = typeof VerticalGravity[keyof typeof VerticalGravity];

export type LayoutModifier = {
  width?: number | string;
  height?: number | string;
  padding?: number | string;
  innerPadding?: number;
  background?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  horizontalGravity?: LinearLayoutHorizontalGravity;
  verticalGravity?: LinearLayoutVerticalGravity;
  children?: React.ReactNode;
};

export const Column = (props: LayoutModifier) => {
  const {
    width,
    height,
    padding,
    innerPadding,
    background,
    horizontalGravity,
    verticalGravity,
    children,
  } = props;

  const style = css({
    width,
    height,
    padding,
    background,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: verticalGravity,
    alignItems: horizontalGravity,
  });

  const childCount = Children.count(children);

  if (childCount === 0) {
    return <div css={style}>{children}</div>;
  }

  return (
    <div css={style}>
      {children &&
        Children.map(children, (child, index) => (
          <>
            {child}
            {innerPadding && index !== childCount - 1 && <Spacer height={innerPadding} />}
          </>
        ))}
    </div>
  );
};

export const Row = (props: LayoutModifier) => {
  const {
    width,
    height,
    padding,
    innerPadding,
    background,
    horizontalGravity,
    verticalGravity,
    children,
  } = props;

  const style = css({
    width,
    height,
    padding,
    background,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: horizontalGravity,
    alignItems: verticalGravity,
  });

  const childCount = Children.count(children);

  if (childCount === 0) {
    return <div css={style}>{children}</div>;
  }

  return (
    <div css={style}>
      {children &&
        Children.map(children, (child, index) => (
          <>
            {child}
            {innerPadding && index !== childCount - 1 && <Spacer width={innerPadding} />}
          </>
        ))}
    </div>
  );
};

export const Grid = (props: LayoutModifier) => {
  const { width, height, padding, innerPadding, background, verticalGravity, children } = props;

  const style = css({
    width,
    height,
    padding,
    background,
    gridGap: innerPadding,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: verticalGravity,
  });

  return <div css={style}>{children}</div>;
};
