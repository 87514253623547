import { useState, useCallback, useEffect, useRef, RefObject } from 'react';
import styled from '@emotion/styled';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import Text from '@/app/component/Text';
import { useCanvasContext } from '@/app/component/page/draw/hooks';
import { useDownloadTool } from '@/app/component/page/draw/hooks/tools';
import { SizeType } from '@/app/component/page/draw/Context/SizeContext';
import { PixelsType } from '@/app/component/page/draw/hooks/types';
import DownloadSizeSelector from '@/app/component/page/draw/DownloadModal/DownloadSizeSelector';
import ButtonComponent from '@/app/component/ButtonComponent';
import useTranslator from '@/app/hooks/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';

const MainTextWrapper = styled.div`
  margin-bottom: 16px;
`;

const DownloadSizeSelectorWrapper = styled.div`
  margin-bottom: 24px;
`;

const CancelButtonWrapper = styled.div`
  margin-top: 20px;
  cursor: pointer;
`;

const PreviewCanvas = styled.canvas`
  background: #fff;
  box-sizing: border-box;
  margin-bottom: 16px;
`;

type Props = {
  canvasRef: RefObject<HTMLCanvasElement>;
  canvasSize: SizeType;
  title: string | undefined;
  pixels: PixelsType | undefined;
  onCloseModal(): void;
};

const ModalBody = ({ canvasRef, canvasSize, title, pixels, onCloseModal }: Props) => {
  const downloadAction = useDownloadTool();
  const [downloadRate, setDownloadRate] = useState<number>(1);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [context] = useCanvasContext(previewCanvasRef);
  const translator = useTranslator();

  const handleDownload = useCallback(() => {
    downloadAction(canvasRef, canvasSize, title, pixels, downloadRate);
  }, [canvasRef, canvasSize, pixels, downloadRate]);

  const handleSelectRate = useCallback((rate: number) => {
    setDownloadRate(rate);
  }, []);

  useEffect(() => {
    if (
      context === null ||
      previewCanvasRef.current === null ||
      canvasRef.current === null ||
      pixels === undefined
    ) {
      return;
    }

    // ONのままだと拡大・縮小時にぼやけてしまうためfalseにする
    context.imageSmoothingEnabled = false;

    // レンダリングされてるcanvasの実際の高さと幅を取得
    const rect = previewCanvasRef.current.getBoundingClientRect();

    // レンダリングされてるcanvasに画像コピー
    context.drawImage(canvasRef.current, 0, 0, rect.width, rect.height);
  }, [context, previewCanvasRef, canvasRef]);

  return (
    <>
      <PreviewCanvas ref={previewCanvasRef} width={240} height={240} />

      <MainTextWrapper>
        <Text
          text={translator(TranslationKeys.SelectSaveSize)}
          textStyle={DotpictTextStyle.REGULAR16}
          colorStyle={DotpictColorStyle.BASE}
        />
      </MainTextWrapper>

      <DownloadSizeSelectorWrapper>
        <DownloadSizeSelector canvasSize={canvasSize} onSelectRate={handleSelectRate} />
      </DownloadSizeSelectorWrapper>

      <ButtonComponent text="OK" width={240} height={48} isFilled handleClick={handleDownload} />
      <CancelButtonWrapper onClick={onCloseModal}>
        <Text
          text={translator(TranslationKeys.Cancel)}
          textStyle={DotpictTextStyle.BOLD14}
          colorStyle={DotpictColorStyle.PRIMARY}
        />
      </CancelButtonWrapper>
    </>
  );
};

export default ModalBody;
