import { MouseEventHandler, useState } from 'react';
import { css } from '@emotion/react';
import Text from '@/app/component/Text';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import { HorizontalGravity, Row, VerticalGravity } from '@/app/component/base/layout/Layout';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import { Spacer } from '@/app/component/base/spacer/Spacer';

type IProps = {
  width: number | string;
  height: number;
  text: string;
  isFilled: boolean;
  color?: string;
  icon?: string;
  isBorder?: boolean;
  handleClick?: MouseEventHandler;
  textStyle?: DotpictTextStyle;
};

const ButtonComponent = ({
  width,
  height,
  text,
  isFilled,
  color,
  icon,
  isBorder,
  handleClick,
  textStyle,
}: IProps) => {
  const [isHover, setHover] = useState(false);
  const unwrappedColor = color ?? DotpictColorStyle.PRIMARY;
  const buttonCss = css({
    height,
    width,
    boxSizing: 'border-box',
    padding: 0,
    borderRadius: 0,
    justifyContent: 'center',
    background: isFilled ? unwrappedColor : 'transparent',
    border:
      isBorder !== undefined && isBorder && !isFilled
        ? `solid 2px ${unwrappedColor}`
        : 'transparent',
  });

  return (
    <button
      css={buttonCss}
      type="button"
      onClick={handleClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Row
        height="100%"
        background={`rgba(0, 0, 0, ${isHover ? 0.1 : 0})`}
        verticalGravity={VerticalGravity.center}
        horizontalGravity={HorizontalGravity.center}
      >
        {icon && (
          <>
            <PixelArtComponent alt="icon" src={icon} width={14} height={14} />
            <Spacer width={6} />
          </>
        )}
        <Text
          text={text}
          textStyle={textStyle ?? DotpictTextStyle.BOLD14}
          colorStyle={isFilled ? DotpictColorStyle.WHITE : DotpictColorStyle.PRIMARY}
        />
      </Row>
    </button>
  );
};

export default ButtonComponent;
