import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import UserComponent from '@/app/component/UserComponent';
import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import { LayoutContext } from '@/app/component/RootComponent';
import FollowButtonComponent from '@/app/component/FollowButtonComponent';
import { Skeleton } from '@material-ui/lab';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import End from '@/app/component/dotpict/end/End';
import TranslationKeys from '@/app/translation/TranslationKeys';
import AdsComponent from '@/app/component/AdsComponent';
import DotpictAdsType from '@/app/ads/DotpictAdsType';
import Works from '@/app/component/Works';
import Like from '@/app/component/Like';
import ShareComponent from '@/app/component/ShareComponent';
import { Helmet } from 'react-helmet';
import {
  Column,
  Grid,
  HorizontalGravity,
  Row,
  VerticalGravity,
} from '@/app/component/base/layout/Layout';
import Thread from '@/app/component/part/work/thread/thread/Thread';
import ThreadInput from '@/app/component/part/work/thread/input/ThreadInput';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import useTranslator from '@/app/hooks/useTranslator';
import { ThreadType } from '@/app/model/ThreadType';
import ButtonComponent from '@/app/component/ButtonComponent';
import { UserType } from '@/app/model/UserType';
import { WorkType } from '@/app/model/WorkType';
import Loading from '@/app/component/dotpict/loading/Loading';
import useWorkDetail from '@/app/component/page/work/detail/useWorkDetail';

export type WorkDetailState = {
  work: WorkType; // 互換性のため
  ogpImageUrl: string;
  workTitle: string;
  workCaption: string;
  workImageUrl: string;
  tags: string[];
  isLiked: boolean;
  likeCount: number;
  width: number;
  height: number;
  createdAt: string;
  shareUrl: string;
  threads: ThreadType[];
  threadNextUrl: string;
  isAllowThread: boolean;
  userId: number;
  user: UserType; // 互換性のため
  userName: string;
  isFollowed: boolean;
  userWorks: WorkType[];
  userEventId: number;
  isVisibleEvent: boolean;
  eventTitle: string;
  isVisibleEdit: boolean;
  isLoadingThread: boolean;
  isLoadingUserWorks: boolean;
};

export type WorkDetailProps = WorkDetailState & {
  handleClickEditWork: () => void;
};

type Props = {
  workId: number;
};

const WorkDetail = ({ workId }: Props) => {
  const { layoutParams } = useContext(LayoutContext);
  const translator = useTranslator();
  const {
    work,
    ogpImageUrl,
    workTitle,
    workCaption,
    workImageUrl,
    tags,
    isLiked,
    likeCount,
    width,
    height,
    createdAt,
    shareUrl,
    threads,
    threadNextUrl,
    isAllowThread,
    userId,
    user,
    userName,
    isFollowed,
    userWorks,
    userEventId,
    isVisibleEvent,
    eventTitle,
    isVisibleEdit,
    isLoadingThread,
    isLoadingUserWorks,
    handleClickEditWork,
  } = useWorkDetail({ workId });

  const detailImageSize = layoutParams.isMobile ? 320 : 512;
  const userWorksDummyItemCount = 4;
  const userWorkImageSize = layoutParams.isMobile ? 128 : 160;

  const gridStyle = css({
    width: '100%',
    display: 'grid',
    justifyContent: 'center',
    gridGap: '4px',
    gridTemplateColumns: `repeat(auto-fill, ${userWorkImageSize}px)`,
  });

  const renderOgp = () => (
    <Helmet>
      <meta property="twitter:title" content={workTitle} />
      <meta property="twitter:description" content={`${workTitle} - ${userName}`} />
      <meta property="twitter:image" content={ogpImageUrl} />
      <meta property="og:title" content={workTitle} />
      <meta property="og:description" content={`${workTitle} - ${userName}`} />
      <meta property="og:image" content={ogpImageUrl} />
    </Helmet>
  );

  return (
    <>
      {renderOgp()}
      <Column horizontalGravity={HorizontalGravity.center}>
        <Spacer height={layoutParams.contentTopSpace} />
        {workImageUrl === '' ? (
          <Skeleton variant="rect" width={detailImageSize} height={detailImageSize} />
        ) : (
          <PixelArtComponent
            alt={workTitle}
            src={workImageUrl}
            width={detailImageSize}
            height={detailImageSize}
          />
        )}
        <Spacer height={32} />
        {isVisibleEdit && (
          <>
            <ButtonComponent
              width={layoutParams.contentWidth - 24 * 2}
              height={48}
              text={translator(TranslationKeys.EditWorkInfo)}
              isFilled={false}
              isBorder
              handleClick={handleClickEditWork}
            />
            <Spacer height={32} />
          </>
        )}
        <Column
          background={DotpictColorStyle.WHITE}
          padding={24}
          width={layoutParams.contentWidth - 24 * 2}
        >
          <Row width="100%" horizontalGravity={HorizontalGravity.spaceBetween}>
            <Column>
              {isVisibleEvent && (
                <Link to={`/userEvents/${userEventId}`}>
                  <Row>
                    <PixelArtComponent
                      src="https://storage.googleapis.com/dotpict-images/web/event/event.svg"
                      alt="event_label"
                      width={55}
                      height={22}
                    />
                    <Spacer width={8} />
                    <Text
                      text={eventTitle}
                      textStyle={DotpictTextStyle.REGULAR14}
                      colorStyle={DotpictColorStyle.PRIMARY}
                    />
                  </Row>
                  <Spacer height={8} />
                </Link>
              )}
              <Grid verticalGravity={VerticalGravity.center}>
                <Text
                  text={workTitle}
                  textStyle={DotpictTextStyle.BOLD20}
                  colorStyle={DotpictColorStyle.BASE}
                />
                {tags.map((tag) => (
                  <React.Fragment key={tag}>
                    <Spacer width={12} />
                    <Link to={`/search/works/tag/${tag}`}>
                      <Text
                        text={`#${tag}`}
                        textStyle={DotpictTextStyle.BOLD16}
                        colorStyle={DotpictColorStyle.PRIMARY}
                      />
                    </Link>
                  </React.Fragment>
                ))}
              </Grid>
              {workCaption.length > 0 && (
                <>
                  <Spacer height={8} />
                  <Text
                    text={workCaption}
                    textStyle={DotpictTextStyle.REGULAR12}
                    colorStyle={DotpictColorStyle.TEXT_GRAY}
                  />
                </>
              )}
              <Spacer height={8} />
              <Row>
                <Link to={`/works/${workId}/likeUsers`}>
                  <Text
                    text={`${likeCount}${translator(TranslationKeys.LikeCount)}`}
                    textStyle={DotpictTextStyle.BOLD14}
                    colorStyle={DotpictColorStyle.BASE}
                  />
                </Link>
                <Spacer width={12} />
                <Text
                  text={`${width}x${height}`}
                  textStyle={DotpictTextStyle.REGULAR14}
                  colorStyle={DotpictColorStyle.BASE}
                />
                <Spacer width={12} />
                <Text
                  text={createdAt}
                  textStyle={DotpictTextStyle.REGULAR14}
                  colorStyle={DotpictColorStyle.BASE}
                />
              </Row>
              <Spacer height={16} />
              <ShareComponent url={shareUrl} title={`${workTitle} #dotpict`} />
            </Column>
            <Like workId={workId} isLike={isLiked} size={52} />
          </Row>
          {layoutParams.isMobile ? (
            <>
              <Spacer height={24} />
              <AdsComponent adsType={DotpictAdsType.RECTANGLE_BANNER} />
              <Spacer height={24} />
            </>
          ) : (
            <Spacer height={48} />
          )}
          <Row
            width="100%"
            horizontalGravity={HorizontalGravity.spaceBetween}
            verticalGravity={VerticalGravity.center}
          >
            <UserComponent user={user} />
            <FollowButtonComponent userId={userId} isFollowed={isFollowed} />
          </Row>
          <Spacer height={24} />
          <Column height={userWorkImageSize}>
            <Works
              works={userWorks}
              isLoading={isLoadingUserWorks}
              isVisibleEnd={false}
              isVisibleAds={false}
              isVisibleLike
              gridStyle={gridStyle}
              imageSize={userWorkImageSize}
              dummyItemCount={userWorksDummyItemCount}
            />
          </Column>
          <Spacer height={40} />
          <Text
            text={translator(TranslationKeys.Comment)}
            textStyle={DotpictTextStyle.BOLD20}
            colorStyle={DotpictColorStyle.BASE}
          />
          <Spacer height={16} />

          {isAllowThread ? (
            <ThreadInput workId={workId} />
          ) : (
            <Column width="100%" horizontalGravity={HorizontalGravity.center}>
              <Text
                text={translator(TranslationKeys.CanNotPostThreads)}
                textStyle={DotpictTextStyle.REGULAR16}
                colorStyle={DotpictColorStyle.BASE}
              />
            </Column>
          )}

          {threads.map((thread) => (
            <React.Fragment key={thread.id}>
              <Spacer height={36} />
              <Thread isVisibleReply work={work} thread={thread} />
            </React.Fragment>
          ))}

          {isLoadingThread && <Loading />}

          {threads.length === 0 && threadNextUrl === '' && (
            <>
              <Spacer height={40} />
              <Column width="100%" horizontalGravity={HorizontalGravity.center}>
                <Text
                  text={translator(TranslationKeys.NoThreads)}
                  textStyle={DotpictTextStyle.REGULAR16}
                  colorStyle={DotpictColorStyle.BASE}
                />
              </Column>
            </>
          )}

          {(threadNextUrl === '' || !isAllowThread) && (
            <Column width="100%" verticalGravity={VerticalGravity.center}>
              <Spacer height={40} />
              <Column width="100%" horizontalGravity={HorizontalGravity.center}>
                <AdsComponent adsType={DotpictAdsType.RECTANGLE_LARGE} />
              </Column>
              <End />
            </Column>
          )}
        </Column>
        <Spacer height={40} />
      </Column>
    </>
  );
};

export default WorkDetail;
