import { Box } from '@material-ui/core';

interface IProps {
  size: number | string;
}

const SquareSpaceComponent = (props: IProps) => {
  const { size } = props;

  return <Box width={size} height={size} />;
};

export default SquareSpaceComponent;
