import { css } from '@emotion/react';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';

interface IProps {
  text: string;
  color: string;
  width: number;
  height: number;
  textStyle: DotpictTextStyle;
}

const BadgeComponent = (props: IProps) => {
  const { text, color, width, height, textStyle } = props;

  const badgeContainerStyle = css({
    display: 'flex',
    width,
    height,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: color,
  });

  return (
    <div css={badgeContainerStyle}>
      <Text text={text} textStyle={textStyle} colorStyle={DotpictColorStyle.WHITE} />
    </div>
  );
};

export default BadgeComponent;
