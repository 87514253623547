import { css } from '@emotion/react';
import { Column, HorizontalGravity, VerticalGravity } from '@/app/component/base/layout/Layout';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';

type Props = {
  id: string;
  width: number;
  height: number;
  text: string;
  onChange: (file: File) => void;
};

const InputStyle = css`
  display: none;
`;

// https://material-ui.com/components/buttons/#upload-button
const FileUploadComponent = ({ id, width, height, text, onChange }: Props) => {
  const styledLabel = css`
    width: ${width};
    height: ${height};
    border: solid 2px ${DotpictColorStyle.PRIMARY};
    background: ${DotpictColorStyle.WHITE};
  `;

  return (
    <label css={styledLabel} htmlFor={id}>
      <Column
        width={width}
        height={height}
        horizontalGravity={HorizontalGravity.center}
        verticalGravity={VerticalGravity.center}
      >
        <Text
          text={text}
          textStyle={DotpictTextStyle.BOLD14}
          colorStyle={DotpictColorStyle.PRIMARY}
        />
      </Column>
      <input
        accept="image/png,image/gif"
        css={InputStyle}
        id={id}
        type="file"
        onChange={(event) => {
          const file = event.target.files?.item(0);
          if (file == null) return;
          onChange(file);
        }}
      />
    </label>
  );
};

export default FileUploadComponent;
