export const defaultDummyItemCount = 100;
export const defaultThumbnailImageGridGap = 8;
export const defaultSmallThumbnailImageGridGap = 4;

export const makingHeadlineTopSpace = '56';
export const makingHeadlineBottomSpace = '16';
export const makingLineSpace = '12';
export const makingImageSpace = '32';
export const makingContentBottomSpace = '56';

export const articleContentTopSpace = '32';
export const articleTitleBottomSpace = '16';
export const articleLineSpace = '12';
export const articleContentBottomSpace = '32';

export const shareBaseUrl = 'https://dotpict.net';
export const serviceNameTwitter = 'Twitter';
