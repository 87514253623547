import { css } from '@emotion/react';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import End from '@/app/component/dotpict/end/End';
import VerticalContainerStyle from '@/app/style/VerticalContainerStyle';
import AdsComponent from '@/app/component/AdsComponent';
import DotpictAdsType from '@/app/ads/DotpictAdsType';
import { UserSummaryType } from '@/app/model/UserSummaryType';
import UserSummaryComponent, {
  DummyUserSummaryComponent,
} from '@/app/component/UserSummaryComponent';
import Loading from '@/app/component/dotpict/loading/Loading';

const centerVerticalContainerStyle = css(VerticalContainerStyle, {
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
});

interface IProps {
  userSummaries: UserSummaryType[];
  isLoading: boolean;
  isVisibleEnd: boolean;
  isVisibleAds: boolean;
  dummyItemCount: number;
}

const UsersComponent = (props: IProps) => {
  const { userSummaries, isLoading, isVisibleEnd, isVisibleAds, dummyItemCount } = props;

  const adsLineCount = 10; // 何行あたりに広告を出すか

  const renderDummyItems = () =>
    [...new Array(dummyItemCount)].map(() => (
      <>
        <DummyUserSummaryComponent />
        <SquareSpaceComponent size={16} />
      </>
    ));

  const renderAds = () => (
    <div css={centerVerticalContainerStyle}>
      <SquareSpaceComponent size={20} />
      <AdsComponent adsType={DotpictAdsType.RECTANGLE_LARGE} />
      <SquareSpaceComponent size={20} />
    </div>
  );

  return (
    <div css={VerticalContainerStyle}>
      {isLoading && userSummaries.length === 0
        ? renderDummyItems()
        : [...new Array(Math.ceil(userSummaries.length / adsLineCount))].map(
            (_: any, index: number) => (
              <>
                {userSummaries
                  .slice(
                    index * adsLineCount,
                    Math.min((index + 1) * adsLineCount, userSummaries.length),
                  )
                  .map((userSummary: UserSummaryType) => (
                    <>
                      <UserSummaryComponent key={userSummary.user.id} userSummary={userSummary} />
                      <SquareSpaceComponent size={16} />
                    </>
                  ))}
                {isVisibleAds && index < userSummaries.length / adsLineCount - 1 && renderAds()}
              </>
            ),
          )}
      {isLoading && userSummaries.length !== 0 && <Loading />}
      {isVisibleEnd && <End />}
    </div>
  );
};

export default UsersComponent;
