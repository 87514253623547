import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { APIContext, GlobalContext } from '@/app/component/RootComponent';
import shouldFetchNext from '@/app/common/Common';
import { Column, HorizontalGravity } from '@/app/component/base/layout/Layout';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import { DotpictAction } from '@/app/reducer/reducer';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import End from '@/app/component/dotpict/end/End';
import { defaultWorksState } from '@/app/state/WorksStateType';
import AdsComponent from '@/app/component/AdsComponent';
import DotpictAdsType from '@/app/ads/DotpictAdsType';
import TimelineWork from '@/app/component/part/work/timeline/TimelineWork';
import { WorkType } from '@/app/model/WorkType';
import { useNavigate } from 'react-router-dom';
import { defaultWorkDetailState } from '@/app/state/IWorkDetailState';

const adsLineCount = 10; // 何行あたりに広告を出すか

const Timeline = () => {
  const { globalState, dispatch } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const [visibleEnd, setVisibleEnd] = useState(false);
  const { client } = useContext(APIContext);
  const navigator = useNavigate();

  const fetchData = async (currentWorks: WorkType[], currentNextUrl: string | null) => {
    setLoading(true);
    setVisibleEnd(false);
    const nextUrl = currentNextUrl || `${client.getBaseUrl}/me/follow_works`;
    const response = await client.fetchWorks(nextUrl);
    const works = currentWorks.concat(response.works);
    const action: DotpictAction = {
      type: 'UPDATE_TIMELINE_STATE',
      payload: {
        timelineState: {
          works,
          nextUrl: response.nextUrl,
        },
      },
    };
    setVisibleEnd(response.nextUrl === '');
    dispatch(action);
    setLoading(false);
    if (shouldFetchNext(globalState.timelineState.nextUrl)) {
      fetchData(works, response.nextUrl);
    }
  };

  const handleScroll = () => {
    if (!loading && shouldFetchNext(globalState.timelineState.nextUrl)) {
      fetchData(globalState.timelineState.works, globalState.timelineState.nextUrl);
    }
  };

  const handleClickWork = (work: WorkType) => {
    dispatch({
      type: 'UPDATE_WORK_DETAIL_STATE',
      payload: {
        workDetailState: {
          ...defaultWorkDetailState,
          work,
          shouldReload: true,
        },
      },
    });
    navigator(`/works/${work.id}`);
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    if (globalState.timelineState.works.length !== 0) return;
    window.scrollTo(0, 0);
    const action: DotpictAction = {
      type: 'UPDATE_TIMELINE_STATE',
      payload: {
        timelineState: defaultWorksState,
      },
    };
    dispatch(action);
    fetchData([], null);
  }, []);

  return (
    <Column horizontalGravity={HorizontalGravity.center}>
      {globalState.timelineState.works.map((work: WorkType, index: number) => (
        <Column horizontalGravity={HorizontalGravity.center} key={work.id}>
          <TimelineWork
            bindModel={{
              workId: work.id,
              title: work.title,
              caption: work.caption,
              imageUrl: work.imageUrl,
              tags: work.tags,
              isLike: work.isLike,
              userId: work.user.id,
              userName: work.user.name,
              profileImageUrl: work.user.profileImageUrl,
            }}
            handleClick={() => handleClickWork(work)}
          />
          <Spacer height={32} />
          {index !== 0 && index % adsLineCount === 0 && (
            <>
              <AdsComponent adsType={DotpictAdsType.RECTANGLE_LARGE} />
              <SquareSpaceComponent size={20} />
            </>
          )}
        </Column>
      ))}

      {loading && <CircularProgress />}

      {visibleEnd && <End />}
    </Column>
  );
};

export default Timeline;
