import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useContext } from 'react';
import VerticalContainerStyle from '@/app/style/VerticalContainerStyle';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import TranslationKeys from '@/app/translation/TranslationKeys';
import { LayoutContext } from '@/app/component/RootComponent';

const NotFound = () => {
  const intl = useIntl();
  const { layoutParams } = useContext(LayoutContext);

  const containerStyle = css(VerticalContainerStyle, {
    padding: `0 ${layoutParams.isMobile ? 24 : 0}`,
    alignItems: 'center',
  });

  return (
    <div css={containerStyle}>
      <SquareSpaceComponent size={128} />
      <Text
        text={intl.formatMessage({ id: TranslationKeys.NotFoundTitle })}
        textStyle={DotpictTextStyle.BOLD20}
        colorStyle={DotpictColorStyle.BASE}
      />
      <SquareSpaceComponent size={90} />
      <PixelArtComponent
        alt="Not Found"
        src="https://storage.googleapis.com/dotpict-images/web/not_found.svg"
        width={116}
        height={148}
      />
      <SquareSpaceComponent size={90} />
      <Text
        text={intl.formatMessage({ id: TranslationKeys.NotFoundMessage1 })}
        textStyle={DotpictTextStyle.REGULAR16}
        colorStyle={DotpictColorStyle.GRAY_DEEP}
      />
      <Text
        text={intl.formatMessage({ id: TranslationKeys.NotFoundMessage2 })}
        textStyle={DotpictTextStyle.REGULAR16}
        colorStyle={DotpictColorStyle.GRAY_DEEP}
      />
      <SquareSpaceComponent size={48} />
      <Link to="/">
        <Text
          text={intl.formatMessage({ id: TranslationKeys.NotFoundGoToTop })}
          textStyle={DotpictTextStyle.BOLD14}
          colorStyle={DotpictColorStyle.PRIMARY}
        />
      </Link>
      <SquareSpaceComponent size={48} />
    </div>
  );
};

export default NotFound;
