import { useMemo } from 'react';
import styled from '@emotion/styled';
import DrawCanvas from '@/app/component/page/canvases/CanvsList/DrawCanvas';
import CanvasItem from '@/app/component/page/canvases/CanvsList/CanvasItem';
import { useCanvasesData } from '@/app/component/page/draw/hooks/storage';
import { CanvasData } from '@/app/component/page/draw/hooks/types';

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 128px);
  grid-gap: 8px;
  grid-auto-flow: row;
  width: 100%;
  padding-left: 12px;
  box-sizing: border-box;
`;

type Props = {
  setOpenNewCanvas: (open: boolean) => void;
};

const CanvasList = ({ setOpenNewCanvas }: Props) => {
  const [canvasItems] = useCanvasesData();

  // 更新日順に並び替える
  const sortedItems = useMemo(
    () =>
      canvasItems.sort((a, b) => {
        const aDate = new Date(a.updatedAt);
        const bDate = new Date(b.updatedAt);
        if (aDate < bDate) {
          return 1;
        }
        if (aDate > bDate) {
          return -1;
        }
        return 0;
      }),
    [canvasItems],
  );

  return (
    <List>
      <DrawCanvas setOpenNewCanvas={setOpenNewCanvas} />

      {sortedItems.map((canvas: CanvasData) => (
        <CanvasItem
          id={canvas.id}
          title={canvas.title}
          canvasSize={canvas.canvasSize}
          pixels={canvas.pixels}
          key={canvas.id}
        />
      ))}
    </List>
  );
};

export default CanvasList;
