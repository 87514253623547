import React, { useEffect } from 'react';
import { Skeleton } from '@material-ui/lab';
import { forceCheck } from 'react-lazyload';
import { SerializedStyles } from '@emotion/react';
import WorkThumbnail from '@/app/component/part/work/thumbnail/WorkThumbnail';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import End from '@/app/component/dotpict/end/End';
import AdsComponent from '@/app/component/AdsComponent';
import DotpictAdsType from '@/app/ads/DotpictAdsType';
import { Column, HorizontalGravity } from '@/app/component/base/layout/Layout';
import Loading from '@/app/component/dotpict/loading/Loading';
import { WorkType } from '../model/WorkType';

const adsLineCount = 10; // 何行あたりに広告を出すか
const innerPadding = 8; // 一旦固定

type Props = {
  works: WorkType[];
  isLoading: boolean;
  isVisibleEnd: boolean;
  isVisibleAds: boolean;
  isVisibleLike: boolean;
  isDeepLink?: boolean;
  gridStyle: SerializedStyles;
  imageSize: number;
  dummyItemCount: number;
};

const Works = ({
  works,
  isLoading,
  isVisibleEnd,
  isVisibleAds,
  isVisibleLike,
  isDeepLink,
  gridStyle,
  imageSize,
  dummyItemCount,
}: Props) => {
  const columns = Math.trunc((832 + innerPadding) / (imageSize + innerPadding));

  useEffect(() => {
    // 直後にforceCheckすると画面に収まり切っていないPixelartComponentがチェック対象にならない
    setTimeout(forceCheck, 50);
  }, [imageSize]);

  const renderDummyItems = () => (
    <div css={gridStyle}>
      {[...new Array(dummyItemCount)].map((_: any, index: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <Skeleton key={index} variant="rect" width={imageSize} height={imageSize} />
      ))}
    </div>
  );

  const renderAds = () => (
    <Column horizontalGravity={HorizontalGravity.center}>
      <SquareSpaceComponent size={20} />
      <AdsComponent adsType={DotpictAdsType.RECTANGLE_LARGE} />
      <SquareSpaceComponent size={20} />
    </Column>
  );

  const renderWorks = () =>
    [...new Array(Math.ceil(works.length / (columns * adsLineCount)))].map(
      (_: any, index: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          <div css={gridStyle}>
            {works
              .slice(
                index * columns * adsLineCount,
                Math.min((index + 1) * columns * adsLineCount, works.length),
              )
              .map((work) => (
                <WorkThumbnail
                  key={work.id}
                  work={work}
                  imageSize={imageSize}
                  isVisibleLike={isVisibleLike}
                  isDeepLink={isDeepLink}
                />
              ))}
          </div>
          {isVisibleAds && index < works.length / (columns * adsLineCount) - 1 && renderAds()}
        </React.Fragment>
      ),
    );

  return (
    <>
      {isLoading && works.length === 0 ? renderDummyItems() : renderWorks()}
      {isLoading && works.length !== 0 && <Loading />}
      {isVisibleEnd && <End />}
    </>
  );
};

export default Works;
