import { GetUserEventDetailService } from '@/app/service/GetUserEventDetail/GetUserEventDetailService';
import defaultUserEvent from '@/app/model/UserEventType';

const dummyGetUserEventDetailService: GetUserEventDetailService = (userEventId: string) => {
  return Promise.resolve({
    ...defaultUserEvent,
    id: Number(userEventId),
  });
};

export default dummyGetUserEventDetailService;
