import { css } from '@emotion/react';

const CenterVerticalContainerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export default CenterVerticalContainerStyle;
