const en: {[key: string]: string} = {
  Empty: ' ',
  Comment: 'Comment',
  Send: 'Send',
  Ads: 'Ads',
  Tweet: 'Tweet',
  Share: 'Share',
  CommentHint: 'Add a comment...',
  CanNotPostThreads: 'This work does not allow comments',
  NoThreads: 'No comments',
  Follow: 'Follow',
  Followed: 'Following',
  Follower: 'Follower',
  PickUpWorks: 'Pick Up',
  LoadMore: 'Load More',
  NewestWorks: 'Newest',
  PickupWorks: 'Pick Up',
  FollowingWorks: 'Following',
  Guidelines: 'Guidelines',
  Title: 'Title',
  Tag: 'Tag',
  User: 'User',
  Email: 'Mail address',
  Password: 'Password',
  Login: 'Login',
  SignUpFromApp: 'Download the app and sign up!',
  Search: 'Search',
  Cancel: 'Cancel',
  MyPage: 'My Page',
  Logout: 'Logout',
  LikeCount: 'Likes',
  PostedWorks: 'Works',
  LikedWorks: 'Liked',
  NotFoundTitle: 'We couldn\'t find the page you were looking for.',
  NotFoundMessage1: 'The page you are looking for may be temporarily inaccessible or may have been moved or deleted.',
  NotFoundMessage2: 'Please make sure that the URL and file name are correct.',
  NotFoundGoToTop: 'Top',
  DrawPixelartFromApp: 'Draw Pixelart With App',
  Company: 'Company',
  SignUp: 'Sign up',
  SignUpSubmit: 'Sign up',
  SignUpPassword: 'Password',
  AlreadyHaveAccount: 'Already have an account? Login',
  Edit: 'Edit',
  EditProfile: 'Edit My Page',
  ChangeImage: 'Change Image',
  UserName: 'User Name',
  UserNamePlaceholder: 'Enter User Name',
  Text: 'Profile',
  TextPlaceholder: 'Enter Your Profile',
  WebSite: 'SNS / Website',
  WebSitePlaceholder: 'Enter URL',
  Save: 'Save',
  AccountName: 'Account Name',
  AccountNamePlaceholder: 'Alphabet, underscore, 3~32 characters',
  Next: 'Next',
  PostWork: 'Upload',
  ViewGuideline: 'View Guidelines',
  Caption: 'Caption',
  CaptionHint: 'Explain your work',
  AllowComments: 'Allow comments',
  SizeLimit: 'PNG or GIF\nUp to 480x480',
  AddFile: 'Add a pixelart',
  OtherSettings: 'Other Settings',
  PostTutorialTitle: 'After agreeing to the guidelines',
  PostTutorialTitle2: 'you will be able to post',
  PostTutorial1: 'You can\'t act of copying and posting copyrighted works such as game images and animation images',
  PostTutorial2: 'You can\'t post works that depict or emphasize underwears, female breasts, buttocks, genitals, and any sexual contents',
  PostTutorial3: 'You can\'t post work such as cutting the human body and a large amount of bleeding',
  PostTutorial4: 'You can\'t post words not intended for work, defamatory, excessive advertising',
  PostTutorial5: 'No post that includes only words and is not made to for an artwork',
  AgreeTheGuideline: 'Agree',
  Back: 'Back',
  Post: 'Upload',
  PostSectionTitle: 'Upload a Pixelart',
  PostCautionTitle: 'Posting anything other than pixelart is prohibited',
  PostCautionSummary: 'This is a community for pixelart lovers. If you post anything other than pixelart, your account will be suspended.',
  PostCautionSummary2: 'And we will delete obscene works, grotesque works, works of others, and nuisances including words as soon as we find them. Please read the guidelines.',
  PostCautionGuidelines: 'For detailed guidelines',
  PostCautionExample: 'What kind of pixelart should I submit?',
  PostCautionExampleDetail: 'Mouse over to see a detailed description of each picture.',
  PostCautionExampleDetail2: 'The definition of each picture is within dotpict and is not defined in a broad sense.',
  Pixelart: 'Pixelart',
  Binaryart: 'Binaryart',
  Convert: 'Convert',
  Oekaki: 'Oekaki',
  Picture: 'Picture',
  PostCautionPixelart: 'A drawing made with a pixel-by-pixel drawing pen, such as dotpict or Aseprite, in which the drawing is done repeatedly in increments of one to several pixels. It is a method of expression that was born in an environment where colors were once limited, so colors are often not used more than necessary.',
  PostCautionBinaryart: 'A drawing made with a pixel-by-pixel drawing pen (binary pen), such as dotpict or Aseprite. This work is drawn like a oekaki, but still retains the expression of the pixel units.',
  PostCautionConvert: 'You may not post anything that uses a tool to convert photos or drawings into pixelarts. If you do, you will be subject to account suspension.',
  PostCautionOekaki: 'Drawings made with brushes or pens, such as drawing tools. If you do, you will be subject to account suspension.',
  PostCautionPicture: 'No photos, even if they are taken of pixelarts. If you do, you will be subject to account suspension.',
  MaxTags: 'Up to 3 tags',
  ErrorImageSizeTooLarge: 'The image size is too large, up to 480x480.',
  UnknownError: 'Error occurred.',
  Notifications: 'Notifications',
  AboutDotpict: ' About dotpict',
  AboutDotpictText1: 'You can post and view pixel arts.',
  AboutDotpictText2: '＼Try drawing pixelarts／',
  AboutDotpictText3: 'There\'s an app for beginners, so give it a try!',
  RequestBox: 'Request Box',
  RequestBoxSettings: 'Request Box Settings',
  RequestBoxEnabled: 'Accepting requests',
  RequestBoxSummaryTitle: 'Description of your request box',
  RequestBoxSummaryText: 'This is the description that appears in your request submission form.',
  RequestBoxSummaryHint: 'I\'d like to draw at my own pace, when I want to!',
  RequestBoxSend: 'Send Request',
  RequestBoxView: 'View Requests',
  RequestReceived: 'Received',
  RequestCompleted: 'Completed',
  RequestAll: 'Requests',
  RequestSucceeded: 'Request has been sent.',
  RequestTextZero: 'Please input your request',
  RequestTextOver: 'Request is limited to 500 characters.',
  RequestDeleteTitle: 'Delete the request',
  RequestDeleteMessage: 'Do you want to delete this request? This operation can not be undone.',
  RequestDeleted: 'The request deleted.',
  RequestBoxSendHint: 'Enter your request details',
  RequestNotifications: 'Request Notifications',
  RequestSend: 'Send',
  DoReply: 'Reply',
  ViewReply: 'View {value} replies',
  MoveToWork: 'Go to the work page',
  UserEventDescription1: 'How to join in a user event',
  UserEventDescription2: '1. Download the template if available',
  UserEventDescription3: '2. Draw a pixelart using the color palette.',
  UserEventDescription4: '3. When you\'ve finished to draw, click the button "Join the Event"',
  EventTemplate: 'Template',
  EventColorCode: 'Color Codes',
  EventDownloadTemplate: 'Download Template',
  EventDownloadColorCodes: 'Download colors as an image',
  EventPostTitle: 'Post a work for this event',
  ParticipatingWorks: 'Works',
  JoiningEvent: 'Joining Event',
  RegistrationDate: 'Registration Date : ',
  Birthday: 'Birthday : ',
  HoldingPeriod: '開催期間 :',
  CurrentScoreRate: '＼ Current score rate ／',
  WhichJoin: '＼ Which team will you join?／',
  BattleEventPointRule1: '+10 points for posting works! ',
  BattleEventPointRule2: 'Like +1 points!',
  FailedLoginError: 'The login attempt failed',
  IncorrectPasswordError: 'Password is incorrect',
  AddMyPageHeaderImage: '+ Add Header Image',
  ChangeMyPageHeaderImage: 'Change Header Image',
  MyPageHeaderSettingTitle: 'Header image is not set',
  MyPageHeaderNote: 'The recommended ratio of images is 3:1.PNG or GIF Up to 480x480',
  ViewImageGuideline: 'View Image Guidelines',
  Point: 'pt',
  JoinTeam: 'Join {value} team ',
  EventResult: '＼ Result／',
  ThumbnailAlt: 'Thumbnail Image of the work',
  JoinEvent: 'Join Event',
  HowToPost: 'How to post',
  Download: 'Download',
  SelectImage: 'Select Image',
  DownloadTemplate: 'Download a template!',
  UsePalette: 'Let\'s use the color palette!',
  EnableUseOtherColors: 'Of course, you can freely use colors other than those in the palette.',
  HowToUsePaletteInAseprite: 'How to set up the palette in Aseprite',
  HideColorCodes: 'Hide color codes',
  ShowColorCodes: 'Show color codes',
  PostToDotpict: 'Once your work is complete, post it to dotpict!',
  NewCanvas: 'NEW',
  MyCanvases: 'Canvases (α)',
  DefaultCanvasTitle: 'Canvas',
  DeleteCanvas: 'Delete',
  SaveCanvas: 'Save Image',
  PostCanvas: 'Post',
  ConfirmDeleteCanvas: 'Are you sure you want to delete this canvas?',
  BackToCanvases: 'Back to Canvases',
  Pen: 'Pen [P]',
  Eraser: 'Eraser [E]',
  Bucket: 'Bucket [B]',
  Line: 'Line [L]',
  Move: 'Move [M]',
  Undo: 'Undo [Z]',
  Redo: 'Redo [Y]',
  ClearAll: 'Clear All',
  SelectSaveSize: 'Select the size you want to save',
  SaveSize: '{scale} (Save size: {size})',
  SelectSize: 'Select Size',
  SelectPalette: 'Select Palette',
  SelectPaletteDescription1: 'The color change function is currently being implemented.',
  SelectPaletteDescription2: 'The selected palette is fixed on this canvas.',
  Create: 'Create',
  TrialTitle: 'The drawing function of the PC version is an alpha version.',
  TrialMessage1: 'It has fewer functions than the app version. If you think the functions are not enough, please install the app version.',
  TrialMessage2: '*Canvas data is saved as local data in your browser. It is not tied to your account, so if you want to continue drawing, use the same browser. Local data in your browser is not guaranteed to be saved permanently, and since this is an alpha version, the data you drew may disappear due to changes in dotpict specifications, etc. Please post or save the pixelart you have drawn.',
  TrialMessage3: 'Known Bugs: We have confirmed that operations other than mouse (e.g., pen tablets) are unstable.',
  PleaseLogin: 'Please login',
  MustLoginToPost: 'You must be logged in to post.',
  SendFeedback: 'Send Feedback',
  Draw: 'Draw',
  Notice: 'Notice',
  Making: 'MAKING',
  MakingTitle: '{authorName}’s Pixelart Making',
  MakingShare: 'Share this article',
  MakingOgpTitle: '{authorName}’s Pixelart Making',
  MakingShareText: '{authorName}’s Pixelart Making "{theme}" #dotpict',
  MakingLikeTitle: 'Was this making helpful?',
  MakingLikedTitle: 'Thank you for your feedback!',
  MakingLike: 'This helps me!',
  MakingTwitter: 'Twitter',
  ViewWorks: 'View Works',
  MakingDescription: 'You can see how {authorName} usually draws a pixelart',
  AnotherMakings: 'See other makings',
  TableOfContents: 'Table of Contents',
  FailedToUpload: 'Failed to upload',
  EditColor: 'Edit Color',
  DrawPixelart: 'Draw Pixelart Now',
  ImageReduction: 'Image reduction',
  ImageReductionDetail: 'You may not post any resized versions of photographs or illustrations. If you do, you will be subject to account suspension.',
  CropPosition: 'Adjust Crop Position',
  ResetCropPosition: 'Reset Crop Position',
  CropDecide: 'Crop',
  ZoomRate: 'Zoom Rate',
  EditWorkInfo: 'Edit Work',
  UpdateWorkInfo: 'Update Work',
  EditImage: 'Edit Image',
  PrivacyTitle: 'Privacy Policy',
  PrivacyText1: '1. Basic policy',
  PrivacyText2: 'The dotpict management team (hereinafter referred to as "book management") recognizes the importance of personal information and believes that protecting personal information is a social obligation, and individual Compliance with information laws and regulations, we will properly acquire, use and manage personal information handled by this management.',
  PrivacyText3: '2. Scope',
  PrivacyText4: 'This Privacy Policy indicates the policy that this management adheres to when handling personal information of a user (honorific title abbreviation) or similar information in this service.',
  PrivacyText5: '3. Acquisition of Personal Information and Purpose of Use',
  PrivacyText6: 'The administration may provide the following information from users.',
  PrivacyText7: '· E-mail address and password used for logging in this service',
  PrivacyText8: '· Advertisement ID (used for delivering optimal advertisement)',
  PrivacyText9: 'In addition, this management will use the information provided by users in this service within the scope of the following purposes. We will not use it for purposes other than purpose without your consent.',
  PrivacyText10: '· To log in to this service',
  PrivacyText11: '· Opinions on this service, confirmation of inquiry content · For reply',
  PrivacyText12: '· To improve this service',
  PrivacyText13: '· To deliver the best advertisement',
  PrivacyText14: '4. Management of personal information',
  PrivacyText15: 'In this administration, we will thoroughly follow the management of information acquired from users.',
  PrivacyText16: '1). Securing the accuracy of information',
  PrivacyText17: 'Regarding information acquired from users, we will endeavor to be accurate and up to date information.',
  PrivacyText18: '2). Safety management measures',
  PrivacyText19: 'Regarding this management, we carefully handle the handling of personal information with extreme care and strict handling.',
  PrivacyText20: '3). Retention period and disposal',
  PrivacyText21: 'Information provided by users will be discarded when this service is terminated. Also, even when it becomes unnecessary, it will be disposed promptly.',
  PrivacyText22: '5. Whether or not a third party is provided',
  PrivacyText23: 'This administration does not provide personal information to third parties without obtaining consent from users. However, in the following cases this is not the limit.',
  PrivacyText24: '· Disclosure or offer is permitted by law',
  PrivacyText25: '6. Disclosure, correction, suspension of use etc. of personal information',
  PrivacyText26: 'When there is an offer such as disclosure, correction, suspension of use etc., we will respond according to the method prescribed by our company. For specific methods, we will inform you individually, so please contact the reception desk below.',
  PrivacyText27: 'support@dotpict.zendesk.com ',
  PrivacyText28: '7. Revision',
  PrivacyText29: 'Revised on October 6, 2018',
  PrivacyText30: 'Formulated February 2, 2018',
  GuidelineTitle: 'Guidelines',
  GuidelineText1: 'These guidelines are to make dotpict a better community for pixelart lovers. Please read and follow the guidelines to use the application happily!',
  GuidelineText2: '🌸Post appropriate content 🌸',
  GuidelineText3: '- A wide range of age groups and countries use dotpict. Please post contents that everyone feels safely.',
  GuidelineText4: '❎No text content that is not a work, No post intentionally leading to the comment field ❎',
  GuidelineText5: '- No post that includes only words and is not made to for an artwork',
  GuidelineText6: '- Ex) No post including “Follow me!”, “Like this!”, or “Request please” because they are not pure artworks.',
  GuidelineText7: '- No down arrow that directs to the comment box',
  GuidelineText8: '- Ex) No post including “Let\'s chat“, “Notice!”, “Read my bio”, and “Any Questions?” because they are not pure artworks.',
  GuidelineText9: '❎ No copy ❎',
  GuidelineText10: '- dotpict is a community where you show your artwork. Do not post a pixel art that is not your work or copy a picture that has been converted to a pixel art with a tool.',
  GuidelineText11: '- No copy of game images and animation images',
  GuidelineText12: '- No post that copies others’ work',
  GuidelineText13: '- No pixel art converted by an application that converts to a pixel art',
  GuidelineText14: '- It is unacceptable to post a pixel art you convert a photo you took into a pixel art.',
  GuidelineText15: '- However, if you draw a pixel art while referring your photo you have taken (no converting app) and add your characters, that would be a wonderful pixel art!',
  GuidelineText16: '❎No non-pixel-art content ❎',
  GuidelineText17: '- No pixel art that only draws stick figures or emoticons on a 128x128 canvas',
  GuidelineText18: '- dotpict is a community for pixel art lovers, so please post a pixel art!',
  GuidelineText19: '- The definition of pixel art is wide and dotpict does not define it clearly. However, we remove inappropriate stick figures and pictures with only one pixel because they do not match to the dotpict community.',
  GuidelineText20: '❎No continuous posting❎',
  GuidelineText21: '- No Continuous submission of similar artworks',
  GuidelineText22: '❎ No disclosure of personal information ❎',
  GuidelineText23: '- No exchange of email address or your personal information',
  GuidelineText24: '❎ No sexual content ❎',
  GuidelineText25: '- We respond very strictly to sexual contents. If you post even once, your account may be banned without any warning.',
  GuidelineText26: '- No underwear, breasts, buttocks, genital emphasis or exposed contents',
  GuidelineText27: '- No sexual acts or contents reminiscent of sexual acts',
  GuidelineText28: '- No contents containing excreta',
  GuidelineText29: '❎ No grotesque content❎',
  GuidelineText30: '- No grotesque contents such as cutting the human body, loss of the human body, or heavy bleeding',
  GuidelineText31: '🌸 Let\'s communicate with compassion 🌸',
  GuidelineText32: '- Do not hurt others, such as swearing or criticizing',
  GuidelineText33: '・ If there is a person who violates the guideline, please do not comment; report it to us. Even comments that you think are good may hurt someone or cause unexpected trouble.',
  GuidelineText34: '* We may remove contents that are not listed above if we are considered to have a problem.',
  GuidelineText35: 'Any contents or comments that violate the guidelines will be deleted, and users who violate it will be warned. The warning is not sent by another user; it is sent as a result of our review. Please read the warning and do not repeat the same.',
  GuidelineText36: 'If you violate repeatedly, your account will be banned. Once your account is banned, it can never be recovered.',
  VerifiedAccountTitle: 'About Verified Account',
  VerifiedAccountText1: 'A Verified Account  is a status given to a user who met the conditions set by us, and those who become verified accounts will have a badge displayed on their profile.',
  VerifiedAccountText2: 'Verified accounts will have early access to new features and limited features, and will not show ads on the drawing screen. (Ads on the SNS screen will still remain)',
  VerifiedAccountText3: 'How to become a verified account',
  VerifiedAccountText4: 'Those who meet the following conditions are eligible:',
  VerifiedAccountText5: 'Has a positive influence on other users as a creator of dotpict',
  VerifiedAccountText6: 'Posts highly creative works',
  VerifiedAccountText7: 'How to continue a verified account',
  VerifiedAccountText8: 'Posts at least one dopict per month (The criteria may change)',
  SixTitle: 'dotpict celebrates its 6th anniversary!',
  SixText1: 'October 27th is dotpict\'s birthday!',
  SixText2: 'We are happy to announce that dotpict is celebrating its 6th anniversary. We would like to thank everyone who regularly uses and contributes to dotpict.',
  SixText3: 'In its seventh year, dotpict plans to advance significantly.',
  SixText4: 'Here are some of the features that will be added to dotpict in the future!',
  SixText5: 'They are still under development, so some changes may occur.',
  SixText6: 'Addition of the Layer Function',
  SixText7: 'The default number of the layers is three and this cannot be changed.',
  SixText8: 'You can set the background color separately from the layers.',
  SixText9: '24 Palette Colors',
  SixText10: 'You will be able to set the palette color from 2 to 24 colors.',
  SixText11: 'With the addition of 24 colors, we are adding an eyedropper function to make it easier to select the desired color from the many colors available.',
  SixText12: 'Additon of Pen Size and Eraser Size',
  SixText13: 'The pen was fixed at 1px, but now it can be set from 1px to 10px.',
  SixText14: 'The additional pen size greatly increases the speed at which you can create large pixelarts.',
  SixText15: 'You can draw roughly with a large pen and then scrape and shape the drawing.',
  SixText16: 'Addition of the Automatic Selection Tool',
  SixText17: 'You can select an area like painting with the bucket. (See image.)',
  SixText18: 'The color of the selected area and the consecutive color will be selected.',
  SixText19: 'This is a very useful feature when you want to move only a portion of the image.',
  SixText20: 'Display of Small Size Work',
  SixText21: 'It is easy to check a small size pixelart with a single tap while drawing the work.',
  SixText22: 'It is a lot easier to handle now that many of us used to go back to the list screen and check the little thumbnails.',
  SixText23: 'More Guide Patterns',
  SixText24: '9 divisions are added to the guide. It becomes easier to draw an art with the perspective in mind.',
  SixText25: 'White color will be added to the the guide colors. It makes it easier to see the guides that were difficult to see in darker pictures.',
  SixText26: '[For iPad Only] Side-by-Side Screen',
  SixText27: 'My Canvases List',
  SixText28: 'My Canvases List will be displayed in a grid, so you will be able to see more works on a single screen.',
  SixText29: 'Due to space constraints, the created date and update date will be moved to each work page.',
  SixText30: 'For Beginners',
  SixText31: 'Even with all these additional features, dotpict is still very much the same for beginners.',
  SixText32: 'Layers, auto-selection, and other complex tools are not needed by most beginners. These functions will be added to Advanced Settings, and many of the features that are added are initially turned off.',
  SixText33: 'In addition, we are planning to add more "Drawing Help" in the drawing screen to provide explanations about the tools with images and videos.',
  SixText34: 'Each tool is labeled to make it easier to know what it is used for.',
  SixText35: 'We are working on many more features, including animations! Enjoy dotpict!',
  SixText36: 'by dotpict Team',
  RequestboxTitle: 'Request Box released!',
  RequestboxText1: 'The Request Box, a feature that allows you to send requests to users who are accepting requests, has been released!',
  RequestboxText2: 'When you go to the profile page of a user who is accepting requests, you will see a "Send Request" button.',
  RequestboxText3: 'If you are willing to accept requests, you can set up the request box now.',
  RequestboxText4: 'Set up now',
};

export default en;
