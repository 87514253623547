import { useContext, useEffect, useLayoutEffect, useReducer } from 'react';
import { APIContext, MeContext } from '@/app/component/RootComponent';
import { useNavigate } from 'react-router-dom';
import shouldFetchNext from '@/app/common/Common';
import { PaletteType } from '@/app/model/PaletteType';
import { SizeType, useSize } from '@/app/component/page/draw/Context/SizeContext';
import { useCreateCanvasData } from '@/app/component/page/draw/hooks/storage';
import useAnalytics from '@/app/hooks/useAnalytics';

type Action =
  | { type: 'SELECT_PALETTE'; payload: { selectedIndex: number } }
  | { type: 'START_FETCH_PALETTES'; payload: { palettes: PaletteType[] } }
  | { type: 'END_FETCH_PALETTES'; payload: { palettes: PaletteType[]; nextUrl: string } };

type State = {
  selectedPaletteIndex: number;
  isLoadingPalette: boolean;
  palettes: PaletteType[];
  nextUrl: string;
};

const reducer = (prev: State, action: Action): State => {
  switch (action.type) {
    case 'SELECT_PALETTE': {
      const { payload } = action;
      return {
        ...prev,
        selectedPaletteIndex: payload.selectedIndex,
      };
    }
    case 'START_FETCH_PALETTES': {
      return {
        ...prev,
        isLoadingPalette: true,
      };
    }
    case 'END_FETCH_PALETTES': {
      const { payload } = action;
      return {
        ...prev,
        isLoadingPalette: false,
        palettes: payload.palettes,
        nextUrl: payload.nextUrl,
      };
    }
    default:
      return prev;
  }
};

export const useNewCanvas = (open: boolean) => {
  const navigator = useNavigate();
  const meState = useContext(MeContext);
  const [size, setSize] = useSize();
  const analytics = useAnalytics();
  const [state, dispatch] = useReducer(reducer, {
    selectedPaletteIndex: -1,
    isLoadingPalette: false,
    palettes: [],
    nextUrl: '',
  });
  const { createCanvasData } = useCreateCanvasData();
  const { client } = useContext(APIContext);

  const fetchPalettes = async (currentPalettes: PaletteType[], currentNextUrl: string) => {
    dispatch({ type: 'START_FETCH_PALETTES', payload: { palettes: currentPalettes } });
    const response = await client.fetchOfficialPalettes(meState.token, currentNextUrl);
    const newPalettes = currentPalettes.concat(response.palettes);
    const newNextUrl = response.nextUrl;
    dispatch({
      type: 'END_FETCH_PALETTES',
      payload: { palettes: newPalettes, nextUrl: newNextUrl },
    });
    if (shouldFetchNext(newNextUrl)) {
      fetchPalettes(newPalettes, newNextUrl);
    }
  };

  const handleScroll = () => {
    if (!state.isLoadingPalette && shouldFetchNext(state.nextUrl)) {
      fetchPalettes(state.palettes, state.nextUrl);
    }
  };

  const handleClickCreate = () => {
    if (state.selectedPaletteIndex === -1) return;
    const palette = state.palettes[state.selectedPaletteIndex].colorCodes;
    const pixels = Array.from({ length: size.height }, () => {
      return Array.from({ length: size.width }, () => 'ffffff');
    });
    const newCanvasId = createCanvasData(size, palette, pixels);
    navigator(`/draw?current=${newCanvasId}`);
    analytics('draw_new_canvas', { canvas_size: `${size.width}x${size.height}` });
  };

  const handleClickPalette = (selectedIndex: number) => {
    dispatch({ type: 'SELECT_PALETTE', payload: { selectedIndex } });
  };

  const handleSelectCanvasSize = (canvasSize: SizeType) => {
    setSize(canvasSize)();
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    if (!open) return;
    fetchPalettes([], `${client.getBaseUrl}/official_palettes`);
  }, [dispatch, meState, open]);

  return {
    ...state,
    size,
    handleSelectCanvasSize,
    handleClickPalette,
    handleClickCreate,
  };
};

export default useNewCanvas;
