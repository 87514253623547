import { useContext, useEffect, useState } from 'react';
import { APIContext, GlobalContext, MeContext } from '@/app/component/RootComponent';
import { DotpictAction } from '@/app/reducer/reducer';
import { RepliesBindModel } from '@/app/component/page/replies/index';
import { useParams } from 'react-router-dom';

type OutputProps = {
  bindModel: RepliesBindModel;
};

const useReplies = (): OutputProps => {
  const { workId, threadId } = useParams();
  const { globalState, dispatch } = useContext(GlobalContext);
  const [isLoading, setLoading] = useState(false);
  const meState = useContext(MeContext);
  const { client } = useContext(APIContext);

  const fetchData = async () => {
    if (workId === undefined || threadId === undefined) return;
    setLoading(true);
    const requestedUserId = meState.user.id;
    const response = await client.fetchChildThreads(meState.token, workId, threadId);
    const action: DotpictAction = {
      type: 'UPDATE_REPLIES_STATE',
      payload: {
        repliesState: {
          work: response.work,
          parentThread: response.parentThread,
          threads: response.threads,
          requestedUserId,
        },
      },
    };
    dispatch(action);
    setLoading(false);
  };

  useEffect(() => {
    if (
      globalState.repliesState.parentThread.id !== Number(workId) ||
      globalState.repliesState.requestedUserId !== meState.user.id
    ) {
      window.scrollTo(0, 0);
      fetchData();
    }
  }, [meState]);

  return {
    bindModel: {
      work: globalState.repliesState.work,
      parentThread: globalState.repliesState.parentThread,
      threads: globalState.repliesState.threads,
      isLoading,
      profileImageUrl: meState.user.profileImageUrl,
    },
  };
};

export default useReplies;
