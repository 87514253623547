import { Column, Row, VerticalGravity } from '@/app/component/base/layout/Layout';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import DotpictLink from '@/app/component/DotpictLinkComponent';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import Text from '@/app/component/Text';
import useTranslator from '@/app/hooks/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import Loading from '@/app/component/dotpict/loading/Loading';
import { ThreadType } from '@/app/model/ThreadType';
import useReplies from '@/app/component/page/replies/useReplies';
import Thread from '@/app/component/part/work/thread/thread/Thread';
import ChildThread from '@/app/component/dotpict/childthread';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import ThreadInput from '@/app/component/part/work/thread/input/ThreadInput';
import { WorkType } from '../../../model/WorkType';

export type RepliesBindModel = {
  work: WorkType;
  parentThread: ThreadType;
  threads: ThreadType[];
  isLoading: boolean;
  profileImageUrl: string;
};

const Replies = () => {
  const translator = useTranslator();
  const { bindModel } = useReplies();

  return (
    <Column>
      <Column>
        <Spacer height={32} />
        <DotpictLink to={`/works/${bindModel.work.id}`}>
          <Row verticalGravity={VerticalGravity.center}>
            <PixelArtComponent
              src="https://storage.googleapis.com/dotpict-images/web/thread/thread_move_to_work.svg"
              alt="thread_move_to_work"
              width={6}
              height={10}
            />
            <Spacer width={8} />
            <Text
              text={translator(TranslationKeys.MoveToWork)}
              textStyle={DotpictTextStyle.BOLD12}
              colorStyle={DotpictColorStyle.PRIMARY}
            />
          </Row>
        </DotpictLink>
        <Spacer height={12} />
        <PixelArtComponent
          alt={bindModel.work.title}
          src={bindModel.work.thumbnailImageUrl}
          width={128}
          height={128}
        />
        <Spacer height={24} />
      </Column>
      <Column background={DotpictColorStyle.WHITE} padding={24}>
        <Text
          text={translator(TranslationKeys.DoReply)}
          textStyle={DotpictTextStyle.BOLD20}
          colorStyle={DotpictColorStyle.BASE}
        />
        <Spacer height={32} />
        <Thread isVisibleReply={false} work={bindModel.work} thread={bindModel.parentThread} />
        <Spacer height={16} />
        {bindModel.threads.map((thread) => (
          <>
            <ChildThread work={bindModel.work} thread={thread} />
            <Spacer height={24} />
          </>
        ))}
        {bindModel.isLoading && <Loading />}
        <Spacer height={8} />
        <ThreadInput
          workId={bindModel.parentThread.workId}
          parentThreadId={bindModel.parentThread.id}
        />
      </Column>
    </Column>
  );
};

export default Replies;
