import { createUserWithEmailAndPassword } from 'firebase/auth';
import { css } from '@emotion/react';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import ButtonComponent from '@/app/component/ButtonComponent';
import InputComponent from '@/app/component/InputComponent';
import { APIContext, GlobalContext, LayoutContext, MeContext } from '@/app/component/RootComponent';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import Text from '@/app/component/Text';
import { dotpictFirebaseAuth } from '@/app/library/dotpictFirebaseAuth';
import { ErrorResponseType } from '@/app/model/responsetype/ErrorResponseType';
import CenterVerticalContainerStyle from '@/app/style/CenterVerticalContainerStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import TranslationKeys from '@/app/translation/TranslationKeys';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import ReCAPTCHA from 'react-google-recaptcha';
import { DotpictAction } from '@/app/reducer/reducer';

const SignUpComponent = () => {
  const { dispatch } = useContext(GlobalContext);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [recaptcha, setRecaptcha] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const meState = useContext(MeContext);
  const { layoutParams } = useContext(LayoutContext);
  const navigator = useNavigate();
  const intl = useIntl();
  const { client } = useContext(APIContext);

  const contentContainerStyle = css(CenterVerticalContainerStyle, {
    width: 400,
    background: DotpictColorStyle.WHITE,
  });

  const handleChangeRecaptcha = (v: string | null) => {
    setRecaptcha(v);
  };

  const handleSignUpButtonClick = async () => {
    if (recaptcha === null || recaptcha === '') {
      setErrorMessage('Please check reCAPTCHA');
      return;
    }
    setErrorMessage('');

    const credential = await createUserWithEmailAndPassword(
      dotpictFirebaseAuth,
      email,
      password,
    ).then();
    const token = await credential.user.getIdToken();

    // NOTE: もしAPIサーバが死んでいた場合はFirebaseとの不整合が生じる可能性がある。レアケースだと思うのでそんな対応はしない
    const user = await client.createUsers(token, recaptcha).catch((error: ErrorResponseType) => {
      setErrorMessage(error.message);
    });
    if (!user) {
      return;
    }
    const action: DotpictAction = {
      type: 'UPDATE_ME_STATE',
      payload: { meState: { isLoggedIn: true, token, user } },
    };
    dispatch(action);
  };

  useEffect(() => {
    if (meState.user.id <= 0) return;
    navigator('/');
  }, [meState]);

  return (
    <div css={CenterVerticalContainerStyle}>
      <SquareSpaceComponent size={layoutParams.contentTopSpace} />
      <div css={contentContainerStyle}>
        <SquareSpaceComponent size={32} />
        <InputComponent
          width={320}
          height={48}
          placeHolder={intl.formatMessage({ id: TranslationKeys.Email })}
          isHidden={false}
          onTextChange={(text: string) => setEmail(text)}
        />
        <SquareSpaceComponent size={20} />
        <InputComponent
          width={320}
          height={48}
          placeHolder={intl.formatMessage({ id: TranslationKeys.SignUpPassword })}
          isHidden
          onTextChange={(text: string) => setPassword(text)}
        />
        {errorMessage !== '' && (
          <>
            <SquareSpaceComponent size={8} />
            <Text
              text={errorMessage}
              textStyle={DotpictTextStyle.BOLD12}
              colorStyle={DotpictColorStyle.ERROR}
            />
          </>
        )}
        <Spacer height={32} />
        <ReCAPTCHA
          sitekey="6LezqT4cAAAAANsqSlBA7k37ZhLQCIzv0BWqwA9s"
          onChange={(v) => handleChangeRecaptcha(v)}
        />
        <SquareSpaceComponent size={32} />
        <ButtonComponent
          width={320}
          height={48}
          text={intl.formatMessage({ id: TranslationKeys.SignUpSubmit })}
          isFilled
          handleClick={handleSignUpButtonClick}
        />
        <SquareSpaceComponent size={8} />
        <ButtonComponent
          width={320}
          height={48}
          text={intl.formatMessage({ id: TranslationKeys.AlreadyHaveAccount })}
          isFilled={false}
          isBorder={false}
          handleClick={() => navigator('/login')}
        />
        <SquareSpaceComponent size={32} />
      </div>
    </div>
  );
};

export default SignUpComponent;
