import React, { useContext, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  CircularProgress,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import HorizontalContainerStyle from '@/app/style/HorizontalContainerStyle';
import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import { APIContext, LayoutContext, MeContext } from '@/app/component/RootComponent';
import SearchIcon from '@/app/image/search.svg';
import { dotpictFirebaseAuth } from '@/app/library/dotpictFirebaseAuth';
import PixelArtComponent from '@/app/component/PixelArtComponent';
import VerticalContainerStyle from '@/app/style/VerticalContainerStyle';
import TranslationKeys from '@/app/translation/TranslationKeys';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import { NotificationType } from '@/app/model/NotificationType';
import { Column, HorizontalGravity, Row } from '@/app/component/base/layout/Layout';
import Notification from '@/app/component/part/notification/Notification';
import useTranslator from '@/app/hooks/useTranslator';
import { styled } from '@material-ui/core/styles';

const itemsCenterHorizontalContainerStyle = css(HorizontalContainerStyle, {
  alignItems: 'center',
});

const notificationWidth = 280;
const inputWidth = 240;
const inputStartEndSpace = 12;
const inputBetweenImageAndTextFieldSpace = 8;

const inputStyle = css({
  border: 'none',
  outline: 'none',
  background: 'transparent',
  fontSize: '13px',
  width: inputWidth - inputStartEndSpace * 2 - inputBetweenImageAndTextFieldSpace,
});

const inputContainerStyle = css(HorizontalContainerStyle, {
  width: inputWidth,
  height: 36,
  background: DotpictColorStyle.WHITE_GRAY,
  alignItems: 'center',
});

const NoPaddingButton = styled(Button)({
  display: 'inline-block',
  padding: 0,
  minHeight: 0,
  minWidth: 48,
});

const HeaderComponent = () => {
  const meState = useContext(MeContext);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const [isVisibleMoreNotifications, setVisibleMoreNotifications] = useState<boolean>(false);
  const [isLoadingNotifications, setLoadingNotifications] = useState<boolean>(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [openMobileSearch, setOpenMobileSearch] = useState(false);
  const { layoutParams } = useContext(LayoutContext);
  const navigator = useNavigate();
  const [renderHeader, setRenderHeader] = useState(true);
  const location = useLocation();
  const translator = useTranslator();
  const menuAnchorRef = React.useRef(null);
  const notificationAnchorRef = React.useRef(null);
  const { client } = useContext(APIContext);

  const contentRootStyle = css(HorizontalContainerStyle, {
    height: 80,
    width: layoutParams.fullWidth,
    justifyContent: 'space-between',
    alignItems: 'center',
  });

  const mobileContentRootStyle = css(HorizontalContainerStyle, {
    height: 44,
    width: layoutParams.fullWidth,
    justifyContent: 'space-between',
    alignItems: 'center',
  });

  const mobileHeaderStartStyle = css(HorizontalContainerStyle, {
    width: 50,
    justifyContent: 'flex-start',
  });

  const mobileHeaderEndStyle = css(HorizontalContainerStyle, {
    width: 50,
    justifyContent: 'flex-end',
  });

  const mobileButtonStyle = {
    maxWidth: 56,
    maxHeight: 44,
    minWidth: 56,
    minHeight: 44,
  };

  const notificationImageStyle = css({
    width: 30,
    height: 30,
  });

  const handleClosePopper = () => {
    setOpenMenu(false);
    setOpenNotifications(false);
  };

  const handleClickNotification = (notification: NotificationType) => {
    if (notification.url) {
      navigator(notification.url.replace('https://dotpict.net', ''));
      handleClosePopper();
    }
  };

  const handleClickMoreNotifications = () => {
    navigator('/notifications');
    handleClosePopper();
  };

  const handleClickProfileImage = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleClickNotifications = async () => {
    if (openNotifications) {
      setOpenNotifications((prevOpen) => !prevOpen);
      return;
    }
    setOpenNotifications((prevOpen) => !prevOpen);
    setVisibleMoreNotifications(false);
    setNotifications([]);
    setLoadingNotifications(true);
    const nextUrl = `${client.getBaseUrl}/me/notifications`;
    const notificationResponse = await client.fetchNotifications(meState.token, nextUrl);
    setVisibleMoreNotifications(notificationResponse.notifications.length > 3);
    setNotifications(notificationResponse.notifications.slice(0, 3));
    setLoadingNotifications(false);
  };

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'UA-55893133-2', {
        page_path: location.pathname,
      });
    }
    const params = new URLSearchParams(location.search);
    setRenderHeader(params.get('noHeader') !== 'true');
  }, [location]);

  const handlePostWork = () => {
    navigator('/upload');
    handleClosePopper();
  };

  const handleClickDraw = () => {
    navigator('/canvases');
    handleClosePopper();
  };

  const handleMyPage = () => {
    navigator('/me/postedWorks');
    handleClosePopper();
  };

  const handleRequestBox = () => {
    navigator('/me/requestBox');
    handleClosePopper();
  };

  const handleLogout = () => {
    const logout = async () => {
      await dotpictFirebaseAuth.signOut();
    };
    logout();
    handleClosePopper();
  };

  function handleListKeyDown(event: any) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenMenu(false);
    }
  }

  const handleSearchKeyPress = (event: any) => {
    const query = event.target.value;
    // 13 => enterkeyのwhich
    if (event.which === 13 && query !== '') {
      navigator(`/search/works/title/${query}`);
      setOpenMobileSearch(false);
    }
  };

  const handleMobileSearchClick = () => {
    setOpenMobileSearch(true);
  };

  const handleMobileSearchCancel = () => {
    setOpenMobileSearch(false);
  };

  const renderLogoComponent = () => (
    <Link to="/">
      <img
        src="https://storage.googleapis.com/dotpict-images/web/logo.svg"
        alt="dotpict"
        width={108}
        height={20}
      />
    </Link>
  );

  const renderMenu = () => (
    <Popper
      open={openMenu}
      anchorEl={menuAnchorRef.current}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...TransitionProps}
          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClosePopper}>
              <MenuList
                autoFocusItem={openMenu}
                id="menu-list-grow"
                onKeyDown={(e) => handleListKeyDown(e)}
              >
                <MenuItem onClick={handleMyPage}>{translator(TranslationKeys.MyPage)}</MenuItem>
                <MenuItem onClick={handleRequestBox}>
                  {translator(TranslationKeys.RequestBox)}
                </MenuItem>
                <MenuItem onClick={handleLogout}>{translator(TranslationKeys.Logout)}</MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );

  const renderNotifications = () => (
    <Popper
      style={{ width: notificationWidth }}
      open={openNotifications}
      anchorEl={notificationAnchorRef.current}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...TransitionProps}
          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
        >
          <Paper>
            <ClickAwayListener onClickAway={() => handleClosePopper()}>
              <MenuList
                autoFocusItem={openNotifications}
                id="menu-list-grow"
                onKeyDown={(e) => handleListKeyDown(e)}
              >
                {notifications.map((notification) => (
                  <MenuItem
                    key={notification.id}
                    style={{ padding: 0 }}
                    onClick={() => handleClickNotification(notification)}
                  >
                    <Notification notification={notification} />
                  </MenuItem>
                ))}
                {isVisibleMoreNotifications && (
                  <MenuItem
                    key={translator(TranslationKeys.LoadMore)}
                    onClick={() => handleClickMoreNotifications()}
                  >
                    <Column width="100%" horizontalGravity={HorizontalGravity.center}>
                      <Text
                        text={translator(TranslationKeys.LoadMore)}
                        textStyle={DotpictTextStyle.BOLD14}
                        colorStyle={DotpictColorStyle.PRIMARY}
                      />
                    </Column>
                  </MenuItem>
                )}
                {isLoadingNotifications && (
                  <Column>
                    <Row horizontalGravity={HorizontalGravity.center}>
                      <Spacer height={12} />
                      <CircularProgress />
                      <Spacer height={12} />
                    </Row>
                  </Column>
                )}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );

  const renderSearchComponent = () => (
    <div css={inputContainerStyle}>
      <SquareSpaceComponent size={inputStartEndSpace} />
      <PixelArtComponent alt="search" src={SearchIcon} width={16} height={16} />
      <SquareSpaceComponent size={inputBetweenImageAndTextFieldSpace} />
      <input
        css={inputStyle}
        type="text"
        placeholder={translator(TranslationKeys.Search)}
        onKeyPress={handleSearchKeyPress}
      />
      <SquareSpaceComponent size={inputStartEndSpace} />
    </div>
  );

  const renderSignUpComponent = (textStyle: DotpictTextStyle) => (
    <Link to="/signup">
      <Row>
        <SquareSpaceComponent size={8} />
        <Text
          text={translator(TranslationKeys.SignUp)}
          textStyle={textStyle}
          colorStyle={DotpictColorStyle.PRIMARY}
        />
        <SquareSpaceComponent size={8} />
      </Row>
    </Link>
  );

  const renderLoginComponent = () => (
    <Link to="/login">
      <Row>
        <SquareSpaceComponent size={8} />
        <Text
          text={translator(TranslationKeys.Login)}
          textStyle={DotpictTextStyle.BOLD16}
          colorStyle={DotpictColorStyle.PRIMARY}
        />
        <SquareSpaceComponent size={8} />
      </Row>
    </Link>
  );

  const renderHeaderComponent = () => (
    <div css={contentRootStyle}>
      <div css={itemsCenterHorizontalContainerStyle}>
        {renderLogoComponent()}
        <SquareSpaceComponent size={96} />
        {renderSearchComponent()}
      </div>
      <div css={itemsCenterHorizontalContainerStyle}>
        {meState.isLoggedIn ? (
          <>
            <NoPaddingButton onClick={() => handlePostWork()}>
              <Column horizontalGravity={HorizontalGravity.center} innerPadding={6}>
                <img
                  css={notificationImageStyle}
                  alt="upload"
                  src="https://storage.googleapis.com/dotpict-images/web/ic_upload.svg"
                />
                <Text
                  text={translator(TranslationKeys.PostWork)}
                  textStyle={DotpictTextStyle.BOLD12}
                  colorStyle={DotpictColorStyle.PRIMARY}
                />
              </Column>
            </NoPaddingButton>
            <Spacer width={24} />
            <NoPaddingButton onClick={() => handleClickDraw()}>
              <Column horizontalGravity={HorizontalGravity.center} innerPadding={6}>
                <img
                  css={notificationImageStyle}
                  alt="draw"
                  src="https://storage.googleapis.com/dotpict-images/web/ic_draw.svg"
                />
                <Text
                  text={translator(TranslationKeys.Draw)}
                  textStyle={DotpictTextStyle.BOLD12}
                  colorStyle={DotpictColorStyle.PRIMARY}
                />
              </Column>
            </NoPaddingButton>
            <Spacer width={24} />
            <NoPaddingButton
              ref={notificationAnchorRef}
              aria-controls={openNotifications ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleClickNotifications}
            >
              <Column horizontalGravity={HorizontalGravity.center} innerPadding={6}>
                <img
                  css={notificationImageStyle}
                  alt="notification"
                  src="https://storage.googleapis.com/dotpict-images/web/ic_notice_active.svg"
                />
                <Text
                  text={translator(TranslationKeys.Notice)}
                  textStyle={DotpictTextStyle.BOLD12}
                  colorStyle={DotpictColorStyle.PRIMARY}
                />
              </Column>
            </NoPaddingButton>
            <Spacer width={24} />
            <Button
              style={{ textTransform: 'none' }}
              ref={menuAnchorRef}
              aria-controls={openMenu ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleClickProfileImage}
            >
              <PixelArtComponent
                alt={meState.user.name}
                src={meState.user.profileImageUrl}
                width={48}
                height={48}
              />
            </Button>
          </>
        ) : (
          <>
            {renderLoginComponent()}
            {renderSignUpComponent(DotpictTextStyle.BOLD16)}
          </>
        )}
      </div>
    </div>
  );

  const renderMobileHeaderComponent = () => (
    <div css={VerticalContainerStyle}>
      <div css={mobileContentRootStyle}>
        <div css={mobileHeaderStartStyle}>
          <Button style={mobileButtonStyle} onClick={handleMobileSearchClick}>
            <PixelArtComponent alt="search" src={SearchIcon} width={16} height={16} />
          </Button>
        </div>
        {renderLogoComponent()}
        <div css={mobileHeaderEndStyle}>
          {meState.isLoggedIn ? (
            <Button
              style={mobileButtonStyle}
              ref={menuAnchorRef}
              aria-controls={openMenu ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleClickProfileImage}
            >
              <PixelArtComponent
                alt="profile_image"
                src={meState.user.profileImageUrl}
                width={24}
                height={24}
              />
            </Button>
          ) : (
            renderSignUpComponent(DotpictTextStyle.BOLD12)
          )}
        </div>
      </div>
      {openMobileSearch && (
        <div css={HorizontalContainerStyle}>
          <SquareSpaceComponent size={16} />
          <div css={VerticalContainerStyle}>
            <SquareSpaceComponent size={16} />
            <Text
              text={translator(TranslationKeys.Search)}
              textStyle={DotpictTextStyle.BOLD16}
              colorStyle={DotpictColorStyle.BASE}
            />
            <SquareSpaceComponent size={16} />
            <div css={itemsCenterHorizontalContainerStyle}>
              {renderSearchComponent()}
              <SquareSpaceComponent size={16} />
              <Button onClick={handleMobileSearchCancel}>
                <Text
                  text={translator(TranslationKeys.Cancel)}
                  textStyle={DotpictTextStyle.BOLD12}
                  colorStyle={DotpictColorStyle.PRIMARY}
                />
              </Button>
            </div>
            <SquareSpaceComponent size={16} />
          </div>
        </div>
      )}
    </div>
  );

  if (!renderHeader) {
    return null;
  }

  return (
    <Row
      width="100%"
      horizontalGravity={HorizontalGravity.center}
      background={DotpictColorStyle.WHITE}
    >
      {layoutParams.isMobile ? renderMobileHeaderComponent() : renderHeaderComponent()}
      {renderMenu()}
      {renderNotifications()}
    </Row>
  );
};

export default HeaderComponent;
