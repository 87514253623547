import WorkDetail from '@/app/component/page/work/detail/WorkDetail';
import { useContext, useMemo } from 'react';
import { APIContext } from '@/app/component/RootComponent';
import { Dependencies, WorkDetailContext } from '@/app/component/page/work/detail/useWorkDetail';
import { PagingType } from '@/app/model/PagingType';
import { useParams } from 'react-router-dom';

const WorkDetailPage = () => {
  const { client } = useContext(APIContext);
  const { workId } = useParams();

  const dependencies = useMemo((): Dependencies => {
    return {
      getWorkDetailService: (workId: number) => client.fetchWorkDetail(workId),
      getThreadsService: (nextUrl: string) => client.fetchThreads(nextUrl),
      getUserWorksService: (paging: PagingType) => client.fetchUserWorks(paging),
      getUserEventDetailService: (userEventId: string) => client.fetchUserEventDetail(userEventId),
    };
  }, [client]);

  return (
    <WorkDetailContext.Provider value={dependencies}>
      <WorkDetail workId={Number(workId)} />
    </WorkDetailContext.Provider>
  );
};

export default WorkDetailPage;
