import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import Works from '@/app/component/Works';
import DefaultGridStyle from '@/app/style/DefaultGridStyle';
import { APIContext, GlobalContext, MeContext } from '@/app/component/RootComponent';
import { defaultDummyItemCount } from '@/app/common/Constant';
import shouldFetchNext from '@/app/common/Common';
import { defaultMyPageState } from '@/app/state/MyPageState';
import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import TranslationKeys from '@/app/translation/TranslationKeys';
import { DotpictAction } from '@/app/reducer/reducer';
import { Column, HorizontalGravity, Row } from '@/app/component/base/layout/Layout';
import MyPageHeader from '@/app/component/page/mypage/MyPageHeader';
import { WorkType } from '@/app/model/WorkType';

const gridImageSize = 192;

export type MyPageProps = {
  type: string;
};

const MyPage = ({ type }: MyPageProps) => {
  const { globalState, dispatch } = useContext(GlobalContext);
  const meState = useContext(MeContext);
  const intl = useIntl();
  const { client } = useContext(APIContext);

  const [loading, setLoading] = useState(false);

  const fetchData = async (currentWorks: WorkType[], nextUrl: string) => {
    setLoading(true);
    const requestedUserId = meState.user.id;
    const response = await client.fetchWorks(nextUrl);
    const works = currentWorks.concat(response.works);
    const action: DotpictAction = {
      type: 'UPDATE_MY_PAGE_STATE',
      payload: {
        myPageState: {
          ...globalState.myPageState,
          type,
          requestedUserId,
          works,
          nextUrl: response.nextUrl,
        },
      },
    };
    dispatch(action);
    setLoading(false);
    if (shouldFetchNext(response.nextUrl)) {
      fetchData(works, response.nextUrl);
    }
  };

  const handleScroll = () => {
    if (!loading && shouldFetchNext(globalState.myPageState.nextUrl)) {
      fetchData(globalState.myPageState.works, globalState.myPageState.nextUrl);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    if (meState.user.id <= 0) {
      return;
    }
    if (type === globalState.myPageState.type && globalState.myPageState.works.length !== 0) {
      return;
    }

    window.scrollTo(0, 0);
    const resetAction: DotpictAction = {
      type: 'UPDATE_MY_PAGE_STATE',
      payload: {
        myPageState: {
          ...defaultMyPageState,
          type,
        },
      },
    };
    dispatch(resetAction);
    const nextUrl =
      type === 'postedWorks'
        ? `${client.getBaseUrl}/users/${meState.user.id}/works`
        : `${client.getBaseUrl}/users/${meState.user.id}/like_works`;
    fetchData([], nextUrl);
  }, [type, meState.user.id]);

  return (
    <>
      <Column horizontalGravity={HorizontalGravity.center}>
        <MyPageHeader user={globalState.meState.user} />
        <SquareSpaceComponent size={40} />
        <Row>
          <Link to="/me/postedWorks">
            <Text
              text={intl.formatMessage({ id: TranslationKeys.PostedWorks })}
              textStyle={DotpictTextStyle.BOLD20}
              colorStyle={
                type === 'postedWorks' ? DotpictColorStyle.PRIMARY : DotpictColorStyle.BASE
              }
            />
          </Link>
          <SquareSpaceComponent size={24} />
          <Link to="/me/likedWorks">
            <Text
              text={intl.formatMessage({ id: TranslationKeys.LikedWorks })}
              textStyle={DotpictTextStyle.BOLD20}
              colorStyle={
                type === 'likedWorks' ? DotpictColorStyle.PRIMARY : DotpictColorStyle.BASE
              }
            />
          </Link>
        </Row>
      </Column>
      <SquareSpaceComponent size={32} />
      <Works
        works={globalState.myPageState.works}
        isLoading={loading}
        isVisibleEnd={globalState.myPageState.nextUrl === ''}
        isVisibleAds={false}
        isVisibleLike={type === 'likedWorks'}
        gridStyle={DefaultGridStyle}
        imageSize={gridImageSize}
        dummyItemCount={defaultDummyItemCount}
      />
    </>
  );
};

export const MyPagePostedWorks: React.FC = () => MyPage({ type: 'postedWorks' });
export const MyPageLikedWorks: React.FC = () => MyPage({ type: 'likedWorks' });
