import React, { useEffect } from 'react';
import { TabLayout } from '@/app/component/base/tablayout/TabLayout';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import { useLocation } from 'react-router-dom';
import useTranslator from '@/app/hooks/useTranslator';
import TranslationKeys from '@/app/translation/TranslationKeys';
import { Column, HorizontalGravity, Row } from '@/app/component/base/layout/Layout';
import useLocalStorage from '@/app/customhook/useLocalStorage';
import CompletedRequests from '@/app/component/page/requestbox/CompletedRequests';
import ReceivedRequests from '@/app/component/page/requestbox/ReceivedRequests';
import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import ButtonComponent from '@/app/component/ButtonComponent';
import RequestBoxSettings from '@/app/component/page/requestbox/settings';

const types = ['received', 'completed'];

const RequestBox: React.FC = () => {
  const location = useLocation();
  const translator = useTranslator();
  const [requestType, setRequestType] = useLocalStorage('requestType', 'received');

  const tabItems = [
    translator(TranslationKeys.RequestReceived),
    translator(TranslationKeys.RequestCompleted),
  ];

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const type = params.get('type');
    if (type !== null && types.includes(type)) {
      setRequestType(type);
    }
  }, [location]);

  const handleClickTagItem = (index: number) => {
    if (tabItems[index] === translator(TranslationKeys.RequestCompleted)) {
      setRequestType('completed');
    } else {
      setRequestType('received');
    }
  };

  const renderContent = (type: string) => {
    switch (type) {
      case 'completed':
        return <CompletedRequests />;
      default:
        return <ReceivedRequests />;
    }
  };

  const convTypeToIndex = (type: string) => {
    if (type === 'completed') {
      return 1;
    }
    return 0;
  };

  return (
    <Column>
      <Spacer height={24} />
      <Row horizontalGravity={HorizontalGravity.spaceBetween}>
        <Text
          text={translator(TranslationKeys.RequestBox)}
          textStyle={DotpictTextStyle.BOLD20}
          colorStyle={DotpictColorStyle.BASE}
        />
        <RequestBoxSettings>
          <ButtonComponent
            width={240}
            height={32}
            text={translator(TranslationKeys.RequestBoxSettings)}
            isFilled={false}
            isBorder
          />
        </RequestBoxSettings>
      </Row>
      <Spacer height={24} />
      <TabLayout
        items={tabItems}
        selectedIndex={convTypeToIndex(requestType)}
        onClick={handleClickTagItem}
      />
      <Spacer height={8} />
      {renderContent(requestType)}
    </Column>
  );
};

export default RequestBox;
