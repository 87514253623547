import { useContext } from 'react';
import AdsComponent from '@/app/component/AdsComponent';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import { LayoutContext } from '@/app/component/RootComponent';
import FooterComponent from '@/app/component/FooterComponent';
import DotpictAdsType from '@/app/ads/DotpictAdsType';
import VerticalContainerStyle from '@/app/style/VerticalContainerStyle';

const SideComponent = () => {
  const { layoutParams } = useContext(LayoutContext);

  return (
    <div css={VerticalContainerStyle}>
      <SquareSpaceComponent size={layoutParams.contentTopSpace} />
      <AdsComponent adsType={DotpictAdsType.RECTANGLE_LARGE} />
      <SquareSpaceComponent size={24} />
      <FooterComponent />
    </div>
  );
};

export default SideComponent;
