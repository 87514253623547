import { WorkType } from '@/app/model/WorkType';
import TestUserSraim from '@/app/test/user/TestUserSraim';

const TestWorkJuneBride: WorkType = {
  id: 3903193,
  title: 'June bride',
  caption:
    '公式企画のドット絵描かせていただきました👰素敵な花嫁/花婿さんを描いてみてください✨\\nI made dotpict official event pixelart! Thank you.',
  tags: ['JuneBride'],
  likeCount: 372,
  thumbnailImageUrl:
    'https://img.dotpicko.net/work/2022/06/01/20/31/2a4ac5550ac0bfa186ce4cbaee9f88f6c978ca7c21c3436ba6a89c4fd6203a5b.png',
  imageUrl:
    'https://img.dotpicko.net/work/2022/06/01/20/31/2a4ac5550ac0bfa186ce4cbaee9f88f6c978ca7c21c3436ba6a89c4fd6203a5b.png',
  ogpImageUrl:
    'https://img.dotpicko.net/ogp_work/2022/06/01/20/31/2a4ac5550ac0bfa186ce4cbaee9f88f6c978ca7c21c3436ba6a89c4fd6203a5b.png"',
  width: 64,
  height: 64,
  user: TestUserSraim,
  isLike: false,
  createdAt: '2022/06/01',
  allowThread: true,
  isAnimation: false,
  userEventId: 0,
  officialEventId: 58,
  shareUrl: 'https://dotpict.net/works/3903193',
  colorCodes: [],
  cropRect: null,
};

export default TestWorkJuneBride;
