import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import BadgeComponent from '@/app/component/BadgeComponent';

interface IProps {
  text: string;
  color: string;
}

const LargeBadgeComponent = (props: IProps) => {
  const { text, color } = props;

  return (
    <BadgeComponent
      text={text}
      color={color}
      width={200}
      height={36}
      textStyle={DotpictTextStyle.BOLD16}
    />
  );
};

export default LargeBadgeComponent;
