import PageControlSelectedIcon from '@/app/image/page_control_selected.svg';
import PageControlUnselectedIcon from '@/app/image/page_control_unselected.svg';
import { Spacer } from '@/app/component/base/spacer/Spacer';
import { Row } from '@/app/component/base/layout/Layout';

export type PageControlProps = {
  selectedIndex: number;
  numberOfItems: number;
};

const PageControl = (props: PageControlProps) => {
  const { selectedIndex, numberOfItems } = props;

  return (
    <Row>
      {[...new Array(numberOfItems)].map((_, index) => (
        <>
          <Spacer width={4} />
          <img
            src={index === selectedIndex ? PageControlSelectedIcon : PageControlUnselectedIcon}
            alt="page_control_icon"
          />
          <Spacer width={4} />
        </>
      ))}
    </Row>
  );
};

export default PageControl;
