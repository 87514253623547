import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MeContext } from '@/app/component/RootComponent';
import { LoginProps } from '@/app/component/page/login/Login';
import useTranslator from '@/app/hooks/useTranslator';
import { LoginService } from '@/app/service/LoginService/LoginService';
import dummyLoginService from '@/app/service/LoginService/dummyLoginService';

export type Dependencies = {
  loginService: LoginService;
};

export const LoginContext = createContext<Dependencies>({
  loginService: dummyLoginService,
});

const useLogin = (): LoginProps => {
  const [mailAddress, setMailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const meState = useContext(MeContext);
  const navigator = useNavigate();
  const translator = useTranslator();
  const { loginService } = useContext(LoginContext);

  useEffect(() => {
    if (meState.user.id <= 0) return;
    navigator('/');
  }, [meState]);

  const handleLoginButtonClick = () => {
    if (mailAddress === '' || password === '') return;
    setErrorMessage('');
    loginService(mailAddress, password).catch((error) => {
      setErrorMessage(translator(error.message));
    });
  };

  const handleMailAddress = (newMailAddress: string) => {
    setMailAddress(newMailAddress);
  };

  const handlePassword = (newPassword: string) => {
    setPassword(newPassword);
  };

  return {
    mailAddress,
    password,
    errorMessage,
    handleMailAddress,
    handlePassword,
    handleLoginButtonClick,
  };
};

export default useLogin;
