import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import HorizontalContainerStyle from '@/app/style/HorizontalContainerStyle';
import { useLocation } from 'react-router-dom';
import Text from '@/app/component/Text';
import DotpictTextStyle from '@/app/style/DotpictTextStyle';
import DotpictColorStyle from '@/app/style/DotpictColorStyle';
import SquareSpaceComponent from '@/app/component/SquareSpaceComponent';
import CenterHorizontalContainerStyle from '@/app/style/CenterHorizontalContainerStyle';

const PortfolioHeaderComponent = () => {
  const [renderHeader, setRenderHeader] = useState(true);
  const location = useLocation();

  const contentRootStyle = css(HorizontalContainerStyle, {
    height: 80,
    width: '100%',
    justifyContent: 'center',
    background: '#FFFFFF',
  });

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'UA-55893133-2', {
        page_path: location.pathname,
      });
    }
    const params = new URLSearchParams(location.search);
    setRenderHeader(params.get('noHeader') !== 'true');
  }, [location]);

  const renderLogoComponent = () => (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <a href="https://dotpict.net">
      <img src="https://storage.googleapis.com/dotpict-images/web/logo.svg" alt="dotpict" />
    </a>
  );

  if (!renderHeader) {
    return null;
  }

  return (
    <div css={contentRootStyle}>
      <div css={CenterHorizontalContainerStyle}>
        <Text
          text="powered by"
          textStyle={DotpictTextStyle.REGULAR13}
          colorStyle={DotpictColorStyle.BASE}
        />
        <SquareSpaceComponent size={16} />
        {renderLogoComponent()}
      </div>
    </div>
  );
};

export default PortfolioHeaderComponent;
